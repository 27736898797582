import moment from "moment";
import React, { Component } from "react";
import PrintHeader from "../PrintHeader/PrintHeader";
var converter = require("number-to-words");

export class PaymentEntryTransactionDetailPrint extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <PrintHeader />
        </div>
        <div
          className="row m-0 p-0"
          style={{ border: "5px solid darkgrey", height: "85%" }}
        >
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>
                {this.props?.PrintName} - {localStorage.getItem("ProjectName")}{" "}
              </u>
            </h4>
            <h5 className="text-right pt-3 ">
              <div className="row">
                <div className="col-2 text-right">
                  <b>Voucher # : </b>
                </div>
                <div className="col-4 text-left">
                  {this.props?.printData?.length > 0 &&
                    this.props?.printData[0]?.VoucherId}
                </div>
              </div>
              <div className="row">
                <div className="col-2 text-right">
                  <b>Voucher Date : </b>
                </div>
                <div className="col-2 text-left">
                  {this.props?.printData?.length > 0 &&
                    moment(
                      this.props?.printData[0]?.EntryDate?.slice(0, 10)
                    ).format("DD-MMM-YYYY")}
                </div>
                <div className="col-4"></div>
                <div className="col-2 text-right">
                  <b>Print Date: </b>
                </div>

                <div className="col-2 text-center">
                  {moment().format("DD-MMM-YYYY")}
                </div>
              </div>
            </h5>

            <div className="mt-3">
              <div class="table-responsive-print col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Account</th>
                      <th>Narration</th>
                      <th>Debit</th>
                      <th>Credit</th>
                    </tr>
                  </thead>
                  {this.props?.printData !== undefined
                    ? this.props?.printData.map((x, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{x?.Account?.AccountTitle}</td>
                          <td>{x?.Narration}</td>
                          <td>
                            {x?.TransactionType === "DEBIT"
                              ? x?.Amount.toLocaleString()
                              : null}
                          </td>

                          <td>
                            {x?.TransactionType === "CREDIT"
                              ? x?.Amount.toLocaleString()
                              : null}
                          </td>
                        </tr>
                      ))
                    : null}
                  <tr>
                    <td></td>
                    <td></td>

                    <td>
                      {" "}
                      <label className="input_label m-0">Total:</label>
                    </td>
                    <td>{this.props?.creditTotal?.toLocaleString()}</td>
                    <td>{this.props?.debitTotal?.toLocaleString()}</td>
                    <td></td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="col-12 text-left mt-2">
              Total Amount:{" "}
              {this.props?.total !== undefined
                ? parseFloat(this.props?.total).toLocaleString()
                : null}
            </div>

            <div className="col-12 text-left mt-2">
              Amount in words:{" "}
              {this.props.total !== undefined
                ? converter.toWords(parseFloat(this.props.total))
                : null}
            </div>
            <div className="signature">
              <h5 className=" pt-3 ">
                <div className="row">
                  <div className="col-2">
                    <b>Prepared By: </b>
                  </div>
                  <div className="col-2">
                    <>----------------</>
                  </div>
                  <div className="col-2">
                    <b>Checked By: </b>
                  </div>
                  <div className="col-2">
                    <>----------------</>
                  </div>
                  <div className="col-2">
                    <b>Approved By: </b>
                  </div>

                  <div className="col-2">
                    <>----------------</>
                  </div>
                </div>
              </h5>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
