import React, { useState, useEffect } from "react";
import "../../App.css";
import profilePic from "../../assets/profilelogo.jpg";

// import { BsBell } from "react-icons/bs";
// import { BsThreeDotsVertical } from "react-icons/bs";
import bellPic from "../../assets/bell.svg";
import dotpic from "../../assets/Group1.svg";
import dashboardPic from "../../assets/dashboard.svg";
import brokerPic from "../../assets/broker.svg";
import { useHistory } from "react-router-dom";
import availablePic from "../../assets/available.svg";

import leadPic from "../../assets/lead.svg";
import taskPic from "../../assets/tasks.svg";
// import todoPic from "../../assets/todolist.svg";
import personPic from "../../assets/person.svg";
import reportPic from "../../assets/report.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Link } from "react-router-dom";
// import component of crm
// import  from "../Accounts/accpayablepages/journalentry";
// AccPayable
import MainAddClientComp from "./Clientpages/addclient";
import CrmDashboard from "./DashboardPages/crmdashboard";
import MainLeadComp from "./LeadPages/leadaddpage";
import MainTaskComp from "./TaskPages/addtask";
import MainMeetingComp from "./TaskPages/addmeeting";
import MainCallComp from "./TaskPages/addcall";
import MainHierarchyComp from "./Teams/hierarchy";
import MainTeamCom from "./Teams/addteams";
import MainMemberCom from "./Teams/addmembers";
import MainAddRoleCom from "../Accounts/roles/roles";
import { useDispatch, useSelector } from "react-redux";
import { getallRoles } from "../actions/getroles";
import Changeform from "../Accounts/changeform/changeform";
import { monthlyAlert } from "../ContextApi/MonthlyAlert";
import { useContext } from "react";
import AltertModal from "../AlertModal/AltertModal";
import axios from "axios";
import moment from "moment";
import ChangeInReqMain from "../Accounts/ChangeInReqMain2/ChangeInReqMain";
import { Users } from "../Users/Users/Users";
import BookingUnits from "../Inventory/Availability/BookedUnits";
import AvailabilityComp from "../Inventory/Availability/Availability";

function SideButton(props) {
  return (
    <>
      <button
        onClick={() => {
          props.onClick();
        }}
        className={
          props.active
            ? "grey side_button_hover row  sideText sideButton  mt-4"
            : "row side_button_hover sideText sideButton  mt-4"
        }
      >
        {props.icon}
        <p className=" ml-3 sideText green_text toggleButton">{props.text}</p>
      </button>
    </>
  );
}
function SideSubButton(props) {
  return (
    <>
      <button
        onClick={() => {
          props.onClick();
        }}
        className={
          props.active ? "sub_button_style_underline" : "sub_button_style "
        }
      >
        {props.icon}
        <span className=" sideText   ">{props.text}</span>
      </button>
      <br></br>
    </>
  );
}
const CrmMainPage = () => {
  const history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      localStorage.clear();
      sessionStorage.clear();
      history.push({ pathname: "/" });
    }
  }, []);
  // toggle state start
  const { changeState } = useContext(monthlyAlert);
  const [showAlert, setshowAlert] = useState(false);
  useEffect(() => {
    if (sessionStorage.getItem("isPaid") === "false") {
      changeState(true);
      setshowAlert(true);
    } else {
      changeState(false);
      setshowAlert(false);
    }
  }, []);
  // useEffect(() => {
  //   axios
  //     .get("https://sheetdb.io/api/v1/dskt971mukoor")
  //     .then((res) => {
  //       if (
  //         res?.data?.length > 0 &&
  //         res.data[0]?.IsPaid === "FALSE" &&
  //         Number(moment().format("DD")) > Number(res.data[0]?.Date)
  //       ) {
  //         sessionStorage.setItem("isPaid", false);
  //         changeState(true);
  //         setshowAlert(true);
  //       } else {
  //         sessionStorage.setItem("isPaid", true);

  //         changeState(false);
  //         setshowAlert(false);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);
  const rolesdata = useSelector((state) => state.GetAllRole);
  let dispatch = useDispatch();
  const [reload, setreload] = useState(true);
  const [toggle, setToggle] = useState(true);
  const toggleClose = () => {
    let list = document.getElementsByClassName("toggleButton");
    if (toggle === true) {
      setToggle(false);
      for (let i = 0; i < list.length; i++) {
        list[i].style.display = "none";
      }
      document.getElementById("tog").removeAttribute("style");
    } else {
      setToggle(true);
      for (let i = 0; i < list.length; i++) {
        list[i].style.display = "block";
      }
      document.getElementById("tog").setAttribute("style", "width: 15em;");
    }
  };
  // toggle state end

  //  sub buttons
  const Dashboard = [
    {
      component: <CrmDashboard></CrmDashboard>,
      rolename: "CRM Dashboard",
    },
  ];

  const Tasks = [
    {
      text: "Meeting",
      component: <MainMeetingComp></MainMeetingComp>,
      rolename: "Meeting",
    },
    {
      text: "Call",
      component: <MainCallComp></MainCallComp>,
      rolename: "Call",
    },
    {
      text: "Other Tasks",
      component: <MainTaskComp></MainTaskComp>,
      rolename: "Other Tasks",
    },
  ];
  // const Todolist = [
  //     {

  //         component: <CrmDashboard></CrmDashboard>,

  //     },

  // ];
  // const Brokers = [
  //     {

  //         component: <CrmDashboard></CrmDashboard>,

  //     },

  // ];
  const Teams = [
    {
      text: "Teams",
      component: <MainTeamCom></MainTeamCom>,
      rolename: "Add Team",
    },
    {
      text: "Member",
      component: <MainMemberCom></MainMemberCom>,
      rolename: "Member",
    },
    {
      text: "Hierarchy",
      component: <MainHierarchyComp></MainHierarchyComp>,
      rolename: "Hierarchy",
    },
  ];

  const Availability = [
    {
      text: "Availability",
      component: <AvailabilityComp></AvailabilityComp>,

      rolename: "Availability",
    },
    {
      text: "Booked Units",
      component: <BookingUnits></BookingUnits>,
      rolename: "Booked Units",
    },
  ];
  const Lead = [
    {
      text: "Leads",
      component: <MainLeadComp></MainLeadComp>,
      rolename: "Leads",
    },
    // {
    //     text: "Manage Lead",
    //     component: <CrmDashboard></CrmDashboard>,

    // },
    // {
    //     text: "Lead Summary",
    //     component: <CrmDashboard></CrmDashboard>,

    // }
  ];

  const client = [
    {
      text: "Clients",
      component: <MainAddClientComp></MainAddClientComp>,
      rolename: "Clients",
    },
    // {
    //     text: "Manage Clients",
    //     component: <CrmDashboard></CrmDashboard>,

    // },
    // {
    //     text: "Client Summary",
    //     component: <CrmDashboard></CrmDashboard>,

    // },
  ];
  // reports subbutton
  // const Reports = [
  //     {
  //         text: "Trail Balance",
  //         component: <CrmDashboard></CrmDashboard>,

  //     },
  //     {
  //         text: "Balance Sheet",
  //         component: <CrmDashboard></CrmDashboard>,

  //     },
  //     {
  //         text: "Profit & Loss",
  //         component: <CrmDashboard></CrmDashboard>,

  //     },
  //     {
  //         text: "Income Statment",
  //         component: <CrmDashboard></CrmDashboard>,

  //     },
  //     {
  //         text: "Cash Flow",
  //         component: <CrmDashboard></CrmDashboard>,

  //     },
  //     {
  //         text: "Retained Earning",
  //         component: <CrmDashboard></CrmDashboard>,

  //     },

  // ];
  // subbutton ends

  const [statesubbutton, setsubbutton] = useState("");

  // buttons state start
  const [chosen1, setChosen1] = useState("Dashboard");
  const [viewcomponent, setview] = useState(null);

  // subbutton
  const [subchosen2, setsubchosen2] = useState({
    text: "Dashboard",
    icon: (
      <img src={dashboardPic} alt="Dashboard" className="ml-3 Icondashboard" />
    ),
    subbtn: Dashboard,
    rolename: "CRM Dashboard",
  });
  const [Menu] = useState([
    {
      text: "Dashboard",
      icon: (
        <img
          src={dashboardPic}
          alt="Dashboard"
          className="ml-3 Icondashboard"
        />
      ),
      rolename: "CRM Dashboard",

      subbtn: Dashboard,
    },
    {
      text: "Leads",
      icon: <img src={leadPic} alt="Leads" className="ml-3 Iconpayable" />,
      rolename: "Leads",

      subbtn: Lead,
    },
    {
      text: "Clients",
      icon: <img src={personPic} alt="Clients" className="ml-3 Iconpayable" />,
      rolename: "Clients",

      subbtn: client,
    },
    {
      text: "Tasks",
      icon: <img src={taskPic} alt="Tasks" className="ml-3 Iconpayable" />,
      rolename: "Tasks",

      subbtn: Tasks,
    },
    // {
    //     text: "Todo List",
    //     icon: <img src={todoPic} alt="List" className="ml-3 Iconpayable" />,
    //     subbtn: Todolist
    // },
    {
      text: "Teams",
      icon: <img src={brokerPic} alt="Brokers" className="ml-3 Iconpayable" />,
      rolename: "Teams",

      subbtn: Teams,
    },
    {
      text: "Availability",
      icon: (
        <img
          src={availablePic}
          alt="Availability"
          className="ml-3 Iconpayable"
        />
      ),
      rolename: "Crm Availability",

      subbtn: Availability,
    },
    // {
    //     text: "Reports",
    //     icon: <img src={reportPic} alt="Reports" className="ml-3 Iconpayable" />,

    //     subbtn: Reports
    // },
  ]);
  // buttons state end
  useEffect(() => {
    dispatch(getallRoles());
  }, []);
  useEffect(() => {
    if (rolesdata?.data?.Roles) {
      if (
        rolesdata?.data?.Roles["CRM Dashboard"] ||
        rolesdata?.data?.Roles["Admin"]
      ) {
        setview(<CrmDashboard />);
      }
    }
  }, [rolesdata]);
  return (
    <>
      {/* top name bar */}

      <div className="row">
        {/* toggle button */}

        <div
          className="col-auto p-0 sidebuttonsheight main_btn_style"
          id="tog"
          style={{ width: "13em " }}
        >
          <div
            onClick={() => {
              toggleClose();
            }}
          >
            {" "}
            <img src={dotpic} alt="dots" className="ml-3 Icongroup" />
          </div>
          <div className="line_styling">
            <hr></hr>
          </div>
          <div className=" m_top_custom pr-3 ">
            {/* buttons map function */}
            {Menu.map((btn, index) =>
              (rolesdata?.data?.Roles &&
                rolesdata?.data?.Roles[btn.rolename] !== undefined) ||
              localStorage.getItem("Designation") === "Admin" ? (
                <SideButton
                  key={index}
                  text={btn.text}
                  icon={btn.icon}
                  onClick={() => {
                    if (showAlert) {
                      changeState(true);
                    }

                    setChosen1(btn.text);

                    setsubchosen2(btn);
                    setview(btn.subbtn[0].component);
                    setsubbutton(btn.subbtn[0].text);
                    setreload(!reload);
                  }}
                  active={btn.text === chosen1}
                />
              ) : null
            )}
          </div>
        </div>

        <div className="col">
          <div className="row pt-1">
            <div className="col-auto pt-1">
              <span className="name_heading mt-1 green_text ">
                {localStorage.getItem("UserName")}
              </span>
              <br></br>
              <span className="designation_head green_text ">
                {localStorage.getItem("Designation")}
              </span>
            </div>
            <div className="col text-center pt-1">
              <div className="row pl-md-5 pl-lg-5 ">
                <div className="col-10 nav-space">
                  {rolesdata?.data?.Roles?.Admin &&
                  `${localStorage.getItem("Designation")}` === "Admin" ? (
                    <>
                      {" "}
                      <Link to="/projectSelection">
                        {" "}
                        <button className="top_menu_btn blue_text ">
                          Accounts
                        </button>
                      </Link>
                      <Link to="/inventory">
                        {" "}
                        <button className="top_menu_btn blue_text">
                          Inventory
                        </button>
                      </Link>
                      <Link to="/hr">
                        {" "}
                        <button className="top_menu_btn blue_text">
                          Human Resource
                        </button>
                      </Link>
                      <Link to="/crm">
                        {" "}
                        <button className="top_menu_btn-select ">CRM</button>
                      </Link>
                      <Link to="/assets">
                        {" "}
                        <button className="top_menu_btn blue_text">
                          Assets
                        </button>
                      </Link>{" "}
                    </>
                  ) : null}
                  {rolesdata?.data?.Roles &&
                  rolesdata?.data?.Roles["Accounts"] !== undefined ? (
                    <Link to="/projectSelection">
                      {" "}
                      <button className="top_menu_btn blue_text ">
                        Accounts
                      </button>
                    </Link>
                  ) : null}
                  {rolesdata?.data?.Roles &&
                  rolesdata?.data?.Roles["Inventory"] !== undefined ? (
                    <Link to="/inventory">
                      {" "}
                      <button className="top_menu_btn blue_text">
                        Inventory
                      </button>
                    </Link>
                  ) : null}
                  {rolesdata?.data?.Roles &&
                  rolesdata?.data?.Roles["HR"] !== undefined ? (
                    <Link to="/hr">
                      {" "}
                      <button className="top_menu_btn blue_text">
                        Human Resource
                      </button>
                    </Link>
                  ) : null}
                  {rolesdata?.data?.Roles &&
                  rolesdata?.data?.Roles["CRM"] !== undefined ? (
                    <Link to="/crm">
                      {" "}
                      <button className="top_menu_btn-select">CRM</button>
                    </Link>
                  ) : null}
                  {rolesdata?.data?.Roles &&
                  rolesdata?.data?.Roles["Asset Module"] !== undefined ? (
                    <Link to="/assets">
                      {" "}
                      <button className="top_menu_btn blue_text">Assets</button>
                    </Link>
                  ) : null}
                  {/* <Link to="/account"> <button className="top_menu_btn blue_text ">Accounts</button></Link>
                                    <Link to="/inventory"> <button className="top_menu_btn blue_text">Inventory</button></Link>
                                    <Link to="/hr"> <button className="top_menu_btn blue_text">Human Resource</button></Link>
                                    <Link to="/crm"> <button className="top_menu_btn-select ">CRM</button></Link>
                                    <Link to="/assets">  <button className="top_menu_btn blue_text">Assets</button></Link> */}
                </div>
                <div className=" col-md-2  ">
                  <span className="right_nav">
                    <span className="mr-1">
                      <img
                        src={bellPic}
                        alt="bell"
                        className="ml-3 Icontaxbell"
                      />
                    </span>
                    <span className="ml-2">
                      <img
                        className="imageCircle"
                        src={profilePic}
                        alt="profile"
                      />
                    </span>
                    <span>
                      <Dropdown className="accountBtn  drop_icon_style">
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="accountBtn p-0"
                        >
                          <RiArrowDropDownLine className=" drop_icon_style" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              localStorage.clear();
                              sessionStorage.clear();
                              history.push({ pathname: "/" });
                            }}
                          >
                            {" "}
                            Logout
                          </Dropdown.Item>
                          {/* <Dropdown.Item
                            onClick={() => {
                              setview(<MainAddRoleCom />);
                              setChosen1("");
                            }}
                          >
                            {"  "}
                            Role
                          </Dropdown.Item> */}
                          {rolesdata?.data?.Roles?.Admin && (
                            <Dropdown.Item
                              onClick={() => {
                                setview(<Users />);
                                setChosen1("");
                              }}
                            >
                              {"  "}
                              Users
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item
                            onClick={() => {
                              setview(<Changeform />);
                              setChosen1("");
                            }}
                          >
                            {"  "}
                            Change Request
                          </Dropdown.Item>

                          {/* <Dropdown.Item
                            onClick={() => {
                              setview(<ChangeInReqMain />);
                              setChosen1("");
                            }}
                          >
                            {"  "}
                            Change Request 2
                          </Dropdown.Item> */}
                        </Dropdown.Menu>
                      </Dropdown>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-auto pl-2 pr-2">
              {subchosen2.subbtn.map((btn, index) =>
                btn.text !== undefined ? (
                  (rolesdata?.data?.Roles &&
                    rolesdata?.data?.Roles[btn.rolename] !== undefined) ||
                  localStorage.getItem("Designation") === "Admin" ? (
                    <SideSubButton
                      key={index}
                      text={btn.text}
                      onClick={() => {
                        if (showAlert) {
                          changeState(true);
                        }
                        setsubbutton(btn.text);
                        setview(btn.component);
                        setreload(!reload);
                      }}
                      active={btn.text === statesubbutton}
                    />
                  ) : null
                ) : null
              )}
            </div>
            <div className="col viewcompmg">
              {/* component rendering */}
              {viewcomponent}
            </div>
          </div>
        </div>
      </div>
      <AltertModal />
    </>
  );
};
export default CrmMainPage;
