import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getallTaxTable } from "../../actions/accountsactions/addtax";

const TaxModal = ({
  setshowTaxmodal,
  stateamount,
  taxtotal,
  settaxtotal,
  allvalues,
  setallvalues,
  enableSaveBtn,
}) => {
  const resultalltaxes = useSelector((state) => state.getAllTaxTable);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getallTaxTable());
  }, [dispatch]);
  console.log("stateAmount", stateamount);
  useEffect(() => {
    let arr = [];
    resultalltaxes?.data?.forEach((item2) => {
      let checked = false;
      allvalues?.forEach((item) => {
        if (item.TaxId === item2.TaxId) {
          item2.Checked = item?.Checked;
          item2.TaxPer = item.TaxPer;
          checked = true;
        }
      });
      if (!checked) {
        item2.Checked = false;
      }
      item2.TaxAmount =
        (item2.TaxPer * parseFloat(stateamount?.replace(/,/g, ""))) / 100;
      item2.TaxSubmitedDetailId = item2.TaxSubmitedDetailId;
      arr.push(item2);
    });
    setallvalues(arr);
  }, [resultalltaxes]);

  useEffect(() => {
    let taxTotalamount = 0;
    allvalues.forEach((value) => {
      console.log(value?.TaxAmount);
      if (value?.Checked) {
        taxTotalamount = Number(taxTotalamount) + Number(value?.TaxAmount);
        console.log(parseInt(taxTotalamount));
      }
    });

    settaxtotal(parseInt(taxTotalamount));
  }, [allvalues]);


  //   const handleChange = (e, index, val) => {
  //     resultalltaxes.data[index].Checked = e.target.checked;
  //     //   enableSaveBtn();
  //     if (e.target.checked == true) {
  //       setalltexas([...alltaxes, val]);
  //       settaxtotal(
  //         parseFloat(
  //           parseFloat(taxtotal.replace(/,/g, "")) +
  //             parseFloat(
  //               document
  //                 .getElementById(`taxtotal${index}`)
  //                 .value.replace(/,/g, "")
  //             )
  //         )?.toString()
  //       );
  //       resultalltaxes.data[index].taxAMt = parseFloat(
  //         (parseFloat(val.TaxPer) / 100) *
  //           parseFloat(stateamount.replace(/,/g, ""))
  //       );
  //     } else {
  //       let value = alltaxes;
  //       value = value.filter((f) => f.TaxId !== val.TaxId);
  //       setalltexas(value);
  //       settaxtotal(
  //         parseFloat(
  //           parseFloat(taxtotal.replace(/,/g, "")) -
  //             parseFloat(
  //               document
  //                 .getElementById(`taxtotal${index}`)
  //                 .value.replace(/,/g, "")
  //             )
  //         )?.toString()
  //       );
  //     }
  //   };

  return (
    <div id="mymodal" className="modal" style={{ display: "block" }}>
      <div className="modal-content-cat">
        <div className="row">
          <div className="col-12  page_heading">
            <h4 className="  text-center pt-2">Select Taxes</h4>
            <span
              class="close pr-2  "
              onClick={() => {
                setshowTaxmodal(false);
              }}
            >
              &times;
            </span>
          </div>
        </div>
        <div className="row">
          <div className=" col-12  table_height pr-0 pl-0">
            <div className=" mt-4 m-2">
              <table className="table table-borderless m-0">
                <thead>
                  <tr>
                    <th>Select</th>
                    <th>Name</th>

                    <th>Percentage</th>
                    <th>Tax Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {allvalues?.map((val, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          id={`advCheck${index}`}
                          type="checkbox"
                          checked={val.Checked ? val.Checked : false}
                          name="check"
                          onChange={(e) => {
                            let abc = JSON.parse(JSON.stringify(allvalues));
                            if(enableSaveBtn)
                          {
                            enableSaveBtn()
                          }
                       
                            if (
                              parseFloat(stateamount?.replace(/,/g, "")) <=
                              parseFloat(
                                taxtotal?.toString()?.replace(/,/g, "")
                              ) +
                                parseFloat(val.TaxAmount)
                            ) {
                              abc[index].Checked = false;
                              e.target.checked = false;
                            } else {
                              abc[index].Checked = e.target.checked;
                            }

                            // let values = allvalues;
                            // values= values.filter((value)=>value.TaxId!==val.TaxId)
                            // setallvalues(values)

                            setallvalues(abc);
                            // handleChange(e, index, val);
                          }}
                        />
                      </td>
                      <td>{val.TaxName}</td>

                      <td>
                        <input
                          type="text"
                          // id={`taxper${index}`}
                          value={parseInt(val.TaxPer).toLocaleString()}
                          onChange={(e) => {

                            if (enableSaveBtn) {
                              enableSaveBtn()
                              
                            }
                 

                            let amnt = 0;
                            allvalues.forEach((valu) => {
                              if (val?.TaxId !== valu?.TaxId && val?.Checked) {
                                amnt += valu?.TaxAmount;
                              }
                            });
                      
                            if (e.target.value === "") {
                              let abc = JSON.parse(JSON.stringify(allvalues));
                              abc[index].TaxPer = "0";
                              e.target.value = "0";
                              abc[index].TaxAmount = "0";
                              setallvalues(abc);
                            } else if (
                              isNaN(Number(e.target.value)) ||
                              parseFloat(amnt) +
                                parseFloat(
                                  (parseFloat(
                                    e.target.value?.replace(/,/g, "")
                                  ) *
                                    parseFloat(
                                      stateamount?.replace(/,/g, "")
                                    )) /
                                    100
                                ) >=
                                parseFloat(stateamount.replace(/,/g, ""))
                            ) {
                              let abc = JSON.parse(JSON.stringify(allvalues));
                              abc[index].TaxPer = val.TaxPer;
                              e.target.value = val.TaxPer;
                              abc[index].TaxAmount = val.TaxAmount;
                              setallvalues(abc);
                            } else if (+(e.target.value > 99)) {
                              e.target.value = 99;
                              e.target.value = e.target.value.toLocaleString();
                            } else {
                              let value = e.target.value.replace(/,/g, "");
                              if (e.target.value.toLowerCase() !== "e") {
                                let abc = JSON.parse(JSON.stringify(allvalues));
                                abc[index].TaxPer = value;

                                abc[index].TaxAmount =
                                  (Number(value) *
                                    parseFloat(
                                      stateamount?.replace(/,/g, "")
                                    )) /
                                  100;

                                setallvalues(abc);
                              }
                            }

                            // resultalltaxes.data[index].TaxPer = e.target.value;
                            // resultalltaxes.data[index].taxAMt = parseFloat(
                            //   (parseFloat(e.target.value) / 100) *
                            //     parseFloat(stateamount.replace(/,/g, ""))
                            // );

                            // if (resultalltaxes.data[index].Checked === true) {
                            //   let value = alltaxes;
                            //   value[index] = resultalltaxes.data[index];
                            //   setalltexas(value);
                            //   if (e.target.value === "") {
                            //     settaxtotal("0");
                            //   } else {
                            //     settaxtotal(
                            //       parseFloat(
                            //         parseFloat(
                            //           (e.target.value / 100) *
                            //             parseFloat(
                            //               document
                            //                 .getElementById("Amount")
                            //                 .value.replace(/,/g, "")
                            //             )
                            //         )
                            //       ).toString()
                            //     );
                            //   }
                            // }
                          }}
                        ></input>
                      </td>

                      <td>
                        <input
                          type="text"
                          // id={`taxtotal${index}`}
                          value={parseInt(val.TaxAmount).toLocaleString()}
                          onChange={(e) => {
                            if(enableSaveBtn)
                            {
                              enableSaveBtn()
                            }
                          

                            let amnt = 0;
                            allvalues.forEach((valu) => {
                              if (val?.TaxId !== valu?.TaxId && val?.Checked) {
                                amnt += valu?.TaxAmount;
                              }
                            });

                            //   if(parseFloat(e.target.value?.replace(/,/g, "")) >parseFloat(taxtotal) )
                            //   {
                            //     console.log(( parseFloat(stateamount.replace(/,/g, "")) - parseFloat(taxtotal))-(parseFloat(e.target.value?.replace(/,/g, ""))))
                            //     return alert('please enter valid values')
                            //   }
                            //  else
                            if (e.target.value === "") {
                              let abc = JSON.parse(JSON.stringify(allvalues));
                              abc[index].TaxPer = "0";
                              e.target.value = "0";
                              abc[index].TaxAmount = "0";
                              setallvalues(abc);
                            } else if (
                              isNaN(Number(e.target.value?.replace(/,/g, "")))
                            ) {
                              let abc = JSON.parse(JSON.stringify(allvalues));
                              abc[index].TaxPer = val.TaxPer;
                              e.target.value = val.TaxAmount;
                              abc[index].TaxAmount = val.TaxAmount;
                              setallvalues(abc);
                            } else if (
                              parseFloat(amnt) +
                                parseFloat(e.target.value.replace(/,/g, "")) >=
                              parseFloat(stateamount.replace(/,/g, ""))
                            ) {
                              // console.log('enter')
                              // if( parseFloat(e.target.value?.replace(/,/g, ""))>=(parseFloat(stateamount.replace(/,/g, "")) - parseFloat(taxtotal)))
                              // {
                              //   return alert('please enter valid values')
                              // }
                              // else
                              // {
                              let abc = JSON.parse(JSON.stringify(allvalues));
                              abc[index].TaxPer = val.TaxPer;
                              e.target.value = val.TaxAmount;
                              abc[index].TaxAmount = val.TaxAmount;
                              setallvalues(abc);
                              // }
                            } else {
                              let value = e.target.value.replace(/,/g, "");
                              // if (e.target.value.toLowerCase() !== "e") {
                              let abc = JSON.parse(JSON.stringify(allvalues));
                              abc[index].TaxAmount = value;
                              abc[index].TaxPer = parseFloat(
                                (Number(value) /
                                  parseFloat(stateamount?.replace(/,/g, ""))) *
                                  100
                              );
                              setallvalues(abc);
                            }

                            // }

                            // if (e.target.value == "") {
                            //   settaxtotal("0");
                            // } else {
                            //   console.log(
                            //     parseFloat(
                            //       parseFloat(
                            //         (e.target.value / 100) *
                            //           parseFloat(
                            //             document
                            //               .getElementById("Amount")
                            //               .value.replace(/,/g, "")
                            //           )
                            //       )
                            //     )
                            //   );
                            //   console.log();
                            //   settaxtotal(
                            //     (prev) =>
                            //       prev +
                            //       parseFloat(
                            //         parseFloat(
                            //           (e.target.value / 100) *
                            //             parseFloat(
                            //               document
                            //                 .getElementById("Amount")
                            //                 .value.replace(/,/g, "")
                            //             )
                            //         )
                            //       )
                            //   );
                            // }
                          }}
                        ></input>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="row">
              <div className=" col-11 text-right pt-2 pr-0 pl-0">
                <label className="input_label m-0"> Total Tax:</label>{" "}
                <input type="text" value={taxtotal?.toLocaleString()} disabled></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaxModal;
