import axios from "axios";
import React, { useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { getallcreditors } from "../../../actions/getcreditors";
import PropTypes from "prop-types";

function AddSellerDialog({ closemodal }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  function addSeller() {
    let regexp = new RegExp("^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$");
    let regexphone = new RegExp("^[0-9+]{4}-[0-9+]{7}$");

    let check =
      document.getElementById("CNIC") !== null
        ? document.getElementById("CNIC").value
        : null;
    let checkcell1 = document.getElementById("Cell1").value;

    if (document.getElementById("creditorName").value === "") {
      toast.info("Name is required");
    } else if (
      document.getElementById("CNIC") !== null
        ? document.getElementById("CNIC").value === ""
        : null
    ) {
      toast.info("CNIC is required");
    } else if (
      !regexp.test(check) && document.getElementById("CNIC") !== null
        ? document.getElementById("CNIC").value !== ""
        : null
    ) {
      toast.info("Invalid CNIC. Format should be like xxxxx-xxxxxxx-x");
    } else if (document.getElementById("Cell1").value === "") {
      toast.info("Cell No is required");
    } else if (!regexphone.test(checkcell1)) {
      toast.info("Invalid Cell No Format should be like xxxx-xxxxxxx");
    } else {
      setLoading(true);
      const formData = {
        LandCreditorName: document.getElementById("creditorName").value,
        LandCreditorCnic: document.getElementById("CNIC").value,
        ContactNumber1: document.getElementById("Cell1").value,
      };

      axios({
        url: `${process.env.REACT_APP_API_URL}LandCreditors/Add`,
        method: "Post",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
        data: JSON.stringify(formData),
      }).then(
        (result) => {
          setLoading(false);
          document.getElementById("creditorsave").disabled = false;
          if (result.data.IsSuccess === true) {
            dispatch(getallcreditors());
            closemodal();
            toast.info(result.data.Message);
            document.getElementById("creditorName").value = "";
            document.getElementById("CNIC").value = "";
            document.getElementById("Cell1").value = "";
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          setLoading(false);
          toast.info("Something went wrong!");
          document.getElementById("creditorsave").disabled = false;
        }
      );
    }
  }
  return (
    <div id="addNewSeller" class="modal">
      <div class="modal-add-seller">
        <div className="row">
          <div className="col-12  page_heading">
            <h4 className="  text-center pt-2">Add Seller</h4>
            <span class="close pr-2  " onClick={closemodal}>
              &times;
            </span>
          </div>
        </div>

        <div class="modal-body">
          <div className="row">
            <div className="col-4 text-left p-0">
              <label className="input_label m-0 ">Name :</label>
            </div>
            <div className="col-8  p-0">
              <input
                type="text"
                className="input_styling ml-md-3"
                id="creditorName"
              />
              <span className="estaric">*</span>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-4 text-left p-0">
              <label className="input_label m-0">CNIC :</label>
            </div>
            <div className="col-8 p-0">
              <input
                type="text"
                className="input_styling ml-md-3 "
                id="CNIC"
                placeholder="xxxxx-xxxxxxx-x"
                onKeyDown={(e) => {
                  if (e.key === "Backspace") {
                    document.getElementById("CNIC").value = "";
                  }
                }}
                onChange={(e) => {
                  if (
                    e.target.value?.length === 5 ||
                    e.target.value?.length === 13
                  ) {
                    document.getElementById("CNIC").value =
                      e.target.value + "-";
                  }
                }}
              />
              <span className="estaric">*</span>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-4 text-left p-0">
              <label className="input_label m-0">Cell No :</label>
            </div>
            <div className="col-8 p-0">
              <input
                type="text"
                className="input_styling ml-md-3"
                id="Cell1"
                placeholder="xxxx-xxxxxxx"
                onKeyDown={(e) => {
                  if (e.key === "Backspace") {
                    document.getElementById("Cell1").value = "";
                  }
                }}
                onChange={(e) => {
                  if (e.target.value?.length === 4) {
                    document.getElementById("Cell1").value =
                      e.target.value + "-";
                  }
                }}
              />
              <span className="estaric">*</span>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          {loading && (
            <Loader
              type="Oval"
              color="black"
              height={30}
              width={30}
              radius={12}
            />
          )}
          <button
            className="save_btn  ml-2"
            id="creditorsave"
            onClick={addSeller}
          >
            <BsCloudUpload size="1.3em" />
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddSellerDialog;
AddSellerDialog.propTypes = {
  closemodal: PropTypes.func,
};
