import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { GrPrint } from "react-icons/gr";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { getallRRpopulatetable } from "../../../actions/vouchersactions/getrecoveryreceipt";
import {
  RecoveryRecModal,
  RecoveryRecPrint,
} from "../../accpayablepages/vouchersprints/receiptprints";
import { CSVLink } from "react-csv";
import RecoveryReceiptModal from "./RecoveryReceiptModal";
import moment from "moment";

export const RecoveryReceiptTable = (props) => {
  const resultsrrtable = useSelector((state) => state.getAllRReceiptTable);
  const [TransactionData, setTransactionData] = useState();
  const [filterdata, setfilterdata] = useState();
  const [statetoggle, settoggle] = useState(true);
  const [printData, SetPrintData] = useState();
  const rolesdata = useSelector((state) => state.GetAllRole);

  // print function
  const componentRef = useRef();

  const printRecoveryRec = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    if (printData !== undefined) {
      printRecoveryRec();
    }
  }, [printData, statetoggle]);
  const [printDataModal, SetPrintDataModal] = useState();
  // print function
  const componentRefModal = useRef();

  const printGenrealRecModal = useReactToPrint({
    content: () => componentRefModal.current,
  });
  function showmodal(data) {
    document.getElementById("mymodal").style.display = "block";
    setTransactionData(data);
  }
  function closemodal() {
    document.getElementById("mymodal").style.display = "none";
  }
  // //Table Data fiter
  // var suggestionlist = [];
  // const onTextChange = (e) => {
  //     const value = e.target.value;
  //     if (value === "") {

  //         dispatch(getallRRpopulatetable({}));
  //     } else {
  //         let suggestions = [];
  //         if (value.length > 0) {
  //             const regex = new RegExp(`${value}`, "i");

  //             suggestions = resultsrrtable.data
  //                 .sort()
  //                 .filter((val) =>
  //                     (regex.test(val.RecoveryReceiptVoucherId) || regex.test(val.Project !== undefined && val.Unit !== null ? val.Unit.UnitName : null)));

  //         }
  //         suggestionlist = suggestions;
  //         // dispatch(GetAllRRTableData(suggestionlist));
  //         setfilterdata(suggestionlist);

  //     }
  // };
  const onTextChange = (e) => {
    if (
      document.getElementById("projectfilter").value === "" &&
      document.getElementById("datefilter").value === "" &&
      document.getElementById("voucherfilter").value === "" &&
      document.getElementById("unitfilter").value === ""
    ) {
      setfilterdata(resultsrrtable.data);
    } else {
      let suggestions = [];
      {
        const regex = new RegExp(
          document.getElementById("projectfilter").value,
          "i"
        );
        const regex1 = new RegExp(
          document.getElementById("voucherfilter").value,
          "i"
        );
        const regex2 = new RegExp(
          document.getElementById("datefilter").value,
          "i"
        );
        const regex3 = new RegExp(
          document.getElementById("unitfilter").value,
          "i"
        );

        suggestions = resultsrrtable.data
          .sort()
          .filter(
            (val) =>
              (document.getElementById("projectfilter").value === "" ||
                regex.test(val.Project.ProjectName)) &&
              (document.getElementById("voucherfilter").value === "" ||
                regex1.test(val.RecoveryReceiptVoucherId)) &&
              (document.getElementById("datefilter").value === "" ||
                regex2.test(val.EntryDate)) &&
              (document.getElementById("unitfilter").value === "" ||
                regex3.test(val.Unit.UnitName))
          );
      }
      setfilterdata(suggestions);
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    let data = {};
    dispatch(getallRRpopulatetable(data));
  }, [dispatch]);
  function ongo() {
    let data = {
      ProjectId: `${localStorage.getItem("ProjectId")}`,
      From: document.getElementById("fromdate").value,
      To: document.getElementById("todate").value,
    };

    dispatch(getallRRpopulatetable(data));
  }
  useEffect(() => {
    setfilterdata(resultsrrtable.data);
  }, [resultsrrtable]);

  const [CsvData, setCsvData] = useState([]);
  useEffect(() => {
    let arr = [];
    var i = 1;

    filterdata?.forEach((val) => {
      let year = val.EntryDate.slice(0, 4);
      let day = val.EntryDate.slice(8, 10);
      let month = val.EntryDate.slice(5, 7);
      arr.push({
        Sr: i++,
        Date: day + "-" + month + "-" + year,
        "Voucher No": val.RecoveryReceiptVoucherId,
        Project: val.Project.ProjectName,
        Unit: val.Unit.UnitName,
        Amount: parseFloat(val.NetAmount).toLocaleString(),
      });
    });
    setCsvData(arr);
  }, [filterdata]);

  return (
    <>
      <div hidden>
        <RecoveryRecPrint ref={componentRef} printData={printData} />
      </div>
      <div hidden>
        <RecoveryRecModal ref={componentRefModal} printData={printDataModal} />
      </div>
      <div className="row pt-3">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label m-0">Search:</label>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            id="datefilter"
            onChange={onTextChange}
            placeholder="Date"
          ></input>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            id="voucherfilter"
            onChange={onTextChange}
            placeholder="Voucher"
          ></input>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            id="projectfilter"
            onChange={onTextChange}
            placeholder="Project"
          ></input>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            id="unitfilter"
            onChange={onTextChange}
            placeholder="Unit"
          ></input>
        </div>
        <div className="col-md-1 pr-0 pl-0">
          <RecoveryReceiptModal />
        </div>
        <div className="col-md-1 pr-0 pl-0">
          {resultsrrtable.loading ? (
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          ) : null}
        </div>
      </div>

      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0"></div>
        <div className="col-md-4   pr-0 pl-0">
          <label className="input_label m-0">From:</label>
          <input type="date" className="input_date ml-3" id="fromdate"></input>
        </div>
        <div className="col-md-4  pr-0 pl-0">
          <label className="input_label m-0">To:</label>
          <input type="date" className="input_date ml-3" id="todate"></input>
        </div>
        <div className="col-md-1 pr-0 pl-0">
          <button className="btn_Go" onClick={ongo}>
            Go
          </button>
        </div>
      </div>

      <div className="table-responsive vouchers_table_height mt-4">
        <table className="table table-borderless m-0">
          <thead>
            <tr>
              <th>Sr</th>
              <th>Date</th>
              <th>Added On</th>
              <th>Voucher </th>
              <th>Project</th>
              <th>Unit</th>
              <th>Amount</th>
              <th className="text-center">
                {rolesdata?.data?.Access?.includes("C") ? (
                  <button
                    className="save_btn"
                    onClick={() => {
                      props.showrecoverytable();
                    }}
                  >
                    New+
                  </button>
                ) : null}
              </th>
            </tr>
          </thead>
          <tbody>
            {filterdata !== undefined
              ? filterdata.map((val, index) => {
                  let year = val.EntryDate.slice(0, 4);
                  let day = val.EntryDate.slice(8, 10);
                  let month = val.EntryDate.slice(5, 7);
                  switch (month) {
                    case "01":
                      month = "Jan";

                      break;
                    case "02":
                      month = "Feb";

                      break;
                    case "03":
                      month = "Mar";

                      break;
                    case "04":
                      month = "Apr";

                      break;
                    case "05":
                      month = "May";

                      break;
                    case "06":
                      month = "Jun";

                      break;
                    case "07":
                      month = "Jul";

                      break;
                    case "08":
                      month = "Aug";

                      break;
                    case "09":
                      month = "Sep";

                      break;
                    case "10":
                      month = "Oct";
                      break;
                    case "11":
                      month = "Nov";
                      break;
                    case "12":
                      month = "Dec";
                      break;
                    default:
                      break;
                  }
                  return (
                    <tr key={index}>
                      <td
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {day + "-" + month + "-" + year}
                      </td>
                      <td
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {moment(
                          val.Transaction[0]?.AddedOn?.slice(0, 10),
                          "YYYY:MM-DD"
                        )?.format("DD-MMM-YYYY")}
                      </td>
                      <td
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {val.RecoveryReceiptVoucherId}
                      </td>
                      {val.Project !== undefined && val.Project !== null ? (
                        <td
                          onClick={() => {
                            showmodal(val.Transaction);
                            SetPrintDataModal(val.Transaction);
                          }}
                        >
                          {val.Project.ProjectName}
                        </td>
                      ) : (
                        <td></td>
                      )}
                      {val.Unit !== undefined ? (
                        <td
                          onClick={() => {
                            showmodal(val.Transaction);
                            SetPrintDataModal(val.Transaction);
                          }}
                        >
                          {val.Unit.UnitName}
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {parseFloat(val.NetAmount).toLocaleString()}
                      </td>

                      <td
                        className="text-center cur_sor"
                        onClick={() => {
                          SetPrintData(val);
                          settoggle(!statetoggle);
                        }}
                      >
                        <GrPrint size="1.3em" />
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
      <button
        style={{ color: "black !important" }}
        className="print_btn ml-2 float-left"
      >
        <CSVLink
          style={{ color: "black" }}
          filename="Recovery_Receipt_Csv"
          data={CsvData}
        >
          Download CSV
        </CSVLink>
      </button>
      <div id="mymodal" class="modal">
        <div class="modal-content-cat_voucher">
          <div className="row">
            <div className="col-12  page_heading">
              <h4 className="  text-center pt-2">Transaction Detail</h4>
              <span class="close pr-2  " onClick={closemodal}>
                &times;
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-2">
              <div className="table-responsive_modal mt-4 table_height-voucher">
                <table className="table table-borderless m-0 ">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Account</th>

                      <th>Debit</th>

                      <th>Credit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {TransactionData !== undefined
                      ? TransactionData.map((x, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{x.Account.AccountTitle}</td>
                            <td>
                              {x.TransactionType === "DEBIT"
                                ? x.Amount.toLocaleString()
                                : null}
                            </td>

                            <td>
                              {x.TransactionType === "CREDIT"
                                ? x.Amount.toLocaleString()
                                : null}
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12 mt-2">
              <button
                className="print_btn float-left"
                onClick={() => {
                  printGenrealRecModal();
                }}
              >
                <TiPrinter size="1.3em" />
                Print
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
