import axios from "axios";
import moment from "moment";
import { useDebugValue, useEffect } from "react";
import { useRef, useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { getallgeneralrec } from "../../../actions/getgeneralrec";
import GeneralRecPrint from "../../accpayablepages/vouchersprints/receiptprints";
import { RiFileEditLine } from "react-icons/ri";
import { GrPrint } from "react-icons/gr";
import { MdDeleteForever } from "react-icons/md";
import GeneralReceiptTransactionDetailModal from "./GeneralReceiptTransactionDetailModal";
import GeneralVoucherDeleteVoucherModal from "./GeneralVoucherDeleteVoucherModal";
import GeneralReceiptModal from "./GeneralReceiptModal";
import { CSVLink } from "react-csv";
import useDownloadCsv from "../../../actions/downloadCsvHook";

export const GeneralReceiptTable = (props) => {
  const [apidata, setapidata] = useState([]);
  const resultsgetgeneralrec = useSelector((state) => state.getAllGeneralRec);
  const rolesdata = useSelector((state) => state.GetAllRole);
  // console.log(rolesdata)
  const dispatch = useDispatch();
  const [TransactionData, setTransactionData] = useState();
  const [filterdata, setfilterdata] = useState();
  const [statetoggle, settoggle] = useState(true);
  const [printData, SetPrintData] = useState();
  const [debitState, setdebitState] = useState(0);
  const [creditState, setcreditState] = useState(0);
  const [apiLoading, setapiLoading] = useState(false);
  const [voucherRowdata, setvoucherRowdata] = useState(null);
  const [loading, setloading] = useState(false);
  const [selectedVouchers, setSelectedVouchers] = useState([]);
  const [voucherIds, setVoucherIds] = useState([]);
  const [rendarComp, setrendarComp] = useState(false);
  const [allLinks, setallLinks] = useState(null);
  const csvLinkRef = useRef();
  const [data, downloadCsv] = useDownloadCsv();

  const [filterObject, setfilterObject] = useState({
    date: "",
    voucherNumber: "",
    account: "",
    status: "",
  });

  const [DeleteVoucherData, setDeleteVoucherData] = useState({
    Email: "",
    Password: "",
    Reason: "",
  });

  // print function

  console.log(voucherIds);
  console.log(voucherRowdata);
  const componentRef = useRef();
  const printGenrealRec = useReactToPrint({
    content: () => componentRef.current,
  });
  const [printDataModal, SetPrintDataModal] = useState();
  // print function

  const onTextChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setfilterObject({ ...filterObject, [name]: value });
    // if (
    //   document.getElementById("accountfilter").value === "" &&
    //   document.getElementById("datefilter").value === "" &&
    //   document.getElementById("voucherfilter").value === "" &&
    //   document.getElementById("submittedId")?.value === "all"
    // ) {
    //   setfilterdata(resultsgetgeneralrec.data.data);
    // } else {
    //   let suggestions = [];
    //   {
    //     const regex = new RegExp(
    //       document.getElementById("accountfilter").value,
    //       "i"
    //     );
    //     const regex1 = new RegExp(
    //       document.getElementById("voucherfilter").value,
    //       "i"
    //     );
    //     const regex2 = new RegExp(
    //       document.getElementById("datefilter").value,
    //       "i"
    //     );
    //     suggestions = resultsgetgeneralrec.data.data
    //       .sort()
    //       .filter(
    //         (val) =>
    //           (document.getElementById("accountfilter").value === "" ||
    //             regex.test(
    //               val.Transaction[1] === undefined
    //                 ? val.GeneralVoucherSaved?.CreditAccount?.AccountTitle
    //                 : val.Transaction[1].Account.AccountTitle
    //             )) &&
    //           (document.getElementById("voucherfilter").value === "" ||
    //             regex1.test(val.GeneralVoucherId)) &&
    //           (document.getElementById("datefilter").value === "" ||
    //             regex2.test(val.EntryDate)) &&
    //           (document.getElementById("submittedId").value === "all"
    //             ? val.Submitted === false || val.Submitted === true
    //             : document.getElementById("submittedId").value === "false"
    //             ? val.Submitted === false
    //             : val.Submitted === true)
    //       );
    //   }

    //   setfilterdata(suggestions);
    // }

    // const tdValue = value === "true" ? "yes" : "no";
    // document.getElementById("tdId").textContent = tdValue;
  };
  useEffect(() => {
    console.log("hello");
    setfilterdata(apidata);
  }, [apidata]);
  useEffect(() => {
    if (printData !== undefined) {
      printGenrealRec();
    }
  }, [printData, statetoggle]);

  function getGeneralreciept() {
    setapiLoading(true);
    let pgurl = `${
      process.env.REACT_APP_API_URL
    }Vouchers/Receipt/General/GetAll?ProjectId=${localStorage.getItem(
      "ProjectId"
    )}`;
    if (localStorage.getItem("ProjectId") === null) {
      pgurl = `${process.env.REACT_APP_API_URL}Vouchers/Receipt/General/GetAll`;
    }
    axios({
      url: pgurl,
      method: "Post",
      data: JSON.stringify({}),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        setapiLoading(false);
        if (result.data.Succeeded === true) {
          setapidata(result.data.data);
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        setapiLoading(false);
        toast.info("Something went wrong!");
      }
    );
  }
  useEffect(() => {
    getGeneralreciept();
  }, []);
  function showmodal(data) {
    console.log(data);
    document.getElementById("mymodal").style.display = "block";
    setTransactionData(data);
    var credit = 0;
    var debit = 0;
    data?.map((val) =>
      val.TransactionType === "CREDIT"
        ? (credit += parseFloat(val.Amount))
        : val.TransactionType === "DEBIT"
        ? (debit += parseFloat(val.Amount))
        : null
    );
    setdebitState(debit);
    setcreditState(credit);
  }
  function closemodal() {
    document.getElementById("mymodal").style.display = "none";
  }
  function showmodal2() {
    document.getElementById("mymodal2").style.display = "block";
  }

  function closemodal2() {
    document.getElementById("mymodal2").style.display = "none";
    setDeleteVoucherData({
      Email: "",
      Password: "",
      Reason: "",
    });
  }
  function ongo() {
    setloading(true);
    let data = {
      ProjectId: `${localStorage.getItem("ProjectId")}`,
      From: document.getElementById("fromdate").value,
      To: document.getElementById("todate").value,
      VoucherId: document.getElementById("voucherfilter").value,
      FilterDate: document.getElementById("datefilter").value,
      Status: document.getElementById("submittedId").value,
    };

    dispatch(getallgeneralrec(data));
  }
  function deleteVoucher() {
    if (DeleteVoucherData?.Email === "") {
      return toast.info("Email is Required");
    } else if (DeleteVoucherData?.Password === "") {
      return toast.info("Password is Required");
    } else if (DeleteVoucherData?.Reason === "") {
      return toast.info("Reason is Required");
    }
    setloading(true);

    const voucherIds1 = [];
    selectedVouchers.forEach((vocuher) => {
      voucherIds1.push(vocuher.id);
    });

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}Scripts/DeleteAllUnsubmittedVouchers`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
      data: {
        Email: DeleteVoucherData?.Email,
        Password: DeleteVoucherData?.Password,
        Reason: DeleteVoucherData?.Reason,
        voucherId: selectedVouchers,
      },
    }).then(
      (result) => {
        console.log(result);
        // document.getElementById("headingsave").disabled = false;
        if (result.data.IsSuccess === true) {
          setDeleteVoucherData({
            Email: "",
            Password: "",
            Reason: "",
          });
          getGeneralreciept();
          setapiLoading(true);
          toast.info(result.data.Message);
          setloading(false);
          setvoucherRowdata(null);
          setSelectedVouchers([]);
          closemodal2();
        } else {
          toast.info(result.data.Message);
          setDeleteVoucherData({
            Email: "",
            Password: "",
            Reason: "",
          });
          setloading(false);
          // setvoucherRowdata(null)
        }
      },
      (error) => {
        toast.info("Someting went wrong");
        // document.getElementById("headingsave").disabled = false;
      }
    );
    setapiLoading(false);
  }

  function handleCheckboxChange() {
    let arr = filterdata
      ?.filter((itm) => itm.checked)
      .map((itm) => itm.GeneralVoucherId);
    setSelectedVouchers(arr);
  }

  const handelDeleteVoucherdataChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setDeleteVoucherData({ ...DeleteVoucherData, [name]: value });
  };

  const [CsvData, setCsvData] = useState([]);
  const [newData, setNewData] = useState([]);
  const handleDownload = (vt) => {
    downloadCsv(vt)
      .then((csvData) => {
        setNewData(csvData);
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  useEffect(() => {
    if (newData.length > 0) {
      let arr = [];
      var i = 1;
      newData?.forEach((val) => {
        arr.push({
          Sr: i++,
          Date: moment(val.EntryDate?.slice(0, 10), "YYYY:MM-DD")?.format(
            "DD-MMM-YY"
          ),
          Voucher: val.GeneralVoucherId,
          Account:
            val.Transaction[val.Transaction.length - 1] !== undefined
              ? val.Transaction[val.Transaction.length - 1].Account !== null &&
                val.Transaction[val.Transaction.length - 1].Account !==
                  undefined
                ? val.Transaction[val.Transaction.length - 1].Account
                    .AccountTitle
                : ""
              : val.GeneralVoucherSaved?.CreditAccount?.AccountTitle,
          Narration: val.Narration,
          Amount: parseFloat(val.Amount).toLocaleString(),
          User: val?.AddedBy?.UserName,
          Submitted: val.Submitted ? "True" : "False",
        });
      });

      setCsvData(arr);
    }
  }, [newData]);
  useEffect(() => {
    if (csvLinkRef.current && CsvData.length > 0) {
      csvLinkRef.current.link.click();
      setCsvData([]);
    }
  }, [CsvData]);
  const getPaginationData = (link) => {
    let data = {
      ProjectId: Number(`${localStorage.getItem("ProjectId")}`),
      From: document.getElementById("fromdate").value,
      To: document.getElementById("todate").value,
    };
    setloading(true);
    console.log(link);
    dispatch(getallgeneralrec(data, link));
  };
  useEffect(() => {
    if (resultsgetgeneralrec.data) {
      console.log(resultsgetgeneralrec);

      setallLinks(resultsgetgeneralrec.data);
      setfilterdata(resultsgetgeneralrec.data.data);

      setloading(false);
    }
  }, [resultsgetgeneralrec]);
  useEffect(() => {
    let data = {
      ProjectId: Number(`${localStorage.getItem("ProjectId")}`),
      From: document.getElementById("fromdate").value,
      To: document.getElementById("todate").value,
    };
    dispatch(getallgeneralrec(data));
  }, [dispatch]);
  return (
    <>
      <div hidden>
        <GeneralRecPrint ref={componentRef} printData={printData} />
      </div>

      <div className="row pt-3">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label m-0">Search:</label>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            autoComplete="off"
            name="date"
            value={filterObject?.date}
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
            type="date"
            className="input_styling "
            id="datefilter"
            onChange={onTextChange}
            placeholder="Date"
          />
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            autoComplete="off"
            name="voucherNumber"
            value={filterObject?.voucherNumber}
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
            type="text"
            className="input_styling "
            id="voucherfilter"
            onChange={onTextChange}
            placeholder="Voucher"
          />
        </div>

        <div className="col-md-2 pr-0 pl-0 ">
          <select
            autoComplete="off"
            name="status"
            value={filterObject?.status}
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
            className="input_styling "
            id="submittedId"
            onChange={onTextChange}
          >
            <option value={""}>All</option>
            <option value={false}>Pending</option>
            <option value={true}>Submitted</option>
          </select>
        </div>
        <div className="col-md-1 pr-0 pl-0">
          <GeneralReceiptModal />
          {/* 
            {resultsgetgeneralrec?.loading ? <Loader type="ThreeDots"
              color="green"
              height={40}
              width={40} /> : null} */}
        </div>
      </div>
      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0"></div>
        {/* <div className="col-md-3 pr-0 pl-0">
  
  
            <input type="text " className="input_styling " onChange={onTextChange} placeholder="Voucher/Account"></input>
          </div> */}
        <div className="col-md-3   pr-0 pl-0">
          <label className="input_label m-0">From:</label>
          <input type="date" className="input_date ml-3" id="fromdate"></input>
        </div>
        <div className="col-md-3  pr-0 pl-0">
          <label className="input_label m-0">To:</label>
          <input type="date" className="input_date ml-3" id="todate"></input>
        </div>

        <div className="col-md-1 pr-0 pl-0">
          <button className="btn_Go" onClick={ongo}>
            Go
          </button>
        </div>
        <div className="col-md-4 pr-0 pl-0">
          {selectedVouchers.length > 0 && (
            <div className="col-md-12 mt-2">
              <div className="float-right">
                {apiLoading && (
                  <Loader
                    type="ThreeDots"
                    color="green"
                    height={40}
                    width={40}
                  />
                )}
                <span>
                  <button
                    onClick={() => {
                      showmodal2(selectedVouchers);
                    }}
                    className="cancel_btn ml-2"
                  >
                    <MdDeleteForever />
                    Delete All
                  </button>
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="col-md-1 pr-0 pl-0">
          {apiLoading || loading ? (
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className="table-responsive vouchers_table_height mt-2">
        <table className="table table-borderless m-0">
          <thead>
            <tr>
              <th>Sr</th>
              <th>{""}</th>
              <th>Date</th>
              <th>Added On</th>
              <th>Voucher</th>
              <th>Account</th>
              <th>Narration</th>
              <th>Amount</th>
              <th>User</th>
              <th>Submitted</th>
              <th className="text-center">
                {rolesdata?.data?.Access?.includes("C") ? (
                  <button
                    className="save_btn"
                    onClick={() => {
                      props.showgeneraltable();
                    }}
                  >
                    New+
                  </button>
                ) : null}
              </th>
            </tr>
          </thead>
          <tbody>
            {filterdata !== undefined
              ? filterdata.map((val, index) => {
                  return (
                    <tr
                      key={index}
                      className="cur_sor"
                      onClick={(e) => {
                        e.stopPropagation();
                        showmodal(val.Transaction);
                      }}
                    >
                      <td
                        className="table_data"
                        // onClick={() => {
                        //   showmodal(val.Transaction);
                        //   SetPrintDataModal(val.Transaction);
                        // }}
                      >
                        {index +
                          1 +
                          (allLinks?.PageNumber - 1) * allLinks?.PageSize}
                      </td>
                      <td>
                        {localStorage.getItem("UserName") === "Admin" ? (
                          <input
                            checked={val.checked ? val.checked : false}
                            key={index}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            onChange={(e) => {
                              e.stopPropagation();
                              filterdata[index].checked = e.target.checked;
                              setrendarComp(!rendarComp);
                              handleCheckboxChange(index);
                            }}
                            type="checkbox"
                            id={`checkedVoucher${index}`}
                          />
                        ) : null}
                      </td>
                      <td
                        className="table_data"
                        // onClick={() => {
                        //   showmodal(val.Transaction);
                        //   SetPrintDataModal(val.Transaction);
                        // }}
                      >
                        {moment(
                          val.EntryDate?.slice(0, 10),
                          "YYYY:MM-DD"
                        )?.format("DD-MMM-YY")}
                      </td>
                      <td
                        className="table_data"
                        // onClick={() => {
                        //   showmodal(val.Transaction);
                        //   SetPrintDataModal(val.Transaction);
                        // }}
                      >
                        {moment(
                          val.Transaction[0]?.AddedOn?.slice(0, 10),
                          "YYYY:MM-DD"
                        )?.format("DD-MMM-YY")}
                      </td>
                      <td
                        className="table_data"
                        // onClick={() => {
                        //   showmodal(val.Transaction);
                        //   SetPrintDataModal(val.Transaction);
                        // }}
                      >
                        {val.GeneralVoucherId}
                      </td>
                      {val.Transaction.length > 0 ? (
                        <td
                          className="table_data"
                          // onClick={() => {
                          //   showmodal(val.Transaction);
                          //   SetPrintDataModal(val.Transaction);
                          // }}
                        >
                          {val.Transaction?.filter(
                            (item) => item.TransactionType === "CREDIT"
                          )
                            .map((item) => item.Account.AccountTitle)
                            .join(", ")}
                        </td>
                      ) : (
                        <td
                          className="table_data"
                          onClick={() => {
                            showmodal(val.Transaction);
                            // SetPrintDataModal(val.Transaction);
                          }}
                        >
                          {val.GeneralVoucherSavedDetail?.length > 0
                            ? val.GeneralVoucherSavedDetail?.map(
                                (item) => item.Account.AccountTitle
                              ).join(", ")
                            : val.GeneralVoucherSaved?.CreditAccount
                                ?.AccountTitle}
                        </td>
                      )}
                      <td
                        className="table_data"
                        onClick={() => {
                          showmodal(val.Transaction);
                          // SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {val.Narration}
                      </td>

                      <td
                        className="table_data"
                        // onClick={() => {
                        //   showmodal(val.Transaction);
                        //   SetPrintDataModal(val.Transaction);
                        // }}
                      >
                        {parseFloat(val.Amount).toLocaleString()}
                      </td>
                      <td
                        className="table_data"
                        // onClick={() => {
                        //   showmodal(val.Transaction);
                        //   SetPrintDataModal(val.Transaction);
                        // }}
                      >
                        {val?.AddedBy?.UserName}
                      </td>
                      {/* <td className="table_data" id="tdId">yes</td> */}
                      {/* <td className="table_data">{filterObject?.status === 'true' ? 'true' : 'false'}</td> */}
                      <td className="table_data">
                        {val.Submitted ? "True" : "False"}
                      </td>

                      <td className="text-center ">
                        {rolesdata?.data?.Access?.includes("U") ? (
                          <RiFileEditLine
                            title="Edit"
                            size="1em"
                            color={
                              val.Submitted === false ? "black" : "#707070"
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              if (val.Submitted === false) {
                                console.log(
                                  " val.GeneralVoucherSavedDetail",
                                  val.GeneralVoucherSavedDetail
                                );
                                props.setvoucherData(val.GeneralVoucherSaved);
                                props.setvoucherSaveDetails(
                                  val?.GeneralVoucherSavedDetail
                                );
                              } else {
                                toast.info("Can't edit voucher");
                              }
                              // props.showgeneraltable();
                            }}
                            className="cur_sor"
                          />
                        ) : null}
                        &nbsp;
                        <GrPrint
                          title="Print"
                          className="cur_sor"
                          onClick={(e) => {
                            e.stopPropagation();
                            SetPrintData(val);
                            settoggle(!statetoggle);
                          }}
                          size="1em"
                        />
                        &nbsp;
                        {/* {localStorage.getItem("UserName") === "Admin" ? (
                        <MdDeleteForever
                          onClick={(e) => {
                            e.stopPropagation();
                            showmodal2(val);
                          }}
                          title="Delete"
                          className="cur_sor"
                          size="1em"
                        />
                      ) : null} */}
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
      <div className="col-12  text-right pr-0 pl-0">
        <button
          disabled={allLinks?.LastPage ? false : true}
          className="print_btn float-right mx-1"
          onClick={() => getPaginationData(allLinks?.LastPage)}
        >
          Last
        </button>
        <button
          disabled={allLinks?.PreviousPage ? false : true}
          className="print_btn float-right mx-1"
          onClick={() => getPaginationData(allLinks?.PreviousPage)}
        >
          Previous
        </button>
        <button
          disabled={allLinks?.NextPage ? false : true}
          className="print_btn float-right mx-1"
          onClick={() => getPaginationData(allLinks?.NextPage)}
        >
          Next
        </button>
        <button
          disabled={allLinks?.FirstPage ? false : true}
          className="print_btn float-right pl-1 mx-1"
          onClick={() => getPaginationData(allLinks?.FirstPage)}
        >
          First
        </button>
      </div>
      <button
        style={{ color: "black !important" }}
        className="print_btn ml-2 float-left"
        onClick={() => handleDownload("GRV")}
      >
        Download CSV
      </button>
      <CSVLink
        ref={csvLinkRef}
        style={{ display: "none" }} // Hide the link element
        filename="General_Receipt_Csv"
        data={CsvData}
      >
        Download CSV
      </CSVLink>
      {/* <button
        style={{ color: "black !important" }}
        className="print_btn ml-2 float-left"
      >
        <CSVLink
          style={{ color: "black" }}
          filename="General_Receipt_Csv"
          data={CsvData}
          onClick={() => downloadCsv("GRV")}
        >
          Download CSV
        </CSVLink>
      </button> */}

      <GeneralVoucherDeleteVoucherModal
        closemodal2={closemodal2}
        DeleteVoucherData={DeleteVoucherData}
        handelDeleteVoucherdataChange={handelDeleteVoucherdataChange}
        loading={loading}
        deleteVoucher={deleteVoucher}
        setvoucherRowdata={setvoucherRowdata}
      />
      <GeneralReceiptTransactionDetailModal
        debitState={debitState}
        closemodal={closemodal}
        TransactionData={TransactionData}
        creditState={creditState}
        printDataModal={printDataModal}
        printData={printData}
      />
    </>
  );
};
