import axios from "axios";
import { toast } from "react-toastify";
export const GetDealSoldData = (data) => {
  return {
    type: "get_dealsolddata",
    payload: data,
    loader: false,
  };
};
export function getdealsoldpopulatetable() {
  return (dispatch) => {
    axios({
      url: `${process.env.REACT_APP_API_URL}Deals/Sold/Get`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetDealSoldData(result.data.Data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
