import axios from "axios";
import { toast } from "react-toastify";
export const GetAllEmployees = (data) => {
  return {
    type: "get_allemployees",
    payload: data,
    loader: false,
  };
};
export const GetEmployeesData = (data) => {
  return {
    type: "get_employeesdata",
    payload: data,
  };
};
export const GetDesignation = (data) => {
  return {
    type: "get_empdesignation",
    payload: data,
    loader: false,
  };
};
export function getallEmployees() {
  return (dispatch) => {
    axios({
      url: `${process.env.REACT_APP_API_URL}Employees/GetEmployee`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllEmployees(result.data.Data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
export function getalldesignation() {
  return (dispatch) => {
    axios({
      url: `${process.env.REACT_APP_API_URL}Employees/Populate`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetDesignation(result.data.Data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
