import React, { useEffect, useState } from "react";
import { GeneralReceiptTable } from "./GeneralReceipt/GeneralReceiptTable";
import { GeneralReceiptForm } from "./GeneralReceipt/GeneralReceiptForm";
import { UnitReceiptTable } from "./UnitReceipt/UnitReceiptTable";
import { UnitReceiptForm } from "./UnitReceipt/UnitReceiptForm";
import { DealSoldTable } from "./DealSoldRec/DealSoldRecTable";
import { DealSoldRecForm } from "./DealSoldRec/DealSoldRecForm";
import { RecoveryReceiptTable } from "./RecoveryReceipt/RecoveryReceiptTable";
import { RecoveryReceiptForm } from "./RecoveryReceipt/RecoveryReceiptForm";
import { InvestReceiptTable } from "./InvestReceipt/InvestReceiptTable";
import { InvestReceiptForm } from "./InvestReceipt/InvestReceiptForm";
import { CommissionReceiptTable } from "./CommissionReceipt/CommissionReceiptTable";
import { CommissionReceiptForm } from "./CommissionReceipt/CommissionReceiptForm";

const ReceiptEntry = () => {
  const [tab, settab] = useState("General Receipt");
  const [voucherData, setvoucherData] = useState(null);
  const [unitVouch, setunitVouch] = useState(null);
  const [voucherSaveDetails, setvoucherSaveDetails] = useState(null);
  const showgeneraltable = () => {
    stateshowgeneral(
      <GeneralReceiptForm
        log={"test"}
        vouchData={voucherData}
        voucherSaveDetails={voucherSaveDetails}
        cancelgeneraltable={cancelgeneraltable}
        setvoucherSaveDetails={setvoucherSaveDetails}
      />
    );
  };
  const cancelgeneraltable = () => {
    setvoucherData(null);
    setvoucherSaveDetails(null);
    stateshowgeneral(
      <GeneralReceiptTable
        setvoucherData={setvoucherData}
        vouchData={voucherData}
        showgeneraltable={showgeneraltable}
        cancelgeneraltable={cancelgeneraltable}
        voucherSaveDetails={voucherSaveDetails}
        setvoucherSaveDetails={setvoucherSaveDetails}
      />
    );
  };
  const [showgeneral, stateshowgeneral] = useState(
    <GeneralReceiptTable
      setvoucherData={setvoucherData}
      vouchData={voucherData}
      showgeneraltable={showgeneraltable}
      cancelgeneraltable={cancelgeneraltable}
      setvoucherSaveDetails={setvoucherSaveDetails}
    />
  );

  useEffect(() => {
    if (voucherData === null) {
      stateshowgeneral(
        <GeneralReceiptTable
          setvoucherData={setvoucherData}
          vouchData={voucherData}
          showgeneraltable={showgeneraltable}
          cancelgeneraltable={cancelgeneraltable}
          setvoucherSaveDetails={setvoucherSaveDetails}
        />
      );
    } else {
      stateshowgeneral(
        <GeneralReceiptForm
          log={"test"}
          vouchData={voucherData}
          voucherSaveDetails={voucherSaveDetails}
          cancelgeneraltable={cancelgeneraltable}
          setvoucherSaveDetails={setvoucherSaveDetails}
        />
      );
    }
  }, [voucherData, voucherSaveDetails]);
  // functions for units screen
  const showunittable = () => {
    stateshowunit(
      <UnitReceiptForm
        setvoucherData={setunitVouch}
        vouchData={unitVouch}
        cancelunittable={cancelunittable}
      />
    );
  };
  const cancelunittable = () => {
    setunitVouch(null);
    stateshowunit(
      <UnitReceiptTable
        setvoucherData={setunitVouch}
        vouchData={unitVouch}
        showunittable={showunittable}
        cancelunittable={cancelunittable}
      />
    );
  };
  const [showunit, stateshowunit] = useState(
    <UnitReceiptTable
      setvoucherData={setunitVouch}
      vouchData={unitVouch}
      showunittable={showunittable}
      cancelunittable={cancelunittable}
    />
  );
  useEffect(() => {
    if (unitVouch === null) {
      stateshowunit(
        <UnitReceiptTable
          setvoucherData={setunitVouch}
          vouchData={unitVouch}
          showunittable={showunittable}
          cancelunittable={cancelunittable}
        />
      );
    } else {
      stateshowunit(
        <UnitReceiptForm
          setvoucherData={setunitVouch}
          vouchData={unitVouch}
          cancelunittable={cancelunittable}
        />
      );
    }
  }, [unitVouch]);
  // functions for recovery reciept screen

  const showrecoverytable = () => {
    stateshowrecovery(
      <RecoveryReceiptForm cancelrecoverytable={cancelrecoverytable} />
    );
  };
  const cancelrecoverytable = () => {
    stateshowrecovery(
      <RecoveryReceiptTable
        showrecoverytable={showrecoverytable}
        cancelrecoverytable={cancelrecoverytable}
      />
    );
  };
  const [showrecovery, stateshowrecovery] = useState(
    <RecoveryReceiptTable
      showrecoverytable={showrecoverytable}
      cancelrecoverytable={cancelrecoverytable}
    />
  );
  // functions for DealSold screen
  const showbookingtable = () => {
    stateshowbooking(
      <DealSoldRecForm cancelbookingtable={cancelbookingtable} />
    );
  };
  const cancelbookingtable = () => {
    stateshowbooking(
      <DealSoldTable
        showbookingtable={showbookingtable}
        cancelbookingtable={cancelbookingtable}
      />
    );
  };
  const [showbooking, stateshowbooking] = useState(
    <DealSoldTable
      showbookingtable={showbookingtable}
      cancelbookingtable={cancelbookingtable}
    />
  );
  // functions for loan screen
  const showloantable = () => {
    stateshowloan(<InvestReceiptForm cancelloantable={cancelloantable} />);
  };
  const cancelloantable = () => {
    stateshowloan(
      <InvestReceiptTable
        showloantable={showloantable}
        cancelloantable={cancelloantable}
      />
    );
  };
  const [showloan, stateshowloan] = useState(
    <InvestReceiptTable
      showloantable={showloantable}
      cancelloantable={cancelloantable}
    />
  );
  // functions for commission screen
  const showcommissiontable = () => {
    stateshowcommission(
      <CommissionReceiptForm cancelcommissiontable={cancelcommissiontable} />
    );
  };
  const cancelcommissiontable = () => {
    stateshowcommission(
      <CommissionReceiptTable
        showcommissiontable={showcommissiontable}
        cancelcommissiontable={cancelcommissiontable}
      />
    );
  };
  const [showcommission, stateshowcommission] = useState(
    <CommissionReceiptTable
      showcommissiontable={showcommissiontable}
      cancelcommissiontable={cancelcommissiontable}
    />
  );

  const [Tabs, setTabs] = useState([
    {
      name: "General Receipt",
      info: "General Receipt",
    },
    {
      name: "Unit Receipt",
      info: "Unit Receipt",
    },
    {
      name: "Deal Sold Rec",
      info: "Deal Sold Rec",
    },
    {
      name: "Recovery Receipt",
      info: "Recovery Receipt",
    },
    {
      name: "Invest Receipt",
      info: "Invest Receipt",
    },
    {
      name: "Com Receipt",
      info: "Com Receipt",
    },
  ]);
  return (
    <>
      <div className="row  ">
        <div className="dash_background col-md-12 p-0">
          <div className="row ">
            <div className="col-md-12 text-center  p-0">
              {Tabs?.map((SingleTab) => {
                return (
                  <button
                    className={
                      tab === SingleTab?.name
                        ? "comp_btns_selected ml-1 "
                        : "comp_btns ml-1 "
                    }
                    onClick={() => {
                      if (SingleTab?.name !== "General Receipt") {
                        if (
                          localStorage.getItem("ProjectName") !== "HEAD OFFICE"
                        ) {
                          settab(SingleTab?.name);
                        }
                      } else {
                        settab(SingleTab?.name);
                      }
                    }}
                  >
                    <span
                      className="comp_btns_text"
                      style={
                        SingleTab?.name === "General Receipt"
                          ? {}
                          : localStorage.getItem("ProjectName") !==
                            "HEAD OFFICE"
                          ? {
                              textDecorationLine: "none",
                            }
                          : {
                              textDecorationLine: "line-through",
                              textDecorationStyle: "solid",
                            }
                      }
                    >
                      <div>{SingleTab?.name}</div>
                    </span>
                  </button>
                );
              })}
            </div>
            <div className="col-12">
              {tab === "General Receipt"
                ? showgeneral
                : tab === "Unit Receipt"
                ? showunit
                : tab === "Recovery Receipt"
                ? showrecovery
                : tab === "Deal Sold Rec"
                ? showbooking
                : tab === "Invest Receipt"
                ? showloan
                : tab === "Com Receipt"
                ? showcommission
                : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReceiptEntry;
