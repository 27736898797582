import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BsCloudUpload } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import {
  getallLeadTaskPopulate,
  getallLeadTaskTable,
} from "../../actions/Crmactions/addleads";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import { FiEdit2 } from "react-icons/fi";
import axios from "axios";
const MainTaskComp = () => {
  const showtasktable = () => {
    stateshowtask("task");
  };
  const canceltasktable = () => {
    stateshowtask("taskTable");
  };
  const [showtask, stateshowtask] = useState("taskTable");

  return (
    <>
      <div className="row ">
        <div className="dash_background col-12 p-0">
          <div className="row ">
            <div className="col-md-12">
              {showtask == "taskTable" ? (
                <AddtaskTable
                  showtasktable={showtasktable}
                  canceltasktable={canceltasktable}
                />
              ) : (
                <AddTask canceltasktable={canceltasktable} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MainTaskComp;
export const AddTask = (props) => {
  const populateLeadTask = useSelector((state) => state.getLeadTaskPopulate);

  let dispatch = useDispatch();
  const [stateleadid, setleadid] = useState();
  const [statetype, settype] = useState();
  const [taskstate, settaskstate] = useState({
    LeadTaskId: 0,
    LeadId: 1,
    TaskForId: null,
    TaskTypeId: null,
    TaskSubTypeId: null,
    EntryTime: null, // time included mention it
    DueTime: null, // time also included here
    Description: null,
    Priority: null,
    Status: null,
    Comments: null,
  });
  var suggestionlist1 = [];

  const designationSelecttion = (e) => {
    const value = e.target.value;
    if (value == "") {
      ReactDOM.render(null, document.getElementById("unitdemo"));
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = populateLeadTask.data.Leads.sort().filter((v) =>
          regex.test(v.LeadIdentifier !== null ? v.LeadIdentifier : null)
        );
      }
      suggestionlist1 = suggestions;

      if (suggestionlist1.length === 0) {
        ReactDOM.render(null, document.getElementById("unitdemo"));
      } else {
        ReactDOM.render(
          <ul className="suggestbox">
            {suggestionlist1.map((item) => (
              <li className="listcss" onClick={() => suggestionselected1(item)}>
                {item === undefined ? item : item.LeadIdentifier}
              </li>
            ))}
          </ul>,
          document.getElementById("unitdemo")
        );
      }
    }
  };
  const onTextChangeEmployeeName = () => {
    var inputValue = document.getElementById("inittiate").value;
    var options = document.getElementById("employeenamelist").childNodes;

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        populateLeadTask.data.Leads.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });
        setleadid(val.LeadId);

        break;
      }
    }
  };
  const suggestionselected1 = (val) => {
    document.getElementById("inittiate").value = val.LeadIdentifier;
    setleadid(val.LeadId);

    // setUnitId(val.UnitId);

    suggestionlist1 = [];
    ReactDOM.render(null, document.getElementById("unitdemo"));
  };
  const saveleadtaskdata = () => {
    if (document.getElementById("inittiate").value === "") {
      toast.info("Select Lead Identifier");
    } else if (document.getElementById("EntryTime").value === "") {
      toast.info("EntryTime is Required");
    } else if (document.getElementById("DueTime").value === "") {
      toast.info("DueTime is Required");
    } else if (document.getElementById("TaskForId").selectedIndex === 0) {
      toast.info("Select Employee");
    } else if (document.getElementById("TaskTypeId").selectedIndex === 0) {
      toast.info("Select Task Type");
    } else if (document.getElementById("TaskSubTypeId").selectedIndex === 0) {
      toast.info("Select Sub Task ");
    } else if (document.getElementById("Priority").selectedIndex === 0) {
      toast.info("Select Priority");
    } else if (document.getElementById("Status").selectedIndex === 0) {
      toast.info("Select Status");
    } else if (document.getElementById("Description").value === "") {
      toast.info("Add Description");
    } else {
      let Lead = taskstate;
      Lead.LeadTaskId = 0;
      Lead.LeadId = parseInt(stateleadid);
      Lead.EntryTime = document.getElementById("EntryTime").value;

      Lead.DueTime = document.getElementById("DueTime").value;
      Lead.TaskForId = parseInt(
        document.getElementById("TaskForId").options[
          document.getElementById("TaskForId").selectedIndex
        ].dataset.id
      );
      Lead.TaskTypeId = parseInt(
        document.getElementById("TaskTypeId").options[
          document.getElementById("TaskTypeId").selectedIndex
        ].dataset.id
      );
      Lead.TaskSubTypeId = parseInt(
        document.getElementById("TaskSubTypeId").options[
          document.getElementById("TaskSubTypeId").selectedIndex
        ].dataset.id
      );
      Lead.Priority =
        document.getElementById("Priority").options[
          document.getElementById("Priority").selectedIndex
        ].value;
      Lead.Status =
        document.getElementById("Status").options[
          document.getElementById("Status").selectedIndex
        ].value;
      Lead.Description = document.getElementById("Description").value;
      Lead.Comments = document.getElementById("Comments").value;

      document.getElementById("leadsave").disabled = true;
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}Leads/Tasks/Add`,
        data: JSON.stringify(Lead),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          document.getElementById("leadsave").disabled = false;
          if (result.data.IsSuccess === true) {
            document.getElementById("DueTime").value = "";
            document.getElementById("EntryTime").value = "";
            document.getElementById("Description").value = "";
            document.getElementById("Status").selectedIndex = 0;
            document.getElementById("Priority").selectedIndex = 0;
            document.getElementById("TaskSubTypeId").selectedIndex = 0;
            document.getElementById("TaskTypeId").selectedIndex = 0;
            document.getElementById("TaskForId").selectedIndex = 0;

            toast.info(result.data.Message);
            props.canceltasktable();
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          toast.info("Someting went wrong");
          document.getElementById("leadsave").disabled = false;
        }
      );
    }
  };
  useEffect(() => {
    dispatch(getallLeadTaskPopulate());
  }, [dispatch]);
  return (
    <>
      <div className="row ">
        <div className="col-12  page_heading">
          <h4 className="  text-center ">Add Task</h4>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-4 mt-md-4  text-left p-0"></div>

        <div className="col-md-1 mt-md-4  text-left p-0">
          <label className="input_label m-0">Lead</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            list="employeenamelist"
            className="input_styling  ml-md-3"
            id="inittiate"
            onChange={onTextChangeEmployeeName}
            placeholder="Enter Lead Identifier"
          ></input>
          <span className="estaric">*</span>
          <datalist id="employeenamelist">
            {populateLeadTask?.data?.Leads?.map((x, idx) => (
              <option index={idx} value={x.LeadIdentifier}>
                {x.LeadId}
              </option>
            ))}
          </datalist>
          <span id="unitdemo"></span>
        </div>
        <div className="col-md-2 mt-md-4  text-left p-0"></div>
      </div>

      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">EntryTime :</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="datetime-local"
            className="input_date_styling  ml-md-3"
            id="EntryTime"
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">DueTime</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="datetime-local"
            className="input_date_styling  ml-md-3"
            id="DueTime"
          ></input>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Employee :</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <select className="input_styling ml-md-3" id="TaskForId">
            <option>Select</option>

            {populateLeadTask.data !== undefined &&
            populateLeadTask.data.Employees !== undefined
              ? populateLeadTask.data.Employees.map((val, index) => (
                  <option key={index} data-id={val.EmployeeId}>
                    {val.EmpName}
                  </option>
                ))
              : null}
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Task Type</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <select
            className="input_styling ml-md-3"
            id="TaskTypeId"
            onChange={() => {
              settype(
                document.getElementById("TaskTypeId").options[
                  document.getElementById("TaskTypeId").selectedIndex
                ].dataset.id
              );
            }}
          >
            <option>Select</option>

            {populateLeadTask.data !== undefined &&
            populateLeadTask.data.TaskTypes !== undefined
              ? populateLeadTask.data.TaskTypes.map((val, index) => (
                  <option key={index} data-id={val.TaskTypeId}>
                    {val.TypeName}
                  </option>
                ))
              : null}
          </select>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Task SubType :</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <select className="input_styling ml-md-3" id="TaskSubTypeId">
            <option>Select</option>

            {populateLeadTask.data !== undefined &&
            populateLeadTask.data.TaskSubTypes !== undefined
              ? populateLeadTask.data.TaskSubTypes.map((val, index) =>
                  parseInt(statetype) === parseInt(val.TaskTypeId) ? (
                    <option key={index} data-id={val.TaskSubTypeId}>
                      {val.SubTypeName}
                    </option>
                  ) : null
                )
              : null}
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Priority</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <select className="input_styling ml-md-3" id="Priority">
            <option>Select</option>
            <option>High</option>
            <option>Medium</option>
            <option>Low</option>
          </select>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Status :</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <select
            className="input_styling ml-md-3"
            id="Status"
            onChange={(e) => {
              if (e.target.value === "Done") {
                document.getElementById("hideshow").style.display = "inline";
              } else {
                document.getElementById("hideshow").style.display = "none";
              }
            }}
          >
            <option>Select</option>
            <option>Pending</option>
            <option>Done</option>
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Description</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <textarea
            className="input_address ml-md-3 "
            id="Description"
          ></textarea>
          <span className="estaric">*</span>
        </div>
      </div>
      <header id="hideshow" style={{ display: "none" }}>
        <div className="row ">
          <div className="col-md-2 mt-md-4  text-left p-0">
            <label className="input_label m-0">Comments</label>
          </div>
          <div className="col-md-3 mt-md-4   p-0">
            <textarea
              className="input_address ml-md-3 "
              id="Comments"
            ></textarea>
            <span className="estaric">*</span>
          </div>
        </div>
      </header>
      <div className=" float-right mt-md-4 ">
        <span>
          <button className="cancel_btn ml-2  " onClick={props.canceltasktable}>
            Cancel
          </button>
        </span>

        <span>
          <button
            className="save_btn ml-2  "
            id="leadsave"
            onClick={saveleadtaskdata}
          >
            <BsCloudUpload size="1.3em" />
            Save
          </button>
        </span>
      </div>

      {/* <div className="row">
                            <div className=" col-12 text-right pr-0 pl-0">
                                <button className="print_btn float-right" ><TiPrinter size="1.3em" />Print</button>


                            </div>
                        </div> */}
    </>
  );
};
export const AddtaskTable = (props) => {
  const resultalltasks = useSelector((state) => state.getLeadTasktable);

  const [stateeditlead, seteditlead] = useState([]);
  const [stateleadid, setleadid] = useState();
  const [filerunits, setfilterunits] = useState();

  let dispatch = useDispatch();
  // print function
  // const componentRef = useRef();

  // const printPaymentRec = useReactToPrint({
  //     content: () => componentRef.current,
  // });
  //Table Data fiter

  var suggestionlist = [];

  const onTextChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setfilterunits(resultalltasks.data);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");

        suggestions = filerunits
          .sort()
          .filter((val) => regex.test(val.Priority) || regex.test(val.Status));
      }
      suggestionlist = suggestions;
      setfilterunits(suggestionlist);
    }
  };
  const [taskstate, settaskstate] = useState({
    LeadTaskId: 0,
    LeadId: 1,
    TaskForId: null,
    TaskTypeId: null,
    TaskSubTypeId: null,
    EntryTime: null, // time included mention it
    DueTime: null, // time also included here
    Description: null,
    Priority: null,
    Status: null,
    Comments: null,
  });
  const saveleadtaskdata = () => {
    let Lead = taskstate;
    Lead.LeadTaskId = parseInt(stateeditlead.LeadTaskId);
    Lead.LeadId = parseInt(stateleadid);
    Lead.EntryTime = null;

    Lead.DueTime = document.getElementById("DueTime").value;
    Lead.TaskForId = null;
    Lead.TaskTypeId = null;
    Lead.TaskSubTypeId = null;
    Lead.Priority =
      document.getElementById("Priority").options[
        document.getElementById("Priority").selectedIndex
      ].value;
    Lead.Status =
      document.getElementById("Status").options[
        document.getElementById("Status").selectedIndex
      ].value;
    Lead.Description = null;
    Lead.Comments = document.getElementById("Comments").value;

    document.getElementById("leadsave").disabled = true;
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}Leads/Tasks/Add`,
      data: JSON.stringify(Lead),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        document.getElementById("leadsave").disabled = false;
        if (result.data.IsSuccess === true) {
          document.getElementById("Status").selectedIndex = 0;
          document.getElementById("Priority").selectedIndex = 0;
          document.getElementById("Comments").value = "";
          // document.getElementById("DueTime").value = "";

          toast.info(result.data.Message);
          document.getElementById("modalofleadedit").style.display = "none";
          dispatch(getallLeadTaskTable());
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Someting went wrong");
        document.getElementById("leadsave").disabled = false;
      }
    );
  };
  useEffect(() => {
    dispatch(getallLeadTaskTable());
  }, [dispatch]);
  useEffect(() => {
    setfilterunits(resultalltasks.data);
  }, [resultalltasks]);
  const openmodalleadsedit = (x) => {
    setleadid(x.LeadId);
    seteditlead(x);
    document.getElementById("modalofleadedit").style.display = "block";
  };
  return (
    <>
      {/* <div hidden>
                <ClientPrint
                    ref={componentRef}

                    printData={resultalltasks}

                /></div> */}
      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label m-0">Search:</label>
        </div>
        <div className="col-md-3 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            placeholder="Priority/Status"
            onChange={onTextChange}
          ></input>
        </div>

        <div className="col-md-1 pr-0 pl-0">
          {resultalltasks.loading ? (
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          ) : null}
        </div>
      </div>
      <div className="row ">
        <div className=" col-12  table_height pr-0 pl-0">
          <div className="table-responsive mt-4">
            <table className="table table-borderless table-hover m-0">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>EntryTime</th>

                  <th>DueTime</th>
                  <th>TaskFor</th>
                  <th>TaskType.</th>
                  <th>TaskSubType</th>
                  <th>Priority</th>
                  <th>Comment</th>

                  <th>Status</th>

                  <th className="text-center">
                    <button
                      className="save_btn"
                      onClick={() => {
                        props.showtasktable();
                      }}
                    >
                      New+
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {filerunits !== undefined
                  ? filerunits.map((val, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{val.EntryTime}</td>
                        <td>{val.DueTime}</td>
                        <td>{val.TaskFor.EmpName}</td>
                        <td>{val.TaskType.TypeName}</td>
                        <td>{val.TaskSubType.SubTypeName}</td>

                        <td>{val.Priority}</td>
                        <td>{val.Comments}</td>
                        <td>{val.Status}</td>

                        <td className="text-center">
                          {val.Status !== "Done" ? (
                            <FiEdit2
                              size="1em"
                              onClick={() => {
                                openmodalleadsedit(val);
                              }}
                            />
                          ) : null}
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
        {/* <div className="col-6  text-right pr-0 pl-0">
                    <button className="print_btn float-left" onClick={() => { printPaymentRec(); }}><TiPrinter size="1.3em" />Print</button>


                </div> */}
      </div>

      <div id="modalofleadedit" class="modalscreen ">
        <div class="modal-content-screen1 pt-1">
          <div className="row ">
            <div className="col-12  page_heading">
              <h4 className="  text-center ">Edit Task</h4>
              <span
                class="close pr-2  "
                onClick={() => {
                  document.getElementById("modalofleadedit").style.display =
                    "none";
                }}
              >
                &times;
              </span>
            </div>
          </div>
          <div className="container">
            <div className="row ">
              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0">DueTime</label>
              </div>
              <div className="col-md-3 mt-md-4   p-0">
                <input
                  type="datetime-local"
                  className="input_date_styling  ml-md-3"
                  key={stateeditlead.DueTime}
                  defaultValue={stateeditlead.DueTime}
                  id="DueTime"
                ></input>
              </div>
              <div className="col-md-2 mt-md-4   p-0"></div>
              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0">Priority</label>
              </div>
              <div className="col-md-3 mt-md-4   p-0">
                <select className="input_styling ml-md-3" id="Priority">
                  {stateeditlead.Priority !== null ? (
                    <option selected>{stateeditlead.Priority}</option>
                  ) : (
                    <option selected>Select</option>
                  )}
                  <option>High</option>
                  <option>Medium</option>
                  <option>Low</option>
                </select>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0 ">Status :</label>
              </div>
              <div className="col-md-3 mt-md-4  p-0">
                <select
                  className="input_styling ml-md-3"
                  id="Status"
                  onChange={(e) => {
                    if (e.target.value === "Done") {
                      document.getElementById("hideshow").style.display =
                        "inline";
                      document.getElementById("hideshow1").style.display =
                        "inline";
                    } else {
                      document.getElementById("hideshow").style.display =
                        "none";
                      document.getElementById("hideshow1").style.display =
                        "none";
                    }
                  }}
                >
                  {stateeditlead.Status !== null ? (
                    <option selected> {stateeditlead.Status}</option>
                  ) : (
                    <option selected> Select</option>
                  )}

                  <option>Pending</option>
                  <option>Done</option>
                </select>
              </div>
              <div className="col-md-2 mt-md-4   p-0"></div>

              <div className="col-md-2 mt-md-4  text-left p-0">
                <span id="hideshow" style={{ display: "none" }}>
                  {" "}
                  <label className="input_label m-0 ">Comment:</label>
                </span>
              </div>
              <div className="col-md-3 mt-md-4  p-0">
                <span id="hideshow1" style={{ display: "none" }}>
                  {" "}
                  <textarea
                    className="input_address ml-md-3 "
                    id="Comments"
                    key={stateeditlead.Comments}
                    defaultValue={
                      stateeditlead !== undefined
                        ? stateeditlead.Comments
                        : null
                    }
                  ></textarea>
                </span>
              </div>
            </div>

            <div className="row ">
              <div className=" col-12 float-right mt-md-4 ">
                <span>
                  <button
                    className="save_btn ml-2  "
                    id="leadsave"
                    onClick={saveleadtaskdata}
                  >
                    <BsCloudUpload size="1.3em" />
                    Save
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
