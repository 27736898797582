import axios from "axios";
import { toast } from "react-toastify";
export const GetAllContractPopulate = (data) => {
  return {
    type: "get_allcontractpopulate",
    payload: data,
    loader: false,
  };
};
export function getallcontractpopulate() {
  return (dispatch) => {
    axios({
      url: `${process.env.REACT_APP_API_URL}Contracts/Populate`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllContractPopulate(result.data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}

export const GetAllContractTableData = (data) => {
  return {
    type: "get_contracttable",
    payload: data,
    loader: false,
  };
};
export function getAllContractTable(data, link) {
  return (dispatch) => {
    let pgurl = `${
      link
        ? link + "&"
        : process.env.REACT_APP_API_URL + "Contracts/GetContracts?"
    }From=${data.From ? data.From : ""}&To=${
      data.To ? data.To : ""
    }&ProjectId=${data?.ProjectId ? data?.ProjectId : ""}&&ContractId=${
      data.ContractId ? data.ContractId : ""
    }&&Status=${data.Status ? data.Status : ""}&&UnitName=${
      data.UnitName ? data.UnitName : ""
    }&&AffliateCnic=${
      data.AffiliateCnic ? data.AffiliateCnic : ""
    }&&CustomerCnic=${data.CustomerCnic ? data.CustomerCnic : ""}`;

    // if (localStorage.getItem("ProjectId") === null) {
    //     pgurl = `${ process.env.REACT_APP_API_URL } Contracts / Get ? from = ${ data.From }& to=${ data.To }& projectId=${ data.ProjectId } `
    // }
    axios({
      url: pgurl,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")} `,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.Succeeded === true) {
          dispatch(GetAllContractTableData(result.data));
        } else {
          toast.info(result.data.message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
export const GetContractTablePopulateData = (data) => {
  return {
    type: "get_contractPopulateData",
    payload: data,
    loader: false,
  };
};
export function getAllContractTablePopulate() {
  return (dispatch) => {
    axios({
      url:
        process.env.REACT_APP_API_URL +
        `Contracts/PopulateFilter?ProjectId=${localStorage.getItem(
          "ProjectId"
        )}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")} `,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log(result);
        if (result.data.IsSuccess === true) {
          dispatch(GetContractTablePopulateData(result.data));
        } else {
          toast.info(result.data.message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}

export const GetContractDetails = (data) => {
  return {
    type: "get_contractDetails",
    payload: data,
    loader: false,
  };
};
export function getContractDetails(ContractId) {
  return (dispatch) => {
    axios({
      url:
        process.env.REACT_APP_API_URL +
        `Contracts/GetContractDetials?ContractId=${ContractId}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")} `,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log(result);
        if (result.data.Succeeded === true) {
          dispatch(GetContractDetails(result.data));
        } else {
          toast.info(result.data.message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
