import axios from "axios";
import { toast } from "react-toastify";
export const GetAllCommissionReceipt = (data) => {
  return {
    type: "get_allcommissionreceipt",
    payload: data,
    loader: false,
  };
};
export function getallcommissionreceipt() {
  return (dispatch) => {
    axios({
      url: `${
        process.env.REACT_APP_API_URL
      }Vouchers/CommissionReceipt/Populate?ProjectId=${localStorage.getItem(
        "ProjectId"
      )}`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllCommissionReceipt(result.data.Data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
export const GetAllCRTableData = (data) => {
  return {
    type: "get_allComRectabledata",
    payload: data,
    loader: false,
  };
};
export function getallCRpopulatetable(data, link) {
  return (dispatch) => {
    let pgurl = `${
      link
        ? link + "&"
        : process.env.REACT_APP_API_URL + "Vouchers/CommissionReceipt/Get?"
    }ProjectId=${localStorage.getItem("ProjectId")}&From=${
      data.From ? data.From : ""
    }&To=${data.To ? data.To : ""}&FilterDate=${
      data.FilterDate ? data.FilterDate : ""
    }&VoucherId=${data.VoucherId ? data.VoucherId : ""}&SellerId=${
      data.SellerId ? data.SellerId : ""
    }&UnitId=${data.UnitId ? data.UnitId : ""}`;
    if (localStorage.getItem("ProjectId") === null) {
      pgurl = `${process.env.REACT_APP_API_URL}Vouchers/CommissionReceipt/Get`;
    }
    axios({
      url: pgurl,
      method: "Post",
      data: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.Succeeded === true) {
          dispatch(GetAllCRTableData(result.data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
