import axios from "axios";
import { toast } from "react-toastify";
export const GetAllTransferContractPopulate = (data) => {
  return {
    type: "get_alltransfercontractpopulate",
    payload: data,
    loader: false,
  };
};
export function getalltransfercontractpopulate() {
  return (dispatch) => {
    let pgurl = `${
      process.env.REACT_APP_API_URL
    }Contracts/Transfer/Populate?ProjectId=${localStorage.getItem(
      "ProjectId"
    )}`;
    if (localStorage.getItem("ProjectId") === null) {
      pgurl = `${process.env.REACT_APP_API_URL}Contracts/Transfer/Populate`;
    }
    axios({
      url: pgurl,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllTransferContractPopulate(result.data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}

export const GetAllTransferContractTableData = (data) => {
  return {
    type: "get_transfercontracttable",
    payload: data,
    loader: false,
  };
};
export function getAllTransferContractTable(data) {
  return (dispatch) => {
    let pgurl = `${
      process.env.REACT_APP_API_URL
    }Contracts/Transfer/GetAll?ProjectId=${localStorage.getItem("ProjectId")}`;
    if (localStorage.getItem("ProjectId") === null) {
      pgurl = `${process.env.REACT_APP_API_URL}Contracts/Transfer/GetAll`;
    }
    axios({
      url: pgurl,
      method: "Post",
      data: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(
            GetAllTransferContractTableData(result.data.TransferContract)
          );
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
