import React from "react";
import { BsCloudUpload } from "react-icons/bs";
import Loader from "react-loader-spinner";

const BookStatus = ({
  open,
  handleClose,
  // selectedStatus,
  Bookings,
  StatusChange,
  isLoading,
  reduction,
  setReduction,
  // setselectedStatus,
  handleDeductChange,
}) => {
  return (
    <div
      id="mymodal"
      className="modal"
      style={{ display: "block" }}
      open={open}
    >
      <div
        style={{
          margin: " 12% 33%",
          height: "23%",
          width: "45%",
          background: "white",
        }}
      >
        <div className="row">
          <div className="col-12  page_heading">
            <h4 className="  text-center pt-2">Cancel Unit Booking</h4>
            <span class="close pr-2  " onClick={handleClose}>
              &times;
            </span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "15px",
            gap: "20px",
          }}
        >
          {/* <div style={{ display: 'flex', flexDirection: 'row', gap: '137px' }}>
                        <label >Status</label>
                        <select
                            name="priority"
                            value={selectedStatus}
                            onChange={(e) => { setselectedStatus(e.target.value) }}
                            className="input_styling"
                            style={{ width: '40%' }}
                        >
                            <option value="" disabled>Select</option>
                            <option value="Available" >Available</option>
                            <option value="Converted">Converted</option>
                            <option value="Cancel">Cancel</option>

                        </select>
                    </div> */}
          <div style={{ display: "flex", flexDirection: "row", gap: "47px" }}>
            <label>Deduction Amount</label>
            <input
              type="text"
              className="input_styling"
              style={{ width: "40%" }}
              value={reduction}
              onChange={handleDeductChange}
              // onChange={(e) => {
              //     const inputValue = e.target.value;
              //     const input = parseFloat(inputValue.replace(/,/g, ''));
              //     if (!isNaN(input)) {
              //         setReduction(input.toLocaleString());
              //     } else {
              //         setReduction('');
              //     }
              // }}
            />
          </div>

          <div
            className=" float-right mt-md-4"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <span>
              {isLoading && (
                <Loader type="ThreeDots" color="green" height={40} width={40} />
              )}
            </span>
            <span>
              <button className="cancel_btn ml-2  " onClick={handleClose}>
                Cancel
              </button>
            </span>

            <span>
              <button
                className="save_btn ml-2  "
                id="savedata"
                onClick={StatusChange}
                disabled={isLoading}
              >
                <BsCloudUpload size="1.3em" />
                Save
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookStatus;
