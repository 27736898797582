import axios from "axios";
import { toast } from "react-toastify";
export const GetAllSectionCat = (data) => {
  return {
    type: "get_allsectioncat",
    payload: data,
    loader: false,
  };
};
export function getallSectionCat() {
  return (dispatch) => {
    axios({
      url: `${process.env.REACT_APP_API_URL}Projects/Category/GetAll`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllSectionCat(result.data.Data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
export const GetAllSectionSubCat = (data) => {
  return {
    type: "get_allsectionsubcat",
    payload: data,
    loader: false,
  };
};
export function getallSectionSubCat() {
  return (dispatch) => {
    axios({
      url: `${process.env.REACT_APP_API_URL}Projects/Subcategory/GetAll`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllSectionSubCat(result.data.Data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
export const GetAllSectionType = (data) => {
  return {
    type: "get_allsectiontype",
    payload: data,
    loader: false,
  };
};
export function getallSectionType() {
  return (dispatch) => {
    axios({
      url: `${process.env.REACT_APP_API_URL}Projects/SectionType/GetAll`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllSectionType(result.data.Data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
