import axios from "axios";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { MdDeleteForever } from "react-icons/md";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import {
  GetDealBoughtData,
  getdealboughtpopulatetable,
} from "../../../actions/inventoryactions/dealbought";
import DealBoughtPrint from "../DealsPrints/DealBoughtPrint";
import { DealIndvidualPrint } from "../DealsPrints/DealIndvidualPrint";
import { CSVLink } from "react-csv";

export const DealBoughtTable = (props) => {
  const resultsalldealbought = useSelector((state) => state.getDealBoughtRed);
  const [dealsolddata, setdealsolddata] = useState({});
  const [statedealboughtid, setdealboughtid] = useState();
  const [printIndvidualData, SetPrintIndvidualData] = useState();
  const dispatch = useDispatch();

  // print function

  const AllcomponentRef = useRef();

  const AllprintPaymentRec = useReactToPrint({
    content: () => AllcomponentRef.current,
  });

  const IndvidualcomponentRef = useRef();

  const IndividualprintPaymentRec = useReactToPrint({
    content: () => IndvidualcomponentRef.current,
  });

  //Table Data fiter

  var suggestionlist = [];

  const onTextChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      dispatch(getdealboughtpopulatetable());
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");

        suggestions = resultsalldealbought.data
          .sort()
          .filter((val) => regex.test(val.DealName));
      }
      suggestionlist = suggestions;
      dispatch(GetDealBoughtData(suggestionlist));
    }
  };
  useEffect(() => {
    dispatch(getdealboughtpopulatetable());
  }, [dispatch]);
  function showmodal(data) {
    document.getElementById("mymodal").style.display = "block";
    setdealsolddata(data);
    setdealboughtid(data.DealBoughtId);
  }
  function closemodal() {
    document.getElementById("mymodal").style.display = "none";
  }
  function deleteunit(unitid) {
    const check = window.confirm("Do you really want to delete unit?");
    if (check == true) {
      axios({
        url: `${process.env.REACT_APP_API_URL}Deals/Unit/Delete?UnitId=${unitid}&DealBoughtId=${statedealboughtid}`,
        method: "delete",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result.data.IsSuccess === true) {
            toast.info(result.data.Message);

            dispatch(getdealboughtpopulatetable());
            closemodal();
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          toast.info("Something went wrong!");
        }
      );
    }
  }
  const [CsvData, setCsvData] = useState([]);
  useEffect(() => {
    let arr = [];
    resultsalldealbought.data?.forEach((val, index) => {
      arr.push({
        Sr: index + 1,
        "Deal Name": val.DealName,
        Project:
          val.Project !== undefined && val.Project !== null
            ? val.Project.ProjectName
            : null,
        Seller:
          val.Seller !== undefined && val.Seller !== null
            ? val.Seller.SellerName
            : null,
        "Total Amt.": parseFloat(val.TotalAmount).toLocaleString(),
        "Deal Amt.": parseFloat(val.DealAmount).toLocaleString(),
        "Amt Per": val.DealAmountPer.toFixed(2),
        "Comm Per": val.CommissionPer,
        "Profit Mrg Per": val.ProfitMarginPer,
      });
    });
    setCsvData(arr);
  }, [resultsalldealbought.data]);

  return (
    <>
      <div hidden>
        <DealBoughtPrint
          ref={AllcomponentRef}
          printData={resultsalldealbought}
        />
      </div>

      <div hidden>
        <DealIndvidualPrint
          ref={IndvidualcomponentRef}
          printData={printIndvidualData}
        />
      </div>
      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label m-0">Search:</label>
        </div>
        <div className="col-md-3 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            placeholder="Deal Name"
            onChange={onTextChange}
          ></input>
        </div>
        {/* <div className="col-md-3 text-center  pr-0 pl-0">
                    <label className="input_label m-0">From:</label>
                    <input type="date" className="input_date ml-3" id="from date"></input>
                </div>
                <div className="col-md-3 text-center pr-0 pl-0">
                    <label className="input_label m-0">To:</label>
                    <input type="date" className="input_date ml-3" id="todate"></input>
                </div>
                <div className="col-md-1 pr-0 pl-0">
                    <button className="btn_Go">Go</button>
                </div> */}
        <div className="col-md-1 pr-0 pl-0">
          {resultsalldealbought.loading ? (
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          ) : null}
        </div>
      </div>
      <div className="row ">
        <div className=" col-12  table_height pr-0 pl-0">
          <div className="table-responsive mt-4">
            <table className="table table-borderless m-0">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th> Deal Name</th>
                  <th>Project </th>

                  <th> Seller</th>
                  <th>Total Amt.</th>
                  <th>Deal Amt.</th>
                  <th>Amt Per</th>
                  <th>Comm Per</th>

                  <th>Profit Mrg Per</th>

                  <th className="text-center">
                    <button
                      className="save_btn"
                      onClick={() => {
                        props.showprojecttable();
                      }}
                    >
                      New+
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {resultsalldealbought.data !== undefined
                  ? resultsalldealbought.data.map((val, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          showmodal(val);
                          SetPrintIndvidualData(val);
                        }}
                      >
                        <td>{index + 1}</td>
                        <td>{val.DealName}</td>
                        <td>
                          {val.Project !== undefined && val.Project !== null
                            ? val.Project.ProjectName
                            : null}
                        </td>
                        <td>
                          {val.Seller !== undefined && val.Seller !== null
                            ? val.Seller.SellerName
                            : null}
                        </td>
                        <td>{parseFloat(val.TotalAmount).toLocaleString()}</td>
                        <td>{parseFloat(val.DealAmount).toLocaleString()}</td>
                        <td>{val.DealAmountPer.toFixed(2)}</td>
                        <td>{val.CommissionPer}</td>
                        <td>{val.ProfitMarginPer}</td>

                        <td></td>
                        {/* <td className="text-center cur_sor" onClick={() => SetPrintData(val)}>
                                            <GrPrint size="1.3em" />
                                        </td> */}
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-6  text-right pr-0 pl-0">
          <button
            className="print_btn float-left"
            onClick={() => {
              AllprintPaymentRec();
            }}
          >
            <TiPrinter size="1.3em" />
            Print
          </button>
          <button
            style={{ color: "black !important" }}
            className="print_btn ml-2 float-left"
          >
            <CSVLink
              style={{ color: "black" }}
              filename="Deal_Bought_Csv"
              data={CsvData}
            >
              Download CSV
            </CSVLink>
          </button>
        </div>
      </div>
      <div id="mymodal" class="modal">
        <div class="modal-content-deal">
          <div className="row">
            <div className="col-12  page_heading">
              <h4 className="  text-center pt-2">Deal Bought Units</h4>
              <span class="close pr-2  " onClick={closemodal}>
                &times;
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-12 mt-2">
              <table class="table  table-borderless">
                <thead class="purple whiteText">
                  <tr>
                    <th scope="col">Sr</th>

                    <th scope="col">Unit</th>
                    <th scope="col">Size</th>
                    <th scope="col">Sqft</th>
                    <th scope="col">Purchase Price</th>

                    <th scope="col">Sale Price</th>
                    <th scope="col">Del</th>
                  </tr>
                </thead>
                <tbody>
                  {dealsolddata === undefined ||
                  dealsolddata.Units === undefined
                    ? // contractinstallment.length == 0
                      null
                    : dealsolddata.Units.map((x, id) => (
                        <tr key={id}>
                          <td>{id + 1}</td>
                          <td>{x.UnitName}</td>
                          <td>{x.Marla + "M " + x.Sarsai + " S"}</td>
                          <td>{x.NetSqft}</td>
                          <td>
                            {x.TotalCost !== null
                              ? x.TotalCost.toLocaleString()
                              : null}
                          </td>
                          <td>
                            {x.Price !== null ? x.Price.toLocaleString() : null}
                          </td>

                          <td
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteunit(x.UnitId)}
                          >
                            <MdDeleteForever size="1.6em" />
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
            <div className="col-12 text-right">
              <button
                className="print_btn mr-2 "
                onClick={() => {
                  IndividualprintPaymentRec();
                }}
              >
                <TiPrinter size="1.3em" />
                Print
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
