import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import {
  GetAllLand,
  getallland,
  getalllandbyId,
} from "../../../actions/inventoryactions/getlandaction";
import { LandIndvidualPrint } from "../LandPrints/LandIndvidualPrint";
import { LandPrint } from "../LandPrints/LandPrint";
import { CSVLink } from "react-csv";
import FullScreenLoader from "../../../FullScreenLoader/FullScreenLoader";

export const LandTable = (props) => {
  const resultallland = useSelector((state) => state.getAllLand);
  const [landinstallment, setlandinstallment] = useState();
  const [printData, SetPrintData] = useState();
  const rowData = useSelector((state) => state.getAllLandById);
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  const componentRefindividual = useRef();

  const printIndvidualContract = useReactToPrint({
    content: () => componentRefindividual.current,
  });
  // print function
  const componentRef = useRef();

  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });
  //Table Data fiter

  var suggestionlist = [];

  const onTextChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      dispatch(getallland());
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");

        suggestions = resultallland.data
          .sort()
          .filter((val) => regex.test(val.LandName));
      }
      suggestionlist = suggestions;
      dispatch(GetAllLand(suggestionlist));
    }
  };
  useEffect(() => {
    dispatch(getallland());
  }, [dispatch]);
  const openmodallandtable = (x) => {
    document.getElementById("modaloflandtable").style.display = "block";
  };
  const [CsvData, setCsvData] = useState([]);

  useEffect(() => {
    if (loader) {
      if (rowData?.data?.Data) {
        setlandinstallment(rowData?.data?.Data);
        SetPrintData(rowData?.data?.Data);
        document.getElementById("modaloflandtable").style.display = "block";
      }
      setLoader(false);
    }
  }, [rowData]);
  useEffect(() => {
    let arr = [];
    resultallland.data?.forEach((val, index) => {
      arr.push({
        Sr: index + 1,
        "Entry Date":
          val.EntryDate !== null
            ? moment(val.EntryDate.split("T")[0]).format("DD-MMM-YYYY")
            : null,
        Creditor:
          val.LandCreditor !== undefined
            ? val.LandCreditor.LandCreditorName
            : null,
        "Land Name": val.LandName,
        "Land Value": val.LandValue.toLocaleString(),
        Size:
          val.MeasureUnit !== undefined
            ? val.MeasureValue + "" + val.MeasureUnit.Unit
            : null,
        "Pay Plan": val.PaymentPlan,
      });
    });
    setCsvData(arr);
  }, [resultallland.data]);
  return (
    <>
      <div hidden>
        <LandPrint
          ref={componentRef}
          printData={resultallland}
        />
      </div>
      <div hidden>
        <LandIndvidualPrint
          ref={componentRefindividual}
          printData={printData}
        />
      </div>
      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label m-0">Search:</label>
        </div>
        <div className="col-md-3 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            onChange={onTextChange}
            placeholder=" Land Name "
          ></input>
        </div>
        {/* <div className="col-md-3 text-center  pr-0 pl-0">
                    <label className="input_label m-0">From:</label>
                    <input type="date" className="input_date ml-3" id="from date"></input>
                </div>
                <div className="col-md-3 text-center pr-0 pl-0">
                    <label className="input_label m-0">To:</label>
                    <input type="date" className="input_date ml-3" id="todate"></input>
                </div>
                <div className="col-md-1 pr-0 pl-0">
                    <button className="btn_Go">Go</button>
                </div> */}
        <div className="col-md-1 pr-0 pl-0">
          {resultallland.loading ? (
            <Loader
              type="ThreeDots"
              color="green"
              height={40}
              width={40}
            />
          ) : null}
        </div>
      </div>

      <div className="row ">
        <div className=" col-12  table_height pr-0 pl-0">
          <div className="table-responsive mt-4">
            <table className="table table-borderless m-0">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Entry Date</th>

                  <th>Creditor</th>
                  <th>Land Name</th>
                  <th>Land Value</th>
                  <th>Size</th>

                  <th>Pay Plan</th>

                  <th className="text-center">
                    <button
                      className="save_btn"
                      onClick={() => {
                        props.showlandtable();
                      }}
                    >
                      New+
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {resultallland.data.map((val, index) => (
                  <tr
                    key={index + 1}
                    className="cur_sor"
                    onClick={() => {
                      // openmodallandtable(val);
                      // SetPrintData(val);
                      dispatch(getalllandbyId(val?.LandPurchasedId));
                      setLoader(true);
                    }}
                  >
                    <td>{index + 1}</td>
                    <td>
                      {val.EntryDate !== null
                        ? moment(val.EntryDate.split("T")[0]).format(
                            "DD-MMM-YYYY"
                          )
                        : null}
                    </td>
                    <td>
                      {val.LandCreditor !== undefined
                        ? val.LandCreditor.LandCreditorName
                        : null}
                    </td>
                    <td>{val.LandName}</td>
                    <td>{val.LandValue.toLocaleString()}</td>
                    <td>
                      {val.MeasureUnit !== undefined
                        ? val.MeasureValue + "" + val.MeasureUnit.Unit
                        : null}
                    </td>

                    <td>{val.PaymentPlan}</td>

                    <td></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-6  text-right pr-0 pl-0">
          <button
            className="print_btn float-left"
            onClick={() => {
              printPaymentRec();
            }}
          >
            <TiPrinter size="1.3em" />
            Print
          </button>
          <button
            style={{ color: "black !important" }}
            className="print_btn ml-2 float-left"
          >
            <CSVLink
              style={{ color: "black" }}
              filename="Land_Csv"
              data={CsvData}
            >
              Download CSV
            </CSVLink>
          </button>
        </div>
      </div>
      <div
        id="modaloflandtable"
        class="modalscreen "
      >
        <div class="modal-content-screen1 pt-1">
          <div className="row ">
            <div className="col-12  page_heading">
              <h4 className="  text-center ">Land Detail</h4>
              <span
                class="close pr-2  "
                onClick={() => {
                  document.getElementById("modaloflandtable").style.display =
                    "none";
                }}
              >
                &times;
              </span>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12 mt-2">
                <div className="table-responsive mt-4 table_height-land">
                  <div className="row">
                    <div className="col-12 ">
                      <h6 className="  text-center pt-2">
                        Transactions Detail
                      </h6>
                    </div>
                  </div>

                  <table className="table table-borderless m-0 ">
                    <thead>
                      <tr>
                        <th>Sr</th>
                        <th>Account</th>
                        <th>Debit</th>

                        <th>Credit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rowData?.data !== undefined &&
                      rowData?.data?.transactions !== undefined
                        ? rowData?.data?.transactions?.map((x, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{x.Account.AccountTitle}</td>
                              <td>
                                {x.TransactionType === "DEBIT"
                                  ? x.Amount.toLocaleString()
                                  : null}
                              </td>

                              <td>
                                {x.TransactionType === "CREDIT"
                                  ? x.Amount.toLocaleString()
                                  : null}
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 mt-2">
                <ul>
                  <li>
                    <b>Entry Date:</b>{" "}
                    {landinstallment !== undefined &&
                    landinstallment.EntryDate !== undefined
                      ? moment(landinstallment.EntryDate.split("T")[0]).format(
                          "DD-MMM-YYYY"
                        )
                      : null}
                  </li>
                  <li>
                    <b> Creditor:</b>{" "}
                    {landinstallment !== undefined &&
                    landinstallment.LandCreditor !== undefined
                      ? landinstallment.LandCreditor.LandCreditorName
                      : null}
                  </li>
                  <li>
                    <b> Payment Plan:</b>{" "}
                    {landinstallment !== undefined &&
                    landinstallment.PaymentPlan !== undefined
                      ? landinstallment.PaymentPlan
                      : null}
                  </li>

                  <li>
                    <b> Kh No:</b>{" "}
                    {landinstallment !== undefined &&
                    landinstallment.KhewatNumber !== null
                      ? landinstallment.KhewatNumber
                      : null}
                  </li>
                  <li>
                    <b> Advance:</b>{" "}
                    {landinstallment !== undefined &&
                    landinstallment.Advance !== null
                      ? landinstallment?.Advance?.toLocaleString()
                      : null}
                  </li>
                </ul>
              </div>
              <div className="col-6 mt-2">
                <ul>
                  <li>
                    <b>Land Name:</b>{" "}
                    {landinstallment !== undefined &&
                    landinstallment.LandName !== undefined
                      ? landinstallment.LandName
                      : null}
                  </li>
                  <li>
                    <b> Land Value:</b>{" "}
                    {landinstallment !== undefined &&
                    landinstallment.LandValue !== null
                      ? landinstallment?.LandValue?.toLocaleString()
                      : null}
                  </li>
                  <li>
                    <b>Size:</b>{" "}
                    {landinstallment !== undefined &&
                    landinstallment.MeasureValue !== null &&
                    landinstallment.MeasureUnit !== null
                      ? landinstallment.MeasureValue +
                        "" +
                        landinstallment?.MeasureUnit?.Unit
                      : null}
                  </li>
                  <li>
                    <b> Sq No:</b>{" "}
                    {landinstallment !== undefined &&
                    landinstallment.SqaNumber !== undefined
                      ? landinstallment.SqaNumber
                      : null}
                  </li>
                  <li>
                    <b> Advance Paid:</b>{" "}
                    {landinstallment !== undefined &&
                    landinstallment.AdvancePaid !== null
                      ? landinstallment?.AdvancePaid?.toLocaleString()
                      : null}
                  </li>
                </ul>
              </div>
            </div>

            <div className="text-center mt-2">
              <b>Installments</b>
            </div>
            <table class="table ">
              <thead class="purple whiteText">
                <tr>
                  <th scope="col">Sr</th>
                  <th scope="col">Due Date</th>
                  <th scope="col">Total Amount</th>
                  <th scope="col">Paid Amount</th>
                  <th scope="col">Remaining Amount</th>
                </tr>
              </thead>
              <tbody>
                {landinstallment === undefined ||
                landinstallment.LandPurchasedInstallment === undefined
                  ? // landinstallment.length == 0
                    null
                  : landinstallment?.LandPurchasedInstallment?.map((x, id) => (
                      <tr key={id}>
                        <td>{id + 1}</td>

                        <td>
                          {moment(x?.DueDate?.split("T")[0]).format(
                            "DD-MMM-YYYY"
                          )}
                        </td>
                        <td>{x?.InstallmentAmount?.toLocaleString()}</td>
                        <td>{x?.PaidAmount?.toLocaleString()}</td>
                        <td>
                          {(
                            x?.InstallmentAmount - x?.PaidAmount
                          )?.toLocaleString()}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
            <div className="row">
              <div className=" col-12 text-right pr-0 pl-0">
                <button
                  className="print_btn float-right"
                  onClick={() => {
                    printIndvidualContract();
                  }}
                >
                  <TiPrinter size="1.3em" />
                  Print
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader && <FullScreenLoader />}
    </>
  );
};
