import axios from "axios";
import { useState } from "react";

function useDownloadCsv() {
  const [data, setData] = useState([]);

  const downloadCsv = (vt) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: `${
          process.env.REACT_APP_API_URL
        }Vouchers/DownloadCSVOfVoucher?ProjectId=${localStorage.getItem(
          "ProjectId"
        )}&VoucherType=${vt}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      })
        .then((result) => {
          if (result.data.IsSuccess) {
            setData(result.data.Data);
            resolve(result.data.Data);
          } else {
            reject(new Error("Error in Downloading CSV."));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return [data, downloadCsv];
}

export default useDownloadCsv;
