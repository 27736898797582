import moment from "moment";
import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";

export class TransferIndvidualPrint extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <PrintHeader />
        </div>
        <div
          className="row m-3 p-0"
          // style={{ border: "5px solid darkgrey", height: "auto" }}
        >
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>
                Transfer Contract Installments -{" "}
                {localStorage.getItem("ProjectName")}{" "}
              </u>
            </h4>
            <div className="row pt-2">
              <div className="col-6 mt-2">
                {/* <ul>
                                    <li>
                                        <b>Project:</b>{" "}
                                        {this.props.printData !== undefined && this.props.printData.Project !== undefined ? this.props.printData.Project.ProjectName : null}
                                    </li>
                                    <li>
                                        <b> Unit:</b>{" "}
                                        {this.props.printData !== undefined && this.props.printData.Unit !== undefined ? this.props.printData.Unit.UnitName : null}
                                    </li>
                                    <li>
                                        <b> Affiliate:</b>{" "}
                                        {this.props.printData !== undefined && this.props.printData.AffiliateCnicNavigation !== undefined ? this.props.printData.AffiliateCnicNavigation.AffiliateName : null}
                                    </li>
                                    <li>
                                        <b> Customer:</b>{" "}
                                        {this.props.printData !== undefined && this.props.printData.NewContract.CustomerCnicNavigation !== undefined ? this.props.printData.NewContract.CustomerCnicNavigation.CustomerName : null}

                                    </li>
                                    <li>
                                        <b> Manger:</b>{" "}
                                        {this.props.printData !== undefined && this.props.printData.Manager !== undefined ? this.props.printData.Manager.Employee.EmployeeName : null}

                                    </li>
                                    <li>
                                        <b>Area:</b>{" "}
                                        {this.props.printData !== undefined && this.props.printData.Unit !== undefined ? this.props.printData.Unit.Marla + "M " + this.props.printData.Unit.Sarsai + " S" : null}
                                    </li>
                                    <li>
                                        <b>Sqft:</b>{" "}
                                        {this.props.printData !== undefined && this.props.printData.Unit !== undefined ? this.props.printData.Unit.NetSqft : null}
                                    </li>
                                    <li>
                                        <b>Dimension(LxW):</b>{" "}
                                        {this.props.printData !== undefined && this.props.printData.Unit !== undefined ? this.props.printData.Unit.DimensionLength + "x" + this.props.printData.Unit.DimensionWidth : null}
                                    </li>
                                    <li>
                                        <b>Rate Per Marla:</b>{" "}
                                        {this.props.printData !== undefined && this.props.printData.Unit !== undefined ? this.props.printData.Unit.RatePerMarla.toLocaleString() : null}
                                    </li>
                                    <li>
                                        <b> Advance:</b>{" "}
                                        {this.props.printData !== undefined && this.props.printData.AdvanceAmount !== null ? this.props.printData.AdvanceAmount : null}

                                    </li>
                                    <li>
                                        <b> Status:</b>{" "}
                                        {this.props.printData !== undefined && this.props.printData.ContractStatus !== null ? this.props.printData.ContractStatus : null}

                                    </li>
                                </ul> */}
              </div>
              <div className="col-6 mt-2">
                {/* <ul>


                                    <li>
                                        <b>Date:</b>{" "}
                                        {this.props.printData !== undefined && this.props.printData.NewContract.EntryDate !== undefined ? this.props.printData.NewContract.EntryDate.split("T")[0] : null}
                                    </li>
                                    <li>
                                        <b> Sale Rate:</b>{" "}
                                        {this.props.printData !== undefined && this.props.printData.SaleRate !== null ? this.props.printData.SaleRate : null}

                                    </li>
                                    <li>
                                        <b>Total Price:</b>{" "}
                                        {this.props.printData !== undefined && this.props.printData.TotalPrice !== null ? this.props.printData.TotalPrice.toLocaleString() : null}

                                    </li>
                                    <li>
                                        <b>Receivable:</b>{" "}
                                        {this.props.printData !== undefined && this.props.printData.CustomerReceivable !== null ? this.props.printData.CustomerReceivable : null}

                                    </li>
                                    <li>
                                        <b> Commission Exp%:</b>{" "}
                                        {this.props.printData !== undefined && this.props.printData.ComissionPer !== null ? this.props.printData.ComissionPer : null}

                                    </li>
                                    <li>
                                        <b> Commission Exp:</b>{" "}
                                        {this.props.printData !== undefined && this.props.printData.ComissionAmount !== null ? this.props.printData.ComissionAmount : null}

                                    </li>
                                    <li>
                                        <b> Commission Inc%:</b>{" "}
                                        {this.props.printData !== undefined && this.props.printData.CompanyCommissionPer !== null ? this.props.printData.CompanyCommissionPer : null}

                                    </li>
                                    <li>
                                        <b> Commission Inc:</b>{" "}
                                        {this.props.printData !== undefined && this.props.printData.CompanyCommissionAmount !== null ? this.props.printData.CompanyCommissionAmount : null}

                                    </li>
                                    <li>
                                        <b> Additional Margin%:</b>{" "}
                                        {this.props.printData !== undefined && this.props.printData.AdditionalMarginPer !== null ? this.props.printData.AdditionalMarginPer : null}

                                    </li>
                                    <li>
                                        <b> Additional Margin:</b>{" "}
                                        {this.props.printData !== undefined && this.props.printData.AdditionalMarginAmount !== null ? this.props.printData.AdditionalMarginAmount : null}

                                    </li>

                                </ul> */}
              </div>
            </div>

            <div className="mt-3">
              <div className="text-center">
                <b>Advance</b>
              </div>
              <table className="table table-hover">
                <thead className="purple whiteText tableCash">
                  <tr className="purple whiteText">
                    <th>Total</th>

                    <th scope="col">Pending </th>
                    <th scope="col">Received</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  {this.props.printData !== undefined ? (
                    <tr>
                      <td>
                        {this.props.printData.NewContract.AdvanceAmount !==
                        undefined
                          ? this.props.printData.NewContract.AdvanceAmount.toLocaleString()
                          : 0}
                      </td>

                      <td>
                        {(
                          this.props.printData.NewContract.AdvanceAmount -
                          this.props.printData.NewContract.AdvanceReceived
                        ).toLocaleString()}
                      </td>
                      <td>
                        {this.props.printData.NewContract.AdvanceReceived.toLocaleString()}
                      </td>

                      <td className="ml-2">
                        {this.props.printData.NewContract.AdvanceAmount -
                          this.props.printData.NewContract.AdvanceReceived !==
                        0
                          ? "Pending"
                          : "Paid"}
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
              <div className="text-center">
                <b>Installments</b>
              </div>
              <table className="table ">
                <thead className="purple whiteText">
                  <tr>
                    <th scope="col">Install No.</th>
                    <th scope="col">Due Date</th>
                    <th scope="col">Total</th>
                    <th scope="col">Pending </th>
                    <th scope="col">Received</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.printData === undefined ||
                  this.props.printData.NewContract.ContractInstallments ===
                    undefined
                    ? // contractinstallment.length == 0
                      null
                    : this.props.printData.NewContract.ContractInstallments.map(
                        (x, id) => (
                          <tr key={id}>
                            <td>{x.InstallmentNo}</td>
                            <td>
                              {moment(x.DueDate.split("T")[0]).format(
                                "DD-MMM-YYYY"
                              )}
                            </td>
                            <td>{x.DueAmount.toLocaleString()}</td>
                            <td>
                              {(
                                x.DueAmount - x.ReceivedAmount
                              ).toLocaleString()}
                            </td>
                            <td>{x.ReceivedAmount.toLocaleString()}</td>
                            <td>{x.InstallmentStatus}</td>
                          </tr>
                        )
                      )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
