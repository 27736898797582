import axios from "axios";
import { toast } from "react-toastify";
export const GetAllComissionPayment = (data) => {
  return {
    type: "get_allcomissionpayment",
    payload: data,
    loader: false,
  };
};
export function getallcomissionpaymentpopulate() {
  return (dispatch) => {
    axios({
      url: `${
        process.env.REACT_APP_API_URL
      }Vouchers/CommissionPayment/Populate?ProjectId=${localStorage.getItem(
        "ProjectId"
      )}`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllComissionPayment(result.data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
export const GetAllComPayTableData = (data) => {
  return {
    type: "get_allcomissionpaymenttabledata",
    payload: data,
    loader: false,
  };
};
export function getallComPaypopulatetable(data, link) {
  return (dispatch) => {
    let pgurl = `${
      link
        ? link + "&"
        : process.env.REACT_APP_API_URL + "Vouchers/CommissionPayment/Get?"
    }ProjectId=${localStorage.getItem("ProjectId")}&From=${
      data.From ? data.From : ""
    }&To=${data.To ? data.To : ""}&FilterDate=${
      data.FilterDate ? data.FilterDate : ""
    }&VoucherId=${data.VoucherId ? data.VoucherId : ""}&AffiliateCNIC=${
      data.AffiliateCNIC ? data.AffiliateCNIC : ""
    }&UnitId=${data.UnitId ? data.UnitId : ""}&Status=${
      data.Status ? data.Status : ""
    }`;
    if (localStorage.getItem("ProjectId") === null) {
      pgurl = `${process.env.REACT_APP_API_URL}Vouchers/CommissionPayment/Get`;
    }
    axios({
      url: pgurl,
      method: "Post",
      data: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.Succeeded === true) {
          dispatch(GetAllComPayTableData(result.data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
