import React, { useState, useRef, useEffect } from "react";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { toast } from "react-toastify";
import CashBookPrint from "../ReportPrints/CashBookPrint";
import { CSVLink } from "react-csv";

const CashBook = () => {
  const [statecashbookdata, setcashbookdata] = useState([]);
  const [statedebit, setdebit] = useState(0);
  const [statecredit, setcredit] = useState(0);
  const [loader, setloader] = useState(false);
  const [statecashinhand, setcashinhand] = useState(0);
  const [stateopeningbal, setopeningbal] = useState(0);
  const [csvData, setcsvData] = useState([]);
  // print function
  const componentRef = useRef();

  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });
  const savedata = () => {
    document.getElementById("go").disabled = true;
    if (
      document.getElementById("fromdate").value === "" ||
      document.getElementById("todate").value === ""
    ) {
      toast.info("Select FromDate To ToDate");
      document.getElementById("go").disabled = false;
    } else {
      setloader(true);

      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}Reports/CashBook`,
        data: JSON.stringify({
          From: document.getElementById("fromdate").value,
          To: document.getElementById("todate").value,
          ProjectId: localStorage.getItem("ProjectId")
            ? localStorage.getItem("ProjectId")
            : null,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result.data.IsSuccess === true) {
            console.log("result", result.data);
            setcashinhand(result.data.CashInHand);
            setopeningbal(result.data.OpeningBalance);
            var credit = 0;
            var debit = 0;
            let arr = [];
            result.data.Data?.map((val) => {
              if (val) {
                arr.push(val);
              }
            });
            arr.map((val) =>
              val.TransactionType === "CREDIT"
                ? (credit += parseFloat(val.Amount))
                : (debit += parseFloat(val.Amount))
            );
            setcredit(credit);

            setdebit(debit);
            setcashbookdata(arr);

            document.getElementById("go").disabled = false;
            setloader(false);
          } else {
            setloader(false);
            document.getElementById("go").disabled = false;
          }
        },
        (error) => {
          setloader(false);
          document.getElementById("go").disabled = false;
        }
      );
    }
  };
  useEffect(() => {
    let arr = [];
    statecashbookdata?.forEach((itm, i) => {
      itm.AccountBalance =
        i == 0
          ? stateopeningbal
          : itm.TransactionType === "CREDIT"
          ? statecashbookdata[i - 1].AccountBalance - itm.Amount
          : statecashbookdata[i - 1].AccountBalance + itm.Amount;
      arr.push({
        Date: moment(itm.EntryDate).format("YYYY-MM-DD"),
        Narration: itm.Narration,
        Debit: itm.TransactionType === "DEBIT" ? parseFloat(itm.Amount) : 0,
        Credit: itm.TransactionType === "CREDIT" ? parseFloat(itm.Amount) : 0,
        Balance: itm.AccountBalance,
        Type: itm.AccountBalance < 0 ? "CR" : "DR",
      });
    });
    arr.push(
      {
        Date: "",
        Narration: "Total",
        Debit: statedebit,
        Credit: statecredit,
        Balance: statedebit - statecredit,
        Type: "",
      },
      {
        Date: "Opening Balance",
        Narration: stateopeningbal,
        Debit: "",
        Credit: "Cash In Hand",
        Balance: statecashinhand,
        Type: "",
      }
    );
    setcsvData(arr);
  }, [statecashbookdata, stateopeningbal]);

  return (
    <>
      <div hidden>
        <CashBookPrint
          ref={componentRef}
          statecredit={statecredit}
          statedebit={statedebit}
          printData={statecashbookdata}
          statecashinhand={statecashinhand}
          stateopeningbal={stateopeningbal}
        />
      </div>
      <div className="row app_font ">
        <div className="dash_background col-12 pt-2 ">
          <div className="row ">
            <div className="col-md-3 text-center  pr-0 pl-0">
              <label className="input_label m-0">From:</label>
              <input
                type="date"
                className="input_date ml-3"
                id="fromdate"
                defaultValue={localStorage.getItem("OpeningYear")}
              ></input>
            </div>
            <div className="col-md-3 text-center pr-0 pl-0">
              <label className="input_label m-0">To:</label>
              <input
                type="date"
                className="input_date ml-3"
                id="todate"
                defaultValue={moment().format("YYYY-MM-DD")}
              ></input>
            </div>
            <div className="col-md-1 pr-0 pl-0">
              <button className="btn_Go" onClick={savedata} id="go">
                Go
              </button>
            </div>
            <div className="col-md-1 pr-0 pl-0">
              {loader && (
                <Loader type="ThreeDots" color="green" height={40} width={40} />
              )}
            </div>
          </div>

          <div className="mt-4 d-flex justify-content-around align-items-center">
            <div className="d-flex align-items-center">
              <p className="m-0 font-weight-bold text-muted">
                Opening Balance:
              </p>
              <p className="m-0 ml-3 font-weight-bold text-muted">
                {parseFloat(stateopeningbal).toLocaleString()}
              </p>
            </div>

            <div className="d-flex align-items-center">
              <p className="m-0 font-weight-bold text-muted">Cash In Hand:</p>
              <p className="m-0 ml-3 font-weight-bold text-muted">
                {parseFloat(statecashinhand).toLocaleString()}
              </p>
            </div>
          </div>

          <div className=" col-12  table_height pr-0 pl-0">
            <div className="table-responsive mt-2">
              <table className="table table-borderless m-0">
                <thead>
                  <tr>
                    <th>Sr</th>
                    <th>Date</th>
                    <th>Narration</th>
                    <th>Debit</th>
                    <th>Credit</th>
                    <th>Balance</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  {statecashbookdata?.map((val, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {moment(val.EntryDate?.split("T")[0]).format(
                          "DD-MMM-YYYY"
                        )}
                      </td>
                      <td>{val.Narration}</td>

                      {val.TransactionType === "DEBIT" ? (
                        <td>{parseFloat(val.Amount)?.toLocaleString()}</td>
                      ) : (
                        <td></td>
                      )}
                      {val.TransactionType === "CREDIT" ? (
                        <td>{parseFloat(val.Amount)?.toLocaleString()}</td>
                      ) : (
                        <td></td>
                      )}
                      <td>
                        {parseFloat(val.AccountBalance)?.toLocaleString()}
                      </td>
                      <td>{val.AccountBalance < 0 ? "CR" : "DR"}</td>
                    </tr>
                  ))}
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      {" "}
                      <label className="input_label m-0">Total:</label>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="input_total_styling "
                        placeholder="Debit"
                        disabled
                        defaultValue={parseFloat(statedebit).toLocaleString()}
                        key={statedebit}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="input_total_styling "
                        placeholder="Credit"
                        disabled
                        defaultValue={parseFloat(statecredit).toLocaleString()}
                        key={statecredit}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="input_total_styling "
                        placeholder="Balance"
                        disabled
                        value={parseFloat(
                          statedebit - statecredit
                        ).toLocaleString()}
                      ></input>
                    </td>
                    <td></td>
                  </tr>

                  {/* <tr>
                    <td></td>
                    <th>Opening Balance</th>
                    <td>{parseFloat(stateopeningbal).toLocaleString()}</td>
                    <td></td>

                    <th>Cash In Hand</th>
                    <th>{parseFloat(statecashinhand).toLocaleString()}</th>
                    <td></td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12  text-right pr-0 pl-0">
            <button
              disabled={statecashbookdata.length > 0 ? false : true}
              className="print_btn float-left"
              onClick={() => {
                printPaymentRec();
              }}
            >
              <TiPrinter size="1.3em" />
              Print
            </button>

            {statecashbookdata?.length > 0 && (
              <button
                style={{ color: "black !important" }}
                className="print_btn ml-2 float-left"
              >
                <CSVLink
                  style={{ color: "black" }}
                  filename="Cash_Book_Csv"
                  data={csvData}
                >
                  Download CSV
                </CSVLink>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CashBook;
