import React, { Component } from 'react'
import PropTypes from "prop-types";
import moment from 'moment';
import PrintHeader from '../../Prints/PrintHeader/PrintHeader';

class PrintBookings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentDateTime: moment().format('DD-MM-YYYY'),
        };
    }
    render() {
        return (
            <div style={{ backgroundColor: "white", height: "100%" }}>
                <div className="row m-0 p-0">
                    <PrintHeader />
                </div>
                <h4 style={{ color: "red", display: 'flex', justifyContent: 'center', marginBottom: '5px' }}>
                    <u>Bookings Unit</u>
                </h4>
                <div className="table-responsive changeFormTable_height mt-2" style={{ border: '1px solid transparent' }}>
                    <table className="table table-borderless m-0">
                        <thead>
                            <tr style={{ borderBottom: '1px solid #DEE2E6' }}>
                                <th>Sr #</th>
                                <th>Project Name</th>
                                <th>Unit Name</th>
                                <th>Number Of Days</th>
                                <th>Person Name</th>
                                <th>Person Cnic</th>
                                <th>Person Phone</th>
                                <th>Token Amount</th>
                            </tr>
                        </thead>

                        <tbody>
                            {this.props.Bookings?.map((val, ind) => {
                                return (
                                    <tr key={ind} style={{ borderBottom: '1px solid #DEE2E6' }}>
                                        <td  >{ind + 1}</td>
                                        <td  >{val?.Project?.ProjectName}</td>
                                        <td >{val?.Unit?.UnitName}</td>
                                        <td >{val.NumberOfDays}</td>
                                        <td >{val.PersonName}</td>
                                        <td >{val.PersonCnic}</td>
                                        <td >{val.PersonPhoneNumber}</td>
                                        <td >{val.TokenAmount}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
export default PrintBookings;

PrintBookings.propTypes = {
    Bookings: PropTypes.any,
};