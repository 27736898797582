import {
  Document,
  Image,
  PDFViewer,
  Page,
  View,
  Text,
  StyleSheet,
  Font,
  Svg,
  Path,
} from "@react-pdf/renderer";
import moment from "moment";
import React, { useEffect } from "react";
import gravitywatermark from "../../../../assets/Gravitywater.jpg";
import QuickSand from "./Quicksand-SemiBold.ttf";
import { useHistory } from "react-router-dom";
import { ContractTable } from "../Contract/ContractTable";
import { useState } from "react";
import { FaCheck } from "react-icons/fa";
import check from "../../../../assets/checkmark.png";

Font.register({
  family: "Quicksand sans-serif",
  src: QuickSand,
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Quicksand sans-serif",
    padding: "0px 20px",
    paddingTop: "180px",
    paddingBottom: "120px",
    fontSize: "12px",
    fontWeight: "light",
  },
  section: {
    border: "1px solid black",
    padding: 3,
    marginBottom: 2,
  },
  infoParent: {
    display: "flex",
    width: "100%",
    gap: 3,
    flexDirection: "row",
    paddingBottom: 1,
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  infoChild: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
  },
  labelFont: {
    fontSize: "10px",
  },
  valueFont: {
    fontSize: "9px",
  },
  linePadding: {
    paddingBottom: 5,
  },
  listItem: {
    flexDirection: "row",
    marginBottom: 5,
  },
  bullet: {
    marginLeft: 5,
  },
  content: {
    flex: 1,
  },
  signatureLine: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
  },
});
const tableStyles = StyleSheet.create({
  table: {
    // marginTop: "2px",
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderLeftWidth: 1,
    borderTopWidth: 1,
    // borderRightWidth: 1,
    // borderBottomWidth: 1,
    borderColor: "#5b5b5b",
  },
  tableRow: {
    // margin: "5px",
    justifyContent: "space-between",

    flexDirection: "row",
    borderLeft: "1px solid black",
  },
  tableCellHeader: {
    // margin: 5,
    padding: 5,
    fontSize: 11,
    fontWeight: 700,
    borderStyle: "solid",
    borderBottomWidth: 1,
    borderTop: "1px solid black",
    borderRightWidth: 1,
    textAlign: "left",
    // flex: 1,
    color: "#298b8b",
    borderColor: "#5b5b5b",
  },
  tableCell: {
    // margin: 5,
    padding: 5,
    fontSize: "10px",
    textAlign: "left",
    borderStyle: "solid",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderColor: "#5b5b5b",
  },
});

function ContractNewPrint({ printData, setPrintData }) {
  const [lastRows, setlastRows] = useState(0);
  const [tableRows, settableRow] = useState([]);
  const [close, setClose] = useState(false);
  const handleClick = () => {
    // setClose(true);
    setPrintData(null);
  };

  useEffect(() => {
    const rowsPerPage = 18; // Adjust as needed
    const totalRows =
      tableRows.length; /* your API data length or any other way to get the total rows */

    const rowsOnLastPage = totalRows % rowsPerPage || rowsPerPage;
    setlastRows(rowsOnLastPage);
  }, [tableRows]);
  useEffect(() => {
    let arr = [];
    let rowArr = [];
    if (printData.ContractInstallments?.length > 0) {
      printData.ContractInstallments?.forEach((row, id) => {
        if (printData?.ContractInstallments?.length == id + 1) {
          if (arr.length > 0) {
            arr.push(
              rowArr.length <= 16 ? (
                <Page size="A4" style={styles.page}>
                  <View key={id} style={tableStyles.tableRow}>
                    <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                      Sr
                    </Text>{" "}
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Description{" "}
                    </Text>{" "}
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Due Date{" "}
                    </Text>
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Due Amount{" "}
                    </Text>
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Paid Date{" "}
                    </Text>
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Paid Amount{" "}
                    </Text>
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Balance{" "}
                    </Text>
                  </View>
            
                  {rowArr}
                  <View key={id} style={tableStyles.tableRow}>
                    <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                      {id + 2}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      Installment
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {moment(row.DueDate.split("T")[0]).format("DD-MMM-YYYY")}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {row.DueAmount.toLocaleString()}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {row.UnitReceiptDetail?.length !== 0 &&
                      row.UnitReceiptDetail[0]?.UnitVoucher?.EntryDate !== null
                        ? moment(
                            row.UnitReceiptDetail[
                              row.UnitReceiptDetail?.length - 1
                            ]?.UnitVoucher?.EntryDate?.split("T")[0]
                          ).format("DD-MMM-YYYY")
                        : row.UnitReceiptDetail?.length !== 0 &&
                          row.UnitReceiptDetail[0]?.AdjustmentVoucher
                            ?.EntryDate !== null
                        ? row.UnitReceiptDetail[0]?.AdjustmentVoucher?.EntryDate?.split(
                            "T"
                          )[0]
                        : null}{" "}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {row?.ReceivedAmount.toLocaleString()}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {(row.DueAmount - row.ReceivedAmount).toLocaleString()}
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "40px",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text
                        style={{
                          paddingBottom: "5px",
                          alignSelf: "center",
                          fontWeight: "extrabold",
                        }}
                      >
                        Buyer{" "}
                      </Text>
                      <View
                        style={{
                          paddingBottom: "5px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>Sign: </Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                            paddingBottom: "10px",
                          }}
                        ></Text>
                      </View>
                      <View
                        style={{
                          paddingBottom: "5px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>Thumb: </Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                            paddingBottom: "10px",
                          }}
                        ></Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "space-between",
                        gap: 4,
                      }}
                    >
                      <Text
                        style={{
                          paddingBottom: "5px",
                          alignSelf: "center",
                          fontWeight: "extrabold",
                        }}
                      >
                        Project director{" "}
                      </Text>
                      <View
                        style={{
                          paddingBottom: "5px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>Name: </Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                            paddingBottom: "10px",
                          }}
                        ></Text>
                      </View>
                      <View
                        style={{
                          paddingBottom: "5px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>
                          Sign:{"    "}
                        </Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                            paddingBottom: "10px",
                          }}
                        ></Text>
                      </View>
                    </View>
                  </View>
                </Page>
              ) : (
                <>
                  <Page size="A4" style={styles.page}>
                    <View key={id} style={tableStyles.tableRow}>
                      <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                        Sr
                      </Text>{" "}
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Description{" "}
                      </Text>{" "}
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Due Date{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Due Amount{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Paid Date{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Paid Amount{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Balance{" "}
                      </Text>
                    </View>
                    {rowArr.slice(0, 16)}
                  </Page>
                  <Page size="A4" style={styles.page}>
                    <View key={id} style={tableStyles.tableRow}>
                      <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                        Sr
                      </Text>{" "}
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Description{" "}
                      </Text>{" "}
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Due Date{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Due Amount{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Paid Date{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Paid Amount{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Balance{" "}
                      </Text>
                    </View>
                    {rowArr.slice(16, rowArr.length)}
                    <View key={id} style={tableStyles.tableRow}>
                      <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                        {id + 2}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        Installment
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {moment(row.DueDate.split("T")[0]).format(
                          "DD-MMM-YYYY"
                        )}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {row.DueAmount.toLocaleString()}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {row.UnitReceiptDetail?.length !== 0 &&
                        row.UnitReceiptDetail[0]?.UnitVoucher?.EntryDate !==
                          null
                          ? moment(
                              row.UnitReceiptDetail[
                                row.UnitReceiptDetail?.length - 1
                              ]?.UnitVoucher?.EntryDate?.split("T")[0]
                            ).format("DD-MMM-YYYY")
                          : row.UnitReceiptDetail?.length !== 0 &&
                            row.UnitReceiptDetail[0]?.AdjustmentVoucher
                              ?.EntryDate !== null
                          ? row.UnitReceiptDetail[0]?.AdjustmentVoucher?.EntryDate?.split(
                              "T"
                            )[0]
                          : null}{" "}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {row?.ReceivedAmount.toLocaleString()}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {(row.DueAmount - row.ReceivedAmount).toLocaleString()}
                      </Text>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "40px",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text
                          style={{
                            paddingBottom: "5px",
                            alignSelf: "center",
                            fontWeight: "extrabold",
                          }}
                        >
                          Buyer{" "}
                        </Text>
                        <View
                          style={{
                            paddingBottom: "5px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Text style={{ fontWeight: "extrabold" }}>
                            Sign:{" "}
                          </Text>
                          <Text
                            style={{
                              minWidth: "100px",
                              maxWidth: "100px",
                              borderBottom: "1px solid black",
                              paddingBottom: "10px",
                            }}
                          ></Text>
                        </View>
                        <View
                          style={{
                            paddingBottom: "5px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Text style={{ fontWeight: "extrabold" }}>
                            Thumb:{" "}
                          </Text>
                          <Text
                            style={{
                              minWidth: "100px",
                              maxWidth: "100px",
                              borderBottom: "1px solid black",
                              paddingBottom: "10px",
                            }}
                          ></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "space-between",
                          gap: 4,
                        }}
                      >
                        <Text
                          style={{
                            paddingBottom: "5px",
                            alignSelf: "center",
                            fontWeight: "extrabold",
                          }}
                        >
                          Project director{" "}
                        </Text>
                        <View
                          style={{
                            paddingBottom: "5px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Text style={{ fontWeight: "extrabold" }}>
                            Name:{" "}
                          </Text>
                          <Text
                            style={{
                              minWidth: "100px",
                              maxWidth: "100px",
                              borderBottom: "1px solid black",
                              paddingBottom: "10px",
                            }}
                          ></Text>
                        </View>
                        <View
                          style={{
                            paddingBottom: "5px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Text style={{ fontWeight: "extrabold" }}>
                            Sign:{"    "}
                          </Text>
                          <Text
                            style={{
                              minWidth: "100px",
                              maxWidth: "100px",
                              borderBottom: "1px solid black",
                              paddingBottom: "10px",
                            }}
                          ></Text>
                        </View>
                   
                   
                      </View>
                    </View>
                  </Page>
                </>
              )
            );
          } else {
            arr.push(
              rowArr.length <= 10 ? (
                <Page size="A4" style={styles.page}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text>
                      <Text style={{ fontWeight: "bold" }}> Contract No. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.ContractId}
                      </Text>
                    </Text>

                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Date. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.EntryDate
                          ? new Date(printData.EntryDate).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )
                          : ""}
                      </Text>
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <Text>
                      <Text style={{ fontWeight: "bold" }}> Name: </Text>{" "}
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.CustomerCnicNavigation?.CustomerName}
                      </Text>{" "}
                    </Text>

                    <Text>
                      <Text style={{ fontWeight: "bold" }}> CNIC:{"   "}</Text>{" "}
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.CustomerCnicNavigation?.CustomerCnic2}
                      </Text>
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <Text>
                      <Text style={{ fontWeight: "extrabold" }}>
                        {" "}
                        Unit No.{" "}
                      </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.Unit?.UnitName}
                      </Text>
                    </Text>

                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Size. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData !== undefined && printData.Unit !== undefined
                          ? printData.Unit.RatePerMarlaOrsqft == "MARLA"
                            ? printData.Unit.Marla +
                              "M " +
                              printData.Unit.Sarsai +
                              " S"
                            : printData.Unit.NetSqft.toFixed(
                                2
                              ).toLocaleString() + "SQFT"
                          : null}
                      </Text>
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>
                        {" "}
                        {printData !== undefined &&
                        printData.Unit !== undefined &&
                        printData.Unit.RatePerMarlaOrsqft == "MARLA"
                          ? "Rate/Marla."
                          : "Rate/Sqft."}{" "}
                      </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData?.SaleRate !== undefined
                          ? printData?.SaleRate?.toLocaleString()
                          : null}
                      </Text>
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Total Value. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData !== undefined &&
                        printData?.TotalPrice !== null
                          ? printData?.TotalPrice?.toLocaleString()
                          : null}
                      </Text>
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Paid Amount. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData !== undefined &&
                        printData?.TotalPrice !== null &&
                        printData?.CustomerReceivable !== null
                          ? (
                              printData?.TotalPrice -
                              printData?.CustomerReceivable
                            ).toLocaleString()
                          : null}
                      </Text>
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Balance. </Text>
                      <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                        {printData !== undefined &&
                        printData?.CustomerReceivable !== null
                          ? (printData?.CustomerReceivable).toLocaleString()
                          : null}
                      </Text>
                    </Text>
                  </View>
                  <View style={{ fontWeight: "bold", marginTop: "9px" }}>
                    <Text> Installment Plan</Text>
                  </View>
                  <View key={id} style={tableStyles.tableRow}>
                    <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                      Sr
                    </Text>{" "}
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Description{" "}
                    </Text>{" "}
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Due Date{" "}
                    </Text>
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Due Amount{" "}
                    </Text>
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Paid Date{" "}
                    </Text>
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Paid Amount{" "}
                    </Text>
                    <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                      Balance{" "}
                    </Text>
                  </View>
                  <View key={id + 1} style={tableStyles.tableRow}>
                      <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                        1
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        Booking
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {printData !== undefined &&
                        printData.EntryDate !== undefined
                          ? moment(printData.EntryDate.split("T")[0]).format(
                              "DD-MMM-YYYY"
                            )
                          : null}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {printData.AdvanceAmount !== undefined
                          ? printData.AdvanceAmount?.toLocaleString()
                          : 0}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {" "}
                        {printData !== undefined &&
                        printData.UnitVoucher?.length > 0
                          ? moment(
                              printData.UnitVoucher[0]?.EntryDate.split("T")[0]
                            ).format("DD-MMM-YYYY")
                          : null}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {printData.AdvanceReceived?.toLocaleString()}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {(
                          printData.AdvanceAmount - printData.AdvanceReceived
                        ).toLocaleString()}
                      </Text>
                    </View>
                  {rowArr}
                  <View key={id} style={tableStyles.tableRow}>
                    <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                      {id + 2}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      Installment
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {moment(row.DueDate.split("T")[0]).format("DD-MMM-YYYY")}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {row.DueAmount.toLocaleString()}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {row.UnitReceiptDetail?.length !== 0 &&
                      row.UnitReceiptDetail[0]?.UnitVoucher?.EntryDate !== null
                        ? moment(
                            row.UnitReceiptDetail[
                              row.UnitReceiptDetail?.length - 1
                            ]?.UnitVoucher?.EntryDate?.split("T")[0]
                          ).format("DD-MMM-YYYY")
                        : row.UnitReceiptDetail?.length !== 0 &&
                          row.UnitReceiptDetail[0]?.AdjustmentVoucher
                            ?.EntryDate !== null
                        ? row.UnitReceiptDetail[0]?.AdjustmentVoucher?.EntryDate?.split(
                            "T"
                          )[0]
                        : null}{" "}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {row?.ReceivedAmount.toLocaleString()}
                    </Text>
                    <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                      {(row.DueAmount - row.ReceivedAmount).toLocaleString()}
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "40px",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text
                        style={{
                          paddingBottom: "5px",
                          alignSelf: "center",
                          fontWeight: "extrabold",
                        }}
                      >
                        Buyer{" "}
                      </Text>
                      <View
                        style={{
                          paddingBottom: "5px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>Sign: </Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                            paddingBottom: "10px",
                          }}
                        ></Text>
                      </View>
                      <View
                        style={{
                          paddingBottom: "5px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>Thumb: </Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                            paddingBottom: "10px",
                          }}
                        ></Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "space-between",
                        gap: 4,
                      }}
                    >
                      <Text
                        style={{
                          paddingBottom: "5px",
                          alignSelf: "center",
                          fontWeight: "extrabold",
                        }}
                      >
                        Project director{" "}
                      </Text>
                      <View
                        style={{
                          paddingBottom: "5px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>Name: </Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                            paddingBottom: "10px",
                          }}
                        ></Text>
                      </View>
                      <View
                        style={{
                          paddingBottom: "5px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>
                          Sign:{"    "}
                        </Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                            paddingBottom: "10px",
                          }}
                        ></Text>
                      </View>
              
              

                    </View>
                  </View>
                </Page>
              ) : (
                <>
                  <Page size="A4" style={styles.page}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          {" "}
                          Contract No.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.ContractId}
                        </Text>
                      </Text>

                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Date. </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.EntryDate
                            ? new Date(printData.EntryDate).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }
                              )
                            : ""}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "bold" }}> Name: </Text>{" "}
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.CustomerCnicNavigation?.CustomerName}
                        </Text>{" "}
                      </Text>

                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          {" "}
                          CNIC:{"   "}
                        </Text>{" "}
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.CustomerCnicNavigation?.CustomerCnic2}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "extrabold" }}>
                          {" "}
                          Unit No.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.Unit?.UnitName}
                        </Text>
                      </Text>

                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Size. </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData.Unit !== undefined
                            ? printData.Unit.RatePerMarlaOrsqft == "MARLA"
                              ? printData.Unit.Marla +
                                "M " +
                                printData.Unit.Sarsai +
                                " S"
                              : printData.Unit.NetSqft.toFixed(
                                  2
                                ).toLocaleString() + "SQFT"
                            : null}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          {" "}
                          {printData !== undefined &&
                          printData.Unit !== undefined &&
                          printData.Unit.RatePerMarlaOrsqft == "MARLA"
                            ? "Rate/Marla."
                            : "Rate/Sqft."}{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData?.SaleRate !== undefined
                            ? printData?.SaleRate?.toLocaleString()
                            : null}
                        </Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          Total Value.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData?.TotalPrice !== null
                            ? printData?.TotalPrice?.toLocaleString()
                            : null}
                        </Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          Paid Amount.{" "}
                        </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData?.TotalPrice !== null &&
                          printData?.CustomerReceivable !== null
                            ? (
                                printData?.TotalPrice -
                                printData?.CustomerReceivable
                              ).toLocaleString()
                            : null}
                        </Text>
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Balance. </Text>
                        <Text
                          style={[styles.labelFont, { fontWeight: "thin" }]}
                        >
                          {printData !== undefined &&
                          printData?.CustomerReceivable !== null
                            ? (printData?.CustomerReceivable).toLocaleString()
                            : null}
                        </Text>
                      </Text>
                    </View>
                    <View style={{ fontWeight: "bold", marginTop: "9px" }}>
                      <Text> Installment Plan</Text>
                    </View>
                    <View key={id} style={tableStyles.tableRow}>
                      <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                        Sr
                      </Text>{" "}
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Description{" "}
                      </Text>{" "}
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Due Date{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Due Amount{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Paid Date{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Paid Amount{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Balance{" "}
                      </Text>
                    </View>
                    <View key={id + 1} style={tableStyles.tableRow}>
                      <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                        1
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        Booking
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {printData !== undefined &&
                        printData.EntryDate !== undefined
                          ? moment(printData.EntryDate.split("T")[0]).format(
                              "DD-MMM-YYYY"
                            )
                          : null}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {printData.AdvanceAmount !== undefined
                          ? printData.AdvanceAmount?.toLocaleString()
                          : 0}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {" "}
                        {printData !== undefined &&
                        printData.UnitVoucher?.length > 0
                          ? moment(
                              printData.UnitVoucher[0]?.EntryDate.split("T")[0]
                            ).format("DD-MMM-YYYY")
                          : null}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {printData.AdvanceReceived?.toLocaleString()}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {(
                          printData.AdvanceAmount - printData.AdvanceReceived
                        ).toLocaleString()}
                      </Text>
                    </View>
                    {rowArr.slice(0, 10)}
                  </Page>
                  <Page size="A4" style={styles.page}>
                    <View key={id} style={tableStyles.tableRow}>
                      <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                        Sr
                      </Text>{" "}
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Description{" "}
                      </Text>{" "}
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Due Date{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Due Amount{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Paid Date{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Paid Amount{" "}
                      </Text>
                      <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                        Balance{" "}
                      </Text>
                    </View>
                    {rowArr.slice(10, rowArr.length)}
                    <View key={id} style={tableStyles.tableRow}>
                      <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                        {id + 2}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        Installment
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {moment(row.DueDate.split("T")[0]).format(
                          "DD-MMM-YYYY"
                        )}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {row.DueAmount.toLocaleString()}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {row.UnitReceiptDetail?.length !== 0 &&
                        row.UnitReceiptDetail[0]?.UnitVoucher?.EntryDate !==
                          null
                          ? moment(
                              row.UnitReceiptDetail[
                                row.UnitReceiptDetail?.length - 1
                              ]?.UnitVoucher?.EntryDate?.split("T")[0]
                            ).format("DD-MMM-YYYY")
                          : row.UnitReceiptDetail?.length !== 0 &&
                            row.UnitReceiptDetail[0]?.AdjustmentVoucher
                              ?.EntryDate !== null
                          ? row.UnitReceiptDetail[0]?.AdjustmentVoucher?.EntryDate?.split(
                              "T"
                            )[0]
                          : null}{" "}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {row?.ReceivedAmount.toLocaleString()}
                      </Text>
                      <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                        {(row.DueAmount - row.ReceivedAmount).toLocaleString()}
                      </Text>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "40px",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text
                          style={{
                            paddingBottom: "5px",
                            alignSelf: "center",
                            fontWeight: "extrabold",
                          }}
                        >
                          Buyer{" "}
                        </Text>
                        <View
                          style={{
                            paddingBottom: "5px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Text style={{ fontWeight: "extrabold" }}>
                            Sign:{" "}
                          </Text>
                          <Text
                            style={{
                              minWidth: "100px",
                              maxWidth: "100px",
                              borderBottom: "1px solid black",
                              paddingBottom: "10px",
                            }}
                          ></Text>
                        </View>
                        <View
                          style={{
                            paddingBottom: "5px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Text style={{ fontWeight: "extrabold" }}>
                            Thumb:{" "}
                          </Text>
                          <Text
                            style={{
                              minWidth: "100px",
                              maxWidth: "100px",
                              borderBottom: "1px solid black",
                              paddingBottom: "10px",
                            }}
                          ></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "space-between",
                          gap: 4,
                        }}
                      >
                        <Text
                          style={{
                            paddingBottom: "5px",
                            alignSelf: "center",
                            fontWeight: "extrabold",
                          }}
                        >
                          Project director{" "}
                        </Text>
                        <View
                          style={{
                            paddingBottom: "5px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Text style={{ fontWeight: "extrabold" }}>
                            Name:{" "}
                          </Text>
                          <Text
                            style={{
                              minWidth: "100px",
                              maxWidth: "100px",
                              borderBottom: "1px solid black",
                              paddingBottom: "10px",
                            }}
                          ></Text>
                        </View>
                        <View
                          style={{
                            paddingBottom: "5px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Text style={{ fontWeight: "extrabold" }}>
                            Sign:{"    "}
                          </Text>
                          <Text
                            style={{
                              minWidth: "100px",
                              maxWidth: "100px",
                              borderBottom: "1px solid black",
                              paddingBottom: "10px",
                            }}
                          ></Text>
                        </View>
                
                
                      </View>
                    </View>
                  </Page>
                </>
              )
            );
          }
        } else if (id + 1 > 16 && (id + 6) % 21 === 0) {
          arr.push(
            <Page size="A4" style={styles.page}>
              <View key={id} style={tableStyles.tableRow}>
                <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                  Sr
                </Text>{" "}
                <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                  Description{" "}
                </Text>{" "}
                <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                  Due Date{" "}
                </Text>
                <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                  Due Amount{" "}
                </Text>
                <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                  Paid Date{" "}
                </Text>
                <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                  Paid Amount{" "}
                </Text>
                <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                  Balance{" "}
                </Text>
              </View>
              {rowArr}
              <View key={id} style={tableStyles.tableRow}>
                <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                  {id + 2}
                </Text>
                <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                  Installment
                </Text>
                <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                  {moment(row.DueDate.split("T")[0]).format("DD-MMM-YYYY")}
                </Text>
                <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                  {row.DueAmount.toLocaleString()}
                </Text>
                <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                  {row.UnitReceiptDetail?.length !== 0 &&
                  row.UnitReceiptDetail[0]?.UnitVoucher?.EntryDate !== null
                    ? moment(
                        row.UnitReceiptDetail[
                          row.UnitReceiptDetail?.length - 1
                        ]?.UnitVoucher?.EntryDate?.split("T")[0]
                      ).format("DD-MMM-YYYY")
                    : row.UnitReceiptDetail?.length !== 0 &&
                      row.UnitReceiptDetail[0]?.AdjustmentVoucher?.EntryDate !==
                        null
                    ? row.UnitReceiptDetail[0]?.AdjustmentVoucher?.EntryDate?.split(
                        "T"
                      )[0]
                    : null}{" "}
                </Text>
                <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                  {row?.ReceivedAmount.toLocaleString()}
                </Text>
                <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                  {(row.DueAmount - row.ReceivedAmount).toLocaleString()}
                </Text>
              </View>
            </Page>
          );
          rowArr = [];
        } else if (id + 1 === 16 && (id + 1) % 16 === 0) {
          arr.push(
            <Page size="A4" style={styles.page}>
              {" "}
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text>
                  <Text style={{ fontWeight: "bold" }}> Contract No. </Text>
                  <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                    {printData?.ContractId}
                  </Text>
                </Text>

                <Text>
                  <Text style={{ fontWeight: "bold" }}>Date. </Text>
                  <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                    {printData?.EntryDate
                      ? new Date(printData.EntryDate).toLocaleDateString(
                          "en-US",
                          {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }
                        )
                      : ""}
                  </Text>
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <Text>
                  <Text style={{ fontWeight: "bold" }}> Name: </Text>{" "}
                  <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                    {printData?.CustomerCnicNavigation?.CustomerName}
                  </Text>{" "}
                </Text>

                <Text>
                  <Text style={{ fontWeight: "bold" }}> CNIC:{"   "}</Text>{" "}
                  <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                    {printData?.CustomerCnicNavigation?.CustomerCnic2}
                  </Text>
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <Text>
                  <Text style={{ fontWeight: "extrabold" }}> Unit No. </Text>
                  <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                    {printData?.Unit?.UnitName}
                  </Text>
                </Text>

                <Text>
                  <Text style={{ fontWeight: "bold" }}>Size. </Text>
                  <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                    {printData !== undefined && printData.Unit !== undefined
                      ? printData.Unit.RatePerMarlaOrsqft == "MARLA"
                        ? printData.Unit.Marla +
                          "M " +
                          printData.Unit.Sarsai +
                          " S"
                        : printData.Unit.NetSqft.toFixed(2).toLocaleString() +
                          "SQFT"
                      : null}
                  </Text>
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <Text>
                  <Text style={{ fontWeight: "bold" }}>
                    {" "}
                    {printData !== undefined &&
                    printData.Unit !== undefined &&
                    printData.Unit.RatePerMarlaOrsqft == "MARLA"
                      ? "Rate/Marla."
                      : "Rate/Sqft."}{" "}
                  </Text>
                  <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                    {printData?.SaleRate !== undefined
                      ? printData?.SaleRate?.toLocaleString()
                      : null}
                  </Text>
                </Text>
                <Text>
                  <Text style={{ fontWeight: "bold" }}>Total Value. </Text>
                  <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                    {printData !== undefined && printData?.TotalPrice !== null
                      ? printData?.TotalPrice?.toLocaleString()
                      : null}
                  </Text>
                </Text>
                <Text>
                  <Text style={{ fontWeight: "bold" }}>Paid Amount. </Text>
                  <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                    {printData !== undefined &&
                    printData?.TotalPrice !== null &&
                    printData?.CustomerReceivable !== null
                      ? (
                          printData?.TotalPrice - printData?.CustomerReceivable
                        ).toLocaleString()
                      : null}
                  </Text>
                </Text>
                <Text>
                  <Text style={{ fontWeight: "bold" }}>Balance. </Text>
                  <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                    {printData !== undefined &&
                    printData?.CustomerReceivable !== null
                      ? (printData?.CustomerReceivable).toLocaleString()
                      : null}
                  </Text>
                </Text>
              </View>
              <View style={{ fontWeight: "bold", marginTop: "9px" }}>
                <Text> Installment Plan</Text>
              </View>
              <View key={id} style={tableStyles.tableRow}>
                <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                  Sr
                </Text>{" "}
                <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                  Description{" "}
                </Text>{" "}
                <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                  Due Date{" "}
                </Text>
                <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                  Due Amount{" "}
                </Text>
                <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                  Paid Date{" "}
                </Text>
                <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                  Paid Amount{" "}
                </Text>
                <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
                  Balance{" "}
                </Text>
              </View>
              <View key={id + 1} style={tableStyles.tableRow}>
                <Text style={[tableStyles.tableCell, { flex: 1 }]}>1</Text>
                <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                  Booking
                </Text>
                <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                  {printData !== undefined && printData.EntryDate !== undefined
                    ? moment(printData.EntryDate.split("T")[0]).format(
                        "DD-MMM-YYYY"
                      )
                    : null}
                </Text>
                <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                  {printData.AdvanceAmount !== undefined
                    ? printData.AdvanceAmount?.toLocaleString()
                    : 0}
                </Text>
                <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                  {" "}
                  {printData !== undefined && printData.UnitVoucher?.length > 0
                    ? moment(
                        printData.UnitVoucher[0]?.EntryDate.split("T")[0]
                      ).format("DD-MMM-YYYY")
                    : null}
                </Text>
                <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                  {printData.AdvanceReceived?.toLocaleString()}
                </Text>
                <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                  {(
                    printData.AdvanceAmount - printData.AdvanceReceived
                  ).toLocaleString()}
                </Text>
              </View>
              {rowArr}
              <View key={id} style={tableStyles.tableRow}>
                <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                  {id + 2}
                </Text>
                <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                  Installment
                </Text>
                <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                  {moment(row.DueDate.split("T")[0]).format("DD-MMM-YYYY")}
                </Text>
                <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                  {row.DueAmount.toLocaleString()}
                </Text>
                <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                  {row.UnitReceiptDetail?.length !== 0 &&
                  row.UnitReceiptDetail[0]?.UnitVoucher?.EntryDate !== null
                    ? moment(
                        row.UnitReceiptDetail[
                          row.UnitReceiptDetail?.length - 1
                        ]?.UnitVoucher?.EntryDate?.split("T")[0]
                      ).format("DD-MMM-YYYY")
                    : row.UnitReceiptDetail?.length !== 0 &&
                      row.UnitReceiptDetail[0]?.AdjustmentVoucher?.EntryDate !==
                        null
                    ? row.UnitReceiptDetail[0]?.AdjustmentVoucher?.EntryDate?.split(
                        "T"
                      )[0]
                    : null}{" "}
                </Text>
                <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                  {row?.ReceivedAmount.toLocaleString()}
                </Text>
                <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                  {(row.DueAmount - row.ReceivedAmount).toLocaleString()}
                </Text>
              </View>
            </Page>
          );

          rowArr = [];
        } else {
          rowArr.push(
            <View key={id} style={tableStyles.tableRow}>
              <Text style={[tableStyles.tableCell, { flex: 1 }]}>{id + 2}</Text>
              <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                Installment
              </Text>
              <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                {moment(row.DueDate.split("T")[0]).format("DD-MMM-YYYY")}
              </Text>
              <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                {row.DueAmount.toLocaleString()}
              </Text>
              <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                {row.UnitReceiptDetail?.length !== 0 &&
                row.UnitReceiptDetail[0]?.UnitVoucher?.EntryDate !== null
                  ? moment(
                      row.UnitReceiptDetail[
                        row.UnitReceiptDetail?.length - 1
                      ]?.UnitVoucher?.EntryDate?.split("T")[0]
                    ).format("DD-MMM-YYYY")
                  : row.UnitReceiptDetail?.length !== 0 &&
                    row.UnitReceiptDetail[0]?.AdjustmentVoucher?.EntryDate !==
                      null
                  ? row.UnitReceiptDetail[0]?.AdjustmentVoucher?.EntryDate?.split(
                      "T"
                    )[0]
                  : null}{" "}
              </Text>
              <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                {row?.ReceivedAmount.toLocaleString()}
              </Text>
              <Text style={[tableStyles.tableCell, { flex: 2 }]}>
                {(row.DueAmount - row.ReceivedAmount).toLocaleString()}
              </Text>
            </View>
          );
        }
      });
    } else {
      arr.push(
        <Page size="A4" style={styles.page}>
          {" "}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text>
              <Text style={{ fontWeight: "bold" }}> Contract No. </Text>
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData?.ContractId}
              </Text>
            </Text>

            <Text>
              <Text style={{ fontWeight: "bold" }}>Date. </Text>
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData?.EntryDate
                  ? new Date(printData.EntryDate).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })
                  : ""}
              </Text>
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <Text>
              <Text style={{ fontWeight: "bold" }}> Name: </Text>{" "}
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData?.CustomerCnicNavigation?.CustomerName}
              </Text>{" "}
            </Text>

            <Text>
              <Text style={{ fontWeight: "bold" }}> CNIC:{"   "}</Text>{" "}
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData?.CustomerCnicNavigation?.CustomerCnic2}
              </Text>
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <Text>
              <Text style={{ fontWeight: "extrabold" }}> Unit No. </Text>
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData?.Unit?.UnitName}
              </Text>
            </Text>

            <Text>
              <Text style={{ fontWeight: "bold" }}>Size. </Text>
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData !== undefined && printData.Unit !== undefined
                  ? printData.Unit.RatePerMarlaOrsqft == "MARLA"
                    ? printData.Unit.Marla + "M " + printData.Unit.Sarsai + " S"
                    : printData.Unit.NetSqft.toFixed(2).toLocaleString() +
                      "SQFT"
                  : null}
              </Text>
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <Text>
              <Text style={{ fontWeight: "bold" }}>
                {" "}
                {printData !== undefined &&
                printData.Unit !== undefined &&
                printData.Unit.RatePerMarlaOrsqft == "MARLA"
                  ? "Rate/Marla."
                  : "Rate/Sqft."}{" "}
              </Text>
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData?.SaleRate !== undefined
                  ? printData?.SaleRate?.toLocaleString()
                  : null}
              </Text>
            </Text>
            <Text>
              <Text style={{ fontWeight: "bold" }}>Total Value. </Text>
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData !== undefined && printData?.TotalPrice !== null
                  ? printData?.TotalPrice?.toLocaleString()
                  : null}
              </Text>
            </Text>
            <Text>
              <Text style={{ fontWeight: "bold" }}>Paid Amount. </Text>
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData !== undefined &&
                printData?.TotalPrice !== null &&
                printData?.CustomerReceivable !== null
                  ? (
                      printData?.TotalPrice - printData?.CustomerReceivable
                    ).toLocaleString()
                  : null}
              </Text>
            </Text>
            <Text>
              <Text style={{ fontWeight: "bold" }}>Balance. </Text>
              <Text style={[styles.labelFont, { fontWeight: "thin" }]}>
                {printData !== undefined &&
                printData?.CustomerReceivable !== null
                  ? (printData?.CustomerReceivable).toLocaleString()
                  : null}
              </Text>
            </Text>
          </View>
          <View style={{ fontWeight: "bold", marginTop: "9px" }}>
            <Text> Installment Plan</Text>
          </View>
          <View key={1} style={tableStyles.tableRow}>
            <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>Sr</Text>{" "}
            <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
              Description{" "}
            </Text>{" "}
            <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
              Due Date{" "}
            </Text>
            <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
              Due Amount{" "}
            </Text>
            <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
              Paid Date{" "}
            </Text>
            <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
              Paid Amount{" "}
            </Text>
            <Text style={[tableStyles.tableCellHeader, { flex: 2 }]}>
              Balance{" "}
            </Text>
          </View>
          <View style={tableStyles.tableRow}>
            <Text style={[tableStyles.tableCell, { flex: 1 }]}>1</Text>
            <Text style={[tableStyles.tableCell, { flex: 2 }]}>Booking</Text>
            <Text style={[tableStyles.tableCell, { flex: 2 }]}>
              {printData !== undefined && printData.EntryDate !== undefined
                ? moment(printData.EntryDate.split("T")[0]).format(
                    "DD-MMM-YYYY"
                  )
                : null}
            </Text>
            <Text style={[tableStyles.tableCell, { flex: 2 }]}>
              {printData.AdvanceAmount !== undefined
                ? printData.AdvanceAmount?.toLocaleString()
                : 0}
            </Text>
            <Text style={[tableStyles.tableCell, { flex: 2 }]}>
              {" "}
              {printData !== undefined && printData.UnitVoucher?.length > 0
                ? moment(
                    printData.UnitVoucher[0]?.EntryDate.split("T")[0]
                  ).format("DD-MMM-YYYY")
                : null}
            </Text>
            <Text style={[tableStyles.tableCell, { flex: 2 }]}>
              {printData.AdvanceReceived?.toLocaleString()}
            </Text>
            <Text style={[tableStyles.tableCell, { flex: 2 }]}>
              {(
                printData.AdvanceAmount - printData.AdvanceReceived
              ).toLocaleString()}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "40px",
            }}
          >
            <View
              style={{
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Text
                style={{
                  paddingBottom: "5px",
                  alignSelf: "center",
                  fontWeight: "extrabold",
                }}
              >
                Buyer{" "}
              </Text>
              <View
                style={{
                  paddingBottom: "5px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text style={{ fontWeight: "extrabold" }}>Sign: </Text>
                <Text
                  style={{
                    minWidth: "100px",
                    maxWidth: "100px",
                    borderBottom: "1px solid black",
                    paddingBottom: "10px",
                  }}
                ></Text>
              </View>
              <View
                style={{
                  paddingBottom: "5px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text style={{ fontWeight: "extrabold" }}>Thumb: </Text>
                <Text
                  style={{
                    minWidth: "100px",
                    maxWidth: "100px",
                    borderBottom: "1px solid black",
                    paddingBottom: "10px",
                  }}
                ></Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "column",
                justifyContent: "space-between",
                gap: 4,
              }}
            >
              <Text
                style={{
                  paddingBottom: "5px",
                  alignSelf: "center",
                  fontWeight: "extrabold",
                }}
              >
                Project director{" "}
              </Text>
              <View
                style={{
                  paddingBottom: "5px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text style={{ fontWeight: "extrabold" }}>Name: </Text>
                <Text
                  style={{
                    minWidth: "100px",
                    maxWidth: "100px",
                    borderBottom: "1px solid black",
                    paddingBottom: "10px",
                  }}
                ></Text>
              </View>
              <View
                style={{
                  paddingBottom: "5px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text style={{ fontWeight: "extrabold" }}>Sign:{"    "}</Text>
                <Text
                  style={{
                    minWidth: "100px",
                    maxWidth: "100px",
                    borderBottom: "1px solid black",
                    paddingBottom: "10px",
                  }}
                ></Text>
              </View>
            </View>
          </View>
        </Page>
      );
    }

    settableRow(arr);
  }, [printData]);

  return (
    <>
      {close ? (
        <ContractTable />
      ) : (
        <>
          <PDFViewer width={"100%"} height={"800px"}>
            {" "}
            <Document>
              <Page size="A4" style={styles.page}>
                <View style={{ width: "100%" }}>
                  <Image
                    style={{
                      width: "400px",
                      position: "absolute",
                      maxHeight: "400px",
                      display: "flex",
                      alignSelf: "center",
                    }}
                    src={gravitywatermark}
                  />
                </View>
                <View style={{ textAlign: "center", fontSize: "14px" }}>
                  <Text>IQRAR NAMA</Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    width: "100%",
                    gap: 3,
                    flexDirection: "row",
                    paddingBottom: 1,
                  }}
                >
                  <Text>Contract</Text>
                  <Text
                    style={{
                      fontSize: "10px",
                      borderBottom: "1px solid black ",
                    }}
                  >
                    {printData?.ContractId}
                  </Text>
                </View>
                <View style={styles.section}>
                  <View style={{ fontWeight: "bold", padding: "5px 0" }}>
                    <Text>Owner Information:</Text>
                  </View>
                  <View style={styles.infoParent}>
                    <View style={{ ...styles.infoChild, width: "45%" }}>
                      <Text
                        style={{
                          ...styles.labelFont,
                        }}
                      >
                        Name:
                      </Text>
                      <Text style={{ ...styles.valueFont }}>
                        {printData?.CustomerCnicNavigation?.CustomerName}
                      </Text>
                    </View>
                    <View style={{ ...styles.infoChild, width: "45%" }}>
                      <Text style={styles.labelFont}>Father/Husb:</Text>
                      <Text style={styles.valueFont}>
                        {printData?.CustomerCnicNavigation?.GuardianName}
                      </Text>{" "}
                    </View>
                  </View>
                  <View style={styles.infoParent}>
                    <View style={{ ...styles.infoChild, width: "45%" }}>
                      <Text style={styles.labelFont}>CNIC:</Text>
                      <Text style={styles.valueFont}>
                        {printData?.CustomerCnicNavigation?.CustomerCnic2}
                      </Text>
                    </View>
                    <View style={{ ...styles.infoChild, width: "45%" }}>
                      <Text style={styles.labelFont}>Ph:</Text>
                      <Text style={styles.valueFont}>
                        {printData?.CustomerCnicNavigation?.ContactNumber1}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.infoParent}>
                    <View style={styles.infoChild}>
                      <Text style={styles.labelFont}>Address:</Text>
                      <Text style={styles.valueFont}>
                        {printData?.CustomerCnicNavigation.CustomerAddress}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.section}>
                  <View style={{ fontWeight: "bold", padding: "5px 0" }}>
                    <Text>Nominee Information:</Text>
                  </View>
                  <View style={styles.infoParent}>
                    <View style={{ ...styles.infoChild, width: "45%" }}>
                      <Text style={styles.labelFont}>Name:</Text>
                      <Text style={styles.valueFont}>
                        {printData?.Nominee?.NomineeName}
                      </Text>
                    </View>
                    <View style={{ ...styles.infoChild, width: "45%" }}>
                      <Text style={styles.labelFont}>Father/Husb:</Text>
                      <Text style={styles.valueFont}>
                        {printData?.Nominee?.NomineeGuardianName}
                      </Text>{" "}
                    </View>
                  </View>
                  <View style={styles.infoParent}>
                    <View style={{ ...styles.infoChild, width: "45%" }}>
                      <Text style={styles.labelFont}>CNIC:</Text>
                      <Text style={styles.valueFont}>
                        {printData?.Nominee?.NomineeCnic}
                      </Text>
                    </View>
                    <View style={{ ...styles.infoChild, width: "45%" }}>
                      <Text style={styles.labelFont}>Ph:</Text>
                      <Text style={styles.valueFont}>
                        {printData?.Nominee?.NomineeCellNoOne}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.infoParent}>
                    <View style={styles.infoChild}>
                      <Text style={styles.labelFont}>Address:</Text>
                      <Text style={styles.valueFont}>
                        {printData?.Nominee?.NomineeAddress}
                      </Text>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    padding: "0",
                    gap: "3px",
                  }}
                >
                  <View
                    style={{
                      width: "50%",
                      paddingRight: "5px",

                      padding: "3px",

                      ...styles.section,
                    }}
                  >
                    <View style={{ fontWeight: "bold", paddingBottom: "5px" }}>
                      <Text>Property Information:</Text>
                    </View>
                    <View style={styles.infoParent}>
                      <View style={{ ...styles.infoChild, width: "100%" }}>
                        <Text style={styles.labelFont}>Name:</Text>
                        <Text style={styles.valueFont}>
                          {localStorage.getItem("ProjectName") ===
                          "THE GRAND CITY"
                            ? "THE GRAND ICONIC CITY"
                            : localStorage.getItem("ProjectName") ===
                              "GHARTAL LAND"
                            ? "DEFENCE PARK CITY"
                            : localStorage.getItem("ProjectName")}
                        </Text>
                      </View>{" "}
                    </View>
                    <View style={styles.infoParent}>
                      <View style={{ ...styles.infoChild, width: "100%" }}>
                        <Text style={styles.labelFont}>Address:</Text>
                        <Text style={styles.valueFont}>
                          {printData?.Project?.ProjectLocation}
                        </Text>
                      </View>{" "}
                    </View>
                    <View style={styles.infoParent}>
                      <View style={{ ...styles.infoChild, width: "100%" }}>
                        <Text style={styles.labelFont}>kh #:</Text>
                        <Text style={styles.valueFont}>
                          {/* {printData?.Unit.LandPurchase?.KhewatNumber} */}
                          1010
                        </Text>
                      </View>{" "}
                    </View>
                    <View style={styles.infoParent}>
                      <View style={{ ...styles.infoChild, width: "100%" }}>
                        <Text style={styles.labelFont}>Sq #:</Text>
                        <Text style={styles.valueFont}>
                          {/* {printData?.Unit?.LandPurchase?.SqaNumber} */}
                          12
                        </Text>
                      </View>{" "}
                    </View>
                  </View>
                  <View
                    style={{
                      width: "50%",
                      padding: "3px",
                      ...styles.section,
                      flexWrap: "wrap",
                    }}
                  >
                    <View style={{ fontWeight: "bold", paddingBottom: "5px" }}>
                      <Text>Unit Information:</Text>
                    </View>
                    <View style={styles.infoParent}>
                      <View style={{ ...styles.infoChild, width: "45%" }}>
                        <Text style={styles.labelFont}>S/Date:</Text>
                        <Text style={styles.valueFont}>
                          {printData !== undefined &&
                          printData?.EntryDate !== undefined
                            ? moment(printData?.EntryDate.split("T")[0]).format(
                                "DD-MMM-YYYY"
                              )
                            : null}
                        </Text>
                      </View>{" "}
                      <View style={{ ...styles.infoChild, width: "45%" }}>
                        <Text style={styles.labelFont}>Res.:</Text>
                        <Text style={styles.valueFont}>
                          {printData !== undefined &&
                          printData.Unit?.Section !== undefined &&
                          printData.Unit?.Section?.Category.CategoryName !==
                            "COMMERCIAL" ? (
                            // <input
                            //   type="checkbox"
                            //   checked
                            //   style={{ width: "20px", height: "20px" }}
                            //   className=" ml-2"
                            // ></input>
                            <Image
                              src={check}
                              style={{
                                position: "absolute",
                                top: 1,
                                left: 2,
                                width: 8,
                                height: 8,
                              }}
                            />
                          ) : null}
                        </Text>
                        <Text style={styles.labelFont}>Comm.:</Text>
                        <Text style={styles.valueFont}>
                          {printData !== undefined &&
                          printData.Unit?.Section !== undefined &&
                          printData.Unit?.Section?.Category.CategoryName ===
                            "COMMERCIAL" ? (
                            // <input
                            //   type="checkbox"
                            //   checked
                            //   style={{ width: "20px", height: "20px" }}
                            //   className=" ml-2"
                            // ></input>
                            <Image src={check} />
                          ) : null}
                        </Text>
                      </View>{" "}
                    </View>
                    <View
                      style={{
                        ...styles.infoParent,
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <View style={{ ...styles.infoChild, width: "61%" }}>
                        <Text style={styles.labelFont}>Unit No:</Text>
                        <Text style={styles.valueFont}>
                          {printData?.Unit?.UnitName}
                        </Text>
                      </View>{" "}
                      <View style={{ ...styles.infoChild, width: "29%" }}>
                        <Text style={styles.labelFont}>Size:</Text>
                        <Text style={styles.valueFont}>
                          {printData !== undefined &&
                          printData.Unit !== undefined
                            ? printData.Unit.RatePerMarlaOrsqft == "MARLA"
                              ? printData.Unit.Marla +
                                "M " +
                                printData.Unit.Sarsai +
                                " S"
                              : printData.Unit.NetSqft.toFixed(
                                  2
                                ).toLocaleString() + "SQFT"
                            : null}
                        </Text>
                      </View>{" "}
                    </View>

                    <View style={styles.infoParent}>
                      <View style={styles.infoChild}>
                        <Text style={styles.labelFont}>
                          {printData !== undefined &&
                          printData?.Unit !== undefined &&
                          printData?.Unit?.RatePerMarlaOrsqft == "MARLA"
                            ? "Per Marla:"
                            : "Per Sqft:"}
                        </Text>
                        <Text style={styles.valueFont}>
                          {printData !== undefined
                            ? printData?.SaleRate?.toLocaleString()
                            : null}
                        </Text>
                      </View>{" "}
                    </View>
                    <View style={styles.infoParent}>
                      <View style={styles.infoChild}>
                        <Text style={styles.labelFont}>Total Value:</Text>
                        <Text style={styles.valueFont}>
                          {printData?.TotalPrice?.toLocaleString()}
                        </Text>
                      </View>{" "}
                    </View>
                  </View>
                </View>
                <View>
                  <View style={{ fontWeight: "bold", paddingBottom: "5px" }}>
                    <Text>Declaration:</Text>
                  </View>
                  <View style={styles.labelFont}>
                    <Text style={styles.linePadding}>
                      I/We do hereby confirm that I/We;
                    </Text>
                    <Text>
                      {" "}
                      a) have read and understood the terms and conditions and
                      do hereby agree to abide by the same
                    </Text>
                    <Text style={styles.linePadding}>
                      {" "}
                      b) have read and understood the terms and conditions and
                      do hereby agree to abide by the same.I/We do hereby
                      further confirm that I/We shall abide by the existing and
                      any future terms and conditions laid down by the Company,{" "}
                      <Text style={{ fontSize: "12px" }}>
                        "Gravity Associates (Pvt.) Limited"
                      </Text>
                      .
                    </Text>
                    <Text style={styles.linePadding}>
                      {" "}
                      c) I/We do hereby further confirm that I/We shall pay the
                      charges / fee as may be decided by the Company for Common
                      and General services of the Society{" "}
                      <Text style={{ fontSize: "12px" }}>
                        "
                        {localStorage.getItem("ProjectName") == "GHARTAL LAND"
                          ? "DEFENCE PARK CITY"
                          : localStorage.getItem("ProjectName") ===
                            "THE GRAND CITY"
                          ? "THE GRAND ICONIC CITY"
                          : localStorage.getItem("ProjectName")}
                        "
                      </Text>
                      .
                    </Text>
                  </View>
                </View>
                {/* <View
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "space-between",
            }}
          > */}
                <View style={{ marginTop: "20px", width: "100%" }}>
                  <View
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "5px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View style={{ width: "100%" }}>
                      <Text
                        style={{ alignSelf: "center", paddingBottom: "20px" }}
                      >
                        Buyer:
                      </Text>
                      <View
                        style={{
                          paddingBottom: "5px",
                          display: "flex",
                          flexDirection: "row",
                          alignSelf: "center",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>Sign </Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                            paddingBottom: "10px",
                          }}
                        ></Text>
                      </View>
                      <View
                        style={{
                          fontWeight: "bold",
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "20px",
                          paddingBottom: "10px",
                          alignSelf: "center",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>Thumb.</Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                          }}
                        ></Text>
                      </View>
                    </View>
                    {printData?.CustomerCnicNavigation?.Image && (
                      <View
                        style={{
                          width: "100%",
                          alignSelf: "center",
                          paddingRight: "10px",
                        }}
                      >
                        <Image
                          style={{
                            width: "80px",

                            maxHeight: "100px",
                          }}
                          src={{
                            uri: printData?.CustomerCnicNavigation?.Image,
                            method: "GET",
                            headers: { "Cache-Control": "no-cache" },
                          }}
                        />
                      </View>
                    )}
                    <View style={{ width: "100%" }}>
                      <View
                        style={{ fontWeight: "bold", paddingBottom: "20px" }}
                      >
                        <Text
                          style={{
                            alignSelf: "center",
                            fontWeight: "extrabold",
                          }}
                        >
                          Project Director
                        </Text>
                      </View>
                      <View
                        style={{
                          paddingBottom: "5px",
                          display: "flex",
                          flexDirection: "row",
                          alignSelf: "center",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>Sign </Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                          }}
                        ></Text>
                      </View>
                      {/* <View
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "5px",
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "20px",
                    padding: "5px",
                    paddingBottom: "10px",
                  }}
                >
                  <Text style={{ fontWeight: "extrabold" }}>Thumb.</Text>
                  <Text
                    style={{
                      minWidth: "100px",
                      maxWidth: "100px",
                      borderBottom: "1px solid black",
                    }}
                  ></Text>
                </View> */}
                    </View>
                  </View>
                </View>
                {/* </View> */}
              </Page>
              <Page size="A4" style={styles.page}>
                <View style={{ width: "100%" }}>
                  <Image
                    style={{
                      width: "400px",
                      position: "absolute",
                      maxHeight: "400px",
                      display: "flex",
                      alignSelf: "center",
                    }}
                    src={gravitywatermark}
                  />
                </View>
                <View>
                  <View style={{ fontWeight: "bold", paddingBottom: "5px" }}>
                    <Text>Terms And Conditions</Text>
                  </View>
                  <View style={styles.labelFont}>
                    <Text style={styles.linePadding}>
                      The developer/Company reserves the right to cancel the
                      allotment of the plot at any time in case of violation of
                      byelaws / Rules / regulations and default in payment of
                      outstanding dues / maintenance charges. Your allotment is
                      particularly regulated as per following major conditions:
                    </Text>
                    <View style={styles.listItem}>
                      <Text style={styles.bullet}>a. </Text>
                      <Text style={styles.content}>
                        The developer will be responsible for development (i.e.
                        Boundary wall, Carpet Roads and sewerage).
                      </Text>
                    </View>
                    <View style={styles.listItem}>
                      <Text style={styles.bullet}>b. </Text>
                      <Text style={styles.content}>
                        Price of the property and the related taxes and duties
                        to be paid by the customer.
                      </Text>
                    </View>
                    <View style={styles.listItem}>
                      <Text style={styles.bullet}>c.{"  "}</Text>
                      <Text style={styles.content}>
                        Legal formalities (transfer / registry) will be
                        completed only on satisfactory receipt of the entire
                        price.A final measurement (Tateema) will be done at the
                        time of possession and applicant agrees to accept the
                        changes in actual size of the plot and allow the
                        developer to adjust the resulting charges accordingly as
                        per booking price.
                      </Text>
                    </View>
                    <View style={styles.listItem}>
                      <Text style={styles.bullet}>d. </Text>
                      <Text style={styles.content}>
                        Should there be any changes in the project’s plan,
                        layouts due to Govt. compliance, then they would be
                        adjusted accordingly. The applicant agrees to accept
                        these changes.
                      </Text>
                    </View>
                    <View style={styles.listItem}>
                      <Text style={styles.bullet}>e. </Text>
                      <Text style={styles.content}>
                        The amount of any cancelled unit will be returned after
                        deduction of 15% of the amount.
                      </Text>
                    </View>
                    <View style={styles.listItem}>
                      <Text style={styles.bullet}>f. </Text>
                      <Text style={styles.content}>
                        The construction of the unit will be subject to the
                        applicable Govt. rules/laws and subject to the approval
                        of the developer.
                      </Text>
                    </View>
                    <View style={styles.listItem}>
                      <Text style={styles.bullet}>g.{"  "}</Text>
                      <Text style={styles.content}>
                        Documentation and transfer charges will be borne by the
                        buyer as suggested by the legal advisor of the
                        developer. Transfer of a unit from one applicant to new
                        buyer will be conducted only on satisfactory receipt of
                        the dues as per payment plan.
                      </Text>
                    </View>
                    <View style={styles.listItem}>
                      <Text style={styles.bullet}>h. </Text>
                      <Text style={styles.content}>
                        The transfer charges of file will be Rs. 5,000/-.
                      </Text>
                    </View>
                    <View style={styles.listItem}>
                      <Text style={styles.bullet}>i. </Text>
                      <Text style={styles.content}>
                        The maintenance and security services of the project
                        will be executed by the developer. Maintenance and
                        security charges will be charged on Marla basis to the
                        unit owners/tenants.
                      </Text>
                    </View>
                    <View style={styles.listItem}>
                      <Text style={styles.bullet}>j.{"  "}</Text>
                      <Text style={styles.content}>
                        No cattle/animals are allowed within the vicinity of the
                        project.
                      </Text>
                    </View>
                    <View style={styles.listItem}>
                      <Text style={styles.bullet}>k.{"  "}</Text>
                      <Text style={styles.content}>
                        One Marla will be of 270 sq. ft.
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "40px",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text
                        style={{
                          paddingBottom: "5px",
                          alignSelf: "center",
                          fontWeight: "extrabold",
                        }}
                      >
                        Buyer{" "}
                      </Text>
                      <View
                        style={{
                          paddingBottom: "5px",
                          display: "flex",
                          flexDirection: "row",
                          alignSelf: "center",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>Sign: </Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                            paddingBottom: "10px",
                          }}
                        ></Text>
                      </View>
                      <View
                        style={{
                          paddingTop: "15px",
                          display: "flex",
                          flexDirection: "row",
                          alignSelf: "center",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>Thumb:</Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                            paddingBottom: "10px",
                          }}
                        ></Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "space-between",
                        gap: 4,
                      }}
                    >
                      <Text
                        style={{
                          paddingBottom: "5px",
                          alignSelf: "center",
                          fontWeight: "extrabold",
                        }}
                      >
                        Witness 1{" "}
                      </Text>
                      <View
                        style={{
                          paddingBottom: "5px",
                          display: "flex",
                          flexDirection: "row",
                          alignSelf: "center",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>Sign: </Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                            paddingBottom: "10px",
                          }}
                        ></Text>
                      </View>
                      <View
                        style={{
                          paddingTop: "15px",
                          display: "flex",
                          flexDirection: "row",
                          alignSelf: "center",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>Thumb:</Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                            paddingBottom: "10px",
                          }}
                        ></Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "space-between",
                        gap: 4,
                      }}
                    >
                      <Text
                        style={{
                          paddingBottom: "5px",
                          alignSelf: "center",
                          fontWeight: "extrabold",
                        }}
                      >
                        Witness 2{" "}
                      </Text>
                      <View
                        style={{
                          paddingBottom: "5px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>Sign: </Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                            paddingBottom: "10px",
                          }}
                        ></Text>
                      </View>
                      <View
                        style={{
                          paddingTop: "15px",
                          display: "flex",
                          flexDirection: "row",
                          alignSelf: "center",
                        }}
                      >
                        <Text style={{ fontWeight: "extrabold" }}>Thumb:</Text>
                        <Text
                          style={{
                            minWidth: "100px",
                            maxWidth: "100px",
                            borderBottom: "1px solid black",
                            paddingBottom: "10px",
                          }}
                        ></Text>
                      </View>
                    </View>
                  </View>
                </View>
              </Page>
              {tableRows}
            </Document>
          </PDFViewer>
          <button
            style={{ color: "black !important", marginBottom: "20px" }}
            className="print_btn float-left"
            onClick={handleClick}
          >
            {"< Go Back"}
          </button>
        </>
      )}
    </>
  );
}

export default ContractNewPrint;
