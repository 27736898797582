import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BsCloudUpload } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { getallTableAssets } from "../../actions/assetsaction/addassets";
import { getalllenders } from "../../actions/assetsaction/assetbuyer";
import { getallSell } from "../../actions/assetsaction/assetssell";
import axios from "axios";
import { MdDeleteForever } from "react-icons/md";
import { toast } from "react-toastify";

import { FiEdit2 } from "react-icons/fi";

import Loader from "react-loader-spinner";
import moment from "moment";
const MainSellAssetsCom = () => {
  const showassetstable = () => {
    stateshowassets("AssetsAdd");
  };
  const cancelassetstable = () => {
    stateshowassets("AssetsTable");
  };
  const [showassets, stateshowassets] = useState("AssetsTable");

  return (
    <>
      <div className="row ">
        <div className="dash_background col-12 p-0">
          <div className="row ">
            <div className="col-md-12">
              {showassets == "AssetsTable" ? (
                <AssetsTable
                  showassetstable={showassetstable}
                  cancelassetstable={cancelassetstable}
                />
              ) : (
                <AssestsAdd cancelassetstable={cancelassetstable} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MainSellAssetsCom;
export const AssestsAdd = (props) => {
  const [stateshowhide, setshowide] = useState(true);
  const [stateassetid, SetAssetId] = useState(0);
  const [StateBuyerId, SetBuyerId] = useState(0);

  const assetsdatastate = useSelector((state) => state.GetTableAssetsReducer);
  const resultalllenders = useSelector(
    (state) => state.GetAllAssetBuyerReducer
  );

  let dispatch = useDispatch();
  var assetssuggestionlist = [];

  const assetSelecttion = (e) => {
    const value = e.target.value;
    if (value == "") {
      ReactDOM.render(null, document.getElementById("assetdemo"));
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = assetsdatastate.data
          .sort()
          .filter((v) => regex.test(v.AssetName !== null ? v.AssetName : null));
      }
      assetssuggestionlist = suggestions;

      if (assetssuggestionlist.length === 0) {
        ReactDOM.render(null, document.getElementById("assetdemo"));
      } else {
        ReactDOM.render(
          <ul className="suggestbox">
            {assetssuggestionlist.map((item) => (
              <li
                className="listcss"
                onClick={() => assetsuggestionselected(item)}
              >
                {item === undefined ? item : item.AssetName}
              </li>
            ))}
          </ul>,
          document.getElementById("assetdemo")
        );
      }
    }
  };
  const onTextChangeAssetName = () => {
    var inputValue = document.getElementById("name").value;
    var options = document.getElementById("assetnamelist").childNodes;

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        assetsdatastate.data.Assets.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });
        SetAssetId(val.AssetId);

        break;
      }
    }
  };
  const assetsuggestionselected = (val) => {
    document.getElementById("name").value = val.AssetName;

    SetAssetId(val.AssetId);

    assetssuggestionlist = [];
    ReactDOM.render(null, document.getElementById("assetdemo"));
  };
  var buyersuggestionlist = [];

  const buyerSelecttion = (e) => {
    const value = e.target.value;
    if (value == "") {
      ReactDOM.render(null, document.getElementById("buyerdemo"));
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = resultalllenders.data
          .sort()
          .filter((v) => regex.test(v.BuyerName !== null ? v.BuyerName : null));
      }
      buyersuggestionlist = suggestions;

      if (buyersuggestionlist.length === 0) {
        ReactDOM.render(null, document.getElementById("buyerdemo"));
      } else {
        ReactDOM.render(
          <ul className="suggestbox">
            {buyersuggestionlist.map((item) => (
              <li
                className="listcss"
                onClick={() => buyersuggestionselected(item)}
              >
                {item === undefined ? item : item.BuyerName}
              </li>
            ))}
          </ul>,
          document.getElementById("buyerdemo")
        );
      }
    }
  };
  const onTextChangeBuyerName = () => {
    var inputValue = document.getElementById("buyer").value;
    var options = document.getElementById("soldtolist").childNodes;

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        resultalllenders.data.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });
        SetBuyerId(val.BuyerId);

        break;
      }
    }
  };
  const buyersuggestionselected = (val) => {
    document.getElementById("buyer").value = val.BuyerName;

    SetBuyerId(val.BuyerId);

    buyersuggestionlist = [];
    ReactDOM.render(null, document.getElementById("buyerdemo"));
  };
  const savedata = () => {
    if (document.getElementById("name").value === "") {
      toast.info("Select Asset");
    } else if (document.getElementById("buyer").value === "") {
      toast.info("Select Buyer");
    } else if (document.getElementById("Entry").value === "") {
      toast.info("Select Date");
    } else {
      document.getElementById("sellsave").disabled = true;
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}Assets/Sell`,
        data: JSON.stringify({
          AssetId: stateassetid,
          BuyerId: StateBuyerId,
          EntryDate: document.getElementById("Entry").value,
          SaleValue: parseInt(
            document.getElementById("sellamount").value.replace(/,/g, "")
          ),
          Notes: document.getElementById("Notes").value,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          document.getElementById("sellsave").disabled = false;
          if (result.data.IsSuccess === true) {
            toast.info(result.data.Message);
            props.cancelassetstable();
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          toast.info("Someting went wrong");
          document.getElementById("sellsave").disabled = false;
        }
      );
    }
  };
  useEffect(() => {
    dispatch(getallTableAssets());
    dispatch(getalllenders());
  }, [dispatch]);

  return (
    <>
      <div className="row ">
        <div className="col-12  page_heading">
          <h4 className="  text-center ">Sell An Asset</h4>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Asset Name :</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="text"
            list="assetnamelist"
            className="input_styling  ml-md-3"
            placeholder="suggestion list"
            id="name"
            onChange={onTextChangeAssetName}
          ></input>
          <span className="estaric">*</span>
          <datalist id="assetnamelist">
            {assetsdatastate?.data?.Assets?.map((x, idx) => (
              <option index={idx} value={x.AssetName}>
                {x.AssetId}
              </option>
            ))}
          </datalist>
          <span id="assetdemo"></span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Sale Date :</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="date"
            className="input_date_styling  ml-md-3"
            id="Entry"
          ></input>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Sold To</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="text"
            list="soldtolist"
            className="input_styling  ml-md-3"
            id="buyer"
            placeholder="suggestion list"
            onChange={onTextChangeBuyerName}
          ></input>
          <span className="estaric">*</span>
          <datalist id="soldtolist">
            {resultalllenders?.data?.map((x, idx) => (
              <option index={idx} value={x.BuyerName}>
                {x.BuyerId}
              </option>
            ))}
          </datalist>
          <span id="buyerdemo"></span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 "> Sell Amount:</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="text"
            className="input_styling  ml-md-3"
            id="sellamount"
            onChange={(e) => {
              if (e.target.value.length > 0)
                document.getElementById("sellamount").value = parseInt(
                  e.target.value.replace(/,/g, "")
                ).toLocaleString();
            }}
          ></input>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Notes</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <textarea className="input_address ml-md-3" id="Notes"></textarea>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className=" float-right mt-md-4 mr-md-3">
        <span>
          <button
            className="cancel_btn ml-2  "
            onClick={props.cancelassetstable}
          >
            Cancel
          </button>
        </span>

        <span>
          <button className="save_btn ml-2  " id="sellsave" onClick={savedata}>
            <BsCloudUpload size="1.3em" />
            Save
          </button>
        </span>
      </div>
    </>
  );
};
export const AssetsTable = (props) => {
  const Assetsdatastate = useSelector((state) => state.GetAssetsSell);

  const [allDesignationData, setallDesignationData] = useState([]);
  const [filerunits, setfilterunits] = useState();

  let dispatch = useDispatch();

  var suggestionlist = [];

  const onTextChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setfilterunits(Assetsdatastate.data);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");

        suggestions = filerunits
          .sort()
          .filter((val) =>
            regex.test(val.Asset !== null ? val.Asset.AssetName : null)
          );
      }
      suggestionlist = suggestions;
      setfilterunits(suggestionlist);
    }
  };

  useEffect(() => {
    dispatch(getallSell());
  }, [dispatch]);
  useEffect(() => {
    setfilterunits(Assetsdatastate.data);
  }, [Assetsdatastate]);
  return (
    <>
      <div className="row  ">
        <div className="dash_background col-12 p-0">
          {/* < div className="row ">
                        <div className="col-md-12   text-left page_heading">
                            <h4 className="m-0">Salaries</h4>
                        </div>
                    </div> */}
          <div className="row pt-3 ">
            <div className="col-md-1 pr-0 pl-0">
              <label className="input_label  m-0">Search:</label>
            </div>
            <div className="col-md-3   pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                placeholder="Asset"
                onChange={onTextChange}
              ></input>
            </div>

            <div className="col-md-1 pr-0 pl-0">
              {Assetsdatastate.loading ? (
                <Loader type="ThreeDots" color="green" height={40} width={40} />
              ) : null}
            </div>
          </div>

          <div className="table-responsive mt-4">
            <table className="table table-borderless  table-hover m-0">
              <thead>
                <tr>
                  <th>Sr</th>

                  <th>Asset</th>

                  <th> Buyer</th>
                  <th>EntryDate</th>
                  <th>SaleValue</th>
                  <th>Note</th>

                  <th className="text-center">
                    <button
                      className="save_btn"
                      onClick={() => {
                        props.showassetstable();
                      }}
                    >
                      New+
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {filerunits !== undefined
                  ? filerunits.map((val, index) => (
                      <tr key={index + 1}>
                        <td className="cur_sor">{index + 1}</td>

                        <td className="cur_sor">
                          {val.Asset !== undefined && val.Asset !== null
                            ? val.Asset.AssetName
                            : null}
                        </td>
                        <td className="cur_sor">
                          {val.Buyer !== undefined && val.Buyer !== null
                            ? val.Buyer.BuyerName
                            : null}
                        </td>

                        <td className="cur_sor">
                          {val.EntryDate !== undefined
                            ? moment(val.EntryDate.split("T")[0]).format(
                                "DD-MMM-YYYY"
                              )
                            : null}
                        </td>
                        <td className="cur_sor">
                          {val.SaleValue !== undefined
                            ? val.SaleValue.toLocaleString()
                            : null}
                        </td>
                        <td className="cur_sor">
                          {val.Notes !== undefined ? val.Notes : null}
                        </td>
                        <td className="cur_sor"></td>

                        {/* <td><FiEdit2 title="Edit" size="1.1em" className="cur_sor" onClick={() => { props.setallallowances(val.EmployeeSalaryAllowances); props.setstate(val); props.setempid(val.Employee.EmployeeId); props.showdesignationtable(); props.setedit(true); }} /></td> */}
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
