import axios from "axios";
import { toast } from "react-toastify";
export const GetAllJournalEnrty = (data) => {
  return {
    type: "get_alljournalentry",
    payload: data,
    loader: false,
  };
};
export function getalljournalenrty(data, link) {
  return (dispatch) => {
    let pgurl = `${
      link
        ? link + "&"
        : process.env.REACT_APP_API_URL + "Vouchers/Journal/GetAll?"
    }ProjectId=${localStorage.getItem("ProjectId")}&From=${
      data.From ? data.From : ""
    }&To=${data.To ? data.To : ""}&Status=${
      data.Status ? data.Status : ""
    }&VoucherId=${data.VoucherId ? data.VoucherId : ""}`;
    if (localStorage.getItem("ProjectId") === null) {
      pgurl = `${process.env.REACT_APP_API_URL}Vouchers/Journal/GetAll`;
    }
    axios({
      url: pgurl,
      method: "Post",
      data: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.Succeeded === true) {
          dispatch(GetAllJournalEnrty(result.data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
