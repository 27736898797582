import axios from "axios";
import { toast } from "react-toastify";
export const GetAllClients = (data) => {
  return {
    type: "get_allclients",
    payload: data,
    loader: false,
  };
};
export const GetClientsData = (data) => {
  return {
    type: "get_clientsdata",
    payload: data,
  };
};

export function getallclients() {
  return (dispatch) => {
    axios({
      url: `${process.env.REACT_APP_API_URL}Managers/GetManagerClient`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllClients(result.data.Data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
export const GetAllCustomers = (data) => {
  return {
    type: "get_allcustomerdata",
    payload: data,
    loader: false,
  };
};
export function getallcustomers() {
  return (dispatch) => {
    let pgurl = `${
      process.env.REACT_APP_API_URL
    }Customers/GetAll?ProjectId=${localStorage.getItem("ProjectId")}`;
    if (localStorage.getItem("ProjectId") === null) {
      pgurl = `${process.env.REACT_APP_API_URL}Customers/GetAll`;
    }
    axios({
      url: pgurl,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          console.log(result.data.Data);
          dispatch(GetAllCustomers(result.data.Data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
export const GetCustomerData = (data) => {
  return {
    type: "get_customerdata",
    payload: data,
  };
};
