import axios from "axios";
import { toast } from "react-toastify";
export const GetAllTaxReceivable = (data) => {
  return {
    type: "get_taxreceivable",
    payload: data,
    loader: false,
  };
};
export function getallTaxReceivableTable() {
  return (dispatch) => {
    axios({
      url: `${process.env.REACT_APP_API_URL}Vouchers/TaxReceivables/Populate`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllTaxReceivable(result.data.Data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
export const GetAllTaxReceivableVoucher = (data) => {
  return {
    type: "get_allreceiptVoucher",
    payload: data,
    loader: false,
  };
};
export function getallTaxReceivableVoucherTable(data) {
  return (dispatch) => {
    axios({
      url: `${
        process.env.REACT_APP_API_URL
      }Vouchers/TaxReceivables/GetAll?ProjectId=${localStorage.getItem(
        "ProjectId"
      )}`,
      method: "Post",
      data: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllTaxReceivableVoucher(result.data.Data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
