import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BsCloudUpload } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { getallProject } from "../../actions/getprojects";

import axios from "axios";
import { MdDeleteForever } from "react-icons/md";
import { BiTask } from "react-icons/bi";
import { TiGroup } from "react-icons/ti";
import { BiPhoneCall } from "react-icons/bi";
import { toast } from "react-toastify";
import { FiEdit2 } from "react-icons/fi";

import moment from "moment";
import { getallmeasurment } from "../../actions/getunit";

import {
  getallPopulateLead,
  getallTableLead,
  getallLeadTaskPopulate,
  getallLeadMeetingPopulate,
  getallLeadCallPopulate,
} from "../../actions/Crmactions/addleads";
import Loader from "react-loader-spinner";
const MainLeadComp = () => {
  const showleadtable = () => {
    stateshowlead("Lead");
  };
  const cancelleadtable = () => {
    stateshowlead("LeadTable");
  };
  const [showemployee, stateshowlead] = useState("LeadTable");

  return (
    <>
      <div className="row ">
        <div className="dash_background col-12 p-0">
          <div className="row ">
            <div className="col-md-12">
              {showemployee == "LeadTable" ? (
                <AddLeadTable
                  showleadtable={showleadtable}
                  cancelleadtable={cancelleadtable}
                />
              ) : (
                <LeadAddPage cancelleadtable={cancelleadtable} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MainLeadComp;
export const LeadAddPage = (props) => {
  const resultsmeasurement = useSelector((state) => state.getAllMeasureUnit);
  const populateLeadData = useSelector((state) => state.getPopulateLeads);

  const [tablepopulate, settablepopulate] = useState([]);
  const [toggle, settoggle] = useState(false);
  const [stateinitiateid, setinitiateid] = useState(0);
  const [statedesignation, setdesignation] = useState();

  const [stateUnits, setUnits] = useState({});
  const [state, setstate] = useState({
    LeadId: 0,
    EntryDate: null,
    SourceOfLead: null,
    ManagerClientId: null,
    Comments: null,
    Classification: null,
    BudgetAmount: null,
    InitiateBy: null,
    Designation: null,
  });

  let dispatch = useDispatch();
  var suggestionlist1 = [];

  const designationSelecttion = (e) => {
    const value = e.target.value;
    if (value == "") {
      ReactDOM.render(null, document.getElementById("unitdemo"));
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = populateLeadData.data.Employees.sort().filter((v) =>
          regex.test(v.EmpName !== null ? v.EmpName : null)
        );
      }
      suggestionlist1 = suggestions;

      if (suggestionlist1.length === 0) {
        ReactDOM.render(null, document.getElementById("unitdemo"));
      } else {
        ReactDOM.render(
          <ul className="suggestbox">
            {suggestionlist1.map((item) => (
              <li className="listcss" onClick={() => suggestionselected1(item)}>
                {item === undefined
                  ? item
                  : item.EmpName.concat(
                      " / ",
                      item.EmpDesignation.DesignationName
                    )}
              </li>
            ))}
          </ul>,
          document.getElementById("unitdemo")
        );
      }
    }
  };
  const suggestionselected1 = (val) => {
    document.getElementById("inittiate").value = val.EmpName;
    setinitiateid(val.EmployeeId);
    setdesignation(val.EmpDesignation.DesignationName);

    // setUnitId(val.UnitId);

    suggestionlist1 = [];
    ReactDOM.render(null, document.getElementById("unitdemo"));
  };
  const custominstallments = () => {
    let item = {
      project: null,
      unitarea: null,
      measureunit: null,
      unittype: null,
      requirment: null,
    };
    settablepopulate([...tablepopulate, item]);
  };
  const deleteinstallment = (idx) => {
    const rows = [...tablepopulate];

    rows.splice(idx, 1);

    settablepopulate(rows);
  };
  const onTextChangeInitiateName = () => {
    var inputValue = document.getElementById("inittiate").value;
    var options = document.getElementById("employeenamelist").childNodes;

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        populateLeadData.data.Employees.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });
        setinitiateid(val.EmployeeId);
        setdesignation(val.EmpDesignation.DesignationName);

        break;
      }
    }
  };
  const savedata = () => {
    if (document.getElementById("Date").value === "") {
      toast.info("Date is Required");
    } else if (document.getElementById("inittiate").value === "") {
      toast.info("Enter Who get this deal");
    } else if (document.getElementById("client").selectedIndex === 0) {
      toast.info("Select Client");
    } else if (document.getElementById("source").selectedIndex === 0) {
      toast.info("Select source");
    } else if (document.getElementById("Classification").selectedIndex === 0) {
      toast.info("Select Classification");
    } else if (document.getElementById("budjet").value === "") {
      toast.info("Enter Budget Amount");
    } else if (document.getElementById("Comment").value === "") {
      toast.info("Add Comments");
    } else if (tablepopulate.length <= 0) {
      toast.info("Add Unit to table");
    } else {
      let Lead = state;
      Lead.LeadId = parseInt(0);
      Lead.InitiateBy = parseInt(stateinitiateid);

      Lead.SourceOfLead =
        document.getElementById("source").options[
          document.getElementById("source").selectedIndex
        ].value;
      Lead.ManagerClientId = parseInt(
        document.getElementById("client").options[
          document.getElementById("client").selectedIndex
        ].dataset.id
      );
      Lead.EntryDate = document.getElementById("Date").value;
      Lead.Comments = document.getElementById("Comment").value;
      Lead.BudgetAmount = parseInt(
        document.getElementById("budjet").value.replace(/,/g, "")
      );
      Lead.Designation = statedesignation;
      Lead.Classification =
        document.getElementById("Classification").options[
          document.getElementById("Classification").selectedIndex
        ].value;

      let LeadDetail = [];
      tablepopulate.forEach((x, idx) => {
        if (document.getElementById(`dealprojecttablefield${idx}`) !== null) {
          LeadDetail.push({
            ProjectId: document.getElementById(`dealprojecttablefield${idx}`)
              .options[
              document.getElementById(`dealprojecttablefield${idx}`)
                .selectedIndex
            ].dataset.id,
            UnitArea: document.getElementById(`dealunitareatablefield${idx}`)
              .value,
            MeasureUnitId: document.getElementById(`dealmeasureunitfield${idx}`)
              .options[
              document.getElementById(`dealmeasureunitfield${idx}`)
                .selectedIndex
            ].dataset.id,
            UnitType: document.getElementById(`dealunittypefield${idx}`)
              .options[
              document.getElementById(`dealunittypefield${idx}`).selectedIndex
            ].value,
            Requirments: document.getElementById(`dealrequirementfield${idx}`)
              .value,
            UnitId: document.getElementById(`dealunitfield${idx}`).options[
              document.getElementById(`dealunitfield${idx}`).selectedIndex
            ].dataset.id,
          });
        }
      });

      document.getElementById("leadsave").disabled = true;
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}Leads/Add`,
        data: JSON.stringify({
          Lead,
          LeadDetail,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          document.getElementById("leadsave").disabled = false;
          if (result.data.IsSuccess === true) {
            document.getElementById("Date").value = "";

            toast.info(result.data.Message);
            props.cancelleadtable();
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          toast.info("Someting went wrong");
          document.getElementById("leadsave").disabled = false;
        }
      );
    }
  };

  useEffect(() => {
    dispatch(getallmeasurment());

    dispatch(getallPopulateLead());
  }, [dispatch]);
  return (
    <>
      <div className="row ">
        <div className="col-md-12   text-left page_heading">
          <h4 className="m-0">Add Lead</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 ">Date:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <input
            type="date"
            className="input_date_styling  ml-md-3"
            defaultValue={moment().format("YYYY-MM-DD")}
            id="Date"
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4  p-0">
          <label className="input_label m-0 ">Initiate By:</label>
        </div>
        <div className="col-md-3 mt-4">
          <input
            type="text"
            id="inittiate"
            list="employeenamelist"
            className="input_styling ml-md-3 "
            onChange={onTextChangeInitiateName}
          ></input>
          <span className="estaric">*</span>
          <datalist id="employeenamelist">
            {populateLeadData?.data?.Employees?.map((x, idx) => (
              <option index={idx} value={x.EmpName}>
                {x.EmployeeId}
              </option>
            ))}
          </datalist>
          <span id="unitdemo"></span>
        </div>
        <div className="col-md-1 mt-4  p-0">
          <label className="input_label m-0 ">Lead Source:</label>
        </div>
        <div className="col-md-3 mt-4">
          <select className="input_styling ml-md-3 " id="source">
            <option>Select</option>
            <option>Employee Ref</option>
            <option>Sales Team Ref</option>
            <option>Social Media</option>
            <option>Advertisement</option>
            <option>Other</option>
          </select>
          <span className="estaric">*</span>
        </div>
      </div>

      <div className="row">
        <div className="col-md-1 mt-4  p-0">
          <label className="input_label m-0 ">Client:</label>
        </div>
        <div className="col-md-3 mt-4">
          <select className="input_styling ml-md-3" id="client">
            <option>Select</option>

            {populateLeadData.data.Clients !== undefined
              ? populateLeadData.data.Clients.map((val, index) => (
                  <option key={index} data-id={val.ManagerClientId}>
                    {val.ManagerClientName}
                  </option>
                ))
              : null}
          </select>
          <span className="estaric">*</span>
        </div>

        <div className="col-md-1 mt-4  p-0">
          <label className="input_label m-0 ">Budget Amount:</label>
        </div>
        <div className="col-md-3 mt-4">
          <input
            type="text"
            className="input_styling   ml-md-3"
            id="budjet"
            onChange={(e) => {
              if (e.target.value.length > 0)
                document.getElementById("budjet").value = parseInt(
                  e.target.value.replace(/,/g, "")
                ).toLocaleString();
            }}
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4  text-left p-0">
          <label className="input_label m-0">Classification:</label>
        </div>

        <div className="col-md-3 mt-4   ">
          <select className="input_styling ml-md-3 " id="Classification">
            <option>Select</option>
            <option>Hot</option>
            <option>Moderate</option>
            <option>Cold</option>
          </select>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1 mt-4  text-left p-0">
          <label className="input_label m-0">Comment:</label>
        </div>

        <div className="col-md-3 mt-4   ">
          <textarea className="input_address ml-md-3 " id="Comment"></textarea>
          <span className="estaric">*</span>
        </div>
        {/* <div className="col-md-1 mt-4  text-left p-0">
                    <label className="input_label m-0">Measure Unit:</label>
                </div>

                <div className="col-md-3 mt-4  ">
                    <select className="input_styling ml-md-3" id="areasize"  >
                        <option>Select</option>
                    
                        {resultsmeasurement.data.map((val, index) => (
                            <option key={index} data-id={val.MeasureUnitId}>{val.Unit}</option>
                        ))}
                    </select>
                </div> */}
      </div>

      <div className="mt-4">
        <div className="table-responsive mt-4">
          <table className="table table-borderless m-0">
            <thead>
              <tr>
                <th>Project</th>
                <th>Unit</th>
                <th>Unit Area</th>
                <th>Measure Unit</th>
                <th>Unit Type</th>
                <th>Requirments</th>

                <th>
                  <button className="save_btn " onClick={custominstallments}>
                    Add
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {tablepopulate.map((x, idx) => (
                <tr key={x.rand}>
                  <td>
                    {/* <input
                                            type="number"

                                            className="input_styling"


                                        /> */}
                    <select
                      id={`dealprojecttablefield${idx}`}
                      className="input_styling "
                      onChange={() => {
                        setUnits(
                          populateLeadData.data.Projects[
                            document.getElementById(
                              `dealprojecttablefield${idx}`
                            ).options[
                              document.getElementById(
                                `dealprojecttablefield${idx}`
                              ).selectedIndex
                            ].dataset.idx
                          ]
                        );
                      }}
                    >
                      <option>Select</option>
                      {populateLeadData.data.Projects.map((x, index) => (
                        <option
                          data-id={x.ProjectId}
                          data-idx={index}
                          key={index}
                        >
                          {x.ProjectName}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      className="input_styling "
                      id={`dealunitfield${idx}`}
                    >
                      <option>Select</option>

                      {stateUnits.Units !== undefined
                        ? stateUnits.Units.map((val, index) => (
                            <option key={index} data-id={val.UnitId}>
                              {val.UnitName}
                            </option>
                          ))
                        : null}
                    </select>
                  </td>
                  <td>
                    <input
                      type="number"
                      id={`dealunitareatablefield${idx}`}
                      className="input_styling"
                      defaultValue={
                        document.getElementById(`dealunitfield${idx}`) !== null
                          ? document.getElementById(`dealunitfield${idx}`)
                              .options[
                              document.getElementById(`dealunitfield${idx}`)
                                .selectedIndex
                            ].dataset.id
                          : null
                      }
                    />
                  </td>
                  <td>
                    <select
                      className="input_styling "
                      id={`dealmeasureunitfield${idx}`}
                    >
                      <option>Select</option>

                      {resultsmeasurement.data.map((val, index) => (
                        <option key={index} data-id={val.MeasureUnitId}>
                          {val.Unit}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      className="input_styling"
                      id={`dealunittypefield${idx}`}
                    >
                      <option>Select</option>
                      <option>Plot</option>
                      <option>House</option>
                      <option>Shop</option>
                      <option>Appartment</option>
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      id={`dealrequirementfield${idx}`}
                      className="input_styling"
                    />
                  </td>
                  <td
                    onClick={() => {
                      deleteinstallment(idx);
                      settoggle(!toggle);
                    }}
                  >
                    <MdDeleteForever size="1.6em" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className=" float-right mt-md-4 ">
        <span>
          <button className="cancel_btn ml-2  " onClick={props.cancelleadtable}>
            Cancel
          </button>
        </span>

        <span>
          <button className="save_btn ml-2  " id="leadsave" onClick={savedata}>
            <BsCloudUpload size="1.3em" />
            Save
          </button>
        </span>
      </div>
    </>
  );
};

export const AddLeadTable = (props) => {
  const resultallleads = useSelector((state) => state.getTableLeads);
  const populateLeadTask = useSelector((state) => state.getLeadTaskPopulate);
  const populateMeetingTask = useSelector(
    (state) => state.getLeadMeetingPopulate
  );
  const populateCallTask = useSelector((state) => state.getLeadCallPopulate);
  const ProjectData = useSelector((state) => state.getProject);

  const populateLeadData = useSelector((state) => state.getPopulateLeads);
  const resultsmeasurement = useSelector((state) => state.getAllMeasureUnit);
  const [statemangerclient, setmangerclient] = useState();
  const [stateeditlead, seteditlead] = useState();
  const [statetype, settype] = useState();
  const [stateUnits, setUnits] = useState({});

  // for project data
  const [stateblocks, setblocks] = useState([]);
  const [stateunit, setunit] = useState([]);

  const [allLeadsData, setallLeadsData] = useState();
  const [allLeadsActivities, setallLeadsActivities] = useState();

  const [stateleadid, setleadid] = useState();
  const dispatch = useDispatch();
  // tabs on edit

  const [statetabs, settabs] = useState("editLead");
  // // print function
  const [filerunits, setfilterunits] = useState();

  var suggestionlist = [];

  const onTextChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setfilterunits(resultallleads.data);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");

        suggestions = filerunits
          .sort()
          .filter(
            (val) =>
              regex.test(val.Lead.LeadIdentifier) ||
              regex.test(val.Lead.LeadStatus)
          );
      }
      suggestionlist = suggestions;
      setfilterunits(suggestionlist);
    }
  };
  useEffect(() => {
    setfilterunits(resultallleads.data);
  }, [resultallleads]);
  const openmodalleadstable = (x) => {
    setallLeadsData(x.Lead);
    setallLeadsActivities(x.Activities);
    document.getElementById("modalofleadtable").style.display = "block";
  };
  const openmodalleadstask = (x) => {
    setleadid(x.LeadId);
    document.getElementById("modalofleadtask").style.display = "block";
  };
  const openmodalleadsmeeting = (x) => {
    setleadid(x.LeadId);
    document.getElementById("modalofleadmeeting").style.display = "block";
  };
  const openmodalleadscall = (x) => {
    setleadid(x.LeadId);
    setmangerclient(x.ManagerClient);
    document.getElementById("modalofleadcall").style.display = "block";
  };
  const openmodalleadsedit = (x) => {
    setleadid(x.LeadId);
    seteditlead(x);
    document.getElementById("modalofleadedit").style.display = "block";
  };
  const [taskstate, settaskstate] = useState({
    LeadTaskId: 0,
    LeadId: 1,
    TaskForId: null,
    TaskTypeId: null,
    TaskSubTypeId: null,
    EntryTime: null, // time included mention it
    DueTime: null, // time also included here
    Description: null,
    Priority: null,
    Status: null,
    Comments: null,
  });
  const saveleadtaskdata = () => {
    if (document.getElementById("EntryTime").value === "") {
      toast.info("EntryTime is Required");
    } else if (document.getElementById("DueTime").value === "") {
      toast.info("DueTime is Required");
    } else if (document.getElementById("TaskForId").selectedIndex === 0) {
      toast.info("Select Employee");
    } else if (document.getElementById("TaskTypeId").selectedIndex === 0) {
      toast.info("Select Task Type");
    } else if (document.getElementById("TaskSubTypeId").selectedIndex === 0) {
      toast.info("Select Sub Task ");
    } else if (document.getElementById("Priority").selectedIndex === 0) {
      toast.info("Select Priority");
    } else if (document.getElementById("Status").selectedIndex === 0) {
      toast.info("Select Status");
    } else if (document.getElementById("Description").value === "") {
      toast.info("Add Description");
    } else {
      let Lead = taskstate;
      Lead.LeadTaskId = 0;
      Lead.LeadId = parseInt(stateleadid);
      Lead.EntryTime = document.getElementById("EntryTime").value;

      Lead.DueTime = document.getElementById("DueTime").value;
      Lead.TaskForId = parseInt(
        document.getElementById("TaskForId").options[
          document.getElementById("TaskForId").selectedIndex
        ].dataset.id
      );
      Lead.TaskTypeId = parseInt(
        document.getElementById("TaskTypeId").options[
          document.getElementById("TaskTypeId").selectedIndex
        ].dataset.id
      );
      Lead.TaskSubTypeId = parseInt(
        document.getElementById("TaskSubTypeId").options[
          document.getElementById("TaskSubTypeId").selectedIndex
        ].dataset.id
      );
      Lead.Priority =
        document.getElementById("Priority").options[
          document.getElementById("Priority").selectedIndex
        ].value;
      Lead.Status =
        document.getElementById("Status").options[
          document.getElementById("Status").selectedIndex
        ].value;
      Lead.Description = document.getElementById("Description").value;
      Lead.Comments = document.getElementById("taskComments").value;

      document.getElementById("leadsave").disabled = true;
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}Leads/Tasks/Add`,
        data: JSON.stringify(Lead),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          document.getElementById("leadsave").disabled = false;
          if (result.data.IsSuccess === true) {
            document.getElementById("DueTime").value = "";
            document.getElementById("EntryTime").value = "";
            document.getElementById("Description").value = "";
            document.getElementById("Status").selectedIndex = 0;
            document.getElementById("Priority").selectedIndex = 0;
            document.getElementById("TaskSubTypeId").selectedIndex = 0;
            document.getElementById("TaskTypeId").selectedIndex = 0;
            document.getElementById("TaskForId").selectedIndex = 0;
            document.getElementById("taskComments").value = "";
            setleadid(null);

            toast.info(result.data.Message);
            dispatch(getallTableLead());
            document.getElementById("modalofleadtask").style.display = "none";
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          toast.info("Someting went wrong");
          document.getElementById("leadsave").disabled = false;
        }
      );
    }
  };
  const [meetingstate, setmeetingstate] = useState({
    LeadMeetingId: 0,
    LeadId: null,
    Locations: null,
    From: null,
    To: null,
    HostId: null,
    Purpose: null,
    Status: null,
    MeetingDate: null,
    Comments: null,
  });
  const saveleadmeetingdata = () => {
    if (document.getElementById("HostId").selectedIndex === 0) {
      toast.info("Select Host");
    } else if (document.getElementById("Location").value === "") {
      toast.info("Add Location");
    } else if (document.getElementById("Purpose").value === "") {
      toast.info("Select Purpose");
    } else if (document.getElementById("meetingStatus").selectedIndex === 0) {
      toast.info("Select Status");
    } else {
      let Lead = meetingstate;
      Lead.LeadMeetingId = 0;
      Lead.LeadId = parseInt(stateleadid);
      Lead.From = document.getElementById("MeetingEntryTime").value;

      Lead.To = document.getElementById("MeetingDueTime").value;
      Lead.HostId = parseInt(
        document.getElementById("HostId").options[
          document.getElementById("HostId").selectedIndex
        ].dataset.id
      );
      Lead.Purpose = document.getElementById("Purpose").value;
      Lead.Locations = document.getElementById("Location").value;

      Lead.Status =
        document.getElementById("meetingStatus").options[
          document.getElementById("meetingStatus").selectedIndex
        ].value;
      Lead.Comments = document.getElementById("meetingComments").value;
      Lead.MeetingDate = document.getElementById("meetingdate").value;

      document.getElementById("leadmeetingsave").disabled = true;
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}Leads/Meeting/Add`,
        data: JSON.stringify(Lead),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          document.getElementById("leadmeetingsave").disabled = false;
          if (result.data.IsSuccess === true) {
            document.getElementById("MeetingDueTime").value = "";
            document.getElementById("MeetingEntryTime").value = "";
            document.getElementById("meetingComments").value = "";
            document.getElementById("meetingStatus").selectedIndex = 0;
            document.getElementById("Purpose").value = "";
            document.getElementById("Location").value = "";
            document.getElementById("meetingdate").value = "";
            document.getElementById("HostId").selectedIndex = 0;
            setleadid(null);

            toast.info(result.data.Message);
            dispatch(getallTableLead());
            document.getElementById("modalofleadmeeting").style.display =
              "none";
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          toast.info("Someting went wrong");
          document.getElementById("leadmeetingsave").disabled = false;
        }
      );
    }
  };
  // call
  const [callstate, setcallstate] = useState({
    LeadCallId: null, // 0 for new other for edit
    LeadId: null,
    ContactName: null,
    ContactNumber: null,
    CallPurpose: null,
    CallType: null,
    CallResult: null,
    Description: null,
    HostId: null,
    Status: null,
    CallDuration: null,
    CallTime: null,
  });
  const saveleadcalldata = () => {
    if (document.getElementById("CallTime").value === "") {
      toast.info("Call Time is Required");
    } else if (document.getElementById("callHostId").selectedIndex === 0) {
      toast.info("Select Host");
    } else if (document.getElementById("ContactName").value === "") {
      toast.info("Add ContactName");
    } else if (document.getElementById("callPurpose").value === "") {
      toast.info("Select Call Purpose");
    } else if (document.getElementById("ContactNo").value === "") {
      toast.info("Select ContactNo");
    } else if (document.getElementById("callStatus").selectedIndex === 0) {
      toast.info("Select Status");
    } else if (document.getElementById("CallType").selectedIndex === 0) {
      toast.info("Select Call Type");
    } else if (document.getElementById("calldescription").value === "") {
      toast.info("Add Description");
    } else {
      let Lead = callstate;
      Lead.LeadCallId = 0;
      Lead.LeadId = parseInt(stateleadid);
      Lead.CallTime = document.getElementById("CallTime").value;

      Lead.HostId = parseInt(
        document.getElementById("callHostId").options[
          document.getElementById("callHostId").selectedIndex
        ].dataset.id
      );
      Lead.CallType =
        document.getElementById("CallType").options[
          document.getElementById("CallType").selectedIndex
        ].value;

      Lead.CallPurpose = document.getElementById("callPurpose").value;
      Lead.ContactNumber = document.getElementById("ContactNo").value;
      Lead.ContactName = document.getElementById("ContactName").value;
      Lead.CallResult = document.getElementById("callresult").value;
      Lead.CallDuration = document.getElementById("CallDuration").value;

      Lead.Status =
        document.getElementById("callStatus").options[
          document.getElementById("callStatus").selectedIndex
        ].value;
      Lead.Description = document.getElementById("calldescription").value;

      document.getElementById("leadcallsave").disabled = true;
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}Leads/Calls/Add`,
        data: JSON.stringify(Lead),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          document.getElementById("leadcallsave").disabled = false;
          if (result.data.IsSuccess === true) {
            document.getElementById("calldescription").value = "";
            document.getElementById("CallDuration").value = "";
            document.getElementById("callresult").value = "";
            document.getElementById("callStatus").selectedIndex = 0;
            document.getElementById("callPurpose").value = "";
            document.getElementById("CallTime").value = "";
            // document.getElementById("ContactNo").value = "";
            // document.getElementById("ContactName").value = "";
            document.getElementById("CallType").selectedIndex = 0;
            document.getElementById("callHostId").selectedIndex = 0;
            setleadid(null);
            toast.info(result.data.Message);
            dispatch(getallTableLead());
            document.getElementById("modalofleadcall").style.display = "none";
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          toast.info("Someting went wrong");
          document.getElementById("leadcallsave").disabled = false;
        }
      );
    }
  };
  // edit functtions

  const [tablepopulate, settablepopulate] = useState([]);
  const [toggle, settoggle] = useState(false);
  const custominstallments = () => {
    let item = {
      project: null,
      unitarea: null,
      measureunit: null,
      unittype: null,
      requirment: null,
    };
    settablepopulate([...tablepopulate, item]);
  };
  const deleteinstallment = (idx) => {
    const rows = [...tablepopulate];

    rows.splice(idx, 1);

    settablepopulate(rows);
  };

  const [state, setstate] = useState({
    LeadId: 0,
    EntryDate: null,
    SourceOfLead: null,
    ManagerClientId: null,
    Comments: null,
    Classification: null,
    BudgetAmount: null,
    InitiateBy: null,
    Designation: null,
  });
  // add token
  function addToken() {
    if (document.getElementById("unit").selectedIndex === 0) {
      toast.info("Select Unit");
    } else if (document.getElementById("tokenamount").value === "") {
      toast.info("Enter Amount");
    } else {
      document.getElementById("tokensave").disabled = true;
      axios({
        url: `${process.env.REACT_APP_API_URL}Leads/ReceiveToken`,
        method: "post",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
        data: JSON.stringify({
          LeadId: stateleadid,
          UnitId:
            document.getElementById("unit").options[
              document.getElementById("unit").selectedIndex
            ].dataset.id,
          TokenAmount: parseInt(
            document.getElementById("tokenamount").value.replace(/,/g, "")
          ),
          TokenComment: document.getElementById("tokencomment").value,
        }),
      }).then(
        (result) => {
          document.getElementById("tokensave").disabled = false;
          if (result.data.IsSuccess === true) {
            document.getElementById("tokencomment").value = "";
            document.getElementById("tokenamount").value = "";
            document.getElementById("unit").selectedIndex = 0;
            document.getElementById("project").selectedIndex = 0;
            document.getElementById("blockdropdown").selectedIndex = 0;
            document.getElementById("modalofleadedit").style.display = "none";
            dispatch(getallTableLead());
            toast.info(result.data.Message);
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          document.getElementById("tokensave").disabled = false;
          toast.info("Something went wrong!");
        }
      );
    }
  }
  function CloseLead() {
    if (document.getElementById("closestatus").selectedIndex === 0) {
      toast.info("Select Status");
    } else {
      document.getElementById("closelead").disabled = true;
      axios({
        url: `${process.env.REACT_APP_API_URL}Leads/Close`,
        method: "post",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
        data: JSON.stringify({
          LeadId: stateleadid,
          LeadStatus:
            document.getElementById("closestatus").options[
              document.getElementById("closestatus").selectedIndex
            ].value,
          Comments: document.getElementById("closecomment").value,
        }),
      }).then(
        (result) => {
          document.getElementById("closelead").disabled = false;
          if (result.data.IsSuccess === true) {
            document.getElementById("closestatus").selectedIndex = 0;

            document.getElementById("modalofleadedit").style.display = "none";
            dispatch(getallTableLead());
            toast.info(result.data.Message);
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          document.getElementById("closelead").disabled = false;
          toast.info("Something went wrong!");
        }
      );
    }
  }
  const savedata = () => {
    let Lead = state;
    Lead.LeadId = stateleadid;
    Lead.InitiateBy = null;

    Lead.SourceOfLead = null;
    Lead.ManagerClientId = null;
    Lead.EntryDate = null;
    Lead.Comments = document.getElementById("editleadcomment").value;
    Lead.BudgetAmount = null;
    Lead.Designation = null;
    Lead.Classification = null;

    let LeadDetail = [];
    tablepopulate.forEach((x, idx) => {
      if (document.getElementById(`dealprojecttablefield${idx}`) !== null) {
        LeadDetail.push({
          ProjectId: document.getElementById(`dealprojecttablefield${idx}`)
            .options[
            document.getElementById(`dealprojecttablefield${idx}`).selectedIndex
          ].dataset.id,
          UnitArea: document.getElementById(`dealunitareatablefield${idx}`)
            .value,
          MeasureUnitId: document.getElementById(`dealmeasureunitfield${idx}`)
            .options[
            document.getElementById(`dealmeasureunitfield${idx}`).selectedIndex
          ].dataset.id,
          UnitType: document.getElementById(`dealunittypefield${idx}`).options[
            document.getElementById(`dealunittypefield${idx}`).selectedIndex
          ].value,
          Requirments: document.getElementById(`dealrequirementfield${idx}`)
            .value,
          UnitId: document.getElementById(`dealunitfield${idx}`).options[
            document.getElementById(`dealunitfield${idx}`).selectedIndex
          ].dataset.id,
        });
      }
    });

    document.getElementById("leadeditsave").disabled = true;
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}Leads/Add`,
      data: JSON.stringify({
        Lead,
        LeadDetail,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        document.getElementById("leadeditsave").disabled = true;
        if (result.data.IsSuccess === true) {
          document.getElementById("editleadcomment").value = "";
          settablepopulate([]);
          toast.info(result.data.Message);
          dispatch(getallTableLead());
          document.getElementById("modalofleadedit").style.display = "none";
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Someting went wrong");
        document.getElementById("leadeditsave").disabled = false;
      }
    );
  };

  useEffect(() => {
    dispatch(getallTableLead());
    dispatch(getallLeadTaskPopulate());
    dispatch(getallLeadMeetingPopulate());
    dispatch(getallLeadCallPopulate());
    dispatch(getallPopulateLead());
    dispatch(getallmeasurment());
    dispatch(getallProject());
  }, [dispatch]);

  return (
    <>
      {/* <div hidden>
                <LandPrint
                    ref={componentRef}

                    printData={resultallleads}

                /></div> */}
      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label m-0">Search:</label>
        </div>
        <div className="col-md-3 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            placeholder="Status/Identifier "
            onChange={onTextChange}
          ></input>
        </div>
        {/* <div className="col-md-3 text-center  pr-0 pl-0">
                    <label className="input_label m-0">From:</label>
                    <input type="date" className="input_date ml-3" id="from date"></input>
                </div>
                <div className="col-md-3 text-center pr-0 pl-0">
                    <label className="input_label m-0">To:</label>
                    <input type="date" className="input_date ml-3" id="todate"></input>
                </div>
                <div className="col-md-1 pr-0 pl-0">
                    <button className="btn_Go">Go</button>
                </div> */}
        <div className="col-md-1 pr-0 pl-0">
          {resultallleads.loading ? (
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          ) : null}
        </div>
      </div>

      <div className="row ">
        <div className=" col-12  table_height pr-0 pl-0">
          <div className="table-responsive mt-4">
            <table className="table table-borderless m-0">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Identifier</th>
                  <th>Entry Date</th>

                  <th>Source</th>

                  <th>Budget</th>
                  <th>Comment</th>
                  <th>Status</th>

                  <th className="text-center">
                    <button
                      className="save_btn"
                      onClick={() => {
                        props.showleadtable();
                      }}
                    >
                      New+
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {filerunits !== undefined
                  ? filerunits.map((val, index) => (
                      <tr key={index + 1}>
                        <td
                          className="cur_sor"
                          onClick={() => {
                            openmodalleadstable(val);
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          className="cur_sor"
                          onClick={() => {
                            openmodalleadstable(val);
                          }}
                        >
                          {val.Lead.LeadIdentifier !== undefined
                            ? val.Lead.LeadIdentifier
                            : null}
                        </td>
                        <td
                          className="cur_sor"
                          onClick={() => {
                            openmodalleadstable(val);
                          }}
                        >
                          {val.Lead.EntryDate !== null
                            ? moment(val.Lead.EntryDate.split("T")[0]).format(
                                "DD-MMM-YYYY"
                              )
                            : null}
                        </td>
                        <td
                          className="cur_sor"
                          onClick={() => {
                            openmodalleadstable(val);
                          }}
                        >
                          {val.Lead.SourceOfLead !== undefined
                            ? val.Lead.SourceOfLead
                            : null}
                        </td>
                        {/* <td className="text-center cur_sor" onClick={() => {
                                            openmodalleadstable(val);

                                        }}></td> */}
                        <td
                          className="cur_sor"
                          onClick={() => {
                            openmodalleadstable(val);
                          }}
                        >
                          {val.Lead.BudgetAmount !== undefined
                            ? val.Lead.BudgetAmount.toLocaleString()
                            : null}
                        </td>

                        <td
                          className="cur_sor"
                          onClick={() => {
                            openmodalleadstable(val);
                          }}
                        >
                          {val.Lead.Comments !== undefined
                            ? val.Lead.Comments
                            : null}
                        </td>

                        <td
                          className="cur_sor"
                          onClick={() => {
                            openmodalleadstable(val);
                          }}
                        >
                          {val.Lead.LeadStatus !== undefined
                            ? val.Lead.LeadStatus
                            : null}
                        </td>

                        <td
                          className="text-center d-flex justify-content-center"
                          title={
                            val.Lead.Classification !== undefined
                              ? val.Lead.Classification === "Hot"
                                ? "Hot"
                                : val.Lead.Classification === "cold"
                                ? "Cold"
                                : "Moderate"
                              : null
                          }
                        >
                          {val.Lead.Classification !== undefined ? (
                            val.Lead.Classification === "Hot" ? (
                              <header
                                style={{ margin: "0px" }}
                                className="circle-red"
                              ></header>
                            ) : val.Lead.Classification === "cold" ? (
                              <header
                                style={{ margin: "0px" }}
                                className="circle-green"
                              ></header>
                            ) : (
                              <header
                                style={{ margin: "0px" }}
                                className="circle-yellow"
                              ></header>
                            )
                          ) : null}
                          <BiTask
                            title="Task"
                            size="1.3em"
                            className="cur_sor"
                            onClick={() => {
                              openmodalleadstask(val.Lead);
                            }}
                          />
                          <TiGroup
                            title="Meeting"
                            size="1.3em"
                            className="cur_sor"
                            onClick={() => {
                              openmodalleadsmeeting(val.Lead);
                            }}
                          />{" "}
                          <BiPhoneCall
                            title="Call"
                            size="1.3em"
                            className="cur_sor"
                            onClick={() => {
                              openmodalleadscall(val.Lead);
                            }}
                          />{" "}
                          <FiEdit2
                            title="Edit"
                            size="1.1em"
                            className="cur_sor"
                            onClick={() => {
                              openmodalleadsedit(val.Lead);
                            }}
                          />
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-6  text-right pr-0 pl-0">
          {/* <button className="print_btn float-left" onClick={() => { printPaymentRec(); }}><TiPrinter size="1.3em" />Print</button> */}
        </div>
      </div>
      {/* model for show information*/}
      <div id="modalofleadtable" class="modalscreen ">
        <div class="modal-content-screen1 pt-1">
          <div className="row ">
            <div className="col-12  page_heading">
              <h4 className="  text-center ">Lead Detail</h4>
              <span
                class="close pr-2  "
                onClick={() => {
                  document.getElementById("modalofleadtable").style.display =
                    "none";
                }}
              >
                &times;
              </span>
            </div>
          </div>
          <div className="container modal_container">
            <div className="table-responsive mt-4">
              <table class="table mt-2">
                <thead class="purple whiteText">
                  <tr>
                    <th scope="col">Project</th>
                    <th scope="col">UnitName</th>

                    <th scope="col">UnitArea</th>
                    <th scope="col">MeasureUnit</th>
                    <th scope="col">UnitType </th>
                    <th scope="col">Requirments </th>
                  </tr>
                </thead>
                <tbody>
                  {allLeadsData !== undefined &&
                  allLeadsData.LeadDetail !== undefined
                    ? // allLeadsData.length == 0
                      allLeadsData.LeadDetail.map((x, id) => (
                        <tr key={id}>
                          <td>{x.Project.ProjectName}</td>
                          <td>{x.Unit.UnitName}</td>

                          <td>{x.UnitArea}</td>
                          <td>{x.MeasureUnit.Unit}</td>
                          <td>{x.UnitType}</td>
                          <td>{x.Requirments}</td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>

            <div className="table-responsive mt-4">
              <h4 className="text-center" colSpan="7">
                <b>Pending Activities</b>
              </h4>
              <table class="table mt-2">
                <thead class="purple whiteText">
                  <tr>
                    <th scope="col"> Type</th>
                    <th scope="col"> Owner </th>
                    <th scope="col">Status </th>
                    <th scope="col">Purpose </th>

                    <th scope="col">Due Date </th>
                  </tr>
                </thead>
                <tbody>
                  {allLeadsActivities !== undefined
                    ? // allLeadsData.length == 0
                      allLeadsActivities.map((x, id) =>
                        x.Status == "Pending" ? (
                          <tr key={id}>
                            <td>{x.ActivityType}</td>
                            <td>{x.ActivityOwner}</td>

                            <td>{x.Status}</td>
                            <td>{x.Purpose}</td>

                            <td>{x.DueDate}</td>
                          </tr>
                        ) : null
                      )
                    : null}
                </tbody>
              </table>
            </div>
            <div className="table-responsive mt-4">
              <h4 className="text-center" colSpan="7">
                <b>Closed Activities</b>
              </h4>

              <table class="table mt-2">
                <thead class="purple whiteText">
                  <tr>
                    <th scope="col"> Type</th>
                    <th scope="col"> Assign To </th>
                    <th scope="col">Status </th>
                    <th scope="col">Purpose </th>
                    <th scope="col">From</th>
                    <th scope="col">To</th>
                    <th scope="col">Due Date </th>
                  </tr>
                </thead>
                <tbody>
                  {allLeadsActivities !== undefined
                    ? // allLeadsData.length == 0
                      allLeadsActivities.map((x, id) =>
                        x.Status == "Done" ? (
                          <tr key={id}>
                            <td>{x.ActivityType}</td>
                            <td>{x.ActivityOwner}</td>

                            <td>{x.Status}</td>
                            <td>{x.Purpose}</td>
                            <td>{x.From}</td>
                            <td>{x.To}</td>
                            <td>{x.DueDate}</td>
                          </tr>
                        ) : null
                      )
                    : null}
                </tbody>
              </table>
            </div>

            {/* <div className="row">
                            <div className=" col-12 text-right pr-0 pl-0">
                                <button className="print_btn float-right" ><TiPrinter size="1.3em" />Print</button>


                            </div>
                        </div> */}
          </div>
        </div>
      </div>
      {/* add task */}
      <div id="modalofleadtask" class="modalscreen ">
        <div class="modal-content-screen1 pt-1">
          <div className="row ">
            <div className="col-12  page_heading">
              <h4 className="  text-center ">Add Task</h4>
              <span
                class="close pr-2  "
                onClick={() => {
                  document.getElementById("modalofleadtask").style.display =
                    "none";
                }}
              >
                &times;
              </span>
            </div>
          </div>
          <div className="container modal_container">
            <div className="row ">
              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0 ">EntryTime :</label>
              </div>
              <div className="col-md-3 mt-md-4  p-0">
                <input
                  type="datetime-local"
                  className="input_date_styling  ml-md-3"
                  id="EntryTime"
                ></input>
              </div>
              <div className="col-md-2 mt-md-4   p-0"></div>
              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0">DueTime</label>
              </div>
              <div className="col-md-3 mt-md-4   p-0">
                <input
                  type="datetime-local"
                  className="input_date_styling  ml-md-3"
                  id="DueTime"
                ></input>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0 ">Employee :</label>
              </div>
              <div className="col-md-3 mt-md-4  p-0">
                <select className="input_styling ml-md-3" id="TaskForId">
                  <option>Select</option>

                  {populateLeadTask.data !== undefined &&
                  populateLeadTask.data.Employees !== undefined
                    ? populateLeadTask.data.Employees.map((val, index) => (
                        <option key={index} data-id={val.EmployeeId}>
                          {val.EmpName}
                        </option>
                      ))
                    : null}
                </select>
              </div>
              <div className="col-md-2 mt-md-4   p-0"></div>
              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0">Task Type</label>
              </div>
              <div className="col-md-3 mt-md-4   p-0">
                <select
                  className="input_styling ml-md-3"
                  id="TaskTypeId"
                  onChange={() => {
                    settype(
                      document.getElementById("TaskTypeId").options[
                        document.getElementById("TaskTypeId").selectedIndex
                      ].dataset.id
                    );
                  }}
                >
                  <option>Select</option>

                  {populateLeadTask.data !== undefined &&
                  populateLeadTask.data.TaskTypes !== undefined
                    ? populateLeadTask.data.TaskTypes.map((val, index) => (
                        <option key={index} data-id={val.TaskTypeId}>
                          {val.TypeName}
                        </option>
                      ))
                    : null}
                </select>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0 ">Task SubType :</label>
              </div>
              <div className="col-md-3 mt-md-4  p-0">
                <select className="input_styling ml-md-3" id="TaskSubTypeId">
                  <option>Select</option>

                  {populateLeadTask.data !== undefined &&
                  populateLeadTask.data.TaskSubTypes !== undefined
                    ? populateLeadTask.data.TaskSubTypes.map((val, index) =>
                        parseInt(statetype) === parseInt(val.TaskTypeId) ? (
                          <option key={index} data-id={val.TaskSubTypeId}>
                            {val.SubTypeName}
                          </option>
                        ) : null
                      )
                    : null}
                </select>
              </div>
              <div className="col-md-2 mt-md-4   p-0"></div>
              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0">Priority</label>
              </div>
              <div className="col-md-3 mt-md-4   p-0">
                <select className="input_styling ml-md-3" id="Priority">
                  <option>Select</option>
                  <option>High</option>
                  <option>Medium</option>
                  <option>Low</option>
                </select>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0 ">Status :</label>
              </div>
              <div className="col-md-3 mt-md-4  p-0">
                <select
                  className="input_styling ml-md-3"
                  id="Status"
                  onChange={(e) => {
                    if (e.target.value === "Done") {
                      document.getElementById("hideshow").style.display =
                        "inline";
                    } else {
                      document.getElementById("hideshow").style.display =
                        "none";
                    }
                  }}
                >
                  <option>Select</option>
                  <option>Pending</option>
                  <option>Done</option>
                </select>
              </div>
              <div className="col-md-2 mt-md-4   p-0"></div>
              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0">Description</label>
              </div>
              <div className="col-md-3 mt-md-4   p-0">
                <textarea
                  className="input_address ml-md-3 "
                  id="Description"
                ></textarea>
              </div>
            </div>

            <header id="hideshow" style={{ display: "none" }}>
              <div className="row ">
                <div className="col-md-2 mt-md-4  text-left p-0">
                  <label className="input_label m-0">Comments</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">
                  <textarea
                    className="input_address ml-md-3 "
                    id="taskComments"
                  ></textarea>
                </div>
              </div>
            </header>
            <div className="row">
              <div className="col-12 text-right mt-md-4 ">
                <span>
                  <button
                    className="save_btn ml-2  "
                    id="leadsave"
                    onClick={saveleadtaskdata}
                  >
                    <BsCloudUpload size="1.3em" />
                    Save
                  </button>
                </span>
              </div>
            </div>

            {/* <div className="row">
                            <div className=" col-12 text-right pr-0 pl-0">
                                <button className="print_btn float-right" ><TiPrinter size="1.3em" />Print</button>


                            </div>
                        </div> */}
          </div>
        </div>
      </div>
      {/* add meetings */}
      <div id="modalofleadmeeting" class="modalscreen ">
        <div class="modal-content-screen1 pt-1">
          <div className="row ">
            <div className="col-12  page_heading">
              <h4 className="  text-center ">Add Meeting</h4>
              <span
                class="close pr-2  "
                onClick={() => {
                  document.getElementById("modalofleadmeeting").style.display =
                    "none";
                }}
              >
                &times;
              </span>
            </div>
          </div>
          <div className="container modal_container">
            <div className="row ">
              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0 ">Host :</label>
              </div>
              <div className="col-md-3 mt-md-4  p-0">
                <select className="input_styling ml-md-3" id="HostId">
                  <option>Select</option>

                  {populateMeetingTask.data.Data !== undefined
                    ? populateMeetingTask.data.Data.map((val, index) => (
                        <option key={index} data-id={val.EmployeeId}>
                          {val.EmpName}
                        </option>
                      ))
                    : null}
                </select>
              </div>
              <div className="col-md-2 mt-md-4   p-0"></div>
              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0">Location</label>
              </div>
              <div className="col-md-3 mt-md-4   p-0">
                <input
                  type="text"
                  className="input_styling  ml-md-3"
                  id="Location"
                ></input>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0">Meeting Date</label>
              </div>
              <div className="col-md-3 mt-md-4   p-0">
                <input
                  type="date"
                  className="input_date_styling  ml-md-3"
                  id="meetingdate"
                ></input>
              </div>

              <div className="col-md-2 mt-md-4   p-0"></div>

              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0 ">Status :</label>
              </div>
              <div className="col-md-3 mt-md-4  p-0">
                <select
                  className="input_styling ml-md-3"
                  id="meetingStatus"
                  onChange={(e) => {
                    if (e.target.value === "Done") {
                      document.getElementById("hideshow5").style.display =
                        "inline";
                      document.getElementById("hideshow2").style.display =
                        "inline";
                      document.getElementById("hideshow3").style.display =
                        "inline";
                    } else {
                      document.getElementById("hideshow5").style.display =
                        "none";
                      document.getElementById("hideshow2").style.display =
                        "none";
                      document.getElementById("hideshow3").style.display =
                        "none";
                    }
                  }}
                >
                  <option>Select</option>
                  <option>Pending</option>
                  <option>Done</option>
                </select>
              </div>
            </div>
            <header id="hideshow5" style={{ display: "none" }}>
              <div className="row ">
                <div className="col-md-2 mt-md-4  text-left p-0">
                  <label className="input_label m-0 ">From :</label>
                </div>
                <div className="col-md-3 mt-md-4  p-0">
                  <input
                    type="time"
                    className="input_date_styling  ml-md-3"
                    id="MeetingEntryTime"
                  ></input>
                </div>
                <div className="col-md-2 mt-md-4   p-0"></div>
                <div className="col-md-2 mt-md-4  text-left p-0">
                  <label className="input_label m-0">To</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">
                  <input
                    type="time"
                    className="input_date_styling  ml-md-3"
                    id="MeetingDueTime"
                  ></input>
                </div>
              </div>
            </header>

            <div className="row ">
              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0 ">Purpose :</label>
              </div>
              <div className="col-md-3 mt-md-4  p-0">
                <textarea
                  className="input_address ml-md-3 "
                  id="Purpose"
                ></textarea>
              </div>
              <div className="col-md-2 mt-md-4   p-0"></div>

              <div className="col-md-2 mt-md-4  text-left p-0">
                <span id="hideshow2" style={{ display: "none" }}>
                  {" "}
                  <label className="input_label m-0">Comments</label>
                </span>
              </div>
              <div className="col-md-3 mt-md-4   p-0">
                <span id="hideshow3" style={{ display: "none" }}>
                  <textarea
                    className="input_address ml-md-3 "
                    id="meetingComments"
                  ></textarea>
                </span>
              </div>
            </div>

            <div className="row ">
              <div className=" col-12 text-right mt-md-4 ">
                <span>
                  <button
                    className="save_btn ml-2  "
                    id="leadmeetingsave"
                    onClick={saveleadmeetingdata}
                  >
                    <BsCloudUpload size="1.3em" />
                    Save
                  </button>
                </span>
              </div>
            </div>

            {/* <div className="row">
                            <div className=" col-12 text-right pr-0 pl-0">
                                <button className="print_btn float-right" ><TiPrinter size="1.3em" />Print</button>


                            </div>
                        </div> */}
          </div>
        </div>
      </div>
      {/* add call */}
      <div id="modalofleadcall" class="modalscreen ">
        <div class="modal-content-screen1 pt-1">
          <div className="row ">
            <div className="col-12  page_heading">
              <h4 className="  text-center ">Add Call</h4>
              <span
                class="close pr-2  "
                onClick={() => {
                  document.getElementById("modalofleadcall").style.display =
                    "none";
                }}
              >
                &times;
              </span>
            </div>
          </div>
          <div className="container modal_container">
            <div className="row ">
              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0 ">Call Time :</label>
              </div>
              <div className="col-md-3 mt-md-4  p-0">
                <input
                  type="datetime-local"
                  className="input_date_styling  ml-md-3"
                  id="CallTime"
                ></input>
              </div>
              <div className="col-md-2 mt-md-4   p-0"></div>
              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0 ">Host :</label>
              </div>
              <div className="col-md-3 mt-md-4  p-0">
                <select className="input_styling ml-md-3" id="callHostId">
                  <option>Select</option>

                  {populateCallTask.data.Data !== undefined
                    ? populateCallTask.data.Data.map((val, index) => (
                        <option key={index} data-id={val.EmployeeId}>
                          {val.EmpName}
                        </option>
                      ))
                    : null}
                </select>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0">Contact Name</label>
              </div>
              <div className="col-md-3 mt-md-4   p-0">
                <input
                  type="text"
                  className="input_styling  ml-md-3"
                  defaultValue={
                    statemangerclient != undefined
                      ? statemangerclient.ManagerClientName
                      : null
                  }
                  id="ContactName"
                ></input>
              </div>
              <div className="col-md-2 mt-md-4   p-0"></div>
              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0">Contact No.</label>
              </div>
              <div className="col-md-3 mt-md-4   p-0">
                <input
                  type="text"
                  className="input_styling  ml-md-3"
                  defaultValue={
                    statemangerclient != undefined
                      ? statemangerclient.ContactNumber1
                      : null
                  }
                  id="ContactNo"
                ></input>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0 ">Call Purpose :</label>
              </div>
              <div className="col-md-3 mt-md-4  p-0">
                <input
                  type="text"
                  className="input_styling  ml-md-3"
                  id="callPurpose"
                ></input>
              </div>
              <div className="col-md-2 mt-md-4   p-0"></div>

              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0 ">Call Type:</label>
              </div>
              <div className="col-md-3 mt-md-4  p-0">
                <select className="input_styling ml-md-3" id="CallType">
                  <option>Select</option>
                  <option>Audio Call</option>
                  <option>Video Call</option>
                </select>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0 ">Call Status :</label>
              </div>
              <div className="col-md-3 mt-md-4  p-0">
                <select
                  className="input_styling ml-md-3"
                  id="callStatus"
                  onChange={(e) => {
                    if (e.target.value === "Done") {
                      document.getElementById("hideshowcall1").style.display =
                        "inline";
                    } else {
                      document.getElementById("hideshowcall1").style.display =
                        "none";
                    }
                  }}
                >
                  <option>Select</option>
                  <option>Pending</option>
                  <option>Done</option>
                </select>
              </div>
              <div className="col-md-2 mt-md-4   p-0"></div>

              <div className="col-md-2 mt-md-4  text-left p-0">
                <label className="input_label m-0">Description</label>
              </div>
              <div className="col-md-3 mt-md-4   p-0">
                <textarea
                  className="input_address ml-md-3 "
                  id="calldescription"
                ></textarea>
              </div>
            </div>
            <header id="hideshowcall1" style={{ display: "none" }}>
              <div className="row ">
                <div className="col-md-2 mt-md-4  text-left p-0">
                  <label className="input_label m-0 ">Call Duration :</label>
                </div>
                <div className="col-md-3 mt-md-4  p-0">
                  <input
                    type="text"
                    className="input_styling  ml-md-3"
                    id="CallDuration"
                    placeholder="1 hour 10 minutes etc"
                  ></input>
                </div>
                <div className="col-md-2 mt-md-4   p-0"></div>
                <div className="col-md-2 mt-md-4  text-left p-0">
                  <label className="input_label m-0">Call Result</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">
                  <textarea
                    className="input_address ml-md-3 "
                    id="callresult"
                  ></textarea>
                </div>
              </div>
            </header>
            <div className="row ">
              <div className=" col-12 text-right mt-md-4 ">
                <span>
                  <button
                    className="save_btn ml-2  "
                    id="leadcallsave"
                    onClick={saveleadcalldata}
                  >
                    <BsCloudUpload size="1.3em" />
                    Save
                  </button>
                </span>
              </div>
            </div>

            {/* <div className="row">
                            <div className=" col-12 text-right pr-0 pl-0">
                                <button className="print_btn float-right" ><TiPrinter size="1.3em" />Print</button>


                            </div>
                        </div> */}
          </div>
        </div>
      </div>
      {/* edit leads */}
      <div id="modalofleadedit" class="modalscreen ">
        <div class="modal-content-screen1 pt-1">
          {/* <div className="row ">
                        <div className="col-12  page_heading">
                            <h4 className="  text-center ">Edit Lead</h4>
                            <span
                                class="close pr-2  "
                                onClick={() => {
                                    document.getElementById("modalofleadedit").style.display =
                                        "none";
                                }}
                            >
                                &times;
                            </span>

                        </div>
                    </div> */}
          <div className="row">
            <div className="col-md-12 tab_heading  p-0">
              <button
                className={
                  statetabs === "editLead"
                    ? "comp_btns_selected mt-1 "
                    : "comp_btns mt-1 "
                }
                onClick={() => {
                  settabs("editLead");
                }}
              >
                <span className="comp_btns_text">Edit Lead</span>
              </button>

              <button
                className={
                  statetabs === "tokentab"
                    ? "comp_btns_selected ml-1 mt-1"
                    : "comp_btns ml-1 mt-1"
                }
                onClick={() => {
                  settabs("tokentab");
                }}
              >
                <span className="comp_btns_text"> Token Tab</span>
              </button>

              <button
                className={
                  statetabs === "closeleadtab"
                    ? "comp_btns_selected ml-1  mt-1"
                    : "comp_btns ml-1 mt-1"
                }
                onClick={() => {
                  settabs("closeleadtab");
                }}
              >
                <span className="comp_btns_text"> Close Lead</span>
              </button>
              <span
                class="close pr-2  pt-2 "
                onClick={() => {
                  document.getElementById("modalofleadedit").style.display =
                    "none";
                  settabs("editLead");
                }}
              >
                &times;
              </span>
            </div>
          </div>
          <div className="container modal_container">
            {statetabs === "editLead" ? (
              <>
                {" "}
                <div className="row  mt-3">
                  <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0 ">Identifier :</label>
                  </div>
                  <div className="col-md-3 mt-md-4  p-0">
                    <input
                      type="text"
                      className="input_styling  ml-md-3"
                      disabled
                      defaultValue={
                        stateeditlead !== undefined
                          ? stateeditlead.LeadIdentifier
                          : null
                      }
                    ></input>
                  </div>
                  <div className="col-md-2 mt-md-4   p-0"></div>
                  <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0 ">Note:</label>
                  </div>
                  <div className="col-md-3 mt-md-4  p-0">
                    <textarea
                      className="input_address ml-md-3 "
                      id="editleadcomment"
                      key={
                        stateeditlead !== undefined
                          ? stateeditlead.Comments
                          : null
                      }
                      defaultValue={
                        stateeditlead !== undefined
                          ? stateeditlead.Comments
                          : null
                      }
                    ></textarea>
                  </div>
                </div>
                <div className="row">
                  <div className=" col-12 text-right pr-0 pl-0">
                    <div className="table-responsive mt-4">
                      <table className="table table-borderless m-0">
                        <thead>
                          <tr>
                            <th>Project</th>
                            <th>Unit</th>

                            <th>Unit Area</th>
                            <th>Measure Unit</th>
                            <th>Unit Type</th>
                            <th>Requirments</th>

                            <th>
                              <button
                                className="save_btn "
                                onClick={custominstallments}
                              >
                                Add
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {tablepopulate.map((x, idx) => (
                            <tr key={x.rand}>
                              <td>
                                {/* <input
                                            type="number"

                                            className="input_styling"


                                        /> */}
                                <select
                                  id={`dealprojecttablefield${idx}`}
                                  className="input_styling "
                                  onChange={() => {
                                    setUnits(
                                      populateLeadData.data.Projects[
                                        document.getElementById(
                                          `dealprojecttablefield${idx}`
                                        ).options[
                                          document.getElementById(
                                            `dealprojecttablefield${idx}`
                                          ).selectedIndex
                                        ].dataset.idx
                                      ]
                                    );
                                  }}
                                >
                                  <option>Select</option>
                                  {populateLeadData.data.Projects.map(
                                    (x, index) => (
                                      <option
                                        data-id={x.ProjectId}
                                        data-idx={index}
                                        key={index}
                                      >
                                        {x.ProjectName}
                                      </option>
                                    )
                                  )}
                                </select>
                              </td>
                              <td>
                                <select
                                  className="input_styling "
                                  id={`dealunitfield${idx}`}
                                >
                                  <option>Select</option>

                                  {stateUnits.Units !== undefined
                                    ? stateUnits.Units.map((val, index) => (
                                        <option
                                          key={index}
                                          data-id={val.UnitId}
                                        >
                                          {val.UnitName}
                                        </option>
                                      ))
                                    : null}
                                </select>
                              </td>
                              <td>
                                <input
                                  type="number"
                                  id={`dealunitareatablefield${idx}`}
                                  className="input_styling"
                                />
                              </td>
                              <td>
                                <select
                                  className="input_styling "
                                  id={`dealmeasureunitfield${idx}`}
                                >
                                  <option>Select</option>

                                  {resultsmeasurement.data.map((val, index) => (
                                    <option
                                      key={index}
                                      data-id={val.MeasureUnitId}
                                    >
                                      {val.Unit}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td>
                                <select
                                  className="input_styling"
                                  id={`dealunittypefield${idx}`}
                                >
                                  <option>Select</option>
                                  <option>Plot</option>
                                  <option>House</option>
                                  <option>Shop</option>
                                  <option>Appartment</option>
                                </select>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  id={`dealrequirementfield${idx}`}
                                  className="input_styling"
                                />
                              </td>
                              <td
                                onClick={() => {
                                  deleteinstallment(idx);
                                  settoggle(!toggle);
                                }}
                              >
                                <MdDeleteForever size="1.6em" />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className=" col-12 text-right mt-md-4 ">
                    <span>
                      <button
                        className="save_btn ml-2  "
                        id="leadeditsave"
                        onClick={savedata}
                      >
                        <BsCloudUpload size="1.3em" />
                        Save
                      </button>
                    </span>
                  </div>
                </div>
              </>
            ) : statetabs === "tokentab" ? (
              <>
                <div className="row  mt-3">
                  <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0 ">Project :</label>
                  </div>
                  <div className="col-md-3 mt-md-4  p-0">
                    <select
                      id="project"
                      className="input_styling  ml-md-3"
                      onChange={() => {
                        setblocks(
                          ProjectData.data[
                            document.getElementById("project").options[
                              document.getElementById("project").selectedIndex
                            ].dataset.idx
                          ]
                        );
                        document.getElementById(
                          "blockdropdown"
                        ).selectedIndex = 0;
                        document.getElementById("unit").selectedIndex = 0;
                      }}
                    >
                      <option>Select</option>
                      {ProjectData.data.map((x, index) => (
                        <option
                          data-id={x.ProjectId}
                          data-idx={index}
                          key={index}
                        >
                          {x.ProjectName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-2 mt-md-4   p-0"></div>
                  <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0 ">Section:</label>
                  </div>
                  <div className="col-md-3 mt-md-4  p-0">
                    <select
                      id="blockdropdown"
                      className="input_styling  ml-md-3"
                      onChange={() => {
                        setunit(
                          stateblocks.Sections[
                            document.getElementById("blockdropdown").options[
                              document.getElementById("blockdropdown")
                                .selectedIndex
                            ].dataset.idx
                          ]
                        );
                        document.getElementById("unit").selectedIndex = 0;
                      }}
                    >
                      <option>Select</option>
                      {stateblocks !== undefined &&
                      stateblocks.Sections !== undefined
                        ? stateblocks.Sections.map((x, index) => (
                            <option
                              data-id={x.SectionId}
                              data-idx={index}
                              key={index}
                            >
                              {x.SectionName}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>
                </div>
                <div className="row  mt-3">
                  <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0 ">Unit :</label>
                  </div>
                  <div className="col-md-3 mt-md-4  p-0">
                    <select id="unit" className="input_styling  ml-md-3">
                      {/* {stateUnitList !== undefined && stateUnitList.Units !== undefined ? stateUnitList.Units.map((x, index) => x.Status === "Available" ? <option data-id={x.UnitId} key={index}>{x.UnitName}</option> : null) : null

} */}
                      <option>Select</option>
                      {stateunit !== undefined && stateunit.Units !== undefined
                        ? stateunit.Units.map((val) =>
                            val.Status === "Available" ? (
                              <option data-id={val.UnitId}>
                                {val.UnitName}
                              </option>
                            ) : null
                          )
                        : null}
                    </select>
                  </div>

                  <div className="col-md-2 mt-md-4   p-0"></div>
                  <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0 ">Token Amount :</label>
                  </div>
                  <div className="col-md-3 mt-md-4  p-0">
                    <input
                      type="text"
                      className="input_styling  ml-md-3"
                      id="tokenamount"
                      onChange={(e) => {
                        if (e.target.value.length > 0)
                          document.getElementById("tokenamount").value =
                            parseInt(
                              e.target.value.replace(/,/g, "")
                            ).toLocaleString();
                      }}
                    ></input>
                  </div>
                </div>
                <div className="row  mt-3">
                  <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0 ">Comment:</label>
                  </div>
                  <div className="col-md-3 mt-md-4  p-0">
                    <textarea
                      className="input_address ml-md-3 "
                      id="tokencomment"
                    ></textarea>
                  </div>
                  <div className="col-md-2 mt-md-4   p-0"></div>
                </div>

                <div className="row ">
                  <div className=" col-12 text-right mt-md-4 ">
                    <span>
                      <button
                        className="save_btn ml-2  "
                        id="tokensave"
                        onClick={() => {
                          addToken();
                        }}
                      >
                        <BsCloudUpload size="1.3em" />
                        Save
                      </button>
                    </span>
                  </div>
                </div>
              </>
            ) : statetabs === "closeleadtab" ? (
              <>
                {" "}
                <div className="row  mt-3">
                  <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0 ">Status :</label>
                  </div>
                  <div className="col-md-3 mt-md-4  p-0">
                    <select
                      type="text"
                      className="input_styling  ml-md-3"
                      id="closestatus"
                    >
                      <option>Select</option>
                      <option>Won</option>
                      <option>Lost</option>
                    </select>
                  </div>
                  <div className="col-md-2 mt-md-4   p-0"></div>
                  <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0 ">Comment:</label>
                  </div>
                  <div className="col-md-3 mt-md-4  p-0">
                    <textarea
                      className="input_address ml-md-3 "
                      id="closecomment"
                      key={
                        stateeditlead !== undefined
                          ? stateeditlead.Comments
                          : null
                      }
                      defaultValue={
                        stateeditlead !== undefined
                          ? stateeditlead.Comments
                          : null
                      }
                    ></textarea>
                  </div>
                </div>
                <div className="row ">
                  <div className=" col-12 text-right mt-md-4 ">
                    <span>
                      <button
                        className="save_btn ml-2  "
                        id="closelead"
                        onClick={CloseLead}
                      >
                        <BsCloudUpload size="1.3em" />
                        Save
                      </button>
                    </span>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
