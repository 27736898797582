import { useState } from "react";
import { LandForm } from "./LandForm";
import { LandTable } from "./LandTable";

const MainLand = () => {
  const showlandtable = () => {
    stateshowland(<LandForm cancellandtable={cancellandtable} />);
  };
  const cancellandtable = () => {
    stateshowland(
      <LandTable
        showlandtable={showlandtable}
        cancellandtable={cancellandtable}
      />
    );
  };
  const [showland, stateshowland] = useState(
    <LandTable
      showlandtable={showlandtable}
      cancellandtable={cancellandtable}
    />
  );

  return (
    <>
      <div className="row ">
        <div className="dash_background col-12 p-0">
          <div className="row ">
            <div className="col-md-12">{showland}</div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MainLand;
