import axios from "axios";
import { toast } from "react-toastify";
export const GetAllPaymentInstallTableData = (data) => {
  return {
    type: "get_allpaymentinstalltabledata",
    payload: data,
    loader: false,
  };
};
export function getallpaymentinstalltable() {
  return (dispatch) => {
    let pgurl = `${
      process.env.REACT_APP_API_URL
    }Vouchers/InstalmentPayment/Get?projectid=${localStorage.getItem(
      "ProjectId"
    )}`;
    if (localStorage.getItem("ProjectId") === null) {
      pgurl = `${process.env.REACT_APP_API_URL}Vouchers/InstalmentPayment/Get`;
    }
    axios({
      url: pgurl,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllPaymentInstallTableData(result.data.Data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
