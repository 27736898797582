import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { GrPrint } from "react-icons/gr";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { getallrecoverypopulatetable } from "../../../actions/vouchersactions/getrecoverybooking";
import { RecoveryBookingPrint } from "../../accpayablepages/vouchersprints/receiptprints";
import { CSVLink } from "react-csv";
import RecoveryBookingModal from "./RecoveryBookingModal";
import moment from "moment";

export const RecoveryBookingTable = (props) => {
  const resultsrecoverytable = useSelector(
    (state) => state.getRecoveryBookingTable
  );
  const [printData, SetPrintData] = useState();
  const [filterdata, setfilterdata] = useState();
  const [statetoggle, settoggle] = useState(true);
  // print function
  const componentRef = useRef();
  const rolesdata = useSelector((state) => state.GetAllRole);

  const printRecoveryBooking = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    if (printData !== undefined) {
      printRecoveryBooking();
    }
  }, [printData, statetoggle]);

  //Table Data fiter
  var suggestionlist = [];
  const onTextChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      dispatch(getallrecoverypopulatetable({}));
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");

        suggestions = resultsrecoverytable.data
          .sort()
          .filter(
            (val) =>
              regex.test(val.RecoveryBookingVoucherId) ||
              regex.test(
                val.Manager !== undefined && val.Manager !== null
                  ? val.Manager.Employee.EmpName
                  : null
              )
          );
      }
      suggestionlist = suggestions;
      // dispatch(GetAllRecoveryTableData(suggestionlist));
      setfilterdata(suggestionlist);
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    let data = {};
    dispatch(getallrecoverypopulatetable(data));
  }, [dispatch]);
  function ongo() {
    let data = {
      ProjectId: `${localStorage.getItem("ProjectId")}`,
      From: document.getElementById("fromdate").value,
      To: document.getElementById("todate").value,
    };

    dispatch(getallrecoverypopulatetable(data));
  }
  useEffect(() => {
    setfilterdata(resultsrecoverytable.data);
  }, [resultsrecoverytable]);
  const [CsvData, setCsvData] = useState([]);
  useEffect(() => {
    let arr = [];
    var i = 1;

    filterdata?.forEach((val) => {
      let year = val.EntryDate.slice(0, 4);
      let day = val.EntryDate.slice(8, 10);
      let month = val.EntryDate.slice(5, 7);
      arr.push({
        Sr: i++,
        Date: day + "-" + month + "-" + year,
        "Voucher No": val.RecoveryBookingVoucherId,
        Manager:
          val.Manager !== undefined && val.Manager.Employee !== undefined
            ? val.Manager.Employee.EmpName
            : "",
        Amount: parseFloat(val.Amount).toLocaleString(),
      });
    });
    setCsvData(arr);
  }, [filterdata]);

  return (
    <>
      <div hidden>
        <RecoveryBookingPrint ref={componentRef} printData={printData} />
      </div>
      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label m-0">Search:</label>
        </div>
        <div className="col-md-3 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            onChange={onTextChange}
            placeholder="Voucher/Manager"
          ></input>
        </div>
        <div className="col-md-3 text-center  pr-0 pl-0">
          <label className="input_label m-0">From:</label>
          <input type="date" className="input_date ml-3" id="fromdate"></input>
        </div>
        <div className="col-md-3 text-center pr-0 pl-0">
          <label className="input_label m-0">To:</label>
          <input type="date" className="input_date ml-3" id="todate"></input>
        </div>
        <div className="col-md-1 pr-0 pl-0">
          <button className="btn_Go" onClick={ongo}>
            Go
          </button>
        </div>
        <div className="col-md-1 pr-0 pl-0">
          <RecoveryBookingModal />
        </div>
        <div className="col-md-1 pr-0 pl-0">
          {resultsrecoverytable.loading ? (
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          ) : null}
        </div>
      </div>

      <div className="table-responsive mt-2">
        <table className="table table-borderless m-0">
          <thead>
            <tr>
              <th>Sr</th>
              <th>Date</th>
              <th>Added On</th>
              <th>Voucher</th>
              <th>Manager</th>
              <th>Amount</th>
              <th className="text-center">
                {rolesdata?.data?.Access?.includes("C") ? (
                  <button
                    className="save_btn"
                    onClick={() => {
                      props.showbookingtable();
                    }}
                  >
                    New+
                  </button>
                ) : null}
              </th>
            </tr>
          </thead>
          <tbody>
            {filterdata !== undefined
              ? filterdata.map((val, index) => {
                  let year = val.EntryDate.slice(0, 4);
                  let day = val.EntryDate.slice(8, 10);
                  let month = val.EntryDate.slice(5, 7);
                  switch (month) {
                    case "01":
                      month = "Jan";

                      break;
                    case "02":
                      month = "Feb";

                      break;
                    case "03":
                      month = "Mar";

                      break;
                    case "04":
                      month = "Apr";

                      break;
                    case "05":
                      month = "May";

                      break;
                    case "06":
                      month = "Jun";

                      break;
                    case "07":
                      month = "Jul";

                      break;
                    case "08":
                      month = "Aug";

                      break;
                    case "09":
                      month = "Sep";

                      break;
                    case "10":
                      month = "Oct";
                      break;
                    case "11":
                      month = "Nov";
                      break;
                    case "12":
                      month = "Dec";
                      break;
                    default:
                      break;
                  }
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{day + "-" + month + "-" + year}</td>
                      <td>
                        {" "}
                        {moment(
                          val.Transaction[0]?.AddedOn?.slice(0, 10),
                          "YYYY:MM-DD"
                        )?.format("DD-MMM-YYYY")}
                      </td>

                      <td>{val.RecoveryBookingVoucherId}</td>
                      {/* {val.Project !== undefined && val.Project !== null ?
                                    <td>{val.Project.ProjectName}</td> : <td></td>} */}
                      {val.Manager !== undefined &&
                      val.Manager.Employee !== undefined ? (
                        <td>{val.Manager.Employee.EmpName}</td>
                      ) : (
                        <td></td>
                      )}
                      <td>{parseFloat(val.Amount).toLocaleString()}</td>
                      <td
                        className="text-center cur_sor"
                        onClick={() => {
                          SetPrintData(val);
                          settoggle(!statetoggle);
                        }}
                      >
                        <GrPrint size="1.3em" />
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
      <button
        style={{ color: "black !important" }}
        className="print_btn ml-2 float-left"
      >
        <CSVLink
          style={{ color: "black" }}
          filename="Recovery_Booking_Csv"
          data={CsvData}
        >
          Download CSV
        </CSVLink>
      </button>
    </>
  );
};
