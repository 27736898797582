import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import PrintBookings from "./PrintBookings";
import { useReactToPrint } from "react-to-print";
import { TiPrinter } from "react-icons/ti";
import FullScreenLoader from "../../FullScreenLoader/FullScreenLoader";
import BookStatus from "./BookStatus";
import { FiEdit2 } from "react-icons/fi";
import axios from "axios";
import { toast } from "react-toastify";
import { getBookUnit } from "../../actions/BookedUnits/BookUnits";

const BookingUnits = (props) => {
  const [search, setSearch] = useState("");
  const [data, setData] = useState();
  const [CsvData, setCsvData] = useState([]);
  const [reduction, setReduction] = useState(0);
  const [validDeduct, setvalidDeduct] = useState("");
  const [unitId, setUnitId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const componentRef = useRef();
  const printChangeReq = useReactToPrint({
    content: () => componentRef.current,
  });
  const Bookings = useSelector((state) => state.getBookedUnits.data?.Data);
  const Status = useSelector((state) => state.getBookedUnits?.loading);
  const [open, setOpen] = useState(false);

  const handleClickOpen = (val) => {
    setUnitId(val?.Unit?.UnitId);
    setvalidDeduct(val?.TokenAmount);
    setOpen(true);
  };
  console.log("UnitId", unitId, validDeduct, reduction);
  const handleClose = () => {
    setOpen(false);
  };

  const handleDeductChange = (e) => {
    const selectedValue = e.target.value;
    const numericValue = Number(selectedValue.replace(/,/g, ""));
    const amount = validDeduct;
    if (numericValue > amount) {
      toast.info("Amount should be less than or equal to Valid Deduct");
      setReduction("");
    } else {
      setReduction(selectedValue);
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBookUnit());
  }, [dispatch]);

  useEffect(() => {
    setData(Bookings);
  }, [Bookings]);
  // console.log(Bookings);
  const StatusChange = (e) => {
    setIsLoading(true);
    // let Status = {
    //     UnitId: unitId,
    //     ReductionAmount: Number(reduction.replace(/,/g, '')),
    //     Status: selectedStatus,
    // }

    axios({
      url: `${
        process.env.REACT_APP_API_URL
      }Leads/CancelBookingUnit?UnitId=${unitId}&ReductionAmount=${
        reduction == "" ? 0 : Number(reduction.replace(/,/g, ""))
      }`,
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
      // data: JSON.stringify(Status),
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          setIsLoading(false);
          toast.info(result.data.Message);
          dispatch(getBookUnit());
          setReduction(0);
          handleClose();
        } else {
          toast.info(result.data.Message);
          setIsLoading(false);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
        setIsLoading(false);
      }
    );
  };
  const globalSearch = (search) => {
    const filteredData = Bookings?.filter((item) => {
      return (
        item?.PersonName.toLowerCase().includes(search.toLowerCase()) ||
        item.Project?.ProjectName.toLowerCase().includes(
          search.toLowerCase()
        ) ||
        item.Unit?.UnitName.toLowerCase().includes(search.toLowerCase()) ||
        item.TokenAmount.toString().includes(search.toString()) ||
        item.PersonCnic.toLowerCase().includes(search.toLowerCase()) ||
        item.NumberOfDays.toString().includes(search.toString()) ||
        item.PersonPhoneNumber.toLowerCase().includes(search.toLowerCase())
      );
    });
    setData(filteredData);
  };

  const handleSearch = (event) => {
    const inputValue = event.target.value;
    setSearch(inputValue);
    globalSearch(inputValue);
  };
  useEffect(() => {
    let arr = [];
    Bookings?.forEach((item, index) => {
      arr.push({
        Sr: index + 1,
        PersonName: item?.PersonName,
        ProjectName: item.Project?.ProjectName,
        UnitName: item.Unit?.UnitName,
        TokenAmount: item.TokenAmount,
        PersonCnic: item.PersonCnic,
        NumberOfDays: item.NumberOfDays,
        PersonPhone: item.PersonPhoneNumber,
      });
    });
    setCsvData(arr);
  }, [Bookings]);

  return (
    <>
      <div style={{ display: "none" }}>
        <PrintBookings ref={componentRef} Bookings={Bookings} />
      </div>
      <div className="row  app_font">
        <div className="dash_background col-12 pt-2 p-3">
          <div className="row pt-3 ">
            <div className="col-md-1 pr-0 pl-0">
              <label className="input_label  m-0">Search:</label>
            </div>
            <div className="col-md-3  tpr-0 pl-0">
              <input
                type="text"
                className="input_styling"
                placeholder="Search..."
                id="searchbySubmodule"
                value={search}
                onChange={handleSearch}
              />
            </div>
            {Status && (
              <Loader type="ThreeDots" color="green" height={40} width={40} />
            )}
          </div>

          <div className="row ">
            <div className=" col-12  table_height pr-0 pl-0">
              <div className="table-responsive mt-4">
                <table className="table table-borderless  table-hover m-0">
                  <thead>
                    <tr>
                      <th>Sr #</th>
                      <th>Project Name</th>
                      <th>Unit Name</th>
                      <th>Number Of Days</th>
                      <th>Client Name</th>
                      <th>Client Cnic</th>
                      <th>Client Phone</th>
                      <th>Token Amount</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((val, index) => (
                      <tr key={index}>
                        <td className="cur_sor">{index + 1}</td>
                        <td className="cur_sor">{val?.Project?.ProjectName}</td>
                        <td className="cur_sor">{val?.Unit?.UnitName}</td>
                        <td className="cur_sor">{val.NumberOfDays}</td>
                        <td className="cur_sor">{val.PersonName}</td>
                        <td className="cur_sor">{val.PersonCnic}</td>
                        <td className="cur_sor">{val.PersonPhoneNumber}</td>
                        <td className="cur_sor">
                          {" "}
                          {parseFloat(val.TokenAmount).toLocaleString()}
                        </td>
                        <td className="cur_sor">{val?.Unit?.Status}</td>
                        <td>
                          <button
                            className="cancel_btn ml-2  "
                            onClick={() => handleClickOpen(val)}
                          >
                            Cancel
                          </button>
                          {/* <FiEdit2 size="1em" style={{ cursor: 'pointer', fontSize: '13px' }} onClick={() => handleClickOpen(val)} /> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="col-6  text-right pr-0 pl-0">
                <button
                  className="print_btn float-left"
                  onClick={() => {
                    printChangeReq();
                  }}
                >
                  <TiPrinter size="1.3em" />
                  Print
                </button>
                <button
                  style={{ color: "black !important" }}
                  className="print_btn ml-2 float-left"
                >
                  <CSVLink
                    style={{ color: "black" }}
                    filename="Contract_Csv"
                    data={CsvData}
                  >
                    Download CSV
                  </CSVLink>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {open === true ? (
        <BookStatus
          open={open}
          handleClose={handleClose}
          StatusChange={StatusChange}
          isLoading={isLoading}
          reduction={reduction}
          setReduction={setReduction}
          Bookings={Bookings}
          handleDeductChange={handleDeductChange}
        />
      ) : null}
    </>
  );
};
export default BookingUnits;
