import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import { toast } from "react-toastify";
import CommissionReceiptModal from "./CommissionReceiptModal";
import CommissionReceiptPrintReport from "../ReportPrints/CommissionReceiptPrintReport";
import CommissionReceiptAllPrintReport from "../ReportPrints/CommissionReceiptAllPrintReport";
import { GrPrint } from "react-icons/gr";
import moment from "moment";

const CommissionReceipt = () => {
  const [dealpaydata, setdealpaydata] = useState({});
  const [printIndvidualData, SetPrintIndvidualData] = useState();
  const [csvData, setcsvData] = useState([]);
  const [filterdata, setfilterdata] = useState([]);
  const [CommissionReceipts, setCommissionReceipts] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [ModalData, setModalData] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [dateLoading, setDateLoading] = useState(false);

  // print function
  const AllcomponentRef = useRef();

  const AllprintPaymentRec = useReactToPrint({
    content: () => AllcomponentRef.current,
  });

  const IndvidualcomponentRef = useRef();

  const IndividualprintPaymentRec = useReactToPrint({
    content: () => IndvidualcomponentRef.current,
    onAfterPrint: () => setModalData(null),
  });

  useEffect(() => {
    if (ModalData) {
      IndividualprintPaymentRec();
    }
  }, [ModalData]);

  const onTextChange = (e) => {
    if (
      document.getElementById("affiliatefilter").value === "" &&
      document.getElementById("unitfilter").value === ""
    ) {
      setfilterdata(CommissionReceipts);
    } else {
      let suggestions = [];
      {
        const regex = new RegExp(
          document.getElementById("affiliatefilter").value,
          "i"
        );
        const regex1 = new RegExp(
          document.getElementById("unitfilter").value,
          "i"
        );

        suggestions = CommissionReceipts.sort().filter(
          (val) =>
            (document.getElementById("affiliatefilter").value === "" ||
              regex.test(val?.Reference)) &&
            (document.getElementById("unitfilter").value === "" ||
              regex1.test(val?.UnitName))
        );
      }
      setfilterdata(suggestions);
    }
  };

  function getComReceiptReport() {
    setDateLoading(true);
    let pgurl = `${process.env.REACT_APP_API_URL}Reports/CommissionReceipts`;
    axios({
      url: pgurl,
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
      data: JSON.stringify(
        fromDate && toDate
          ? {
              From: moment(fromDate).format("YYYY-MM-DD"),
              To: moment(toDate).format("YYYY-MM-DD"),
              ProjectId: localStorage.getItem("ProjectId")
                ? localStorage.getItem("ProjectId")
                : null,
            }
          : {
              ProjectId: localStorage.getItem("ProjectId")
                ? localStorage.getItem("ProjectId")
                : null,
            }
      ),
    }).then(
      (result) => {
        setDateLoading(false);
        document.getElementById("go").disabled = false;

        if (result.data.IsSuccess === true) {
          console.log(result);
          setCommissionReceipts(result?.data?.Data);
          setfilterdata(result?.data?.Data);
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        setDateLoading(false);
        document.getElementById("go").disabled = false;
        toast.info("Something went wrong!");
      }
    );
  }

  useEffect(() => {
    getComReceiptReport();
  }, []);

  // if (filterdata !== undefined) {
  //     filterdata.map((val, index) => {
  //         Total += parseFloat(val.CurrentBalance);
  //     })
  // }

  let TotalValue = 0;
  let TotalCommission = 0;
  let TotalRecieved = 0;
  let TotalPayable = 0;

  if (filterdata !== undefined) {
    filterdata.map((val, index) => {
      TotalRecieved += val?.Receipts;
      TotalValue += parseFloat(val?.ContractPrice);
      TotalCommission += val?.CommissionAmount;
      TotalPayable += val?.CommissionAmount - val?.Receipts;
    });
  }
  useEffect(() => {
    let arr = [];
    filterdata?.forEach((item) => {
      arr.push({
        Date: moment(item?.EntryDate).format("DD-MMM-YYYY"),
        Affiliate: item?.Reference,
        Unit: item?.UnitName,
        ["Total Value"]: item?.ContractPrice?.toLocaleString(),
        ["Commission Amount"]: item?.CommissionAmount?.toLocaleString(),
        Revieved: item?.Receipts?.toLocaleString(),
        Remaining: (item?.CommissionAmount - item?.Receipts)?.toLocaleString(),
      });
    });
    arr.push({
      Date: "",
      Affiliate: "",
      Unit: "Grand Total",
      ["Total Value"]: TotalValue,
      ["Commission Amount"]: TotalCommission,
      Revieved: TotalRecieved,
      Remaining: TotalPayable,
    });
    setcsvData(arr);
  }, [filterdata]);

  const savedata = () => {
    document.getElementById("go").disabled = true;
    // (fromDate === "" || toDate === "")
    if (
      (fromDate === "" && toDate !== "") ||
      (fromDate !== "" && toDate === "")
    ) {
      toast.info("Select From and To Date");
      document.getElementById("go").disabled = false;
    } else {
      getComReceiptReport();
      document.getElementById("go").disabled = false;
    }
  };

  return (
    <>
      <div hidden>
        <CommissionReceiptPrintReport
          ref={IndvidualcomponentRef}
          Data={ModalData}
        />
      </div>

      <div hidden>
        <CommissionReceiptAllPrintReport
          ref={AllcomponentRef}
          Data={filterdata}
        />
      </div>
      <div className="row  app_font">
        <div className="dash_background col-12 pt-2">
          <div className="row ">
            <div className="col-12 mt-0">
              <div className="row ">
                <div className="col-md-3 text-center  pr-0 pl-0">
                  <label className="input_label m-0">From:</label>
                  <input
                    type="date"
                    className="input_date ml-3"
                    id="fromdate"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  ></input>
                </div>
                <div className="col-md-3 text-center pr-0 pl-0">
                  <label className="input_label m-0">To:</label>
                  <input
                    type="date"
                    className="input_date ml-3"
                    id="todate"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </div>
                <div className="col-md-1 pr-0 pl-0">
                  <button className="btn_Go" onClick={savedata} id="go">
                    Go
                  </button>
                </div>
                <div className="col-md-1 pr-0 pl-0">
                  {dateLoading && (
                    <Loader
                      type="ThreeDots"
                      color="green"
                      height={40}
                      width={40}
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              style={{ backgroundColor: "#CAF5F5", height: "1px" }}
              className="col-12 m-2"
            ></div>
            <div className="col-12">
              <div className="row ">
                <div className="col-md-1 pr-0 pl-0">
                  <label className="input_label m-0">Search:</label>
                </div>

                <div className="col-md-3 pr-0 pl-0">
                  <input
                    type="text "
                    className="input_styling "
                    id="affiliatefilter"
                    onChange={onTextChange}
                    placeholder="Affiliate Name"
                  ></input>
                </div>

                <div className="col-md-3 pr-0 pl-0">
                  <input
                    type="text "
                    className="input_styling "
                    id="unitfilter"
                    onChange={onTextChange}
                    placeholder="Unit"
                  ></input>
                </div>
              </div>
            </div>
          </div>

          <div className=" col-12  table_height pr-0 pl-0">
            <div className="table-responsive mt-2">
              <table className="table table-borderless m-0">
                <thead>
                  <tr>
                    <th scope="col">Sr</th>
                    <th scope="col">Date</th>
                    <th scope="col">Affiliate</th>
                    <th scope="col">Unit</th>
                    <th scope="col">Total Value</th>
                    <th scope="col">Commission Amount</th>
                    <th scope="col">Revieved</th>
                    <th scope="col">Remaining</th>
                    <th scope="col">Print</th>
                  </tr>
                </thead>
                <tbody>
                  {filterdata !== undefined
                    ? filterdata.map((x, id) => (
                        <>
                          <tr key={id}>
                            <td>{id + 1}</td>
                            <td>
                              {moment(x?.EntryDate).format("DD-MMM-YYYY")}
                            </td>
                            <td>{x?.Reference}</td>
                            <td>{x?.UnitName}</td>
                            <td>{x?.ContractPrice?.toLocaleString()}</td>
                            <td>{x?.CommissionAmount?.toLocaleString()}</td>
                            <td>{x?.Receipts?.toLocaleString()}</td>
                            <td>
                              {(
                                x?.CommissionAmount - x?.Receipts
                              )?.toLocaleString()}
                            </td>
                            <td>
                              <GrPrint
                                className="reportcommissiondetails"
                                onClick={() => {
                                  setModalData(x);
                                }}
                              />
                            </td>
                          </tr>
                        </>
                      ))
                    : null}
                  <tr>
                    <td colSpan="2" className="text-center">
                      <b>Grand Total</b>
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                      <b>{TotalValue.toLocaleString()}</b>
                    </td>
                    <td>
                      <b>{TotalCommission.toLocaleString()}</b>
                    </td>
                    <td>
                      <b>{TotalRecieved.toLocaleString()}</b>
                    </td>
                    <td>
                      <b>{TotalPayable.toLocaleString()}</b>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-6  text-right pr-0 pl-0">
            <button
              className="print_btn float-left"
              onClick={() => {
                AllprintPaymentRec();
              }}
            >
              <TiPrinter size="1.3em" />
              Print
            </button>
            {csvData.length > 0 && (
              <button
                style={{ color: "black !important" }}
                className="print_btn ml-2 float-left"
              >
                <CSVLink
                  style={{ color: "black" }}
                  filename="Commission Receipt"
                  data={csvData}
                >
                  Download CSV
                </CSVLink>
              </button>
            )}
          </div>
        </div>
      </div>

      {showModal ? (
        <CommissionReceiptModal
          setshowModal={setshowModal}
          ModalData={ModalData}
          setModalData={setModalData}
          IndividualprintPaymentRec={IndividualprintPaymentRec}
        />
      ) : null}
    </>
  );
};

export default CommissionReceipt;
