import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Country, State, City } from "country-state-city";
import Loader from "react-loader-spinner";

export const SellerForm = (props) => {
  const sellerdatastate = useSelector((state) => state.getSellerdatastate);
  const resultsproject = useSelector((state) => state.getProject);
  const [individualcheck, setindividualcheck] = useState(true);
  const [allCountries, setallCountries] = useState([]);
  const [allStates, setallStates] = useState([]);
  const [allCity, setallCity] = useState([]);
  const [selectedCountries, setselectedCountries] = useState(null);
  const [selectedStates, setselectedStates] = useState(null);
  const [selectedCity, setselectedCity] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const data = Country?.getAllCountries()?.map((country) => ({
      value: country.name,
      displayValue: `${country.name}-${country.isoCode}`,
      isoCode: country.isoCode,
    }));
    setallCountries(data);
  }, [Country]);
  useEffect(() => {
    const data = State.getStatesOfCountry(
      selectedCountries?.split("-")[1]
    )?.map((state) => ({
      value: state.name,
      displayValue: `${state.name}-${state.isoCode}`,
    }));
    setallStates(data);
  }, [selectedCountries]);
  useEffect(() => {
    const data = City.getCitiesOfState(
      selectedCountries?.split("-")[1],
      selectedStates?.split("-")[1]
    ).map((city) => ({
      value: city.name,
      displayValue: city.name,
    }));
    console.log(data);
    setallCity(data);
  }, [selectedCountries, selectedStates]);
  let populatedata = {};
  if (
    sellerdatastate.sellerdata !== undefined ||
    sellerdatastate.sellerdata !== null
  ) {
    populatedata = sellerdatastate.sellerdata;
  } else {
    populatedata = sellerdatastate;
  }
  // function emptyfields() {
  //     document.getElementById("Country").selectedIndex = 0;
  //     document.getElementById("City").selectedIndex = 0;
  //     document.getElementById("project").selectedIndex = 0;
  //     document.getElementById("Phone").value = "";
  //     document.getElementById("Address").value = "";

  //     document.getElementById("creditorName").value = "";
  //     document.getElementById("CNIC").value = "";
  //     document.getElementById("Cell1").value = "";
  //     document.getElementById("Cell2").value = "";
  //     document.getElementById("Email").value = "";

  // }
  function addSeller() {
    let regexp = new RegExp("^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$");
    let regexphone = new RegExp("^[0-9+]{4}-[0-9+]{7}$");
    let regexptcl = new RegExp("^[0-9+]{3}-[0-9+]{7}$");
    let regexcompany = new RegExp("^[0-9+]{7}$");
    let check =
      document.getElementById("CNIC") !== null
        ? document.getElementById("CNIC").value
        : null;
    let checkcell1 = document.getElementById("Cell1").value;
    let checkcell2 = document.getElementById("Cell2").value;
    let checkptcl = document.getElementById("Phone").value;
    let checkcompanyreg =
      document.getElementById("Companyregistration") !== null
        ? document.getElementById("Companyregistration").value
        : null;
    if (document.getElementById("creditorName").value === "") {
      toast.info("Name is required");
    } else if (
      document.getElementById("CNIC") !== null
        ? document.getElementById("CNIC").value === ""
        : null
    ) {
      toast.info("CNIC is required");
    } else if (
      document.getElementById("Companyregistration") !== null
        ? document.getElementById("Companyregistration").value === ""
        : null
    ) {
      toast.info("Company registration is required");
    } else if (
      !regexp.test(check) && document.getElementById("CNIC") !== null
        ? document.getElementById("CNIC").value !== ""
        : null
    ) {
      toast.info("Invalid CNIC. Format should be like xxxxx-xxxxxxx-x");
    } else if (
      !regexcompany.test(checkcompanyreg) &&
      document.getElementById("Companyregistration") !== null
        ? document.getElementById("Companyregistration").value !== ""
        : null
    ) {
      toast.info("Invalid Company Reg No. Should contain 7 digits");
    } else if (document.getElementById("project").selectedIndex === 0) {
      toast.info("Select any Project");
    }
    // else if (document.getElementById("CNIC").value === "") { toast.info("CNIC is required") }
    // else if (!regexp.test(check)) {
    //     toast.info('Invalid CNIC. Format should be like xxxxx-xxxxxxx-x');

    // }
    else if (selectedCountries === null || selectedCountries === "Select") {
      toast.info("Select any country");
    } else if (selectedStates === null || selectedStates === "Select") {
      toast.info("Select any State");
    } else if (selectedCity === null || selectedCity === "Select") {
      toast.info("Select any city");
    } else if (
      !regexptcl.test(checkptcl) &&
      document.getElementById("Phone").value !== ""
    ) {
      toast.info("Invalid Phone. Format should be like xxx-xxxxxxx");
    } else if (document.getElementById("Cell1").value === "") {
      toast.info("cell no.1  is required");
    } else if (!regexphone.test(checkcell1)) {
      toast.info("Invalid Cell No. 1 Format should be like xxxx-xxxxxxx");
    } else if (
      !regexphone.test(checkcell2) &&
      document.getElementById("Cell2").value !== ""
    ) {
      toast.info("Invalid Cell No. 2 Format should be like xxxx-xxxxxxx");
    } else if (document.getElementById("Address").value === "") {
      toast.info("Address is required");
    } else {
      setIsLoading(true);
      var SellerDetails = sellerdatastate;

      SellerDetails.SellerId = populatedata.SellerId;
      // SellerDetails.AccountId = populatedata.AccountId;
      SellerDetails.SellerName = document.getElementById("creditorName").value;
      SellerDetails.SellerAddress = document.getElementById("Address").value;
      // SellerDetails.SellerCnic = document.getElementById("CNIC").value;
      SellerDetails.SellerCnic =
        individualcheck === true
          ? document.getElementById("CNIC").value
          : document.getElementById("Companyregistration").value;

      SellerDetails.SellerCellNumber1 = document.getElementById("Cell1").value;
      SellerDetails.SellerCellNumber2 = document.getElementById("Cell2").value;
      SellerDetails.SellerPhoneNumber = document.getElementById("Phone").value;
      SellerDetails.SellerEmail = document.getElementById("Email").value;
      SellerDetails.SellerType =
        individualcheck === true ? "INDIVIDUAL" : "COMPANY";

      SellerDetails.SellerCity = selectedCity;
      SellerDetails.SellerCountry = selectedCountries;
      SellerDetails.State = selectedStates;

      document.getElementById("creditorsave").disabled = true;
      axios({
        url: `${process.env.REACT_APP_API_URL}Sellers/Add?ProjectId=${
          document.getElementById("project").options[
            document.getElementById("project").selectedIndex
          ].dataset.id
        }`,
        method: "Post",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
        data: JSON.stringify(SellerDetails),
      }).then(
        (result) => {
          document.getElementById("creditorsave").disabled = false;

          if (result.data.IsSuccess === true) {
            // emptyfields();
            setIsLoading(false);

            toast.info(result.data.Message);
            props.cancelcreditortable();
          } else {
            if (result.data.Import === true) {
              if (window.confirm(result.data.Message)) {
                SellerImport();
              }
            } else {
              toast.info(result.data.Message);
            }
          }
        },
        (error) => {
          setIsLoading(false);

          toast.info("Something went wrong!");
          document.getElementById("creditorsave").disabled = false;
        }
      );
    }
  }
  function SellerImport() {
    axios({
      url: `${process.env.REACT_APP_API_URL}Sellers/Import?CNIC=${
        individualcheck === true
          ? document.getElementById("CNIC").value
          : document.getElementById("Companyregistration").value
      }&ProjectId=${
        document.getElementById("project").options[
          document.getElementById("project").selectedIndex
        ].dataset.id
      }`,
      method: "Post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          // emptyfields();

          toast.info(result.data.Message);
          props.cancelcreditortable();
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  }
  useEffect(() => {
    if (
      populatedata.SellerCountry !== null &&
      populatedata.SellerCountry !== undefined
    ) {
      setselectedCountries(populatedata.SellerCountry);
    }
    if (populatedata.State !== null && populatedata.State !== undefined) {
      setselectedStates(populatedata.State);
    }
    if (
      populatedata.SellerCity !== null &&
      populatedata.SellerCity !== undefined
    ) {
      setselectedCity(populatedata.SellerCity);
    }
  }, [populatedata]);
  useEffect(() => {
    {
      populatedata.SellerType === "INDIVIDUAL"
        ? setindividualcheck(true)
        : setindividualcheck(false);
    }
  }, [populatedata.SellerType]);
  return (
    <>
      <div className="row ">
        <div className="col-md-12  text-left page_heading">
          <h4 className="m-0">Add Seller</h4>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-4 mt-2 text-right">
          <span className="txt input_label"> Individual</span>
        </div>
        <div className="col-md-1 mt-2 text-left">
          <span>
            {" "}
            <input
              type="radio"
              id="Investment"
              name="InvestmentType"
              checked={individualcheck}
              onClick={() => {
                // document.getElementById("showhide").style.display = "none";
                // document.getElementById("showhide2").style.display = "block";
                // document.getElementById("cityshowhide").style.display = "block";
                setindividualcheck(true);
              }}
            ></input>
          </span>
        </div>
        <div className="col-md-4 mt-2 text-right">
          <span className="txt input_label"> Company</span>
        </div>
        <div className="col-md-1 mt-2 text-left">
          <span>
            {" "}
            <input
              type="radio"
              name="existingType"
              id="existingproject"
              checked={!individualcheck}
              onClick={() => {
                setindividualcheck(false);
                // document.getElementById("showhide2").style.display = "none";
                // document.getElementById("showhide").style.display = "block";
              }}
            ></input>
          </span>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Project :</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <select className="input_styling ml-md-3" id="project">
            <option>Select</option>
            {resultsproject.data.map((val, index) =>
              // <option data-id={val.ProjectId}>{val.ProjectName}</option>
              populatedata.ProjectSellers !== undefined ? (
                populatedata.ProjectSellers.map((x) =>
                  parseInt(x.ProjectId) === parseInt(val.ProjectId) ? (
                    <option selected data-id={val.ProjectId}>
                      {val.ProjectName}
                    </option>
                  ) : null
                )
              ) : (
                <option data-id={val.ProjectId}>{val.ProjectName}</option>
              )
            )}
          </select>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Name :</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3"
            id="creditorName"
            defaultValue={populatedata.SellerName}
          />
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Email</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="email"
            className="input_styling ml-md-3 "
            id="Email"
            defaultValue={populatedata.SellerEmail}
          />
        </div>
      </div>
      <div className="row ">
        {/* <div className="col-md-2 mt-md-4  text-left p-0">
                    <label className="input_label m-0">CNIC</label>
                </div>
                <div className="col-md-3 mt-md-4   p-0">

                    <input type="text" className="input_styling ml-md-3 " id="CNIC" placeholder="xxxxx-xxxxxxx-x" defaultValue={populatedata.SellerCnic} /><span className="estaric">*</span>

                </div> */}
        <div className="col-md-2 mt-md-4  text-left p-0">
          {populatedata.SellerType === "INDIVIDUAL" ||
          populatedata.SellerType === "COMPANY" ? (
            <label className="input_label m-0">
              {populatedata.SellerType === "INDIVIDUAL"
                ? "CNIC"
                : "Company Reg"}
            </label>
          ) : (
            <label className="input_label m-0">
              {individualcheck === true ? "CNIC" : "Company Reg"}
            </label>
          )}
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          {individualcheck === true ? (
            <>
              <input
                type="text"
                className="input_styling ml-md-3 "
                id="CNIC"
                placeholder="xxxxx-xxxxxxx-x"
                defaultValue={populatedata.SellerCnic}
              />
              <span className="estaric">*</span>
            </>
          ) : (
            <>
              <input
                type="text"
                className="input_styling ml-md-3 "
                id="Companyregistration"
                placeholder="xxxxxxx"
                defaultValue={populatedata.SellerCnic}
              />
              <span className="estaric">*</span>
            </>
          )}
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Phone No </label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="Phone"
            placeholder="xxx-xxxxxxx"
            defaultValue={populatedata.SellerPhoneNumber}
          />
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Country</label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <select
            id="SellerCountry"
            className="input_styling ml-md-3"
            name="country"
            onChange={(e) => {
              setselectedCountries(e.target.value);
            }}
            value={selectedCountries}
          >
            {/* <option selected>Select</option>
                        {populatedata.SellerCountry !== null && populatedata.SellerCountry !== undefined ?
                            <option selected > {populatedata.SellerCountry}</option> :
                            <option value="Pakistan">Pakistan</option>
                        } */}
            <option selected>Select</option>
            {allCountries?.map((item, i) => {
              return (
                <>
                  <option key={i} value={item?.displayValue?.toUpperCase()}>
                    {item?.displayValue?.toUpperCase()}
                  </option>
                </>
              );
            })}
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">States</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <select
            className="input_styling ml-md-3"
            id="SellerState"
            onChange={(e) => {
              setselectedStates(e.target.value);
            }}
            value={selectedStates}
          >
            <option selected>Select</option>
            {allStates?.map((item, i) => {
              return (
                <>
                  <option key={i} value={item?.displayValue?.toUpperCase()}>
                    {item?.displayValue?.toUpperCase()}
                  </option>
                </>
              );
            })}
          </select>
          <span className="estaric">*</span>
        </div>
      </div>

      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">City</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <select
            className="input_styling ml-md-3"
            id="SellerCity"
            onChange={(e) => {
              setselectedCity(e.target.value);
            }}
            value={selectedCity}
          >
            <option selected>Select</option>
            {allCity?.map((item, i) => {
              return (
                <>
                  <option key={i} value={item?.displayValue?.toUpperCase()}>
                    {item?.displayValue?.toUpperCase()}
                  </option>
                </>
              );
            })}
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Cell No 1:</label>
        </div>
        <div className="col-md-3 mt-md-4  p-0">
          <input
            type="text"
            className="input_styling ml-md-3"
            id="Cell1"
            placeholder="xxxx-xxxxxxx"
            defaultValue={populatedata.SellerCellNumber1}
          />
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0">Cell No 2: </label>
        </div>
        <div className="col-md-3 mt-md-4   p-0">
          <input
            type="number"
            className="input_styling ml-md-3 "
            id="Cell2"
            placeholder="xxxx-xxxxxxx"
            defaultValue={populatedata.SellerCellNumber2}
          />
        </div>
        <div className="col-md-2 mt-md-4   p-0"></div>
        <div className="col-md-2 mt-md-4  text-left p-0">
          <label className="input_label m-0 ">Address:</label>
        </div>
        <div className="col-md-3 mt-4   p-0">
          <textarea
            className="input_address ml-md-3 "
            id="Address"
            defaultValue={populatedata.SellerAddress}
          ></textarea>
          <span className="estaric">*</span>
        </div>
      </div>

      <div className="row pr-md-2 pt-md-4">
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
          className=" col-12 pr-0 pl-0  text-right "
        >
          <span>
            <button
              className="cancel_btn ml-2  "
              onClick={() => {
                props.cancelcreditortable();
              }}
            >
              Cancel
            </button>
          </span>
          <span className="ml-2">
            {isLoading ? (
              <>
                <Loader
                  type="Oval"
                  color="black"
                  height={30}
                  width={30}
                  radius={12}
                />
              </>
            ) : null}
          </span>
          <span>
            <button
              className="save_btn  ml-2"
              id="creditorsave"
              disabled={isLoading}
              onClick={addSeller}
            >
              <BsCloudUpload size="1.3em" />
              Save
            </button>
          </span>
        </div>
      </div>
    </>
  );
};
