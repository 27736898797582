import axios from "axios";
import { toast } from "react-toastify";
export const GetChartOfAccounts = (data) => {
  return {
    type: "get_chartofacc",
    payload: data,
    loader: false,
  };
};
export function GetAllChartOfAcc() {
  return (dispatch) => {
    let pgurl = `${
      process.env.REACT_APP_API_URL
    }Accounts/GetHeadings?projectid=${localStorage.getItem("ProjectId")}`;
    if (localStorage.getItem("ProjectId") === null) {
      pgurl = `${process.env.REACT_APP_API_URL}Accounts/GetHeadings`;
    }
    axios({
      method: "get",
      url: pgurl,

      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        // if (result.data.IsSuccess === true) {
        dispatch(GetChartOfAccounts(result.data));

        // }
        // else {
        //     toast.info(result.data.Message);
        // }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
