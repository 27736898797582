import { useEffect } from "react";
import { useState } from "react";
import { GrFormAdd } from "react-icons/gr";
import Deletepic from "../../../../assets/delete.png";
export const GeneralReceiptTab = (props) => {
  const [renderComp, setrenderComp] = useState(false);
  useEffect(() => {
    let arr = [];
    if (props.voucherSaveDetails === undefined) {
      props.setdisableBtn(true);
      props.setrowstate([
        {
          generalVoucherSavedDetailId: null,
          accountId: 0,
          amount: 0,
          description: "",
        },
      ]);
    } else {
      props.setdisableBtn(false);
      let amt = 0;
      props.voucherSaveDetails?.forEach((item) => {
        console.log("item", item);
        amt += Number(item.Amount);

        arr.push({
          accountId: item.Account.AccountId,
          generalVoucherSavedDetailId: item.GeneralVoucherSavedDetailId,
          amount: item.Amount,
          AccTitle: item.Account.AccountTitle,
          description: item?.Description || "",
        });
      });
      props.setSumAmt(amt);

      props.setrowstate(arr);
      setrenderComp(!renderComp);

      // setrowData(arr);

      // document.getElementById("totalamount1").value = parseInt(
      //   amt.toString()
      // ).toLocaleString();
    }
  }, [props.voucherSaveDetails]);
  const handleChange = (idx) => (e) => {
    props.setdisableBtn(true);
    const { name, value } = e.target;
    // console.log(name);
    const rows = props.rowstate;
    // console.log(rows);
    if (name === "Accountid") rows[idx].accountId = Number(value);
    if (name === "AccountTitle") {
      var inputValue = document.getElementById(`AccountTitle${idx}`).value;
      var options = document.getElementById(`mylist${idx}`).childNodes;

      for (var i = 0; i < options.length; i++) {
        if (options[i].value === inputValue) {
          rows[idx].AccTitle = options[i].attributes.value.value;
          rows[idx].accountId = Number(options[i].attributes.accountid.value);

          document.getElementById(`accountid${idx}`).value =
            options[i].attributes.accountid.value;
          break;
        }
      }

      // this.onTextChange(value, idx);
    }

    // setrowData(rows);
    props.setrowstate(rows);
  };
  const handleAddRow = () => {
    props.setdisableBtn(true);
    const item = {
      generalVoucherSavedDetailId: null,
      accountId: 0,
      amount: 0,
    };
    props.setrowstate([...props.rowstate, item]);
  };

  const handleRemoveSpecificRow = (idx) => () => {
    props.setdisableBtn(true);
    const rows = props.rowstate;
    rows.splice(idx, 1);
    // setrowData(rows);
    props.setrowstate(rows);
    setrenderComp(!renderComp);
  };
  //sum amount function

  const handleChangeAmount = (idx) => (e) => {
    // console.log("debit amount");
    props.setdisableBtn(true);

    const { name, value } = e.target;
    // console.log(name);
    const rows = props.rowstate;

    rows[idx].amount =
      value === "" ? 0 : Number(parseInt(value.replace(/,/g, "")));
    document.getElementById(`amount${idx}`).value =
      value === "" ? 0 : parseInt(value.replace(/,/g, "")).toLocaleString();
    sumamount();
    // setrowData(rows);
    props.setrowstate(rows);
    setrenderComp(!renderComp);
  };

  const handleChangeDescription = (idx) => (e) => {
    // console.log("debit amount");
    props.setdisableBtn(true);

    const { name, value } = e.target;
    // console.log(name);
    const rows = props.rowstate;

    rows[idx].description = value;
    document.getElementById(`desc${idx}`).value = value;
    // setrowData(rows);
    props.setrowstate(rows);
    setrenderComp(!renderComp);
  };

  const sumamount = () => {
    var len = document.getElementById("tablebody").rows.length;
    var sum = 0;
    var i;
    for (i = 0; i < len; i++) {
      var id = "amount".concat(i.toString());
      var amount = document.getElementById(id).value.replace(/,/g, "");
      if (Number.isInteger(parseFloat(amount))) {
        sum = sum + parseFloat(amount);
      }
    }
    console.log("sum", sum);
    props.setSumAmt(sum);

    // document.getElementById("totalamount1").value = parseInt(
    //   sum.toString()
    // ).toLocaleString();
  };
  console.log("props.rowstate", props.rowstate);
  useEffect(() => {
    if (props.voucherSaveDetails) {
      setrenderComp(!renderComp);
    }
  }, [props.voucherSaveDetails]);
  return (
    <div>
      <div className="row clearfix">
        <div className="col-md-12 p-0 column">
          <table className="table table-bordered table-hover" id="tab_logic">
            <thead>
              <tr>
                <th className="text-center"> Sr# </th>
                <th className="text-center" hidden>
                  {" "}
                  Account Id{" "}
                </th>
                <th className="text-center"> Account Title </th>
                <th className="text-center"> Description </th>
                <th className="text-center"> Amount </th>
                <th className="text-center">
                  <span onClick={handleAddRow}>
                    {" "}
                    <GrFormAdd className="add_icon drow" />
                  </span>
                </th>
              </tr>
            </thead>
            <tbody id="tablebody">
              {props.rowstate?.map((item, idx) => (
                <tr id="addr0" key={idx}>
                  <td className="text-center">{idx + 1}</td>
                  <td hidden>
                    <input
                      id={`accountid${idx}`}
                      type="text"
                      readOnly
                      name="Accountid"
                      value={item.accountId}
                      onChange={handleChange(idx)}
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      id={`AccountTitle${idx}`}
                      name="AccountTitle"
                      defaultValue={item.AccTitle}
                      onChange={handleChange(idx)}
                      className="form-control"
                      list={`mylist${idx}`}
                    />
                    <datalist id={`mylist${idx}`}>
                      {props.resultsallaccount?.data?.map((x, idx) => (
                        <option accountid={x.AccountId} value={x.AccountTitle}>
                          {x.AccountIdCode}
                        </option>
                      ))}
                    </datalist>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="desc"
                      id={`desc${idx}`}
                      value={item.description}
                      onChange={handleChangeDescription(idx)}
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="Amount1"
                      id={`amount${idx}`}
                      value={item.amount}
                      onChange={handleChangeAmount(idx)}
                      className="form-control"
                    />
                  </td>

                  <td className="text-center">
                    <h6>
                      <span onClick={handleRemoveSpecificRow(idx)}>
                        <img
                          style={{
                            cursor: "pointer",
                            width: "19px",
                            marginTop: "8px",
                          }}
                          src={Deletepic}
                          alt="delete"
                        ></img>
                      </span>
                    </h6>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
