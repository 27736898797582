import React, { useEffect } from "react";
import axios from "axios";
import ActivityIndicator from "react-activity-indicator";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import background from "../assets/background.jpg";
import { useDispatch } from "react-redux";
import { GetProjectData } from "./actions/projectselectionaction";
import { getallRoles } from "./actions/getroles";
import { GoEye } from "react-icons/go";
import Loader from "react-loader-spinner";
const divStyle = {
  width: "100vw",
  height: "100vh",
  backgroundImage: `url(${background})`,
  backgroundSize: "cover",
};
const Login = () => {
  const [passwordvisible, setpasswordvisible] = React.useState(false);
  const [displayLoader, setdisplayLoader] = React.useState(false);

  let dispatch = useDispatch();
  let history = useHistory();

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      logIn();
    }
  }

  function logIn() {
    var Email = document.getElementById("username").value;
    var Password = document.getElementById("password").value;

    var newdata = { Email: Email, Password: Password };
    if (Email === "" || Password === "") {
      alert("Please provide Email and Password");
    } else {
      setdisplayLoader(true);
      document.getElementById("loginbutton").disabled = true;

      axios({
        url: `${process.env.REACT_APP_API_URL}Auth/Login`,
        method: "Post",
        headers: {
          "content-type": "application/json",
        },
        data: JSON.stringify(newdata),
      }).then(
        (result) => {
          console.log(result.data);
          document.getElementById("loginbutton").disabled = false;
          setdisplayLoader(false);

          if (
            result.data.LoginResponse !== undefined
              ? result.data.LoginResponse.IsSuccess === true
              : alert("Something went wrong")
          ) {
            localStorage.setItem("LoginId", result.data.LoginResponse.LoginId);
            localStorage.setItem("token", result.data.LoginResponse.Token);
            // localStorage.setItem("Roles", result.data.LoginResponse.Roles[0]);
            localStorage.setItem(
              "OpeningYear",
              result.data.YearOpening.split("T")[0]
            );
            localStorage.setItem(
              "UserName",
              result.data.LoginResponse.UserName
            );
            localStorage.setItem("Designation", result.data.Designation);

            dispatch(getallRoles(result.data.Roles));
            if (result.data.Designation === "Admin") {
              dispatch(GetProjectData(result.data.Projects));
              history.push({
                pathname: "/inventory",
              });
            } else {
              if (result.data.LoginResponse.Roles["Accounts"] !== undefined) {
                dispatch(GetProjectData(result.data.Projects));
                history.push({
                  pathname: "/projectSelection",
                });
              } else if (
                result.data.LoginResponse.Roles["Inventory"] !== undefined
              ) {
                history.push({
                  pathname: "/inventory",
                });
              } else if (result.data.LoginResponse.Roles["HR"] !== undefined) {
                history.push({
                  pathname: "/hr",
                });
              } else if (result.data.LoginResponse.Roles["CRM"] !== undefined) {
                history.push({
                  pathname: "/crm",
                });
              } else if (
                result.data.LoginResponse.Roles["Asset Module"] !== undefined
              ) {
                history.push({
                  pathname: "/assets",
                });
              } else if (
                result.data.LoginResponse.Roles["Admin"] !== undefined
              ) {
                dispatch(GetProjectData(result.data.Projects));
                history.push({
                  pathname: "/inventory",
                });
              } else {
                history.push({
                  pathname: "/",
                });
              }
            }
          } else {
            alert(
              result.data.LoginResponse !== undefined
                ? result.data.LoginResponse.Message
                : alert("Try Again")
            );
          }
        },
        (error) => {
          document.getElementById("loginbutton").disabled = false;
          setdisplayLoader(false);
        }
      );
    }
  }
  return (
    <>
      <div style={divStyle}>
        <div className="loginbox ">
          <div className="row">
            <div className="col-12 logintext">Login</div>
            <div className="col-12 mt-4">
              <input
                type="text"
                placeholder="Email"
                className="logininput"
                id="username"
              />
            </div>

            <div className="col-12 mt-5">
              {""}
              <input
                type={passwordvisible ? "text" : "password"}
                placeholder="Password"
                className="logininput"
                id="password"
                onKeyPress={handleKeyPress}
              />
              <GoEye
                onClick={() => {
                  setpasswordvisible(!passwordvisible);
                }}
                size={"1.3em"}
                id="togglePassword"
                style={{ marginLeft: "-30px" }}
              />
            </div>

            <div className="col-12 mt-3">
              <button
                onClick={logIn}
                className=" text-center mt-4 p-2 logIn "
                id="loginbutton"
              >
                {" "}
                {displayLoader ? (
                  <Loader type="Oval" color="white" height={16} width={16} />
                ) : (
                  "LOGIN"
                )}
              </button>
            </div>
            {/* <div className="col-12 mt-3 text-center"> */}
            {/* <Link to="/forgot"> <span className="password">
                                Forgot Password?
                            </span>
                            </Link> */}
            {/* </div> */}
            {/* <div id="activityind" className="activityindicator col-12 pl-4 text-center">
                            <ActivityIndicator
                                number={5}
                                diameter={40}

                                duration={300}
                                activeColor="#298B8B"
                                borderColor="white"
                                borderWidth={3}
                                borderRadius="10%"
                            />
                        </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
