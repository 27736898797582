import axios from "axios";
import { toast } from "react-toastify";
export const GetAllSalaryPayment = (data) => {
  return {
    type: "get_allSalaryPayment",
    payload: data,
    loader: false,
  };
};
export function getallSalaryPayment() {
  return (dispatch) => {
    let pgurl = `${process.env.REACT_APP_API_URL}Vouchers/SalaryPayment/Populate`;
    if (localStorage.getItem("ProjectId") === null) {
      pgurl = `${process.env.REACT_APP_API_URL}Vouchers/SalaryPayment/Populate`;
    }
    axios({
      url: pgurl,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllSalaryPayment(result.data.Employees));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}

export const GetAllSalaryTableData = (data) => {
  return {
    type: "get_allSalaryTableData",
    payload: data,
    loader: false,
  };
};
export function getallSalaryTable(data) {
  return (dispatch) => {
    let pgurl = `${process.env.REACT_APP_API_URL}Vouchers/SalaryPayment/GetAll`;
    if (localStorage.getItem("ProjectId") === null) {
      pgurl = `${process.env.REACT_APP_API_URL}Vouchers/SalaryPayment/Get`;
    }
    axios({
      url: pgurl,
      method: "Post",
      data: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllSalaryTableData(result.data.SalaryVoucher));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
