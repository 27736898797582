import axios from "axios";
import { toast } from "react-toastify";
export const InstallRescedule = (data) => {
  return {
    type: "get_installrescedule",
    payload: data,
    loader: false,
  };
};
export function installrescedule() {
  return (dispatch) => {
    axios({
      url: `${
        process.env.REACT_APP_API_URL
      }Contracts/RescheduleInstallments/Populate?ProjectId=${localStorage.getItem(
        "ProjectId"
      )}`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(InstallRescedule(result.data.Data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
export const GetResceduleData = (data) => {
  return {
    type: "get_resceduledata",
    payload: data,
  };
};
