import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import { MdDeleteForever } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getallProject } from "../../../actions/getprojects";
import { getaffiliatepopulatetable } from "../../../actions/inventoryactions/getaffiliates";

export const DealSoldForm = (props) => {
  const AffiliateData = useSelector((state) => state.getAffiliatesRed);
  const ProjectData = useSelector((state) => state.getProject);
  const [stateSectionList, setSectionList] = useState([]);
  const [stateUnitList, setUnitList] = useState([]);
  const [tabledata, settabledata] = useState([]);
  const [UnitIds, setUnitIdsList] = useState([]);
  const [state, setstate] = useState({
    DealSoldId: 0,
    DealName: null,
    AffiliateCnic: null,
    Amount: 0,
    EntryDate: null,
    ExpDate: null,
    Note: null,
    ProjectId: 0,
    CommissionPer: null,
    ProfitMarginPer: null,
    AdvancePer: null,
  });
  const [populatedata, setpopulatedata] = useState([]);
  const [stateblocks, setblocks] = useState([]);
  const [stateunit, setunit] = useState([]);
  //
  const [defaultamount, setdefaultamount] = useState(
    props.editstate !== "true"
      ? 0
      : props.editfielddata.TotalSaleValue !== null
      ? props.editfielddata.TotalSaleValue.toLocaleString()
      : null
  );
  const [DealUnits, setUnits] = useState([]);
  const [advancepercentage, setadvancepercentage] = useState(0);
  const [advanceamount, setadvanceamount] = useState(0);
  const [totalsaleamount, settotalsaleamount] = useState(0);
  const [toggle, settoggle] = useState(false);
  const [advancesumfield, setadvancesumfield] = useState(0);

  let totalamountdeal = 0;
  useEffect(() => {
    if (props.editstate !== "true") {
    }
  }, [totalsaleamount]);

  const custominstallments = () => {
    let check = 0;
    props.tablepopulate.forEach((x) => {
      if (
        x.unitid ===
        parseInt(
          document.getElementById("unit").options[
            document.getElementById("unit").selectedIndex
          ].dataset.id
        )
      ) {
        toast.info("Multiple units cannot be added!");
        check = 1;
      }
    });

    if (document.getElementById("unit").selectedIndex !== 0 && check === 0) {
      let item = {
        rand: Math.random(),
        project: parseInt(
          document.getElementById("project").options[
            document.getElementById("project").selectedIndex
          ].dataset.id
        ),
        unitid: parseInt(
          document.getElementById("unit").options[
            document.getElementById("unit").selectedIndex
          ].dataset.id
        ),
        // split: document.getElementById("unit").options[document.getElementById("unit").selectedIndex].dataset.split,
        block:
          document.getElementById("blockdropdown").options[
            document.getElementById("blockdropdown").selectedIndex
          ].value,
        unit: document.getElementById("unit").options[
          document.getElementById("unit").selectedIndex
        ].value,
        marlas:
          document.getElementById("unit").options[
            document.getElementById("unit").selectedIndex
          ].dataset.marlas,
        sarsais:
          document.getElementById("unit").options[
            document.getElementById("unit").selectedIndex
          ].dataset.sarsai,
        Amount: document
          .getElementById("unit")
          .options[
            document.getElementById("unit").selectedIndex
          ].dataset.amount.toLocaleString(),
        Ratemarla:
          document.getElementById("unit").options[
            document.getElementById("unit").selectedIndex
          ].dataset.ratemarla,
        rateperms:
          document.getElementById("unit").options[
            document.getElementById("unit").selectedIndex
          ].dataset.rateperms,
        netsqft:
          document.getElementById("unit").options[
            document.getElementById("unit").selectedIndex
          ].dataset.netsqft,
      };
      props.settablepopulate([...props.tablepopulate, item]);
      document.getElementById("project").disabled = true;
    } else if (document.getElementById("unit").selectedIndex === 0) {
      toast.info("Please select a Unit!");
    }
  };

  let totaladvanceamount = 0;
  useEffect((idx) => {
    if (props.tablepopulate.length !== 0) {
      props.tablepopulate.forEach((x, idx) => {
        totaladvanceamount =
          totaladvanceamount +
          Math.round(
            parseFloat(
              document
                .getElementById(`dealsoldadvancefield${idx}`)
                .value.replace(/,/g, "")
            ) * 10
          ) /
            10;
      });
    }

    setadvancesumfield(parseInt(totaladvanceamount).toLocaleString());
  });
  // }, [totalsaleamount, advancesumfield])
  // }, [totalsaleamount, advancesumfield])

  const deleteinstallment = (idx) => {
    const rows = [...props.tablepopulate];

    rows.splice(idx, 1);

    props.settablepopulate(rows);
  };

  useEffect(() => {
    props.tablepopulate.forEach((x, idx) => {
      // totaladvanceamount = totaladvanceamount + Math.round(parseFloat(document.getElementById(`dealsoldadvancefield${idx}`).value) * 10) / 10
      totalamountdeal =
        totalamountdeal +
        Math.round(
          parseFloat(
            document
              .getElementById(`dealsoldtotalsalefield${idx}`)
              .value.replace(/,/g, "")
          ) * 10
        ) /
          10;

      // setadvanceamount(totaladvanceamount)
      setdefaultamount(parseFloat(totalamountdeal).toLocaleString());
    });

    // if (advanceamount !== 0) {
    document.getElementById("advancepercentage").value =
      (document.getElementById("dealsoldamount").value.replace(/,/g, "") /
        document.getElementById("dealamount").value.replace(/,/g, "")) *
      100;
    if (props.tablepopulate.length === 0) {
      document.getElementById("advancepercentage").value = 0;
    }
    // }

    if (props.tablepopulate.length === 0) {
      // setadvanceamount(0)
      if (props.editstate !== "true") {
        setdefaultamount(0);
      }
      document.getElementById("project").disabled = false;
    }
  }, [toggle]);

  useEffect(() => {
    // if (advanceamount !== 0)
    document.getElementById("advancepercentage").value =
      (parseFloat(
        document.getElementById("dealsoldamount").value.replace(/,/g, "")
      ) /
        parseFloat(
          document.getElementById("dealamount").value.replace(/,/g, "")
        )) *
      100;
  });

  const savedata = () => {
    if (document.getElementById("Date").value === "") {
      toast.info("Date is Required");
    } else if (document.getElementById("dueDate").value === "") {
      toast.info("Due Date is Required");
    } else if (document.getElementById("dealname").value === "") {
      toast.info("Enter the Deal Name");
    } else if (
      document.getElementById("affiliates").selectedIndex === "Select"
    ) {
      toast.info("Select Affiliate");
    } else if (
      document.getElementById("dealamount").value === "" ||
      document.getElementById("dealamount").value <= 0
    ) {
      toast.info("Deal Amount can't be empty");
    } else if (
      document.getElementById("dealsoldamount").value === "" ||
      document.getElementById("dealsoldamount").value <= 0
    ) {
      toast.info("Deal Sold Amount can't be empty");
    } else if (parseFloat(document.getElementById("commission").value) > 100) {
      toast.info(" Commission Percentage Should be less than 100");
    } else if (
      parseFloat(document.getElementById("advancepercentage").value) > 100
    ) {
      toast.info(" Advance Percentage Should be less than 100");
    } else if (
      parseFloat(document.getElementById("profitmargin").value) > 100
    ) {
      toast.info(" Profit Margin Percentage Should be less than 100");
    } else if (props.tablepopulate.length <= 0) {
      toast.info("Add Unit to table");
    } else {
      let DealSold = state;
      DealSold.DealSoldId = parseInt(props.dealunitid);
      DealSold.AffiliateCnic =
        document.getElementById("affiliates").options[
          document.getElementById("affiliates").selectedIndex
        ].dataset.id;
      DealSold.DealName = document.getElementById("dealname").value;
      DealSold.Amount = parseInt(
        document.getElementById("dealsoldamount").value.replace(/,/g, "")
      );
      DealSold.EntryDate = document.getElementById("Date").value;
      DealSold.ExpDate = document.getElementById("dueDate").value;
      // DealSold.NetProfit = parseInt(document.getElementById("percent").value);
      DealSold.Note = document.getElementById("Terms").value;
      DealSold.ProjectId = parseInt(
        document.getElementById("project").options[
          document.getElementById("project").selectedIndex
        ].dataset.id
      );
      DealSold.CommissionPer = document.getElementById("commission").value;
      DealSold.ProfitMarginPer = document.getElementById("profitmargin").value;
      DealSold.AdvancePer = Math.ceil(
        document.getElementById("advancepercentage").value
      );

      let UnitsDeatils = [];
      props.tablepopulate.forEach((x, idx) => {
        if (document.getElementById(`dealsoldtablefield${idx}`) !== null) {
          UnitsDeatils.push({
            UnitId: x.unitid,
            OldSaleRate: parseInt(x.Amount.replace(/,/g, "")),
            NewSaleRate: parseInt(
              document
                .getElementById(`dealsoldtablefield${idx}`)
                .value.replace(/,/g, "")
            ),
            TotalSaleValue: parseInt(
              document
                .getElementById(`dealsoldtotalsalefield${idx}`)
                .value.replace(/,/g, "")
            ),
            AdvanceAmount: parseInt(
              document
                .getElementById(`dealsoldadvancefield${idx}`)
                .value.replace(/,/g, "")
            ),
            AdvancePer:
              document.getElementById(`unit`).options[
                document.getElementById("unit").selectedIndex
              ].dataset.advanceamount,
          });
        }
      });

      document.getElementById("loansave").disabled = true;
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}Deals/Sold/Add`,
        data: JSON.stringify({
          DealSold,
          UnitsDeatils,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          document.getElementById("loansave").disabled = false;
          if (result.data.IsSuccess === true) {
            document.getElementById("Date").value = "";
            document.getElementById("dueDate").value = "";
            // document.getElementById("percent").value = "";
            document.getElementById("Terms").value = "";
            document.getElementById("dealamount").value = "";
            document.getElementById("dealname").value = "";
            document.getElementById("affiliates").selectedIndex = 0;
            // document.getElementById("againstproject").checked = false;
            // document.getElementById("hideshow").style.display = "none";
            document.getElementById("unit").selectedIndex = 0;
            document.getElementById("blockdropdown").selectedIndex = 0;
            document.getElementById("project").selectedIndex = 0;

            settabledata([]);
            setUnitIdsList([]);
            setUnitList([]);
            setSectionList([]);
            setstate({
              DealName: null,
              AffiliateCnic: null,
              Amount: 0,
              EntryDate: null,
              ExpDate: null,
              Note: null,
              ProjectId: 0,
            });
            props.settablepopulate([]);
            props.setedittablepopulate([]);
            props.setedit("false");
            props.setdealunitid(0);
            props.seteditfielddata({});
            toast.info(result.data.Message);
            props.cancelloantable();
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          toast.info("Someting went wrong");
          document.getElementById("loansave").disabled = false;
        }
      );
    }
  };

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getaffiliatepopulatetable());
    dispatch(getallProject());
  }, []);
  return (
    <>
      <div className="row ">
        <div className="col-md-12   text-left page_heading">
          <h4 className="m-0">Deal Sold</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Date:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <input
            type="date"
            className="input_date_styling  ml-md-3"
            id="Date"
            defaultValue={
              props.editstate !== "true"
                ? moment().format("YYYY-MM-DD")
                : props.editfielddata.EntryDate !== undefined
                ? moment(props.editfielddata.EntryDate.split("T")[0]).format(
                    "DD-MMM-YYYY"
                  )
                : null
            }
          ></input>
          <span className="estaric">*</span>
        </div>
        {/* <div className="col-md-1 mt-4 text-left p-0">
                    <label className="input_label m-0 pl-md-4">Project:</label>
                </div>
                <div className="col-md-3 mt-4 ">

                    <select id="project" className="input_styling  ml-md-3" >
                        <option>Select</option>
                        {ProjectData.data.map((x, index) => <option data-id={x.ProjectId} key={index}>{x.ProjectName}</option>)

                        }

                    </select>
                </div> */}
        <div className="col-md-1 mt-4 text-left p-0">
          <label className="input_label m-0 pl-md-4">Deal Name:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="dealname"
            defaultValue={props.editfielddata.DealName}
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4  p-0">
          <label className="input_label m-0 pl-md-4">Affiliate:</label>
        </div>
        <div className="col-md-3 mt-4">
          <select id="affiliates" className="input_styling  ml-md-3">
            {props.editstate !== "true" ? (
              <option>Select</option>
            ) : (
              <option>
                {props.editfielddata.AffiliateCnicNavigation.AffiliateName}
              </option>
            )}
            {AffiliateData.data.map((x) => (
              <option data-id={x.AffiliateCnic}>{x.AffiliateName}</option>
            ))}
          </select>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1 mt-4 p-0">
          <label className="input_label m-0 pl-md-4">Ex. Date:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <input
            type="date"
            className="input_date_styling  ml-md-3"
            id="dueDate"
            defaultValue={
              props.editfielddata.ExpDate !== undefined
                ? moment(props.editfielddata.ExpDate.split("T")[0]).format(
                    "DD-MMM-YYYY"
                  )
                : null
            }
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4 text-left p-0">
          <label className="input_label m-0 pl-md-4">Deal Amt:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="dealamount"
            disabled
            key={defaultamount}
            defaultValue={defaultamount}
            placeholder="First add units into table"
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4 text-left p-0">
          <label className="input_label m-0 pl-md-4">Sold Amt:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="dealsoldamount"
            disabled
            defaultValue={advancesumfield}
            key={advancesumfield}
            placeholder="First add units into table"
          ></input>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1 mt-4  p-0">
          <label className="input_label m-0 pl-md-4">Com.%:</label>
        </div>
        <div className="col-md-3 mt-4">
          <input
            type="number"
            className="input_styling ml-md-3 "
            id="commission"
            defaultValue={props.editfielddata.CommissionPer}
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4  p-0">
          <label className="input_label m-0 pl-md-4">Adv%:</label>
        </div>
        <div className="col-md-3 mt-4">
          <input
            type="number"
            className="input_styling ml-md-3 "
            id="advancepercentage"
            defaultValue={props.editfielddata.AdvancePer}
            placeholder="First add units into table"
            disabled
            //   onChange={(e) => {
            //     setadvancepercentage(parseFloat(e.target.value))
            //   }}
          />
          <span className="estaric">*</span>
        </div>
        <div className="col-md-1 mt-4  p-0">
          <label className="input_label m-0 pl-md-4">Add. Mrg%:</label>
        </div>
        <div className="col-md-3 mt-4">
          <input
            type="number"
            className="input_styling ml-md-3 "
            id="profitmargin"
            defaultValue={props.editfielddata.ProfitMarginPer}
          ></input>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1 mt-4 text-left p-0">
          <label className="input_label m-0 pl-md-4">Note:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <textarea
            className="input_address ml-md-3 "
            id="Terms"
            defaultValue={props.editfielddata.Note}
          ></textarea>
          <span className="estaric">*</span>
        </div>
      </div>
      {/* <span id="hideshow" style={{ display: "none" }}> */}
      <div className="row">
        <div className="col-md-1 mt-4 text-left p-0">
          <label className="input_label m-0 pl-md-4">Project:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <select
            id="project"
            className="input_styling  ml-md-3"
            onChange={() => {
              setblocks(
                ProjectData.data[
                  document.getElementById("project").options[
                    document.getElementById("project").selectedIndex
                  ].dataset.idx
                ]
              );
              document.getElementById("blockdropdown").selectedIndex = 0;
              document.getElementById("unit").selectedIndex = 0;
            }}
          >
            <option>Select</option>
            {ProjectData.data.map((x, index) =>
              x.ProjectOwner !== "Other" ? (
                props.editstate !== "true" ? (
                  <option data-id={x.ProjectId} data-idx={index} key={index}>
                    {x.ProjectName}
                  </option>
                ) : x.ProjectName ===
                  props.editfielddata.Project.ProjectName ? (
                  <option data-id={x.ProjectId} data-idx={index} key={index}>
                    {x.ProjectName}
                  </option>
                ) : null
              ) : null
            )}
            :
          </select>
          <span className="estaric">*</span>
        </div>

        <div className="col-md-1 mt-4 text-left p-0">
          <label className="input_label m-0 pl-md-4">Section:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <select
            id="blockdropdown"
            className="input_styling  ml-md-3"
            onChange={() => {
              setunit(
                stateblocks.Sections[
                  document.getElementById("blockdropdown").options[
                    document.getElementById("blockdropdown").selectedIndex
                  ].dataset.idx
                ]
              );
              document.getElementById("unit").selectedIndex = 0;
              setadvanceamount(
                stateblocks.Sections[
                  document.getElementById("blockdropdown").options[
                    document.getElementById("blockdropdown").selectedIndex
                  ].dataset.idx
                ].AdvanceAmountPer
              );
            }}
          >
            <option>Select</option>
            {stateblocks !== undefined && stateblocks.Sections !== undefined
              ? stateblocks.Sections.map((x, index) => (
                  <option data-id={x.SectionId} data-idx={index} key={index}>
                    {x.SectionName}
                  </option>
                ))
              : null}
          </select>
          <span className="estaric">*</span>
          {/* <select  id="blockdropdown" className="input_styling  ml-md-3" onChange={() => { setUnitList(stateSectionList.Sections[document.getElementById("Block").options[document.getElementById("Block").selectedIndex].dataset.idx]) }}>
                        <option>Select</option>
                        {stateSectionList !== undefined && stateSectionList.Sections !== undefined ? stateSectionList.Sections.map((x, index) => <option data-id={x.SectionId} data-idx={index} key={index}>{x.SectionName}</option>) : null

                        }

                    </select> */}
        </div>
        <div className="col-md-1 mt-4 text-left p-0">
          <label className="input_label m-0 pl-md-4">Unit:</label>
        </div>
        <div className="col-md-3 mt-4 ">
          <select id="unit" className="input_styling  ml-md-3">
            {/* {stateUnitList !== undefined && stateUnitList.Units !== undefined ? stateUnitList.Units.map((x, index) => x.Status === "Available" ? <option data-id={x.UnitId} key={index}>{x.UnitName}</option> : null) : null

                        } */}
            <option>Select</option>
            {stateunit !== undefined && stateunit.Units !== undefined
              ? stateunit.Units.map((val) =>
                  val.Status === "AVAILABLE" ? (
                    <option
                      data-netsqft={val.NetSqft}
                      data-rateperms={val.RatePerMarlaOrsqft}
                      data-marlas={val.Marla}
                      data-sarsai={val.Sarsai}
                      data-id={val.UnitId}
                      data-amount={val.CostPerMarla}
                      data-ratemarla={val.RatePerMarla}
                      data-advanceamount={val.Section.AdvanceAmountPer}
                    >
                      {val.UnitName}
                    </option>
                  ) : null
                )
              : null}
          </select>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-10"></div>

        {/* <div className="col-md-1 text-right pt-2 "><button className="save_btn " onClick={() => {
                    settabledata([]); setUnitIdsList([]);
                }}>Remove</button></div> */}
        <div className="col-md-2 text-right pt-2  ">
          <button className="save_btn " onClick={custominstallments}>
            Add
          </button>
        </div>
      </div>
      {/* <table className="table table-borderless m-0">
                        <thead>
                            <tr>
                                <th>Project</th>
                                <th>Block</th>
                                <th>Unit</th>

                            </tr>
                        </thead>
                        <tbody>
                            {tabledata.map((val, index) => (
                                <tr>
                                    <td>{val.project}</td>
                                    <td>{val.section}</td>
                                    <td>{val.Unit}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table> */}
      <div className="mt-4">
        <div className="table-responsive_modal mt-4">
          <table className="table table-borderless m-0">
            <thead>
              <tr>
                <th>Project </th>
                <th>Section</th>
                <th>Unit</th>
                <th>Size</th>
                <th>Cost Rate</th>
                <th>Sale Rate</th>
                <th>T.Sale Amt</th>
                <th>Adv Amt</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {props.tablepopulate.map((x, idx) => (
                <tr key={x.rand}>
                  <td>{x.project}</td>
                  <td>{x.block}</td>
                  <td>{x.unit}</td>

                  <td>
                    {x.rateperms == "SQFT"
                      ? `${x.netsqft}SQFT`
                      : `${x.marlas}M ${x.sarsais}S`}
                  </td>
                  <td>{parseFloat(x.Amount).toLocaleString()}</td>
                  <td>
                    <input
                      type="text"
                      id={`dealsoldtablefield${idx}`}
                      className="input_styling"
                      // defaultValue="0"
                      placeholder={parseFloat(x.Ratemarla).toLocaleString()}
                      defaultValue={x.NewSaleRate}
                      onChange={(e) => {
                        let currentval = e.target.value;
                        if (e.target.value.length > 0) {
                          document.getElementById(
                            `dealsoldtablefield${idx}`
                          ).value = parseFloat(
                            document
                              .getElementById(`dealsoldtablefield${idx}`)
                              .value.replace(/,/g, "")
                          ).toLocaleString();

                          if (x.rateperms == "MARLA") {
                            let marla = x.marlas.toString();
                            let sarsai = x.sarsais.toString();
                            let marlasarsai = marla.concat(
                              ".",
                              sarsai,
                              sarsai,
                              sarsai,
                              sarsai
                            );

                            let total =
                              parseFloat(marlasarsai) *
                              parseFloat(e.target.value.replace(/,/g, ""));
                            document.getElementById(
                              `dealsoldtotalsalefield${idx}`
                            ).value = parseFloat(
                              Math.ceil(total)
                            ).toLocaleString();
                            // settotalsaleamount(parseFloat(e.target.value.replace(/,/g, '')))
                            document.getElementById(
                              `dealsoldadvancefield${idx}`
                            ).value = parseInt(
                              Math.round(
                                (advanceamount / 100) *
                                  parseFloat(
                                    document
                                      .getElementById(
                                        `dealsoldtotalsalefield${idx}`
                                      )
                                      .value.replace(/,/g, "")
                                  ) *
                                  10
                              ) / 10
                            ).toLocaleString();
                            setadvancesumfield(
                              advanceamount +
                                Math.round(
                                  (advanceamount / 100) *
                                    parseFloat(
                                      document
                                        .getElementById(
                                          `dealsoldtotalsalefield${idx}`
                                        )
                                        .value.replace(/,/g, "")
                                    ) *
                                    10
                                ) /
                                  10
                            );
                          } else if (x.rateperms == "SQFT") {
                            let total =
                              parseFloat(x.netsqft) *
                              parseFloat(e.target.value.replace(/,/g, ""));
                            // settotalsaleamount(parseFloat(e.target.value.replace(/,/g, '')))
                            document.getElementById(
                              `dealsoldtotalsalefield${idx}`
                            ).value = parseFloat(
                              Math.ceil(total)
                            ).toLocaleString();
                            // settotalsaleamount(parseFloat(e.target.value.replace(/,/g, '')))
                            // document.getElementById(`dealsoldadvancefield${idx}`).value = parseFloat(parseFloat(Math.round(((advanceamount / 100) * parseFloat(document.getElementById(`dealsoldtotalsalefield${idx}`).value.replace(/,/g, ''))) * 10) / 10)).toLocaleString();
                            document.getElementById(
                              `dealsoldadvancefield${idx}`
                            ).value = parseInt(
                              Math.round(
                                (advanceamount / 100) *
                                  parseFloat(
                                    document
                                      .getElementById(
                                        `dealsoldtotalsalefield${idx}`
                                      )
                                      .value.replace(/,/g, "")
                                  ) *
                                  10
                              ) / 10
                            ).toLocaleString();

                            setadvancesumfield(
                              advanceamount +
                                Math.round(
                                  (advanceamount / 100) *
                                    parseFloat(
                                      document
                                        .getElementById(
                                          `dealsoldtotalsalefield${idx}`
                                        )
                                        .value.replace(/,/g, "")
                                    ) *
                                    10
                                ) /
                                  10
                            );
                          }
                          if (props.tablepopulate.length !== 0) {
                            props.tablepopulate.forEach((x, idx) => {
                              totalamountdeal =
                                totalamountdeal +
                                Math.round(
                                  parseFloat(
                                    document
                                      .getElementById(
                                        `dealsoldtotalsalefield${idx}`
                                      )
                                      .value.replace(/,/g, "")
                                  ) * 10
                                ) /
                                  10;
                            });
                          }

                          setdefaultamount(
                            parseFloat(totalamountdeal).toLocaleString()
                          );
                        } else {
                          document.getElementById(
                            `dealsoldtablefield${idx}`
                          ).value = "";
                          document.getElementById(
                            `dealsoldadvancefield${idx}`
                          ).value = "";
                          document.getElementById(
                            `dealsoldtotalsalefield${idx}`
                          ).value = "";
                          document.getElementById("dealamount").value = "";
                          document.getElementById("dealsoldamount").value = "";
                          document.getElementById("advancepercentage").value =
                            null;
                          // setadvancesumfield();
                        }

                        // if (x.RatePerMarlaOrsqft == "MARLA") {
                        //     let currentval = e.target.value;
                        //     let total =
                        //         parseFloat(x.marlas) *
                        //         parseFloat(currentval);
                        //     document.getElementById(`dealsoldtotalsalefield${ idx }`).value = Math.ceil(total);
                        //     console.log(total);

                        // }

                        // else if (x.RatePerMarlaOrsqft == "SQFT") {
                        //     let total =
                        //         parseFloat(x.Unit.NetSqft) * parseFloat(currentval);

                        //     document.getElementById(`dealsoldtotalsalefield${ idx }`).value = Math.ceil(total);
                        //     console.log(total);

                        // }

                        // document.getElementById(`dealsoldtotalsalefield${ idx }`).value = Math.round((parseFloat(x.marlas) * parseFloat(e.target.value)) * 10) / 10
                        // document.getElementById(`dealsoldadvancefield${ idx }`).value = Math.round(((advanceamount / 100) * parseFloat(document.getElementById(`dealsoldtotalsalefield${ idx }`).value)) * 10) / 10
                        // setadvancesumfield(advanceamount+Math.round(((advanceamount / 100) * parseFloat(document.getElementById(`dealsoldtotalsalefield${ idx }`).value)) * 10) / 10)
                        // // let marla = x.marlas.toString();
                        // let sarsai = x.sarsais.toString();
                        // console.log(marla)
                        // let marlasarsai = marla.concat(".", sarsai, sarsai, sarsai, sarsai);

                        // let total =
                        //     parseFloat(marlasarsai) *
                        //     parseFloat(e.target.value);
                        // document.getElementById(`dealsoldtotalsalefield${ idx }`).value = Math.ceil(total);
                        // console.log(total);
                        // settotalsaleamount(parseFloat(e.target.value))
                        // document.getElementById(`dealsoldadvancefield${ idx }`).value = Math.round(((advanceamount / 100) * parseFloat(document.getElementById(`dealsoldtotalsalefield${ idx }`).value)) * 10) / 10
                        // setadvancesumfield(advanceamount + Math.round(((advanceamount / 100) * parseFloat(document.getElementById(`dealsoldtotalsalefield${ idx }`).value)) * 10) / 10)
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      id={`dealsoldtotalsalefield${idx}`}
                      className="input_styling"
                      disabled
                      defaultValue={x.TotalSaleValue}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      id={`dealsoldadvancefield${idx}`}
                      className="input_styling"
                      // key={advanceamount}
                      defaultValue={x.AdvanceAmount}
                      disabled
                      // onChange={
                      // (e) => {
                      //     setadvancesumfield(parseFloat(e.target.value))
                      // }
                      // }
                    />
                  </td>
                  <td
                    onClick={() => {
                      deleteinstallment(idx);
                      settoggle(!toggle);
                    }}
                  >
                    <MdDeleteForever size="1.6em" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* </span> */}
      <div className="row">
        <div className="col-md-7 mt-4 text-left p-0"></div>
      </div>
      <div className=" float-right mt-md-4 ">
        <span>
          <button
            className="cancel_btn ml-2  "
            onClick={() => {
              props.seteditfielddata({});
              props.setedit("false");
              props.settablepopulate([]);
              props.setedittablepopulate([]);
              props.cancelloantable();
              props.setdealunitid(0);
            }}
          >
            Cancel
          </button>
        </span>

        <span>
          <button className="save_btn ml-2  " id="loansave" onClick={savedata}>
            <BsCloudUpload size="1.3em" />
            Save
          </button>
        </span>
      </div>
    </>
  );
};
