import axios from "axios";
import { BsCloudUpload } from "react-icons/bs";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export const TaxForm = (props) => {
  const taxdatastate = useSelector((state) => state.getTaxData);
  let populatedata = {};
  if (taxdatastate.taxdata !== undefined || taxdatastate.taxdata !== null) {
    populatedata = taxdatastate.taxdata;
  } else {
    populatedata = taxdatastate;
  }

  function savedata() {
    if (document.getElementById("Amount").value > 100) {
      toast.info("Percentage Value should be less than 100");
    } else if (document.getElementById("Amount").value === "") {
      toast.info("Enter tax percentage");
    } else if (document.getElementById("name").value === "") {
      toast.info("Enter tax type");
    } else {
      var TaxDetails = taxdatastate;

      TaxDetails.TaxId = populatedata.TaxId;
      TaxDetails.TaxName = document.getElementById("name").value;
      TaxDetails.TaxPer = document.getElementById("Amount").value;
      document.getElementById("savedata").disabled = true;
      axios({
        url: `${process.env.REACT_APP_API_URL}Tax/Add`,
        method: "post",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
        data: JSON.stringify(TaxDetails),
      }).then(
        (result) => {
          document.getElementById("savedata").disabled = false;
          if (result.data.IsSuccess === true) {
            document.getElementById("name").value = "";
            document.getElementById("Amount").value = "";

            toast.info(result.data.Message);
            props.canceltaxtable();
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          document.getElementById("savedata").disabled = false;
          toast.info("Something went wrong!");
        }
      );
    }
  }

  return (
    <>
      <div className="row ">
        <div className="col-md-12   text-left page_heading">
          <h4 className="m-0">Add Tax</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0">Tax Name:</label>
        </div>
        <div className="col-md-3 mt-4   p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="name"
            defaultValue={populatedata.TaxName}
          ></input>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-4   p-0"></div>

        <div className="col-md-2 mt-4  text-left p-0">
          <label className="input_label m-0">Percentage:</label>
        </div>
        <div className="col-md-3 mt-4   p-0">
          <input
            type="number"
            className="input_styling  ml-md-3 "
            id="Amount"
            defaultValue={populatedata.TaxPer}
          ></input>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className=" float-right mt-md-4 ">
        <span>
          <button className="cancel_btn ml-2  " onClick={props.canceltaxtable}>
            Cancel
          </button>
        </span>

        <span>
          <button className="save_btn ml-2  " id="savedata" onClick={savedata}>
            <BsCloudUpload size="1.3em" />
            Save
          </button>
        </span>
      </div>
    </>
  );
};
