import axios from "axios";
import { toast } from "react-toastify";
export const GetBalanceSheet = (data) => {
  return {
    type: "get_allbalancesheet",
    payload: data,
    loader: false,
  };
};
export function getbalancesheet(fromDate, toDate, setDateLoading) {
  let pgurl = `${process.env.REACT_APP_API_URL}Reports/BalanceSheet?ProjectId=${
    localStorage.getItem("ProjectId") ? localStorage.getItem("ProjectId") : null
  }&StartDate=${fromDate}&EndDate=${toDate}`;
  if (localStorage.getItem("ProjectId") === null) {
    pgurl = `${process.env.REACT_APP_API_URL}Reports/BalanceSheet?StartDate=${fromDate}&EndDate=${toDate}`;
  }
  return (dispatch) => {
    axios({
      url: pgurl,
      method: "Post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (setDateLoading) {
          setDateLoading(false);
        }
        if (result.data.IsSuccess === true) {
          dispatch(GetBalanceSheet(result.data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        if (setDateLoading) {
          setDateLoading(false);
        }
        toast.info("Something went wrong!");
      }
    );
  };
}
