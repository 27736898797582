import axios from "axios";
import { toast } from "react-toastify";
export const GetProjectsReport = (data) => {
  return {
    type: "get_allprojectreport",
    payload: data,
    loader: false,
  };
};
export function getProjectsReport() {
  let pgurl = `${
    process.env.REACT_APP_API_URL
  }Reports/Projects?ProjectId=${localStorage.getItem("ProjectId")}`;
  if (localStorage.getItem("ProjectId") === null) {
    pgurl = `${process.env.REACT_APP_API_URL}Reports/InstallmentPayments`;
  }
  return (dispatch) => {
    axios({
      url: pgurl,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetProjectsReport(result.data.Data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
