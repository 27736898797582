import React, { useEffect, useState } from "react";

import Payablepic from "../../../assets/pay.png";
import receivePic from "../../../assets/paymethod.png";
import money from "../../../assets/money.png";
import { GrUserManager } from "react-icons/gr";
import { GiCash } from "react-icons/gi";

import { Doughnut } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import balancepic from "../../../assets/onlinebanking.png";
import { getaccBalanceSumm } from "../../actions/accountdashboard/balancesummary";
import { useSelector, useDispatch } from "react-redux";

import CashFlow from "../Reports/CashBook/CashBook";
import RecoveryReport from "../Reports/ManagerRecovery/ManagerRecovery";
import { RecoveryReceiptReport } from "../Reports/RecoveryReceipt/RecoveryReceipt";
import axios from "axios";
import AccountPayable from "../Reports/AccountPayable/AccountPayable";
import AccountReceivable from "../Reports/AccountReceviable/AccountReceivable";
import LandPaymentReport from "./LandPaymentReport";
const AccDashboard = () => {
  const resultsallbalance = useSelector((state) => state.getAccBalanceSummary);
  let dispatch = useDispatch();
  const [statecashreceived, setcashreceived] = useState([]);
  const [statecashpaid, setcashpaid] = useState([]);
  const [stateFromTo, setFromTo] = useState([]);
  const [statetaxdetail, settaxdetail] = useState();
  const [stateassetdetail, setassetdetail] = useState();

  function getCashDetail() {
    let pgurl = `${
      process.env.REACT_APP_API_URL
    }Reports/DashBoard/CashSummary?ProjectId=${localStorage.getItem(
      "ProjectId"
    )}`;
    if (localStorage.getItem("ProjectId") === null) {
      pgurl = `${process.env.REACT_APP_API_URL}Reports/DashBoard/CashSummary`;
    }
    axios({
      url: pgurl,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          setcashreceived(result.data.CashTransactionsReceived);
          setcashpaid(result.data.CashTransactionsPaid);
          setFromTo(result.data.Dates);
        } else {
          alert(result.data.Message);
        }
      },
      (error) => {
        alert("Something went wrong!");
      }
    );
  }
  function getTaxDetail() {
    let pgurl = `${
      process.env.REACT_APP_API_URL
    }Reports/DashBoard/TaxSummary?ProjectId=${localStorage.getItem(
      "ProjectId"
    )}`;
    if (localStorage.getItem("ProjectId") === null) {
      pgurl = `${process.env.REACT_APP_API_URL}Reports/DashBoard/TaxSummary`;
    }
    axios({
      url: pgurl,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          settaxdetail(result.data);
        } else {
          alert(result.data.Message);
        }
      },
      (error) => {
        alert("Something went wrong!");
      }
    );
  }
  function getAssetsDetail() {
    let pgurl = `${
      process.env.REACT_APP_API_URL
    }Reports/DashBoard/COASummary?ProjectId=${localStorage.getItem(
      "ProjectId"
    )}`;
    if (localStorage.getItem("ProjectId") === null) {
      pgurl = `${process.env.REACT_APP_API_URL}Reports/DashBoard/COASummary`;
    }
    axios({
      url: pgurl,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          setassetdetail(result.data);
        } else {
          alert(result.data.Message);
        }
      },
      (error) => {
        alert("Something went wrong!");
      }
    );
  }
  const datapie = {
    maintainAspectRatio: false,
    responsive: false,
    labels: ["Payables", "Receivables", "CashBalance", "BankBalance"],
    datasets: [
      {
        data: [
          resultsallbalance.data.Payables,
          resultsallbalance.data.Receivables,
          resultsallbalance.data.CashBalance,
          resultsallbalance.data.BankBalance,
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(75,192,192,0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(54, 162, 235, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(75,192,192,1)",
          "rgba(255, 206, 86, 1)",
          "rgba(54, 162, 235, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    maintainAspectRatio: false,
  };
  const data = {
    labels: ["TaxReceived", "TaxReceivable", "TaxPayable", "TaxPaid"],
    datasets: [
      {
        label: "Tax Detail",
        data: [
          statetaxdetail !== undefined ? statetaxdetail.TaxReceived : null,
          statetaxdetail !== undefined ? statetaxdetail.TaxReceivable : null,
          statetaxdetail !== undefined ? statetaxdetail.TaxPayable : null,
          statetaxdetail !== undefined ? statetaxdetail.TaxPaid : null,
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const dataAssets = {
    labels: ["Assets", "Liability", "Equity", "Income", "Expense"],
    datasets: [
      {
        label: "Summary",
        data: [
          stateassetdetail !== undefined ? stateassetdetail.Assets : null,
          stateassetdetail !== undefined ? stateassetdetail.Liability : null,
          stateassetdetail !== undefined ? stateassetdetail.Equity : null,
          stateassetdetail !== undefined ? stateassetdetail.Income : null,
          stateassetdetail !== undefined ? stateassetdetail.Expense : null,
        ],
        backgroundColor: [
          "rgba(75, 192, 192, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(255, 99, 132, 0.2)",
        ],
        borderColor: [
          "rgba(75, 192, 192, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(75, 192, 192, 1)",

          "rgba(255, 99, 132, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const linedata = {
    // labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"],
    labels: stateFromTo.map(
      (val) =>
        val.split("T")[0].split("-")[1] + "-" + val.split("T")[0].split("-")[2]
    ),

    datasets: [
      {
        label: "Cash Paid",
        data: statecashpaid.map((val) => val.Amount),
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
      {
        label: "Cash Received",
        data: statecashreceived.map((val) => val.Amount),

        fill: false,
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
      },
    ],
  };
  const openmodalaccpayable = (x) => {
    document.getElementById("modalofaccpayable").style.display = "block";
  };
  const openmodalaccreceivable = (x) => {
    document.getElementById("modalofaccreceivable").style.display = "block";
  };
  const openmodalcashbalance = (x) => {
    document.getElementById("modalofcashbalance").style.display = "block";
  };
  const openmodalmanager = (x) => {
    document.getElementById("modalofmanager").style.display = "block";
  };
  const openmodalrecovery = (x) => {
    document.getElementById("modalofrecovery").style.display = "block";
  };
  useEffect(() => {
    dispatch(getaccBalanceSumm());
  }, []);
  useEffect(() => {
    getCashDetail();
    getTaxDetail();
    getAssetsDetail();
  }, []);
  return (
    <>
      <div className="row mr-2">
        <div className="col-12 p-0">
          <div
            className="row  px-3"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <div className="dashboardboxsmallAccount">
              <div onClick={openmodalaccpayable} className="cur_sor">
                <div className="text-center" style={{ fontSize: "20px" }}>
                  {" "}
                  PKR {""}
                  {Math.round(
                    resultsallbalance.data.Payables
                  ).toLocaleString()}{" "}
                </div>
                <div
                  className="text-center"
                  style={{
                    fontSize: "100%",
                    fontWeight: "600",
                    color: "rgba(255, 99, 132, 1)",
                  }}
                >
                  Payable
                </div>
                <div className="text-center pb-2">
                  <img src={Payablepic} alt="Loan" style={{ width: "25px" }} />
                </div>
              </div>
            </div>

            <div className="dashboardboxsmallAccount ">
              <div onClick={openmodalaccreceivable} className="cur_sor">
                <div className="text-center" style={{ fontSize: "20px" }}>
                  PKR {""}
                  {Math.round(
                    resultsallbalance.data.Receivables
                  ).toLocaleString()}
                </div>
                <div
                  className="text-center"
                  style={{
                    fontSize: "100%",
                    fontWeight: "600",
                    color: "rgba(75,192,192,1)",
                  }}
                >
                  {" "}
                  Receivable
                </div>
                <div className="text-center pb-2">
                  <img src={receivePic} alt="Loan" style={{ width: "25px" }} />
                </div>
              </div>
            </div>

            <div className="dashboardboxsmallAccount ">
              <div onClick={openmodalcashbalance} className="cur_sor">
                <div className="text-center" style={{ fontSize: "20px" }}>
                  PKR {""}
                  {Math.round(
                    resultsallbalance.data.CashBalance
                  ).toLocaleString()}
                </div>
                <div
                  className="text-center"
                  style={{
                    fontSize: "100%",
                    fontWeight: "600",
                    color: "rgba(255, 206, 86, 1)",
                  }}
                >
                  Cash Balance
                </div>
                <div className="text-center pb-2">
                  <img src={money} alt="Loan" style={{ width: "25px" }} />
                </div>
              </div>
            </div>

            <div className="dashboardboxsmallAccount">
              <div className="cur_sor">
                <div className="text-center" style={{ fontSize: "20px" }}>
                  PKR {""}
                  {Math.round(
                    resultsallbalance.data.BankBalance
                  ).toLocaleString()}
                </div>
                <div
                  className="text-center"
                  style={{
                    fontSize: "100%",
                    fontWeight: "600",
                    color: "rgba(54, 162, 235, 1)",
                  }}
                >
                  Bank Balance
                </div>
                <div className="text-center pb-2">
                  <img src={balancepic} alt="Loan" style={{ width: "25px" }} />
                </div>
              </div>
            </div>

            <div className="dashboardboxsmallAccount ">
              <div onClick={openmodalmanager} className="cur_sor">
                <div className="text-center" style={{ fontSize: "20px" }}>
                  PKR {""}
                  {Math.round(resultsallbalance.data.Recovery).toLocaleString()}
                </div>
                <div
                  className="text-center"
                  style={{
                    fontSize: "100%",
                    fontWeight: "600",
                    color: "rgba(54, 162, 235, 1)",
                  }}
                >
                  Manager Recovery
                </div>
                {/* <div className="text-center pb-2"><img src={loanPic} alt="Loan" style={{ width: "25px" }} /></div> */}
                <div className="text-center pb-2">
                  <GrUserManager size="1.3em" />
                </div>
              </div>
            </div>
            <div className="dashboardboxsmallAccount ">
              <div onClick={openmodalrecovery} className="cur_sor">
                <div className="text-center" style={{ fontSize: "20px" }}>
                  PKR {""}
                  {Math.round(
                    resultsallbalance.data.Installments
                  ).toLocaleString()}
                </div>
                <div
                  className="text-center"
                  style={{
                    fontSize: "100%",
                    fontWeight: "600",
                    color: "rgba(54, 162, 235, 1)",
                  }}
                >
                  Installment
                </div>
                {/* <div className="text-center pb-2"><img src={balancepic} alt="Loan" style={{ width: "25px" }} /></div> */}
                <div className="text-center pb-2">
                  <GiCash size="1.3em" />
                </div>
              </div>
            </div>
            {localStorage.getItem("ProjectOwner") === "OTHER" && (
              <div className="dashboardboxsmallAccount ">
                <div
                  onClick={() =>
                    (document.getElementById(
                      "modaloflandpayment"
                    ).style.display = "block")
                  }
                  className="cur_sor"
                >
                  <div className="text-center" style={{ fontSize: "20px" }}>
                    PKR {""}
                    {Math.round(
                      resultsallbalance.data.LandPayment
                    ).toLocaleString()}
                  </div>
                  <div
                    className="text-center"
                    style={{
                      fontSize: "100%",
                      fontWeight: "600",
                      color: "rgba(54, 162, 235, 1)",
                    }}
                  >
                    Land Payments
                  </div>
                  {/* <div className="text-center pb-2"><img src={balancepic} alt="Loan" style={{ width: "25px" }} /></div> */}
                  <div className="text-center pb-2">
                    <GiCash size="1.3em" />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="row ">
            <div className=" col-md-6">
              <div className="dashboardtoprow ">
                <Bar data={dataAssets} options={options} />
              </div>
            </div>
            <div className=" col-md-6">
              <div className="dashboardtoprow ">
                <Doughnut data={datapie} options={options} />
              </div>
            </div>
            <div className=" col-md-6">
              <div className="dashboardtoprow ">
                <h5 className="text-center">Cash History(last 30 days)</h5>
                <Line data={linedata} options={options} />
              </div>
            </div>
            <div className=" col-md-6">
              <div className="dashboardtoprow  ">
                <Bar data={data} options={options} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="modalofaccpayable" class="modalscreen ">
        <div class="modal-content-dashboard pt-1">
          <div className="row ">
            <div className="col-12  page_heading">
              <h4 className="  text-center ">Account Payable</h4>
              <span
                class="close pr-2  "
                onClick={() => {
                  document.getElementById("modalofaccpayable").style.display =
                    "none";
                }}
              >
                &times;
              </span>
            </div>
          </div>
          <div className="row ">
            <div className="col-12  mt-3">
              <AccountPayable />
            </div>
          </div>
        </div>
      </div>
      <div id="modalofaccreceivable" class="modalscreen ">
        <div class="modal-content-dashboard pt-1">
          <div className="row ">
            <div className="col-12  page_heading">
              <h4 className="  text-center ">Account Receivable</h4>
              <span
                class="close pr-2  "
                onClick={() => {
                  document.getElementById(
                    "modalofaccreceivable"
                  ).style.display = "none";
                }}
              >
                &times;
              </span>
            </div>
          </div>
          <div className="row ">
            <div className="col-12  mt-3">
              <AccountReceivable />
            </div>
          </div>
        </div>
      </div>
      <div id="modalofcashbalance" class="modalscreen ">
        <div class="modal-content-dashboard pt-1">
          <div className="row ">
            <div className="col-12  page_heading">
              <h4 className="  text-center ">Cash Book</h4>
              <span
                class="close pr-2  "
                onClick={() => {
                  document.getElementById("modalofcashbalance").style.display =
                    "none";
                }}
              >
                &times;
              </span>
            </div>
          </div>
          <div className="row ">
            <div className="col-12  mt-3">
              <CashFlow />
            </div>
          </div>
        </div>
      </div>
      <div id="modalofmanager" class="modalscreen ">
        <div class="modal-content-dashboard pt-1">
          <div className="row ">
            <div className="col-12  page_heading">
              <h4 className="  text-center ">Manager Recovery</h4>
              <span
                class="close pr-2  "
                onClick={() => {
                  document.getElementById("modalofmanager").style.display =
                    "none";
                }}
              >
                &times;
              </span>
            </div>
          </div>
          <div className="row ">
            <div className="col-12  mt-3">
              <RecoveryReport />
            </div>
          </div>
        </div>
      </div>
      <div id="modalofrecovery" class="modalscreen ">
        <div class="modal-content-dashboard pt-1">
          <div className="row ">
            <div className="col-12  page_heading">
              <h4 className="  text-center "> Recovery Report</h4>
              <span
                class="close pr-2  "
                onClick={() => {
                  document.getElementById("modalofrecovery").style.display =
                    "none";
                }}
              >
                &times;
              </span>
            </div>
          </div>
          <div className="row ">
            <div className="col-12  mt-3">
              <RecoveryReceiptReport />
            </div>
          </div>
        </div>
      </div>
      <div id="modaloflandpayment" class="modalscreen ">
        <div class="modal-content-dashboard pt-1">
          <div className="row ">
            <div className="col-12  page_heading">
              <h4 className="  text-center "> Land Payments</h4>
              <span
                class="close pr-2  "
                onClick={() => {
                  document.getElementById("modaloflandpayment").style.display =
                    "none";
                }}
              >
                &times;
              </span>
            </div>
          </div>
          <div className="row m-0 p-0 ">
            <div className="col-12 m-0 p-0  mt-3">
              <LandPaymentReport />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccDashboard;
