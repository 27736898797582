import React, { useState, useEffect } from "react";
import "../../App.css";
import profilePic from "../../assets/profilelogo.jpg";

import { useHistory } from "react-router-dom";

import bellPic from "../../assets/bell.svg";
import dotpic from "../../assets/Group1.svg";

import dashboardPic from "../../assets/dashboard.svg";
import depriciationPic from "../../assets/depriciation.svg";
import maintainPic from "../../assets/maintenence.svg";
import assestsPic from "../../assets/assets.svg";

import Dropdown from "react-bootstrap/Dropdown";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import AssestsDashboard from "./dashboard";

import MainAssetsCom from "./assetspages/assets";
import MainSellAssetsCom from "./assetspages/sellassets";
import MaintainenceComp from "./assetspages/maintainence";
import MainAddBuyerComp from "./assetspages/assetbuyer";
import DepriciationComp from "./assetspages/depreciation";
import MainAddSellerComp from "../Inventory/landcreditor/addseller";
import MainAddRoleCom from "../Accounts/roles/roles";
import { useDispatch, useSelector } from "react-redux";
import { getallRoles } from "../actions/getroles";
import Changeform from "../Accounts/changeform/changeform";
import { useContext } from "react";
import { monthlyAlert } from "../ContextApi/MonthlyAlert";
import AltertModal from "../AlertModal/AltertModal";
import axios from "axios";
import moment from "moment";
import ChangeInReqMain from "../Accounts/ChangeInReqMain2/ChangeInReqMain";
import { Users } from "../Users/Users/Users";

function SideButton(props) {
  return (
    <>
      <button
        onClick={() => {
          props.onClick();
        }}
        className={
          props.active
            ? "grey side_button_hover row sideText sideButton  mt-4"
            : "row side_button_hover sideText sideButton  mt-4"
        }
      >
        {props.icon}
        <p className=" ml-3 sideText green_text toggleButton">{props.text}</p>
      </button>
    </>
  );
}
function SideSubButton(props) {
  return (
    <>
      <button
        onClick={() => {
          props.onClick();
        }}
        className={
          props.active ? "sub_button_style_underline" : "sub_button_style "
        }
      >
        {props.icon}
        <span className=" sideText   ">{props.text}</span>
      </button>
      <br></br>
    </>
  );
}
const AssetsMainPage = () => {
  const history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      localStorage.clear();
      sessionStorage.clear();
      history.push({ pathname: "/" });
    }
  }, []);
  const { changeState } = useContext(monthlyAlert);
  const [showAlert, setshowAlert] = useState(false);
  useEffect(() => {
    if (sessionStorage.getItem("isPaid") === "false") {
      changeState(true);
      setshowAlert(true);
    } else {
      changeState(false);
      setshowAlert(false);
    }
  }, []);

  // useEffect(() => {
  //   console.log("dasdsads");
  //   axios
  //     .get("https://sheetdb.io/api/v1/dskt971mukoor")
  //     .then((res) => {
  //       if (
  //         res?.data?.length > 0 &&
  //         res.data[0]?.IsPaid === "FALSE" &&
  //         Number(moment().format("DD")) > Number(res.data[0]?.Date)
  //       ) {
  //         sessionStorage.setItem("isPaid", false);
  //         changeState(true);
  //         setshowAlert(true);
  //       } else {
  //         sessionStorage.setItem("isPaid", true);

  //         changeState(false);
  //         setshowAlert(false);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);
  // toggle state start
  const rolesdata = useSelector((state) => state.GetAllRole);
  let dispatch = useDispatch();
  const [reload, setreload] = useState(true);

  const [toggle, setToggle] = useState(true);
  const toggleClose = () => {
    let list = document.getElementsByClassName("toggleButton");
    if (toggle === true) {
      setToggle(false);
      for (let i = 0; i < list.length; i++) {
        list[i].style.display = "none";
      }
      document.getElementById("tog").removeAttribute("style");
    } else {
      setToggle(true);
      for (let i = 0; i < list.length; i++) {
        list[i].style.display = "block";
      }
      document.getElementById("tog").setAttribute("style", "width: 15em;");
    }
  };

  // toggle state end
  // account payable sub buttons
  const Maintainance = [
    {
      component: <MaintainenceComp></MaintainenceComp>,
      rolename: "Maintainance",
    },
  ];
  const Depriciation = [
    {
      component: <DepriciationComp></DepriciationComp>,
      rolename: "Depriciation",
    },
  ];
  const Dashboard = [
    {
      component: <AssestsDashboard></AssestsDashboard>,
      rolename: "Asset Dashboard",
    },
  ];
  // const Reports = [
  //     {

  //         component: <AssestsDashboard></AssestsDashboard>,

  //     },

  // ];

  const Assets = [
    {
      text: "Asset",
      component: <MainAssetsCom></MainAssetsCom>,
      rolename: "Add Asset",
    },
    // {
    //     text: "Check Out",
    //     component: <AssestsDashboard></AssestsDashboard>,

    // },
    {
      text: "Sell",
      component: <MainSellAssetsCom></MainSellAssetsCom>,
      rolename: "Sell",
    },
    {
      text: "Buyer",
      component: <MainAddBuyerComp></MainAddBuyerComp>,
      rolename: "Buyer",
    },
    {
      text: "Seller",
      component: <MainAddSellerComp></MainAddSellerComp>,
      rolename: "Seller",
    },
  ];
  // account payable sub buttons

  const [statesubbutton, setsubbutton] = useState("");

  // buttons state start
  const [chosen1, setChosen1] = useState("Dashboard");
  const [viewcomponent, setview] = useState(null);

  // subbutton
  const [subchosen2, setsubchosen2] = useState({
    text: "Dashboard",
    rolename: "Asset Dashboard",
    icon: (
      <img src={dashboardPic} alt="Dashboard" className="ml-3 Icondashboard" />
    ),
    subbtn: Dashboard,
  });
  const [Menu] = useState([
    {
      text: "Dashboard",
      icon: (
        <img
          src={dashboardPic}
          alt="Dashboard"
          className="ml-3 Icondashboard"
        />
      ),
      rolename: "Asset Dashboard",
      subbtn: Dashboard,
    },
    {
      text: "Assets",
      icon: <img src={assestsPic} alt="Assets" className="ml-3 Iconpayable" />,
      rolename: "Assets",
      subbtn: Assets,
    },
    {
      text: "Maintainance",
      icon: (
        <img
          src={maintainPic}
          alt="Maintainance"
          className="ml-3 Iconpayable"
        />
      ),
      rolename: "Maintainance",
      subbtn: Maintainance,
    },
    {
      text: "Depriciation",
      icon: (
        <img
          src={depriciationPic}
          alt="Depriciation"
          className="ml-3 Iconpayable"
        />
      ),
      rolename: "Depriciation",
      subbtn: Depriciation,
    },
    // {
    //     text: "Reports",
    //     icon: <img src={reportPic} alt="Reports" className="ml-3 Iconpayable" />,

    //     subbtn: Reports
    // }
  ]);
  // buttons state end
  useEffect(() => {
    dispatch(getallRoles());
  }, []);
  useEffect(() => {
    if (rolesdata?.data?.Roles) {
      if (
        rolesdata?.data?.Roles["Asset Dashboard"] ||
        rolesdata?.data?.Roles["Admin"]
      ) {
        setview(<AssestsDashboard />);
      }
    }
  }, [rolesdata]);
  return (
    <>
      {/* top name bar */}

      <div className="row">
        {/* toggle button */}

        <div
          className="col-auto p-0 sidebuttonsheight  main_btn_style"
          id="tog"
          style={{ width: "13em " }}
        >
          <div
            onClick={() => {
              toggleClose();
            }}
            className="threedots"
          >
            {" "}
            <img src={dotpic} alt="purchase" className="ml-3 Icongroup" />
          </div>
          <div className="line_styling">
            <hr></hr>
          </div>
          <div className=" m_top_custom pr-3 ">
            {/* buttons map function */}
            {Menu.map((btn, index) =>
              (rolesdata?.data?.Roles &&
                rolesdata?.data?.Roles[btn.rolename] !== undefined) ||
              localStorage.getItem("Designation") === "Admin" ? (
                <SideButton
                  key={index}
                  text={btn.text}
                  icon={btn.icon}
                  onClick={() => {
                    if (showAlert) {
                      changeState(true);
                    }

                    setChosen1(btn.text);

                    setsubchosen2(btn);
                    setview(btn.subbtn[0].component);
                    setsubbutton(btn.subbtn[0].text);
                    setreload(!reload);
                  }}
                  active={btn.text === chosen1}
                />
              ) : null
            )}
          </div>
        </div>

        <div className="col">
          {/* top nav bar start */}
          <div className="row pt-1">
            <div className="col-auto pt-1">
              <span className="name_heading mt-1 green_text ">
                {localStorage.getItem("UserName")}
              </span>
              <br></br>
              <span className="designation_head green_text ">
                {localStorage.getItem("Designation")}
              </span>
            </div>
            <div className="col text-center pt-1">
              <div className="row pl-md-5 pl-lg-5">
                <div className="col-10 nav-space">
                  {rolesdata?.data?.Roles?.Admin &&
                  `${localStorage.getItem("Designation")}` === "Admin" ? (
                    <>
                      {" "}
                      <Link to="/projectSelection">
                        {" "}
                        <button className="top_menu_btn blue_text ">
                          Accounts
                        </button>
                      </Link>
                      <Link to="/inventory">
                        {" "}
                        <button className="top_menu_btn blue_text">
                          Inventory
                        </button>
                      </Link>
                      <Link to="/hr">
                        {" "}
                        <button className=" top_menu_btn blue_text">
                          Human Resource
                        </button>
                      </Link>
                      <Link to="/crm">
                        {" "}
                        <button className="top_menu_btn blue_text">CRM</button>
                      </Link>
                      <Link to="/assets">
                        {" "}
                        <button className="top_menu_btn-select ">Assets</button>
                      </Link>
                    </>
                  ) : null}
                  {rolesdata?.data?.Roles &&
                  rolesdata?.data?.Roles["Accounts"] !== undefined ? (
                    <Link to="/projectSelection">
                      {" "}
                      <button className="top_menu_btn blue_text">
                        Accounts
                      </button>
                    </Link>
                  ) : null}
                  {rolesdata?.data?.Roles &&
                  rolesdata?.data?.Roles["Inventory"] !== undefined ? (
                    <Link to="/inventory">
                      {" "}
                      <button className="top_menu_btn blue_text">
                        Inventory
                      </button>
                    </Link>
                  ) : null}
                  {rolesdata?.data?.Roles &&
                  rolesdata?.data?.Roles["HR"] !== undefined ? (
                    <Link to="/hr">
                      {" "}
                      <button className="top_menu_btn blue_text">
                        Human Resource
                      </button>
                    </Link>
                  ) : null}
                  {rolesdata?.data?.Roles &&
                  rolesdata?.data?.Roles["CRM"] !== undefined ? (
                    <Link to="/crm">
                      {" "}
                      <button className="top_menu_btn blue_text">CRM</button>
                    </Link>
                  ) : null}
                  {rolesdata?.data?.Roles &&
                  rolesdata?.data?.Roles["Asset Module"] !== undefined ? (
                    <Link to="/assets">
                      {" "}
                      <button className="top_menu_btn-select ">Assets</button>
                    </Link>
                  ) : null}
                  {/* <Link to="/account">  <button className="top_menu_btn blue_text ">Accounts</button></Link>
                                    <Link to="/inventory">   <button className="top_menu_btn blue_text">Inventory</button></Link>
                                    <Link to="/hr">   <button className=" top_menu_btn blue_text">Human Resource</button></Link>
                                    <Link to="/crm">  <button className="top_menu_btn blue_text">CRM</button></Link>
                                    <Link to="/assets">  <button className="top_menu_btn-select ">Assets</button></Link> */}
                </div>
                <div className=" col-md-2  ">
                  <span className="right_nav">
                    <span className=" mr-1">
                      <img
                        src={bellPic}
                        alt="bell"
                        className="ml-3 Icontaxbell"
                      />
                    </span>
                    <span className=" ml-2">
                      <img
                        className="imageCircle"
                        src={profilePic}
                        alt="profile"
                      />
                    </span>
                    <span>
                      <Dropdown className="accountBtn  drop_icon_style">
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="accountBtn p-0"
                        >
                          <RiArrowDropDownLine className=" drop_icon_style" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              localStorage.clear();
                              sessionStorage.clear();
                              history.push({ pathname: "/" });
                            }}
                          >
                            {" "}
                            Logout
                          </Dropdown.Item>
                          {/* <Dropdown.Item
                            onClick={() => {
                              setview(<MainAddRoleCom />);
                              setChosen1("");
                            }}
                          >
                            {"  "}
                            Role
                          </Dropdown.Item> */}
                          {rolesdata?.data?.Roles?.Admin && (
                            <Dropdown.Item
                              onClick={() => {
                                setview(<Users />);
                                setChosen1("");
                              }}
                            >
                              {"  "}
                              Users
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item
                            onClick={() => {
                              setview(<Changeform />);
                              setChosen1("");
                            }}
                          >
                            {"  "}
                            Change Request
                          </Dropdown.Item>
                          {/* <Dropdown.Item
                            onClick={() => {
                              setview(<ChangeInReqMain />);
                              setChosen1("");
                            }}
                          >
                            {"  "}
                            Change Request 2
                          </Dropdown.Item> */}
                        </Dropdown.Menu>
                      </Dropdown>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-auto pl-2 pr-2">
              {subchosen2.subbtn.map((btn, index) =>
                btn.text !== undefined ? (
                  (rolesdata?.data?.Roles &&
                    rolesdata?.data?.Roles[btn.rolename] !== undefined) ||
                  localStorage.getItem("Designation") === "Admin" ? (
                    <SideSubButton
                      text={btn.text}
                      key={index}
                      onClick={() => {
                        if (showAlert) {
                          changeState(true);
                        }

                        setsubbutton(btn.text);
                        setview(btn.component);
                        setreload(!reload);
                      }}
                      active={btn.text === statesubbutton}
                    />
                  ) : null
                ) : null
              )}
            </div>
            <div className="col viewcompmg">
              {/* component rendering */}
              {viewcomponent}
            </div>
          </div>
        </div>
        {/* top nav bar end */}
      </div>
      <AltertModal />
    </>
  );
};
export default AssetsMainPage;
