import axios from "axios";
import { toast } from "react-toastify";
export const GetAllAvailable = (data) => {
  return {
    type: "get_allcrmavailability",
    payload: data,
    loader: false,
  };
};
export function getallAvailable() {
  return (dispatch) => {
    axios({
      url: `${process.env.REACT_APP_API_URL}CRMReports/Availability`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllAvailable(result.data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
export const GetAllLeads = (data) => {
  return {
    type: "get_allleads",
    payload: data,
    loader: false,
  };
};
export function getallLeads() {
  return (dispatch) => {
    axios({
      url: `${process.env.REACT_APP_API_URL}Leads/ReserveUnit/Populate`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllLeads(result.data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
