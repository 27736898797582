const allcreditor = { data: [], loading: true };
const creditordata = {
  LandCreditorId: 0,
  LandCreditorName: null,
  LandCreditorAddress: null,
  ContactNumber1: null,
  ContactNumber2: null,
  PhoneNumber: null,
  Country: null,
  City: null,
  Email: null,
  LandCreditorCnic: null,
  Type: null,
  AccountId: 0,
};
export const GetAllCreditorReducer = (state = allcreditor, action) => {
  switch (action.type) {
    case "get_allcreditor":
      return {
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};
export const GetCreditordataReducer = (state = creditordata, action) => {
  switch (action.type) {
    case "get_creditordata":
      return {
        creditordata: action.payload,
      };
    default:
      return {
        creditordata: creditordata,
      };
  }
};
