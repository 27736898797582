import axios from "axios";
import { toast } from "react-toastify";
export const GetAllCreditors = (data) => {
  return {
    type: "get_allcreditor",
    payload: data,
    loader: false,
  };
};
export const GetCreditorData = (data) => {
  return {
    type: "get_creditordata",
    payload: data,
  };
};
export function getallcreditors() {
  return (dispatch) => {
    axios({
      url: `${process.env.REACT_APP_API_URL}LandCreditors/GetAll`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllCreditors(result.data.Data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
