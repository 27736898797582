import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import moment from "moment";

import { TiPrinter } from "react-icons/ti";
import axios from "axios";
import { getallacounts } from "../../../actions/getheading";
import { getallProject } from "../../../actions/getprojects";

import { useSelector, useDispatch } from "react-redux";
import Loader from "react-loader-spinner";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import GeneralLedgerPrint from "../ReportPrints/GeneralLedgerPrint";
import FullScreenLoader from "../../../FullScreenLoader/FullScreenLoader";
import { CSVLink } from "react-csv";
const GeneralLedger = () => {
  const resultsallaccount = useSelector((state) => state.getAllAccountsReducer);
  const dispatch = useDispatch();
  const [defaultAccountId, setdefaultAccountId] = useState(0);
  const [stateledgerdata, setledgerdata] = useState([]);
  const [stateaccountdata, setaccountdata] = useState({});
  const [statefromdate, setfromdate] = useState();
  const [statetodate, settodate] = useState();
  const [stateopenbalancetype, setopenbalancetype] = useState();
  const [openingbaldate, setopeningbaldate] = useState(null);
  const [selectedProject, setselectedProject] = useState(0);
  const resultsproject = useSelector((state) => state.getProject);
  const [showProjects, setshowProjects] = useState(false);
  const [allPropjects, setallPropjects] = useState([]);
  const [renderComp, setrenderComp] = useState(false);
  const [accountTitle, setaccountTitle] = useState(null);
  const [showTransactions, setshowTransactions] = useState(false);
  const [TransactionData, setTransactionData] = useState([]);
  const [statedebit, setdebit] = useState(0);
  const [statecredit, setcredit] = useState(0);
  const [loader, setloader] = useState(false);
  const [stateopeningbal, setopeningbal] = useState(0);
  const [csvData, setCsvData] = useState([]);
  const [transactionDebitTotal, settransactionDebitTotal] = useState(0);
  const [transactionCreditTotal, settransactionCreditTotal] = useState(0);
  const [screenLoader, setscreenLoader] = useState(false);

  // print function
  const componentRef = useRef();

  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (resultsproject?.data) {
      setallPropjects(resultsproject?.data);
    }
  }, [resultsproject]);

  useEffect(() => {
    dispatch(getallProject());

    dispatch(getallacounts());
  }, [dispatch]);
  var suggestionlist = [];
  const onTextChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      ReactDOM.render(null, document.getElementById("demo"));
      // document.getElementById("accounttitle2").removeAttribute("disabled");
    } else {
      let suggestions = [];
      if (value !== "") {
        const regex = new RegExp(`${value}`, "i");
        suggestions = resultsallaccount.data
          .sort()
          .filter((v) => regex.test(v.AccountTitle));
        // document.getElementById("accounttitle2").setAttribute("disabled", "true");
      }
      suggestionlist = suggestions;
      suggestionlist.push("Add New");
      if (suggestionlist.length === 0) {
        ReactDOM.render(null, document.getElementById("demo"));
      } else {
        ReactDOM.render(
          <ul className="suggestbox">
            {suggestionlist.map((item) => (
              <li className="listcss" onClick={() => suggestionselected(item)}>
                {item.AccountTitle}
              </li>
            ))}
          </ul>,
          document.getElementById("demo")
        );
      }
    }
  };
  const onTextChangeAutoSuggestion = () => {
    var inputValue = document.getElementById("autosugges").value;
    var options = document.getElementById("autosuggeslist").childNodes;
    console.log("inputValue", inputValue);
    console.log("options", options[0].value);

    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        resultsallaccount.data.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            console.log("x", x);
            val = x;
          }
        });
        setdefaultAccountId(val.AccountId);
        setaccountTitle(val.AccountTitle);
        break;
      }
    }
  };
  const suggestionselected = (val) => {
    if (val === "Add New") {
      suggestionlist = [];
      ReactDOM.render(null, document.getElementById("demo"));
    } else {
      document.getElementById("autosugges").value = val.AccountTitle;

      setdefaultAccountId(val.AccountId);
      suggestionlist = [];
      ReactDOM.render(null, document.getElementById("demo"));
    }
  };
  const saveGeneralLedger = () => {
    document.getElementById("go").disabled = true;
    if (
      localStorage.getItem("ProjectId") === undefined &&
      selectedProject <= 0
    ) {
      toast.info("Select Project");
    } else if (
      document.getElementById("fromdate").value === "" ||
      document.getElementById("todate").value === ""
    ) {
      toast.info("Select FromDate To ToDate");
      document.getElementById("go").disabled = false;
    } else if (document.getElementById("autosugges").value === "") {
      document.getElementById("go").disabled = false;
      toast.info("Select Account ");
    } else {
      let projArr = [];
      allPropjects?.forEach((itm) => {
        if (itm.check && itm.check === true) {
          projArr.push(itm.ProjectId?.toString());
        }
      });
      setloader(true);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}Reports/GeneralLedger`,
        data: JSON.stringify({
          From: document.getElementById("fromdate").value,
          To: document.getElementById("todate").value,
          ID: parseInt(defaultAccountId),
          ProjectId: localStorage.getItem("ProjectId")
            ? localStorage.getItem("ProjectId")
            : null,
          Projects: projArr,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result.data.IsSuccess === true) {
            setledgerdata(result.data.Data);
            setaccountdata(result.data.Account);
            settodate(result.data.To);
            setfromdate(result.data.From);
            setopenbalancetype(result.data.OpeningBalanceType);
            setopeningbal(result.data.OpeningBalance);
            setopeningbaldate(result.data.OpeningBalanceDate);

            var credit = 0;

            result.data.Data.map((val) =>
              val.TransactionType === "CREDIT"
                ? (credit += parseFloat(val.Amount))
                : 0
            );

            setcredit(credit);
            var debit = 0;

            result.data.Data.map((val) =>
              val.TransactionType === "DEBIT"
                ? (debit += parseFloat(val.Amount))
                : 0
            );
            setdebit(debit);
            setloader(false);
            document.getElementById("go").disabled = false;
          } else {
            setloader(false);
            document.getElementById("go").disabled = false;
          }
        },
        (error) => {
          toast.info("Something Went Wrong.. Try Again");
          setloader(false);
          document.getElementById("go").disabled = false;
        }
      );
    }
  };
  const getTransactions = (id) => {
    setscreenLoader(true);
    axios({
      method: "get",
      url: `${
        process.env.REACT_APP_API_URL
      }Reports/GeneralLedgerTransactions?projectid=${
        localStorage.getItem("ProjectId")
          ? localStorage.getItem("ProjectId")
          : null
      }&VoucherId=${id}`,

      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    })
      .then((res) => {
        setscreenLoader(false);
        if (res.data.IsSuccess === true) {
          setTransactionData(res.data.Data);
          let DebitAmount = 0;
          let creditAmount = 0;
          res.data.Data?.forEach((item) => {
            if (item.TransactionType === "DEBIT") {
              DebitAmount = DebitAmount + item.Amount;
            } else if (item.TransactionType === "CREDIT") {
              creditAmount = creditAmount + item.Amount;
            }
          });
          settransactionDebitTotal(DebitAmount);
          settransactionCreditTotal(creditAmount);
          setshowTransactions(true);
        }
      })
      .catch((err) => {
        setscreenLoader(false);
        toast.error("Something went wrong!");
      });
  };
  useEffect(() => {
    let arr = [
      {
        Date: moment(openingbaldate?.split("T")[0]).format("YYYY-MM-DD"),
        Narration: "Opening Balance",
        Initiate: "",
        Reference: "",
        Credit: "",
        Debit: "",
        Balance:
          stateopeningbal !== undefined ? parseFloat(stateopeningbal) : 0,
        Type: stateopenbalancetype,
      },
    ];
    stateledgerdata?.forEach((item) => {
      arr.push({
        Date: moment(item).format("YYYY-MM-DD"),
        Narration: item.Narration,
        Initiate: item.InitProjectName?.ProjectName,
        Reference: item.VoucherId,
        Credit:
          item.TransactionType === "CREDIT" ? parseFloat(item.Amount) : "",
        Debit: item.TransactionType === "DEBIT" ? parseFloat(item.Amount) : "",
        Balance: item.AccountBalance,
        Type: item.BalanceType,
      });
    });
    arr.push({
      Date: "",
      Narration: "",
      Initiate: "",
      Reference: "Total",
      Credit: statecredit,
      Debit: statedebit,
      Balance: stateledgerdata[stateledgerdata.length - 1]?.AccountBalance,
      Type: "",
    });
    setCsvData(arr);
  }, [stateledgerdata, openingbaldate, stateopeningbal, stateopenbalancetype]);
  // useEffect(() => {
  //     if (TransactionData?.length > 0) {
  //         setshowTransactions(true);

  //     }
  // }, [TransactionData])

  return (
    <>
      <div hidden>
        <GeneralLedgerPrint
          ref={componentRef}
          statecredit={statecredit}
          statedebit={statedebit}
          printData={stateledgerdata}
          stateopeningbal={stateopeningbal}
          balance={stateledgerdata[
            stateledgerdata.length - 1
          ]?.AccountBalance?.toLocaleString()}
          accountTitle={accountTitle}
          entrydate={
            document.getElementById("todate") !== null
              ? document.getElementById("todate").value
              : null
          }
          stateopenbalancetype={stateopenbalancetype}
          stateaccountdata={stateaccountdata}
          statefromdate={statefromdate}
          statetodate={statetodate}
        />
      </div>
      <div className="row   app_font">
        <div className="dash_background col-12  pt-2">
          <div className="row ">
            {localStorage.getItem("ProjectId") ? null : (
              <>
                <div className="col-md-1 pr-0 pl-0">
                  <label className="input_label m-0">Select Project:</label>
                </div>
                <div className="col-md-2 pr-0 pl-0">
                  <input
                    type="text"
                    className="input_styling "
                    value={`${selectedProject} selected`}
                    onClick={() => setshowProjects(true)}
                  ></input>
                </div>
              </>
            )}

            <div className="col-md-1 pr-0 pl-0">
              <label className="input_label m-0">Select Account:</label>
            </div>

            <div className="col-md-2 pr-0 pl-0">
              <input
                type="text"
                list="autosuggeslist"
                className="input_styling "
                id="autosugges"
                onChange={onTextChangeAutoSuggestion}
              ></input>
              <datalist id="autosuggeslist">
                {resultsallaccount?.data?.map((x, idx) => (
                  <option
                    index={idx}
                    value={x.AccountTitle + " " + x.AccountId}
                  >
                    {x.HeadingTitle}
                  </option>
                ))}
              </datalist>
              <span id="demo"> </span>
            </div>
            <div className="col-md-2 text-center  pr-0 pl-0">
              <label className="input_label m-0">From:</label>
              <input
                type="date"
                className="input_date ml-3"
                id="fromdate"
                defaultValue={localStorage.getItem("OpeningYear")}
              ></input>
            </div>
            <div className="col-md-2 text-center pr-0 pl-0">
              <label className="input_label m-0">To:</label>
              <input
                type="date"
                className="input_date ml-3"
                id="todate"
                defaultValue={moment().format("YYYY-MM-DD")}
              ></input>
            </div>
            <div className="col-md-1 pr-0 pl-0">
              <button className="btn_Go" onClick={saveGeneralLedger} id="go">
                Go
              </button>
            </div>
          </div>

          {loader && (
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          )}

          <div className=" col-12  table_height pr-0 pl-0">
            <div className="table-responsive mt-2">
              <table className="table table-borderless m-0">
                <thead>
                  <tr>
                    <th>Sr</th>
                    <th>Date</th>
                    <th>Narration</th>
                    <th>Initiate</th>
                    <th>Reference</th>
                    <th>Debit</th>
                    <th>Credit</th>
                    <th>Balance</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>
                      {moment(openingbaldate?.split("T")[0]).format(
                        "DD-MMM-YYYY"
                      )}
                    </td>

                    <th colSpan={1}>Opening Balance</th>

                    <td></td>
                    <td></td>
                    <th></th>
                    <th></th>
                    {/* <td>{parseFloat(stateopeningbal) < 0 ? parseFloat(stateopeningbal).toLocaleString().split("-")[1] + " Cr" : parseFloat(stateopeningbal).toLocaleString() + "Dr"}</td> */}
                    <td>
                      {stateopeningbal !== undefined
                        ? parseFloat(stateopeningbal).toLocaleString()
                        : 0}
                    </td>

                    <td>{stateopenbalancetype}</td>
                  </tr>

                  {stateledgerdata?.map((val, index) => (
                    <tr
                      onClick={() => {
                        // setshowTransactions(true);
                        getTransactions(val.VoucherId);
                      }}
                      key={index}
                    >
                      <td>{index + 2}</td>
                      <td>
                        {moment(val.EntryDate.split("T")[0]).format(
                          "DD-MMM-YYYY"
                        )}
                      </td>
                      <td>{val.Narration}</td>
                      <td>{val.InitProjectName?.ProjectName}</td>
                      <td>{val.VoucherId}</td>
                      {val.TransactionType === "DEBIT" ? (
                        <td>{parseFloat(val.Amount).toLocaleString()}</td>
                      ) : (
                        <td></td>
                      )}
                      {val.TransactionType === "CREDIT" ? (
                        <td>{parseFloat(val.Amount).toLocaleString()}</td>
                      ) : (
                        <td></td>
                      )}
                      {/* <td>{parseFloat(val.AccountBalance).toLocaleString()}</td> */}
                      <td>
                        {val.AccountBalance < 0
                          ? parseFloat(val.AccountBalance)
                              .toLocaleString()
                              .split("-")[1]
                          : parseFloat(val.AccountBalance).toLocaleString()}
                      </td>
                      <td>{val.BalanceType}</td>
                    </tr>
                  ))}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      {" "}
                      <label className="input_label m-0">Total:</label>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="input_total_styling "
                        placeholder="Debit"
                        disabled
                        defaultValue={parseFloat(statedebit).toLocaleString()}
                        key={statedebit}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="input_total_styling "
                        placeholder="Credit"
                        disabled
                        defaultValue={parseFloat(statecredit).toLocaleString()}
                        key={statecredit}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="input_total_styling "
                        placeholder="Balance"
                        disabled
                        value={stateledgerdata[
                          stateledgerdata.length - 1
                        ]?.AccountBalance?.toLocaleString()}
                      ></input>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12  text-right pr-0 pl-0">
            <button
              disabled={stateledgerdata.length > 0 ? false : true}
              className="print_btn float-left"
              onClick={() => {
                printPaymentRec();
              }}
            >
              <TiPrinter size="1.3em" />
              Print
            </button>

            {stateledgerdata.length > 0 && (
              <button
                style={{ color: "black !important" }}
                className="print_btn ml-2 float-left"
              >
                <CSVLink
                  style={{ color: "black" }}
                  filename={`${accountTitle} Legder Csv`}
                  data={csvData}
                >
                  Download CSV
                </CSVLink>
              </button>
            )}
          </div>
        </div>
      </div>
      {showProjects && (
        <div style={{ display: "block" }} class="modalscreen ">
          <div class="modal-content-screen1 pt-1">
            <div className="row ">
              <div className="col-12  page_heading">
                <h4 className="  text-center ">Projects</h4>
                <span
                  class="close pr-2  "
                  onClick={() => {
                    setshowProjects(false);
                  }}
                >
                  &times;
                </span>
              </div>
            </div>
            <div className="row">
              <div className="table-responsive mt-4">
                <table className="table table-borderless m-0">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Project Name</th>
                      <th> Category</th>
                      <th> Owner</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {allPropjects !== undefined
                      ? allPropjects?.map((val, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{val.ProjectName}</td>
                            <td>
                              {val.ProjectCategory !== undefined &&
                              val.ProjectCategory !== null
                                ? val.ProjectCategory.ProjectCategoryName
                                : null}
                            </td>
                            <td>{val.ProjectOwner}</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={val.check ? val.check : false}
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setselectedProject(selectedProject + 1);
                                  } else {
                                    setselectedProject(selectedProject - 1);
                                  }
                                  val.check = e.target.checked;
                                  setrenderComp(!renderComp);
                                }}
                              />
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      {showTransactions && (
        <div id="mymodal" style={{ display: "block" }} class="modal">
          <div class="modal-content-cat_voucher">
            <div className="row">
              <div className="col-12  page_heading">
                <h4 className="  text-center pt-2">Transaction Detail</h4>
                <span
                  class="close pr-2  "
                  onClick={() => {
                    setshowTransactions(false);
                  }}
                >
                  &times;
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mt-2">
                <div className="table-responsive_modal mt-4 table_height-voucher">
                  <table className="table table-borderless m-0 ">
                    <thead>
                      <tr>
                        <th>Sr</th>
                        <th>Account</th>
                        <th>Narration</th>
                        <th>Debit</th>

                        <th>Credit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {TransactionData !== undefined
                        ? TransactionData.map((x, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{x.Account.AccountTitle}</td>
                              <td>{x.Narration}</td>
                              <td>
                                {x.TransactionType === "DEBIT"
                                  ? x.Amount.toLocaleString()
                                  : null}
                              </td>

                              <td>
                                {x.TransactionType === "CREDIT"
                                  ? x.Amount.toLocaleString()
                                  : null}
                              </td>
                            </tr>
                          ))
                        : null}
                      <tr>
                        <td></td>
                        <td></td>

                        <td>
                          {" "}
                          <label className="input_label m-0">Total:</label>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="input_total_styling "
                            placeholder="Debit"
                            disabled
                            defaultValue={parseFloat(
                              transactionDebitTotal
                            ).toLocaleString()}
                          ></input>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="input_total_styling "
                            placeholder="Credit"
                            disabled
                            defaultValue={parseFloat(
                              transactionCreditTotal
                            ).toLocaleString()}
                          ></input>
                        </td>
                        {/* <td><input type="text" className="input_total_styling " placeholder="Balance" disabled value={stateledgerdata[stateledgerdata.length - 1]?.AccountBalance?.toLocaleString()}></input></td> */}
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-12 mt-2">
                {/* <button className="print_btn float-left" onClick={() => { printGenrealRecModal(); }}><TiPrinter size="1.3em" />Print</button> */}
              </div>
            </div>
          </div>
        </div>
      )}
      {screenLoader && <FullScreenLoader setshowModal={setscreenLoader} />}
    </>
  );
};

export default GeneralLedger;
