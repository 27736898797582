import axios from "axios";
import { toast } from "react-toastify";
export const GetAllGeneralRec = (data) => {
  return {
    type: "get_allgeneralrec",
    payload: data,
    loader: false,
  };
};
export function getallgeneralrec(data, link) {
  return (dispatch) => {
    let pgurl = `${
      link
        ? link + "&"
        : process.env.REACT_APP_API_URL + "Vouchers/Receipt/General/GetAll?"
    }ProjectId=${localStorage.getItem("ProjectId")}&From=${
      data.From ? data.From : ""
    }&To=${data.To ? data.To : ""}&Status=${
      data.Status ? data.Status : ""
    }&FilterDate=${data.FilterDate ? data.FilterDate : ""}&VoucherId=${
      data.VoucherId ? data.VoucherId : ""
    }`;

    if (localStorage.getItem("ProjectId") === null) {
      pgurl = `${process.env.REACT_APP_API_URL}Vouchers/Receipt/General/GetAll`;
    }
    axios({
      url: pgurl,
      method: "Post",
      data: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.Succeeded === true) {
          dispatch(GetAllGeneralRec(result.data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
