import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { useEffect } from "react";
import { getallsellers } from "../../../actions/inventoryactions/addseller";
import { getallProject } from "../../../actions/getprojects";
import moment from "moment";
import { BsCloudUpload } from "react-icons/bs";
import { getallrepurchasepopulate } from "../../../actions/inventoryactions/repurchaseunit";
import { Spinner } from "react-bootstrap";
import Loader from "react-loader-spinner";

export const RePurchase = (props) => {
  // const resultsallproject = useSelector((state) => state.getProject);
  const resultallsellers = useSelector((state) => state.getAllSellers);
  const contractprojectpopulatedata = useSelector(
    (state) => state.getRePurchasePopulate
  );
  const [stateprojectid, setprojectid] = useState(0);
  const [marlafieldchange, setmarlafieldchange] = useState();
  const [valmarlas, setMarlas] = useState();
  const [unitlist, setunitlist] = useState([]);
  const [UnitId, setUnitId] = useState(0);
  const [selectedProjectOwner, setselectedProjectOwner] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [customerAvailable, setcustomerAvailable] = useState(false);
  const [customerName, setcustomerName] = useState("");
  const [customerCnic, setcustomerCnic] = useState("");
  console.log(Boolean(customerAvailable));
  const dispatch = useDispatch();
  const [state, setstate] = useState({
    unitpurchase: {
      UnitIdOld: 0,
      PurchaseRate: null,
      TotalAmount: null,

      EntryDate: null,
      SellerId: 0,
    },
  });
  // project on change
  console.log(selectedProjectOwner);
  const setunits = (e) => {
    const projectId = e.target.selectedOptions[0].getAttribute("data-id");
    setSelectedProject(projectId);
    if (e.target.value === "Select") {
      document.getElementById("contractShop").readOnly = true;
      document.getElementById("contractShop").placeholder =
        "Select Project First";
    } else {
      setselectedProjectOwner(
        document.getElementById("contractproject").options[
          document.getElementById("contractproject").selectedIndex
        ].dataset?.projectowner
      );
      document.getElementById("contractShop").readOnly = false;
      document.getElementById("contractShop").placeholder = "";
      // setprojectadvanceper(document.getElementById("contractproject").options[document.getElementById("contractproject").selectedIndex].dataset.advpay);
      setprojectid(
        document.getElementById("contractproject").options[
          document.getElementById("contractproject").selectedIndex
        ].dataset.id
      );
      let value = e.target.value;

      contractprojectpopulatedata.data.map((x) => {
        if (x.ProjectName.trim() === value.trim()) {
          // setDuration(x.Installementduration);
          if (x.Units != null) {
            setunitlist(x.Units);
          } else {
            setunitlist([]);
            setMarlas("");
            document.getElementById("NetMarla").value = "";
          }
        }
      });
    }
    if (e.target.value) {
      setMarlas("");
      //  document.getElementById("Date").value = "";
      document.getElementById("contractShop").value = "";
      document.getElementById("PurchaseRate").value = "";
      document.getElementById("TotalAmount").value = "";
      // document.getElementById("NetMarla").value = "";
    }
  };

  // const setunits = (e) => {
  //   const projectId = e.target.selectedOptions[0].getAttribute("data-id");
  //   setSelectedProject(projectId);
  //   if (e.target.value === "Select") {
  //     document.getElementById("contractShop").readOnly = true;
  //     document.getElementById("contractShop").placeholder = "Select Project First";
  //   } else {
  //     setselectedProjectOwner(
  //       document.getElementById("contractproject").options[
  //         document.getElementById("contractproject").selectedIndex
  //       ].dataset?.projectowner
  //     );
  //     document.getElementById("contractShop").readOnly = false;
  //     document.getElementById("contractShop").placeholder = "";

  //     setprojectid(
  //       document.getElementById("contractproject").options[
  //         document.getElementById("contractproject").selectedIndex
  //       ].dataset.id
  //     );
  //     console.log(e.target.value);
  //     const selectedUnit = e.target.value;
  //     const selectedUnitId = parseInt(e.target.selectedOptions[0].getAttribute("data-unit-id")) + 1;

  //     contractprojectpopulatedata.data.map((x) => {
  //       if (x.ProjectName.trim() === selectedUnit.trim()) {
  //         if (x.Units != null) {
  //           setunitlist(x.Units);
  //         }

  //         setUnitId(selectedUnitId);

  //         document.getElementById("Date").value = "";
  //         document.getElementById("contractShop").value = "";
  //         document.getElementById("PurchaseRate").value = "";
  //         document.getElementById("TotalAmount").value = "";
  //         setMarlas(null);
  //       }
  //     });
  //   }
  // };
  // unit on change function
  var suggestionlist1 = [];
  // const UnitonTextChange = (e) => {
  //   const value = e.target.value;
  //   if (value == "") {
  //     ReactDOM.render(null, document.getElementById("unitdemo"));
  //   } else {
  //     let suggestions = [];
  //     if (value.length > 0) {
  //       const regex = new RegExp(`${value}`, "i");
  //       suggestions = unitlist
  //         .sort()
  //         .filter((v) => regex.test(v.UnitName !== null ? v.UnitName : null));
  //     }
  //     suggestionlist1 = suggestions;

  //     if (suggestionlist1.length === 0) {
  //       ReactDOM.render(null, document.getElementById("unitdemo"));
  //     } else {
  //       ReactDOM.render(
  //         <ul className="suggestbox">
  //           {suggestionlist1.map((item) => (
  //             <li className="listcss" onClick={() => suggestionselected1(item)}>
  //               {item === undefined ? item : item.UnitName}
  //             </li>
  //           ))}
  //         </ul>,
  //         document.getElementById("unitdemo")
  //       );
  //     }
  //   }
  // };
  const onTextChangeContractShop = (e) => {
    var inputValue = document.getElementById("contractShop").value;
    var options = document.getElementById("contractshoplist").childNodes;
    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        unitlist.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });
        console.log(val);
        document.getElementById("PurchaseRate").value = parseFloat(
          val.CostPerMarla
        ).toLocaleString();
        document.getElementById("TotalAmount").value = parseFloat(
          val.TotalCost
        ).toLocaleString();
        setUnitId(val?.UnitId);
        setcustomerName(val?.Contract?.CustomerCnicNavigation?.CustomerName);
        setcustomerCnic(val?.Contract?.CustomerCnicNavigation?.CustomerCnic);

        // setNetAmount(val.netAmount);
        // setNetTotalAmount(val.netAmount);
        console.log(val.RatePerMarlaOrsqft);
        let valM;
        if (val.RatePerMarlaOrsqft === "MARLA") {
          console.log(val);
          let marla = val.Marla.toString();
          let sarsai = val.Sarsai.toString();
          let marlasarsai = marla.concat(".", sarsai, sarsai, sarsai, sarsai);
          valM = parseFloat(marlasarsai);

          setMarlas(parseFloat(marlasarsai));
          setmarlafieldchange("Marla");
        }
        if (val.RatePerMarlaOrsqft == "SQFT") {
          valM = parseFloat(val.NetSqft);
          setMarlas(parseFloat(val.NetSqft));
          setmarlafieldchange("Sqft");
        }
        break;
      }
    }
  };

  const suggestionselected1 = (val) => {
    document.getElementById("contractShop").value = val.UnitName;
    document.getElementById("PurchaseRate").value = parseFloat(
      val.CostPerMarla
    ).toLocaleString();
    document.getElementById("TotalAmount").value = parseFloat(
      val.TotalCost
    ).toLocaleString();

    setUnitId(val.UnitId);
    // setNetAmount(val.netAmount);
    // setNetTotalAmount(val.netAmount);
    let valM;
    if (val.RatePerMarlaOrsqft == "MARLA") {
      let marla = val.Marla.toString();
      let sarsai = val.Sarsai.toString();
      let marlasarsai = marla.concat(".", sarsai, sarsai, sarsai, sarsai);
      valM = parseFloat(marlasarsai);
      console.log("marla");
      setMarlas(parseFloat(marlasarsai));
      setmarlafieldchange("Marla");
    }
    if (val.RatePerMarlaOrsqft == "SQFT") {
      valM = parseFloat(val.NetSqft);
      console.log("sqft");

      setMarlas(parseFloat(val.NetSqft));
      setmarlafieldchange("Sqft");
    }
    // setMarlas(parseFloat(val.valmarlas));

    // setcostprice(parseFloat(val.NetAmount));

    suggestionlist1 = [];
    ReactDOM.render(null, document.getElementById("unitdemo"));
  };
  //     const savedata = () => {
  //       if (
  //         document.getElementById("contractproject").selectedIndex === 0 ||
  //         document.getElementById("seller").selectedIndex === 0
  //       ) {
  //         toast.info("Select Project and Seller");
  //       } else if (document.getElementById("contractShop").value === "") {
  //         toast.info("Enter Unit Name");
  //       } else if (document.getElementById("TotalAmount").value === "") {
  //         toast.info("Enter Total Amount");
  //       } else if (document.getElementById("PurchaseRate").value === "") {
  //         toast.info("Enter Purchase Rate");
  //       } else {
  //         let data = state;
  //         data.ProjectId = parseInt(
  //           document.getElementById("contractproject").options[
  //             document.getElementById("contractproject").selectedIndex
  //           ].dataset.id
  //         );
  //         data.SellerId = parseInt(
  //           document.getElementById("seller").options[
  //             document.getElementById("seller").selectedIndex
  //           ].dataset.id
  //         );
  //         data.UnitId = UnitId;
  //         data.TotalAmount = parseFloat(
  //           document.getElementById("TotalAmount").value.replace(/,/g, "")
  //         );
  //         data.EntryDate = document.getElementById("Date").value;
  //         data.PurchaseRate = parseFloat(
  //           document.getElementById("PurchaseRate").value.replace(/,/g, "")
  //         );
  //         {

  //         }

  // if(selectedProjectOwner === "OTHER"){
  //   data.PurchaseCost  = parseFloat(
  //     document.getElementById("PurchaseCost").value.replace(/,/g, "")
  //   )

  // }
  //         document.getElementById("projectsave").disabled = true;

  //         axios({
  //           method: "post",
  //           url: `${process.env.REACT_APP_API_URL}UnitPurchase/AddRepurchaseUnit`,
  //           data: JSON.stringify(data),
  //           headers: {
  //             Authorization: `Bearer ${localStorage.getItem("token")}`,
  //             "content-type": "application/json",
  //           },
  //         }).then(
  //           (result) => {
  //             document.getElementById("projectsave").disabled = false;
  //             if (result.data.IsSuccess === true) {
  //               document.getElementById("contractproject").selectedIndex = 0;
  //               document.getElementById("seller").selectedIndex = 0;

  //               document.getElementById("PurchaseRate").value = "";
  //               document.getElementById("TotalAmount").value = "";
  //               document.getElementById("contractShop").value = "";

  //               setprojectid(0);

  //               toast.info(result.data.Message);
  //               props.cancelprojecttable();
  //             } else {
  //               toast.info(result.data.Message);
  //             }
  //           },
  //           (error) => {
  //             document.getElementById("projectsave").disabled = false;

  //           }
  //         );
  //       }
  //     };

  console.log(customerAvailable);
  const savedata = () => {
    if (
      document.getElementById("contractproject").selectedIndex === 0
      // document.getElementById("seller").selectedIndex === 0
    ) {
      toast.info("Select Project and Seller");
    } else if (document.getElementById("contractShop").value === "") {
      toast.info("Enter Unit Name");
    } else if (document.getElementById("TotalAmount").value === "") {
      toast.info("Enter Total Amount");
    } else if (document.getElementById("PurchaseRate").value === "") {
      toast.info("Enter Purchase Rate");
    } else {
      setIsLoading(true);

      let data = state;
      data.unitpurchase.ProjectId = parseInt(
        document.getElementById("contractproject").options[
          document.getElementById("contractproject").selectedIndex
        ].dataset.id
      );

      if (customerAvailable === false) {
        data.unitpurchase.SellerId = parseInt(
          document.getElementById("seller").options[
            document.getElementById("seller").selectedIndex
          ].dataset.id
        );
      }
      if (customerAvailable === true) {
        data.unitpurchase.CustomerCnic = customerCnic;
      }

      data.IsBuyer = customerAvailable;

      data.unitpurchase.UnitIdOld = UnitId;
      data.unitpurchase.TotalAmount = parseFloat(
        document.getElementById("TotalAmount").value.replace(/,/g, "")
      );
      data.unitpurchase.EntryDate = document.getElementById("Date").value;
      data.unitpurchase.PurchaseRate = parseFloat(
        document.getElementById("PurchaseRate").value.replace(/,/g, "")
      );

      if (selectedProjectOwner === "OTHER") {
        data.unitpurchase.PurchaseCost = parseFloat(
          document.getElementById("PurchaseCost").value.replace(/,/g, "")
        );
      }
      document.getElementById("projectsave").disabled = true;

      if (customerAvailable === true) {
        delete data.unitpurchase.SellerId;
      }
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}UnitPurchase/AddRepurchaseUnit`,
        data: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          setIsLoading(false);
          document.getElementById("projectsave").disabled = false;
          if (result.data.IsSuccess === true) {
            document.getElementById("contractproject").selectedIndex = 0;
            if (document.getElementById("seller")) {
              document.getElementById("seller").selectedIndex = 0;
            }
            setcustomerName("");
            document.getElementById("PurchaseRate").value = "";
            document.getElementById("TotalAmount").value = "";
            document.getElementById("contractShop").value = "";

            setprojectid(0);

            toast.info(result.data.Message);
            props.cancelprojecttable();
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          setIsLoading(false);

          document.getElementById("projectsave").disabled = false;
        }
      );
    }
  };
  useEffect(() => {
    dispatch(getallProject());
    dispatch(getallsellers());
    dispatch(getallrepurchasepopulate());
  }, [dispatch]);
  console.log(contractprojectpopulatedata?.data);
  return (
    <>
      {/* ProjectOwner */}

      <div className="row ">
        <div className="col-md-1 pr-0 pl-0"></div>
        <div className="col-md-12  text-left page_heading">
          <h4 className="m-0">RePurchase Unit</h4>
        </div>
        <div className="col-md-12 text-right">
          <span>
            {contractprojectpopulatedata.data.length <= 0 ? (
              <Loader type="ThreeDots" color="green" height={40} width={40} />
            ) : null}
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0 ">Project Name:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <select
            id="contractproject"
            className="input_styling  ml-md-3"
            onChange={setunits}
            // disabled={contractprojectpopulatedata.data.isLoading}
          >
            <option>Select Project</option>

            {contractprojectpopulatedata?.data !== undefined
              ? contractprojectpopulatedata?.data?.map((x, idx) => (
                  <option
                    key={idx}
                    value={x.ProjectName}
                    data-projectOwner={x?.ProjectOwner}
                    data-id={x.ProjectId}
                  >
                    {x.ProjectName}
                  </option>
                ))
              : null}
          </select>
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-4  p-0"></div>
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0 ">Date:</label>
        </div>
        <div className="col-md-3 mt-4  p-0">
          <input
            type="date"
            className="input_date_styling  ml-md-3"
            id="Date"
            defaultValue={moment().format("YYYY-MM-DD")}
          ></input>
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0 ">Unit:</label>
        </div>

        <div className="col-md-3 mt-4   p-0">
          <span>
            <input
              type="text"
              id="contractShop"
              placeholder="Select Project First"
              className="input_styling ml-md-3 "
              onChange={onTextChangeContractShop}
              autoComplete="off"
              list="contractshoplist"
            ></input>
            <span className="estaric">*</span>
            <datalist id="contractshoplist">
              {unitlist?.map((x, idx) => {
                console.log();
                return (
                  <option index={idx} value={x.UnitName} data-unit-id={x.id}>
                    {x.TotalCost}
                  </option>
                );
              })}
            </datalist>
          </span>
          <span id="unitdemo"></span>
        </div>
        <div className="col-md-2 mt-4  p-0"></div>
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">
            {marlafieldchange === "Marla" ? "Marla" : "SQft"}
          </label>
        </div>
        <div className="col-md-3 mt-4   p-0">
          <input
            // type="number"
            className="input_styling ml-md-3 "
            id="NetMarla"
            defaultValue={valmarlas}
            onChange={(e) => {
              if (e.target.value.length > 0) {
                document.getElementById("TotalAmount").value = parseFloat(
                  parseFloat(e.target.value.replace(/,/g, "")) *
                    parseFloat(
                      document
                        .getElementById("PurchaseRate")
                        .value.replace(/,/g, "")
                    )
                ).toLocaleString();
                document.getElementById("NetMarla").value = parseInt(
                  e.target.value.replace(/,/g, "")
                ).toLocaleString();
              } else {
                document.getElementById("TotalAmount").value = "";
              }
            }}
          />
          <span className="estaric">*</span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0 ">Purchase Rate:</label>
        </div>

        <div className="col-md-3 mt-4   p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            id="PurchaseRate"
            onChange={(e) => {
              if (e.target.value.length > 0) {
                document.getElementById("TotalAmount").value = parseFloat(
                  parseFloat(e.target.value.replace(/,/g, "")) *
                    document.getElementById("NetMarla").value
                ).toLocaleString();
                document.getElementById("PurchaseRate").value = parseInt(
                  e.target.value.replace(/,/g, "")
                ).toLocaleString();
              } else {
                document.getElementById("TotalAmount").value = "";
              }
            }}
          />
          <span className="estaric">*</span>
        </div>
        <div className="col-md-2 mt-4  p-0"></div>

        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Total Amount:</label>
        </div>
        <div className="col-md-3 mt-4   p-0">
          <input
            type="text"
            className="input_styling ml-md-3 "
            disabled
            id="TotalAmount"
          />
          <span className="estaric">*</span>
        </div>
      </div>
      {/* <div className="row">
          {
            selectedProjectOwner==="PERSONAL"?
       <>
        <div className="col-md-2 mt-4 text-left p-0">
            <label className="input_label m-0">Customer Available:</label>
          </div>
          <div className="col-md-3 mt-4   p-0">
          <input
            type="checkbox"
            id="CustomerAvailCheck"
            style={{marginLeft:"15px"}}
            onClick={() => {
              setcustomerAvailable(!customerAvailable)

            }}
          />

          </div>
          </>
          :        
            selectedProjectOwner === "OTHER"?
              <>
              <div className="col-md-2 mt-4 text-left p-0">
                <label className="input_label m-0">Purchase Cost:</label>
              </div>
              <div className="col-md-3 mt-4   p-0">
                <input
                  type="number"
                  className="input_styling ml-md-3 "
                  id="PurchaseCost"
                />
                <span className="estaric">*</span>
              </div>
              </>:null
              
             } */}

      <div className="row">
        <div className="col-md-2 mt-4 text-left p-0">
          <label className="input_label m-0">Customer Available:</label>
        </div>
        <div className="col-md-3 mt-4   p-0">
          <input
            type="checkbox"
            id="CustomerAvailCheck"
            style={{ marginLeft: "15px" }}
            onClick={() => {
              setcustomerAvailable(!customerAvailable);
            }}
          />
        </div>
        <div className="col-md-2 mt-4  p-0"></div>
        {/* {selectedProjectOwner === "OTHER" || selectedProjectOwner === "PERSONAL"?<div className="col-md-2 mt-4  p-0"></div>:null}  */}
        {customerAvailable === true ? (
          <>
            <div className="col-md-2 mt-4 text-left p-0">
              <label className="input_label m-0">Customer Name:</label>
            </div>
            <div className="col-md-3 mt-4   p-0">
              <input
                type="text"
                className="input_styling ml-md-3 "
                disabled
                value={customerName}
              />
              {/* <select className="input_styling ml-md-3" id="seller">
            <option>Select</option>
            {resultallsellers.data.map((val, index) =>
              val.ProjectSellers.map((x) =>
                parseInt(x.ProjectId) == parseInt(stateprojectid) ? (
                  <option data-id={val.SellerId}>{val.SellerName}</option>
                ) : null
              )
            )}
          </select> */}
              {/* <span className="estaric">*</span> */}
            </div>
          </>
        ) : (
          <>
            {/* {selectedProjectOwner === "PERSONAL"?<div className="col-md-2 mt-4  p-0"></div>:null}  */}
            <div className="col-md-2 mt-4 text-left p-0">
              <label className="input_label m-0">Seller:</label>
            </div>
            <div className="col-md-3 mt-4   p-0">
              <select className="input_styling ml-md-3" id="seller">
                <option>Select</option>
                {resultallsellers.data.map((val, index) =>
                  val.ProjectSellers.map((x) =>
                    parseInt(x.ProjectId) == parseInt(stateprojectid) ? (
                      <option data-id={val.SellerId}>{val.SellerName}</option>
                    ) : null
                  )
                )}
              </select>
              {/* <span className="estaric">*</span> */}
            </div>
          </>
        )}
        {/*               
              <div className="row" >
               <div className="col-md-2 mt-4 text-left p-0">
            <label className="input_label m-0" >Purchase Cost:</label>
          </div>
           <div className="col-md-6 mt-4 p-0" >
             <input
               type="number"
               className="input_styling ml-md-2 "
               id="PurchaseCost"
             />
             <span className="estaric">*</span>
           </div>
           </div>   */}

        {/* {
selectedProjectOwner === "OTHER"?
  <>
  <div className="col-md-2 mt-4 text-left p-0">
    <label className="input_label m-0">Purchase Cost:</label>
  </div>
  <div className="col-md-3 mt-4   p-0">
    <input
      type="number"
      className="input_styling ml-md-3 "
      id="PurchaseCost"
    />
    <span className="estaric">*</span>
  </div>
  </>:null
  } */}
      </div>
      <div className="row">
        {selectedProjectOwner === "OTHER" ? (
          <>
            <div className="col-md-2 mt-4 text-left p-0">
              <label className="input_label m-0">Purchase Cost:</label>
            </div>
            <div className="col-md-3 mt-4   p-0">
              <input
                type="number"
                className="input_styling ml-md-3 "
                id="PurchaseCost"
              />
              <span className="estaric">*</span>
            </div>
          </>
        ) : null}
      </div>

      <div className="row pr-md-2 pt-md-5">
        <div className=" col-6 pr-0 pl-0  "></div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
          className=" col-6 pr-0 pl-0  text-right "
        >
          <span>
            <button
              className="cancel_btn ml-2  "
              onClick={props.cancelprojecttable}
            >
              Cancel
            </button>
          </span>
          <span className="ml-2">
            {isLoading ? (
              <>
                <Loader
                  type="Oval"
                  color="black"
                  height={30}
                  width={30}
                  radius={12}
                />
              </>
            ) : null}
          </span>
          <span>
            <button
              className="save_btn ml-2"
              id="projectsave"
              onClick={savedata}
              disabled={isLoading}
            >
              <>
                <BsCloudUpload size="1.3em" className="mr-2" />
                Save
              </>
            </button>
          </span>
          {/* <span>
              <button
                className="save_btn ml-2  "
                id="projectsave"
                onClick={savedata}
              >
                <BsCloudUpload size="1.3em" />
                Save
              </button>
            </span> */}
        </div>
      </div>
    </>
  );
};
