import axios from "axios";
import moment from "moment";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { GrPrint } from "react-icons/gr";
import { MdDeleteForever } from "react-icons/md";
import { RiFileEditLine } from "react-icons/ri";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { getallComPaypopulatetable } from "../../../actions/vouchersactions/getcompayment";
import { CommissionPaymentPrint } from "../../accpayablepages/vouchersprints/paymentprint";
import CommossionPaymentDeleteVoucherModal from "./ComissionPaymentDeleteVoucherModal";
import CommissionPaymentTransactionDetailModal from "./CommissionPaymentTransactionDetailModal";
import { CSVLink } from "react-csv";
import CommissionPaymentModal from "./CommissionPaymentModal";
import useDownloadCsv from "../../../actions/downloadCsvHook";
import { getAllContractTablePopulate } from "../../../actions/accountsactions/addcontract";

export const CommissionPaymentTable = (props) => {
  const resultsrrtable = useSelector(
    (state) => state.getAllCommissionPaymentTable
  );
  const [TransactionData, setTransactionData] = useState();
  const dispatch = useDispatch();
  const [filterdata, setfilterdata] = useState();
  const [statetoggle, settoggle] = useState(true);
  const [printData, SetPrintData] = useState();
  const [debitState, setdebitState] = useState(0);
  const [creditState, setcreditState] = useState(0);
  const [SelecetdVoucher, setSelecetdVoucher] = useState(null);
  const [selectedVouchers, setSelectedVouchers] = useState([]);
  const [rendarComp, setrendarComp] = useState(false);
  console.log(SelecetdVoucher);
  const rolesdata = useSelector((state) => state.GetAllRole);
  const [allLinks, setallLinks] = useState(null);
  const [data, downloadCsv] = useDownloadCsv();
  const [unitId, setunitId] = useState("");
  const [allCustomers, setallCustomers] = useState([]);
  const [allUnits, setallUnits] = useState([]);
  const [customerCnic, setcustomerCnic] = useState("");

  const resultsallcontractspopulate = useSelector(
    (state) => state.getContractTablePopulate
  );
  useEffect(() => {
    if (resultsallcontractspopulate.data) {
      setallCustomers(resultsallcontractspopulate.data?.Buyers);
      setallUnits(resultsallcontractspopulate.data?.Units);
    }
  }, [resultsallcontractspopulate]);
  const [filterObject, setfilterObject] = useState({
    date: "",
    voucherNumber: "",
    seller: "",
    unit: "",
    status: "",
  });
  const onTextChangeUnit = () => {
    var inputValue = document.getElementById("unitfilterId").value;
    var options = document.getElementById("unitfilterIdOpt").childNodes;
    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        allUnits.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });

        // document.getElementById("City").value = val.Nominee.NomineeCity;
        // document.getElementById("Country").value = val.Nominee.NomineeCountry;
        setunitId(val.UnitId);

        break;
      }
      setunitId("");
    }
  };
  const onTextChangeAffiliate = () => {
    var inputValue = document.getElementById("buyerfilterId").value;
    var options = document.getElementById("buyerfilterIdOpt").childNodes;
    let val;
    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        allCustomers.forEach((x, idx) => {
          if (idx == options[i].attributes.index.value) {
            val = x;
          }
        });

        // document.getElementById("City").value = val.Nominee.NomineeCity;
        // document.getElementById("Country").value = val.Nominee.NomineeCountry;
        console.log(val);
        setcustomerCnic(val.CustomerCnicNavigation?.CustomerCnic);
        break;
      }
      setcustomerCnic("");
    }
  };
  /////////// Delete Voucher Data and functions Start ///////////////
  const [DeleteVoucherData, setDeleteVoucherData] = useState({
    Email: "",
    Password: "",
    Reason: "",
  });
  const [showPassword, setshowPassword] = useState(false);
  const [loading, setloading] = useState(false);

  const showmodal2 = (data) => {
    setSelecetdVoucher(data);
    document.getElementById("DeleteVoucherModal").style.display = "block";
  };

  const closeDeleteVoucherModal = () => {
    document.getElementById("DeleteVoucherModal").style.display = "none";
    setSelecetdVoucher(null);
    setDeleteVoucherData({
      Email: "",
      Password: "",
      Reason: "",
    });
  };

  const handelDeleteVoucherdataChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setDeleteVoucherData({ ...DeleteVoucherData, [name]: value });
  };

  function deleteVoucher() {
    if (DeleteVoucherData?.Email === "") {
      return toast.error("Email is Required");
    } else if (DeleteVoucherData?.Password === "") {
      return toast.error("Password is Required");
    } else if (DeleteVoucherData?.Reason === "") {
      return toast.error("Reason is Required");
    }
    setloading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}Scripts/DeleteAllUnsubmittedVouchers`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
      data: {
        Email: DeleteVoucherData?.Email,
        Password: DeleteVoucherData?.Password,
        Reason: DeleteVoucherData?.Reason,
        voucherId: selectedVouchers,
      },
    }).then(
      (result) => {
        console.log(result);
        setloading(false);
        if (result.data.IsSuccess === true) {
          setDeleteVoucherData({
            Email: "",
            Password: "",
            Reason: "",
          });
          toast.info(result.data.Message);
          setSelectedVouchers([]);
          dispatch(getallComPaypopulatetable({}));
          closeDeleteVoucherModal();
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Someting went wrong");
      }
    );
  }

  function handleCheckboxChange(i) {
    let arr = [];

    filterdata?.forEach((itm) => {
      if (itm.checked) {
        arr.push(itm.CommissionPaymentVoucherId);
      }
    });
    setSelectedVouchers(arr);
  }

  /////////// Delete Voucher Data and functions End ///////////////
  // print function
  const componentRef = useRef();

  const printRecoveryRec = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    if (printData !== undefined) {
      printRecoveryRec();
    }
  }, [printData, statetoggle]);
  const [printDataModal, SetPrintDataModal] = useState();
  // print function

  //Table Data fiter
  // var suggestionlist = [];
  // const onTextChange = (e) => {
  //     const value = e.target.value;
  //     if (value === "") {

  //         dispatch(getallComPaypopulatetable({}));
  //     } else {
  //         let suggestions = [];
  //         if (value.length > 0) {
  //             const regex = new RegExp(`${value}`, "i");

  //             suggestions = resultsrrtable.datasetSelectedVouchers
  //                 .sort()
  //                 .filter((val) =>
  //                     (regex.test(val.CommissionPaymentVoucherId) || regex.test(val.AffiliateCnicNavigation !== undefined && val.AffiliateCnicNavigation !== null ? val.AffiliateCnicNavigation.AffiliateName : val.Employee.EmpName)));

  //         }
  //         suggestionlist = suggestions;
  //         // dispatch(GetAllComPayTableData(suggestionlist));
  //         setfilterdata(suggestionlist);

  //     }
  // };
  const onTextChange = (e) => {
    let name = e?.target?.name;
    let value = e?.target?.value;

    setfilterObject({ ...filterObject, [name]: value });
    if (
      document.getElementById("sellerfilter")?.value === "" &&
      document.getElementById("datefilter")?.value === "" &&
      document.getElementById("voucherfilter")?.value === "" &&
      document.getElementById("unitfilter")?.value === "" &&
      document.getElementById("submittedId")?.value === "all"
    ) {
      setfilterdata(resultsrrtable?.data.data);
    }
    // else {
    //   let suggestions = [];
    //   {
    //     const regex = new RegExp(
    //       document.getElementById("sellerfilter").value,
    //       "i"
    //     );
    //     const regex1 = new RegExp(
    //       document.getElementById("voucherfilter").value,
    //       "i"
    //     );
    //     const regex2 = new RegExp(
    //       document.getElementById("datefilter").value,
    //       "i"
    //     );
    //     const regex3 = new RegExp(
    //       document.getElementById("unitfilter").value,
    //       "i"
    //     );

    //     suggestions = resultsrrtable.data.data
    //       .sort()
    //       .filter(
    //         (val) =>
    //           (document.getElementById("sellerfilter").value === "" ||
    //             regex.test(
    //               val.AffiliateCnicNavigation.AffiliateName !== null
    //                 ? val.AffiliateCnicNavigation.AffiliateName
    //                 : val.Employee.EmpName
    //             )) &&
    //           (document.getElementById("voucherfilter").value === "" ||
    //             regex1.test(val.CommissionPaymentVoucherId)) &&
    //           (document.getElementById("datefilter").value === "" ||
    //             regex2.test(val.EntryDate)) &&
    //           (document.getElementById("unitfilter").value === "" ||
    //             regex3.test(val.Unit.UnitName)) &&
    //           (document.getElementById("submittedId").value === "all"
    //             ? val.Submitted === false || val.Submitted === true
    //             : document.getElementById("submittedId").value === "false"
    //             ? val.Submitted === false
    //             : val.Submitted === true)
    //       );
    //   }
    //   setfilterdata(suggestions);
    // }
  };

  function showmodal(data) {
    document.getElementById("mymodal").style.display = "block";
    setTransactionData(data);
    var credit = 0;
    var debit = 0;
    data?.map((val) =>
      val.TransactionType === "CREDIT"
        ? (credit += parseFloat(val.Amount))
        : val.TransactionType === "DEBIT"
        ? (debit += parseFloat(val.Amount))
        : null
    );
    setdebitState(debit);
    setcreditState(credit);
  }
  function closemodal() {
    document.getElementById("mymodal").style.display = "none";
  }
  useEffect(() => {
    let data = {};
    dispatch(getallComPaypopulatetable(data));
    dispatch(getAllContractTablePopulate());
  }, [dispatch]);
  useEffect(() => {
    setfilterdata(resultsrrtable.data);
  }, [resultsrrtable]);
  function ongo() {
    setloading(true);
    let data = {
      ProjectId: `${localStorage.getItem("ProjectId")}`,
      From: document.getElementById("fromdate").value,
      To: document.getElementById("todate").value,
      VoucherId: document.getElementById("voucherfilter").value,
      FilterDate: document.getElementById("datefilter").value,
      UnitId: unitId,
      AffiliateCNIC: customerCnic,
      Status: document.getElementById("submittedId").value,
    };

    dispatch(getallComPaypopulatetable(data));
  }

  const getPaginationData = (link) => {
    let data = {
      ProjectId: Number(`${localStorage.getItem("ProjectId")}`),
      From: document.getElementById("fromdate").value,
      To: document.getElementById("todate").value,
    };
    setloading(true);
    dispatch(getallComPaypopulatetable(data, link));
  };
  useEffect(() => {
    if (resultsrrtable.data) {
      setallLinks(resultsrrtable.data);
      setfilterdata(resultsrrtable.data.data);

      setloading(false);
    }
  }, [resultsrrtable]);
  const [CsvData, setCsvData] = useState([]);
  const [newData, setNewData] = useState([]);
  const csvLinkRef = useRef();

  const handleDownload = (vt) => {
    downloadCsv(vt)
      .then((csvData) => {
        setNewData(csvData);
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  useEffect(() => {
    if (newData.length > 0) {
      let arr = [];
      var i = 1;
      newData?.forEach((val) => {
        arr.push({
          Sr: i++,
          Date: moment(val.EntryDate.split("T")[0]).format("DD-MMM-YYYY"),
          "Voucher No": val.CommissionPaymentVoucherId,
          "Affiliate/Employee":
            val.AffiliateCnicNavigation !== undefined
              ? val.AffiliateCnicNavigation.AffiliateName !== null
                ? val.AffiliateCnicNavigation.AffiliateName
                : val.Employee.EmpName
              : "",
          // Project: val.Project !== undefined && val.Project !== null ? val.Project.ProjectName : '',
          Unit: val.Unit !== undefined ? val.Unit.UnitName : "",
          Amount: parseFloat(val.Amount).toLocaleString(),
        });
      });
      setCsvData(arr);
    }
  }, [newData]);
  useEffect(() => {
    if (csvLinkRef.current && CsvData.length > 0) {
      csvLinkRef.current.link.click();
      setCsvData([]);
    }
  }, [CsvData]);

  return (
    <>
      <div hidden>
        <CommissionPaymentPrint ref={componentRef} printData={printData} />
      </div>

      <div className="row pt-3">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label m-0">Search:</label>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            autoComplete="off"
            name="date"
            value={filterObject?.date}
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
            type="date"
            className="input_styling "
            id="datefilter"
            onChange={onTextChange}
            placeholder="Date"
          />
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            autoComplete="off"
            name="voucherNumber"
            value={filterObject?.voucherNumber}
            type="text"
            className="input_styling "
            id="voucherfilter"
            onChange={onTextChange}
            placeholder="Voucher"
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
          />
        </div>
        {/* <div className="col-md-2 pr-0 pl-0">
          <input
            autoComplete="off"
            name="seller"
            value={filterObject?.seller}
            type="text"
            className="input_styling "
            id="sellerfilter"
            onChange={onTextChange}
            placeholder="Employee/Affiliate"
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
          />
        </div> */}
        <div className="col-md-2 pr-0 pl-0">
          <input
            className="input_styling ml-md-3"
            id="buyerfilterId"
            defaultValue={""}
            autoComplete="off"
            readOnly
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
            placeholder="Employee/Affiliate"
            list="buyerfilterIdOpt"
            onChange={onTextChangeAffiliate}
          />
          <datalist id="buyerfilterIdOpt">
            {allCustomers?.map((x, idx) => (
              <option
                index={idx}
                value={x?.CustomerCnicNavigation?.CustomerName}
              >
                {x?.CustomerCnicNavigation?.CustomerCnic2}
              </option>
            ))}
          </datalist>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            className="input_styling ml-md-3"
            id="unitfilterId"
            autoComplete="off"
            defaultValue={""}
            readOnly
            onMouseDown={(e) => {
              e.target.removeAttribute("readOnly");
            }}
            placeholder="Unit"
            list="unitfilterIdOpt"
            onChange={onTextChangeUnit}
          />
          <datalist id="unitfilterIdOpt">
            {allUnits?.map((x, idx) => {
              return (
                <option index={idx} value={x.UnitName}>
                  {x.UnitId}
                </option>
              );
            })}
          </datalist>
        </div>
        <div className="col-md-2 pr-0 pl-0 ">
          <select
            name="status"
            value={filterObject?.status}
            className="input_styling "
            id="submittedId"
            onChange={onTextChange}
            readonly="readonly"
            onMouseDown={(e) => {
              e.target.removeAttribute("readonly");
            }}
          >
            <option value={""}>All</option>
            <option value={false}>Pending</option>
            <option value={true}>Submitted</option>
          </select>
        </div>
        <CommissionPaymentModal />
        <div className=" pl-3">
          {resultsrrtable.loading || loading ? (
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          ) : null}
        </div>
      </div>

      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0"></div>
        <div className="col-md-4   pr-0 pl-0">
          <label className="input_label m-0">From:</label>
          <input type="date" className="input_date ml-3" id="fromdate"></input>
        </div>
        <div className="col-md-4  pr-0 pl-0">
          <label className="input_label m-0">To:</label>
          <input type="date" className="input_date ml-3" id="todate"></input>
        </div>
        <div className="col-md-1 pr-0 pl-0">
          <button className="btn_Go" onClick={ongo}>
            Go
          </button>
        </div>
        <div className="col-md-12 pr-0 pl-0">
          {selectedVouchers.length > 0 && (
            <div className="col-md-12 mt-2">
              <div className="float-right">
                {loading && (
                  <Loader
                    type="ThreeDots"
                    color="green"
                    height={40}
                    width={40}
                  />
                )}
                <span>
                  <button
                    onClick={() => {
                      showmodal2(selectedVouchers);
                    }}
                    className="cancel_btn ml-2"
                  >
                    <MdDeleteForever />
                    Delete All
                  </button>
                </span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="table-responsive vouchers_table_height mt-2">
        <table className="table table-borderless m-0">
          <thead>
            <tr>
              <th>Sr</th>
              <th>{""}</th>
              <th>Date</th>
              <th>Added On</th>
              <th>Voucher</th>
              <th>Affiliate/Employee</th>
              {/* <th>Project</th> */}
              <th>Unit</th>
              <th>Amount</th>
              <th className="text-center">
                {rolesdata?.data?.Access?.includes("C") ? (
                  <button
                    className="save_btn"
                    onClick={() => {
                      props.showcommissiontable();
                    }}
                  >
                    New+
                  </button>
                ) : null}
              </th>
            </tr>
          </thead>
          <tbody>
            {filterdata !== undefined
              ? filterdata.map((val, index) => {
                  let year = val.EntryDate.slice(0, 4);
                  let day = val.EntryDate.slice(8, 10);
                  let month = val.EntryDate.slice(5, 7);
                  switch (month) {
                    case "01":
                      month = "Jan";

                      break;
                    case "02":
                      month = "Feb";

                      break;
                    case "03":
                      month = "Mar";

                      break;
                    case "04":
                      month = "Apr";

                      break;
                    case "05":
                      month = "May";

                      break;
                    case "06":
                      month = "Jun";

                      break;
                    case "07":
                      month = "Jul";

                      break;
                    case "08":
                      month = "Aug";

                      break;
                    case "09":
                      month = "Sep";

                      break;
                    case "10":
                      month = "Oct";
                      break;
                    case "11":
                      month = "Nov";
                      break;
                    case "12":
                      month = "Dec";
                      break;
                    default:
                      break;
                  }
                  return (
                    <tr key={index}>
                      <td
                        className="table_data"
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {index +
                          1 +
                          (allLinks?.PageNumber - 1) * allLinks?.PageSize}
                      </td>
                      <td>
                        {localStorage.getItem("UserName") === "Admin" ? (
                          <input
                            checked={val.checked ? val.checked : false}
                            key={index}
                            onChange={(e) => {
                              filterdata[index].checked = e.target.checked;
                              setrendarComp(!rendarComp);
                              handleCheckboxChange(index);
                            }}
                            type="checkbox"
                            id={`checkedVoucher${index}`}
                          />
                        ) : null}
                      </td>
                      <td
                        className="table_data"
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {moment(val.EntryDate.split("T")[0]).format(
                          "DD-MMM-YYYY"
                        )}
                      </td>
                      <td
                        className="table_data"
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {moment(
                          val.Transaction[0]?.AddedOn?.slice(0, 10),
                          "YYYY:MM-DD"
                        )?.format("DD-MMM-YYYY")}
                      </td>
                      <td
                        className="table_data"
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {val.CommissionPaymentVoucherId}
                      </td>
                      {val.AffiliateCnicNavigation !== undefined ? (
                        <td
                          className="table_data"
                          onClick={() => {
                            showmodal(val.Transaction);
                            SetPrintDataModal(val.Transaction);
                          }}
                        >
                          {val.AffiliateCnicNavigation.AffiliateName !== null
                            ? val.AffiliateCnicNavigation.AffiliateName
                            : val.Employee.EmpName}
                        </td>
                      ) : (
                        <td></td>
                      )}
                      {/* {val.Project !== undefined && val.Project !== null ?
                                      <td>{val.Project.ProjectName}</td> : <td></td>} */}
                      {val.Unit !== undefined ? (
                        <td
                          className="table_data"
                          onClick={() => {
                            showmodal(val.Transaction);
                            SetPrintDataModal(val.Transaction);
                          }}
                        >
                          {val.Unit.UnitName}
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td
                        className="table_data"
                        onClick={() => {
                          showmodal(val.Transaction);
                          SetPrintDataModal(val.Transaction);
                        }}
                      >
                        {parseFloat(val.Amount).toLocaleString()}
                      </td>

                      <td className="text-center">
                        {rolesdata?.data?.Access?.includes("U") ? (
                          <RiFileEditLine
                            title="Edit"
                            size="1em"
                            color={
                              val.Submitted === false ? "black" : "#707070"
                            }
                            onClick={() => {
                              if (val.Submitted === false) {
                                props.setvoucherData(val);
                              } else {
                                toast.info("Can't edit voucher");
                              }
                              // props.showgeneraltable();
                            }}
                            className="cur_sor"
                          />
                        ) : null}
                        &nbsp;
                        <GrPrint
                          className="cur_sor"
                          onClick={() => {
                            SetPrintData(val);
                            settoggle(!statetoggle);
                          }}
                          size="1em"
                        />
                        &nbsp;
                        {/* {localStorage.getItem("UserName") === "Admin" ? (
                          <MdDeleteForever
                            onClick={() => {
                              showmodal2(val);
                              document.getElementById(
                                "DeeteVoucherModal"
                              ).style.display = "block";
                            }}
                            title="Delete"
                            className="cur_sor"
                            size="1em"
                          />
                        ) : null} */}
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
      <div className="col-12  text-right pr-0 pl-0">
        <button
          disabled={allLinks?.LastPage ? false : true}
          className="print_btn float-right mx-1"
          onClick={() => getPaginationData(allLinks?.LastPage)}
        >
          Last
        </button>
        <button
          disabled={allLinks?.PreviousPage ? false : true}
          className="print_btn float-right mx-1"
          onClick={() => getPaginationData(allLinks?.PreviousPage)}
        >
          Previous
        </button>
        <button
          disabled={allLinks?.NextPage ? false : true}
          className="print_btn float-right mx-1"
          onClick={() => getPaginationData(allLinks?.NextPage)}
        >
          Next
        </button>
        <button
          disabled={allLinks?.FirstPage ? false : true}
          className="print_btn float-right pl-1 mx-1"
          onClick={() => getPaginationData(allLinks?.FirstPage)}
        >
          First
        </button>
      </div>
      <button
        style={{ color: "black !important" }}
        className="print_btn ml-2 float-left"
        onClick={() => handleDownload("CPV")}
      >
        Download CSV
      </button>
      <CSVLink
        ref={csvLinkRef}
        style={{ display: "none" }} // Hide the link element
        filename="Commission_Payment_Csv"
        data={CsvData}
      >
        Download CSV
      </CSVLink>

      <CommossionPaymentDeleteVoucherModal
        setSelecetdVoucher={setSelecetdVoucher}
        DeleteVoucherData={DeleteVoucherData}
        handelDeleteVoucherdataChange={handelDeleteVoucherdataChange}
        closeDeleteVoucherModal={closeDeleteVoucherModal}
        showPassword={showPassword}
        setshowPassword={setshowPassword}
        loading={loading}
        deleteVoucher={deleteVoucher}
      />

      <CommissionPaymentTransactionDetailModal
        debitState={debitState}
        creditState={creditState}
        printDataModal={printDataModal}
        closemodal={closemodal}
        TransactionData={TransactionData}
      />
    </>
  );
};
