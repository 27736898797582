import axios from "axios";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import ChangeStatusModal from "./ChangeStatusModal";
export const Users = (props) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserStatus, setSelectedUserStatus] = useState(false);
  const [allUsers, setallUsers] = useState([]);
  const [loading, setloading] = useState(false);
  console.log(allUsers);
  // print function
  const componentRef = useRef();

  useEffect(() => {
    callGetApi();
  }, []);

  useEffect(() => {
    if (selectedUser) {
      setSelectedUserStatus(!selectedUser?.IsActive);
      showModal();
    } else {
      setSelectedUserStatus(false);
    }
  }, [selectedUser]);

  function callGetApi() {
    setloading(true);
    axios({
      url: `${process.env.REACT_APP_API_URL}Auth/GetAllUser`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        setloading(false);
        if (result.data.IsSuccess === true) {
          setallUsers(result?.data?.Data);
        } else {
          toast.warn(result.data.Message);
        }
      },
      (error) => {
        setloading(false);
        toast.error("Something went wrong!");
      }
    );
  }

  function showModal() {
    document.getElementById("changeStatusModal").style.display = "block";
  }

  function closeModal() {
    document.getElementById("changeStatusModal").style.display = "none";
    setSelectedUser(null);
  }
  return (
    <>
      <>
        <div className="row pt-3 ">
          {/* <div className="col-md-3 text-center  pr-0 pl-0">
                    <label className="input_label m-0">From:</label>
                    <input type="date" className="input_date ml-3" id="from date"></input>
                </div>
                <div className="col-md-3 text-center pr-0 pl-0">
                    <label className="input_label m-0">To:</label>
                    <input type="date" className="input_date ml-3" id="todate"></input>
                </div>
                <div className="col-md-1 pr-0 pl-0">
                    <button className="btn_Go">Go</button>
                </div> */}
          <div className="col-md-1 pr-0 pl-0">
            {loading ? (
              <Loader type="ThreeDots" color="green" height={40} width={40} />
            ) : null}
          </div>
        </div>

        <div className="row ">
          <div className=" col-12  table_height pr-0 pl-0">
            <div className="table-responsive mt-4">
              <table className="table table-borderless table-hover m-0">
                <thead>
                  <tr>
                    <th>Sr</th>
                    <th>Name</th>
                    <th>Email</th>
                    {/* <th>User Name</th> */}
                    <th>Access</th>
                    <th>Projects</th>

                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {allUsers?.map((val, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{val.FullName}</td>
                      <td>{val.Email}</td>
                      {/* <td>{val.UserName}</td> */}
                      <td>{val.Access}</td>
                      <td>{val?.Projects?.map((val, index) => val + " ")}</td>

                      <td>
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id={`customSwitch${index}`}
                            checked={val?.IsActive}
                            onChange={() => {
                              setSelectedUser(val);
                            }}
                          />
                          <label
                            class="custom-control-label"
                            for={`customSwitch${index}`}
                          ></label>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
      <ChangeStatusModal
        selectedUser={selectedUser}
        selectedUserStatus={selectedUserStatus}
        closemodal={closeModal}
        callGetApi={callGetApi}
      />
    </>
  );
};
