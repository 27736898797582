const allland = { data: [], loading: true };
const alllandbyid = { data: [], loading: true };

export const GetAllLandReducer = (state = allland, action) => {
  switch (action.type) {
    case "get_alllands":
      return {
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};
export const GetAllLandByIdReducer = (state = alllandbyid, action) => {
  switch (action.type) {
    case "get_alllands_byid":
      return {
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};
