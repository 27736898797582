import React from "react";

import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Switch, Route, useHistory } from "react-router-dom";
import AccountMainPage from "./component/Accounts/accountParentComponent.js";
import HrMainPage from "./component/HR/hrComponent.js";
import AssetsMainPage from "./component/Assets/assetsComponent.js";
import InventoryMainPage from "./component/Inventory/inventoryComponent.js";
import CrmMainPage from "./component/CRM/crmComponent.js";
import Login from "./component/login";
import Verify from "./component/verification";
import Forget from "./component/forgetPass";
import ResetPass from "./component/resetPass";
import ProjectSelection from "./component/projectSelection";

import { ToastContainer } from "react-toastify";
import { monthlyAlert } from "./component/ContextApi/MonthlyAlert";
import { useContext } from "react";
import axios from "axios";
import { useEffect } from "react";
import moment from "moment";
import AltertModal from "./component/AlertModal/AltertModal";
// import { NewPurchasePrint } from "./component/Inventory/Projects/ProjectsPrint/NewPurchasePrint";

function App() {
  const { changeState } = useContext(monthlyAlert);
  const history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      history.push("/");
    }
  }, []);

  // useEffect(() => {
  //   axios
  //     .get("https://sheetdb.io/api/v1/dskt971mukoor")
  //     .then((res) => {
  //       if (
  //         res?.data?.length > 0 &&
  //         res.data[0]?.IsPaid === "FALSE" &&
  //         Number(moment().format("DD")) > Number(res.data[0]?.Date)
  //       ) {
  //         sessionStorage.setItem("isPaid", false);
  //         changeState(true);
  //       } else {
  //         sessionStorage.setItem("isPaid", true);

  //         changeState(false);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/verify" component={Verify} />
        <Route exact path="/forgot" component={Forget} />
        <Route exact path="/reset" component={ResetPass} />
        <Route exact path="/account" component={AccountMainPage} />
        <Route exact path="/hr" component={HrMainPage} />
        <Route exact path="/assets" component={AssetsMainPage} />
        <Route exact path="/inventory" component={InventoryMainPage} />
        <Route exact path="/crm" component={CrmMainPage} />
        <Route exact path="/projectSelection" component={ProjectSelection} />
        {/* <Route exact path="/test" component={NewPurchasePrint} /> */}
        {/* <Route exact path="/" component={UnderMaintenancePage} /> */}
        <ToastContainer autoClose={2000} />
        <AltertModal />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
