const allbookedunits = { data: [], loading: true };

export const GetBookedUnits = (state = allbookedunits, action) => {
  switch (action.type) {
    case "get_bookunits":
      return {
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};
