import axios from "axios";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { BsCloudUpload } from "react-icons/bs";
import { FiEdit2 } from "react-icons/fi";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { toast, ToastContainer } from "react-toastify";
import { getallProject } from "../../../actions/getprojects";
import { getallSection } from "../../../actions/getsections";
import { getallunits } from "../../../actions/getunit";
import { getdealboughtpopulatetable } from "../../../actions/inventoryactions/dealbought";
import { getallland } from "../../../actions/inventoryactions/getlandaction";
import { AllUnitPrint } from "../ProjectsPrint/AllUnitPrint";
import { UnitPrint } from "../ProjectsPrint/UnitPrint";
import UpdateUnitModal from "./UpdateUnitModal";

export const UnitTable = (props) => {
  const resultsproject = useSelector((state) => state.getProject);
  const resultallland = useSelector((state) => state.getAllLand);
  const resultsalldealbought = useSelector((state) => state.getDealBoughtRed);
  const resultsallunits = useSelector((state) => state.getAllUnits);
  const [filerunits, setfilterunits] = useState();
  const dispatch = useDispatch();
  const [creditordata, setcreditordata] = useState({});
  const [editunitid, seteditunitid] = useState();
  const [stateSectionList, setSectionList] = useState([]);
  const [stateprojectid, setprojectid] = useState(0);
  const [printData, SetPrintData] = useState();
  const [errorlist, seterrorlist] = useState();
  const [showUpdateModal, setshowUpdateModal] = useState(false);

  useEffect(() => {
    if (
      creditordata?.RatePerMarlaOrsqft === "MARLA" &&
      document.getElementById("radiomarlas")
    ) {
      document.getElementById("radiomarlas").checked = true;
    }
  }, [creditordata?.RatePerMarlaOrsqft]);

  console.log(creditordata);

  // print function
  const componentRef = useRef();

  const printRecoveryBooking = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    if (printData !== undefined) {
      printRecoveryBooking();
    }
  }, [printData]);
  // all projects print

  const AllcomponentRef = useRef();

  const AllprintPaymentRec = useReactToPrint({
    content: () => AllcomponentRef.current,
  });
  //Table Data fiter

  const onTextChange = (e) => {
    if (
      document.getElementById("projectfilter").value === "" &&
      document.getElementById("unitfilter").value === "" &&
      document.getElementById("sectionfilter").value === "" &&
      document.getElementById("areafilter").value === ""
    ) {
      setfilterunits(resultsallunits.data);
    } else {
      let suggestions = [];
      {
        const regex = new RegExp(
          document.getElementById("projectfilter").value,
          "i"
        );
        const regex1 = new RegExp(
          document.getElementById("sectionfilter").value,
          "i"
        );
        const regex2 = new RegExp(
          document.getElementById("unitfilter").value,
          "i"
        );
        const regex3 = new RegExp(
          document.getElementById("areafilter").value,
          "i"
        );

        suggestions = resultsallunits.data
          .sort()
          .filter(
            (val) =>
              (document.getElementById("projectfilter").value === "" ||
                regex.test(val.Project.ProjectName)) &&
              (document.getElementById("sectionfilter").value === "" ||
                regex1.test(val.Section.SectionName)) &&
              (document.getElementById("unitfilter").value === "" ||
                regex2.test(val.UnitName)) &&
              (document.getElementById("areafilter").value === "" ||
                regex3.test(val.Marla + "M" + val.Sarsai + "S"))
          );
      }
      setfilterunits(suggestions);
    }
  };
  // var suggestionlist = [];

  // const onTextChange = (e) => {
  //     const value = e.target.value;
  //     if (value === "") {

  //         dispatch(getallunits());
  //     } else {
  //         let suggestions = [];
  //         if (value.length > 0) {
  //             const regex = new RegExp(`${value}`, "i");

  //             suggestions = resultsallunits.data
  //                 .sort()
  //                 .filter((val) =>
  //                     (regex.test(val.UnitName)));

  //         }
  //         suggestionlist = suggestions;
  //         dispatch(GetAllUnits(suggestionlist));
  //     }
  // };
  useEffect(() => {
    dispatch(getallunits());
  }, [dispatch]);
  useEffect(() => {
    setfilterunits(resultsallunits.data);
  }, [resultsallunits]);
  function showmodal(data) {
    document.getElementById("mymodal").style.display = "block";
    setcreditordata(data);
  }
  function closemodal() {
    document.getElementById("mymodal").style.display = "none";
  }
  function showsaleratemodal(unitid, data) {
    seteditunitid(unitid);

    setcreditordata(data);
    setshowUpdateModal(true);
  }
  function closesaleratemodal() {
    document.getElementById("salerateedit").style.display = "none";
    // document.getElementById("Saleedit").value = "";
    // document.getElementById("totalSaleedit").value = "";
  }

  function showimportmodal() {
    document.getElementById("importmodal").style.display = "block";
  }
  function closeimportmodal() {
    document.getElementById("importmodal").style.display = "none";
  }
  function showerrormodal(data) {
    document.getElementById("errormodal").style.display = "block";
    seterrorlist(data);
  }
  function closeerrormodal() {
    document.getElementById("errormodal").style.display = "none";
  }
  function uploadcsv() {
    if (
      document.getElementById("projectname").options[
        document.getElementById("projectname").selectedIndex
      ].value === "Select"
    ) {
      toast.info("Select Project");
    } else if (
      document.getElementById("sectionname").options[
        document.getElementById("sectionname").selectedIndex
      ].value === "Select"
    ) {
      toast.info("Select Section");
    } else if (
      document.getElementById("deals") !== null
        ? document.getElementById("deals").selectedIndex === 0
        : null && document.getElementById("land") !== null
        ? document.getElementById("land").selectedIndex === 0
        : null
    ) {
      toast.info("Select Land/Deal after Project Selection");
    } else if (document.getElementById("singleupload").value == "") {
      toast.info("Choose CSV");
    } else {
      var formData = new FormData();

      formData.append("file", document.getElementById("singleupload").files[0]);
      formData.append(
        "ProjectId",
        document.getElementById("projectname").options[
          document.getElementById("projectname").selectedIndex
        ].dataset.id
      );
      formData.append(
        "SectionId",
        document.getElementById("sectionname").options[
          document.getElementById("sectionname").selectedIndex
        ].dataset.id
      );
      if (
        document.getElementById("land") !== null &&
        document.getElementById("land").selectedIndex !== 0
      )
        formData.append(
          "LandPurchasedId",
          document.getElementById("land").options[
            document.getElementById("land").selectedIndex
          ].dataset.id
        );
      if (
        document.getElementById("deals") !== null &&
        document.getElementById("deals").selectedIndex !== 0
      )
        formData.append(
          "DealBoughtId",
          document.getElementById("deals").options[
            document.getElementById("deals").selectedIndex
          ].dataset.id
        );

      document.getElementById("cvssave").disabled = true;
      document.getElementById("closeicon").disabled = true;

      axios({
        url: `${process.env.REACT_APP_API_URL}Projects/Units/Import`,
        method: "post",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "multipart/form-data",
        },
        data: formData,
      }).then(
        (result) => {
          if (result.data.isSuccess === true) {
            toast.info(result.data.message);

            dispatch(getallunits());
            document.getElementById("singleupload").value = "";
            closeimportmodal();
            document.getElementById("cvssave").disabled = false;
            document.getElementById("closeicon").disabled = false;
          } else {
            // toast.info(result.data.message);
            if (result.data.errors.length >= 0) {
              showerrormodal(result.data.errors);
              closeimportmodal();
              document.getElementById("singleupload").value = "";
              document.getElementById("cvssave").disabled = false;
              document.getElementById("closeicon").disabled = false;
            }
          }
        },
        (error) => {
          document.getElementById("cvssave").disabled = false;
          document.getElementById("closeicon").disabled = false;
        }
      );
    }
  }
  useEffect(() => {
    dispatch(getallProject());

    dispatch(getallSection());

    dispatch(getallland());
    dispatch(getdealboughtpopulatetable());
  }, [dispatch]);

  const [CsvData, setCsvData] = useState([]);
  useEffect(() => {
    let arr = [];
    filerunits?.forEach((val, index) => {
      arr.push({
        Sr: index + 1,
        Project:
          val.Project !== null && val.Project !== undefined
            ? val.Project.ProjectName
            : "",
        Section:
          val.Section !== null && val.Section !== undefined
            ? val.Section.SectionName
            : "",
        Unit: val.UnitName,
        Area: val.Marla + "M" + val.Sarsai + "S",
        Dimension:
          val.DimensionLength !== null
            ? val.DimensionLength + "x" + val.DimensionWidth
            : "0x0",
        "S.Rate": parseFloat(val.RatePerMarla).toLocaleString(),
        "Total Value": parseFloat(val.Price).toLocaleString(),
      });
    });
    setCsvData(arr);
  }, [filerunits]);

  return (
    <>
      <div hidden>
        <UnitPrint ref={componentRef} printData={printData} />
      </div>
      <div hidden>
        <AllUnitPrint ref={AllcomponentRef} printData={resultsallunits} />
      </div>
      {showUpdateModal && (
        <UpdateUnitModal
          setshowUpdateModal={setshowUpdateModal}
          creditordata={creditordata}
          setcreditordata={setcreditordata}
          editunitid={editunitid}
        />
      )}
      <div className="row pt-3 ">
        <div className="col-md-1 pr-0 pl-0">
          <label className="input_label m-0">Search:</label>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            id="projectfilter"
            onChange={onTextChange}
            placeholder="Project "
          ></input>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            id="sectionfilter"
            onChange={onTextChange}
            placeholder="Section"
          ></input>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            id="unitfilter"
            onChange={onTextChange}
            placeholder="Unit "
          ></input>
        </div>
        <div className="col-md-2 pr-0 pl-0">
          <input
            type="text "
            className="input_styling "
            id="areafilter"
            onChange={onTextChange}
            placeholder="Area "
          ></input>
        </div>

        <div className="col-md-2 text-right ">
          <button className="save_btn">
            {/* <a href="../../csvfile/UnitsCSV.csv" download>Download</a> */}

            <CSVLink
              data={[
                [
                  `Name`,
                  `Length(feet)`,
                  `Width(feet)`,
                  `Marla`,
                  `Sarsai`,
                  `Net Sqfts`,
                  `Net Marla`,
                  `Rate Per Marla`,
                  `Price`,
                  `Cost Per Marla`,
                  `Total Cost`,
                  `Rate Per Marla or Sqft`,
                ],
                [
                  `U-3`,
                  15,
                  60,
                  3,
                  0,
                  256,
                  3,
                  500000,
                  1500000,
                  400000,
                  1200000,
                  `Marla`,
                ],
              ]}
              filename={"csvfile.csv"}
              className="save_btn"
              target="_blank"
            >
              Download
            </CSVLink>
          </button>
        </div>
        <div className="col-md-1 text-right pr-5">
          <button
            className="save_btn"
            onClick={() => {
              showimportmodal();
            }}
          >
            Import
          </button>
          {resultsallunits.loading ? (
            <Loader type="ThreeDots" color="green" height={40} width={40} />
          ) : null}
        </div>
      </div>

      <div className="row ">
        <div className=" col-12  table_height pr-0 pl-0">
          <div className="table-responsive mt-4">
            <table className="table table-borderless m-0">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Project</th>
                  <th>Section </th>
                  <th>Unit</th>
                  <th>Area</th>
                  <th>Dimension</th>
                  <th>S.Rate</th>
                  <th>Total Value</th>

                  <th className="text-center">
                    <button
                      className="save_btn"
                      onClick={() => {
                        props.showunittable();
                      }}
                    >
                      New+
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {filerunits !== undefined
                  ? filerunits.map((val, index) => (
                      <tr key={index}>
                        <td
                          className=" cur_sor "
                          onClick={() => {
                            showmodal(val);
                          }}
                        >
                          {index + 1}
                        </td>
                        {val.Project !== null && val.Project !== undefined ? (
                          <td
                            className=" cur_sor "
                            onClick={() => {
                              showmodal(val);
                            }}
                          >
                            {val.Project.ProjectName}
                          </td>
                        ) : (
                          <td></td>
                        )}
                        {val.Section !== null && val.Section !== undefined ? (
                          <td
                            className=" cur_sor "
                            onClick={() => {
                              showmodal(val);
                            }}
                          >
                            {val.Section.SectionName}
                          </td>
                        ) : (
                          <td></td>
                        )}
                        <td
                          className=" cur_sor "
                          onClick={() => {
                            showmodal(val);
                          }}
                        >
                          {val.UnitName}
                        </td>

                        {/* <td>{val.NetMarla}</td> */}
                        <td
                          className=" cur_sor "
                          onClick={() => {
                            showmodal(val);
                          }}
                        >
                          {val.Marla + "M" + val.Sarsai + "S"}
                        </td>
                        <td
                          className=" cur_sor "
                          onClick={() => {
                            showmodal(val);
                          }}
                        >
                          {val.DimensionLength !== null
                            ? val.DimensionLength + "x" + val.DimensionWidth
                            : "0x0"}
                        </td>
                        <td
                          className=" cur_sor "
                          onClick={() => {
                            showmodal(val);
                          }}
                        >
                          {parseFloat(val.RatePerMarla).toLocaleString()}
                        </td>

                        <td
                          className=" cur_sor "
                          onClick={() => {
                            showmodal(val);
                          }}
                        >
                          {parseFloat(val.Price).toLocaleString()}
                        </td>
                        <td
                          className="text-center cur_sor "
                          onClick={() => showsaleratemodal(val.UnitId, val)}
                        >
                          <FiEdit2 size="1em" />
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-6  text-right pr-0 pl-0">
          <button
            className="print_btn float-left"
            onClick={() => {
              AllprintPaymentRec();
            }}
          >
            <TiPrinter size="1.3em" />
            Print
          </button>
          <button
            style={{ color: "black !important" }}
            className="print_btn ml-2 float-left"
          >
            <CSVLink
              style={{ color: "black" }}
              filename="Unit_Csv"
              data={CsvData}
            >
              Download CSV
            </CSVLink>
          </button>
        </div>
      </div>

      <div id="mymodal" class="modal">
        <div class="modal-content-cat">
          <div className="row">
            <div className="col-12  page_heading">
              <h4 className="  text-center pt-2">Units Detail</h4>
              <span class="close pr-2  " onClick={closemodal}>
                &times;
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-6 mt-2">
              <ul>
                <li>
                  <b>Project:</b>{" "}
                  {creditordata.Project !== undefined
                    ? creditordata.Project.ProjectName
                    : null}
                </li>
                <li>
                  <b>Section:</b>{" "}
                  {creditordata.Section !== undefined
                    ? creditordata.Section.SectionName
                    : null}
                </li>
                <li>
                  <b>Unit:</b> {creditordata.UnitName}
                </li>

                <li>
                  <b>Marla:</b> {creditordata.Marla}
                </li>
                <li>
                  <b>Sarsai:</b> {creditordata.Sarsai}
                </li>
                <li>
                  <b>LxW:</b>{" "}
                  {creditordata.DimensionLength +
                    "x" +
                    creditordata.DimensionWidth}
                </li>
                <li>
                  <b>Measurment Unit:</b> {creditordata.RatePerMarlaOrsqft}
                </li>
              </ul>
            </div>
            <div className="col-6 mt-2">
              <ul>
                <li>
                  <b>Net Marla:</b>{" "}
                  {parseFloat(creditordata.NetMarla).toLocaleString()}
                </li>
                <li>
                  <b>Net Sqft:</b>{" "}
                  {parseFloat(creditordata.NetSqft).toLocaleString()}
                </li>
                <li>
                  <b>Sale Rate:</b>{" "}
                  {parseFloat(creditordata.RatePerMarla).toLocaleString()}
                </li>
                <li>
                  <b>Total Value:</b>{" "}
                  {parseFloat(creditordata.Price).toLocaleString()}
                </li>
                <li>
                  <b>Cost Rate:</b>{" "}
                  {parseFloat(creditordata.CostPerMarla).toLocaleString()}
                </li>
                <li>
                  <b>Total Cost:</b>{" "}
                  {parseFloat(creditordata.TotalCost).toLocaleString()}
                </li>
              </ul>
            </div>
          </div>

          <div className="col-12 text-right"></div>
        </div>
      </div>
      <div id="importmodal" class="modal">
        <div class="modal-content-cat">
          <div className="row">
            <div className="col-12  page_heading">
              <h4 className="  text-center pt-2">Upload CSV</h4>
              <button
                class="close pr-2  "
                onClick={closeimportmodal}
                id="closeicon"
              >
                &times;
              </button>
            </div>
          </div>

          <div className="row ">
            <div className="col-md-2 mt-4  text-center p-0">
              <label className="input_label m-0 ">Project Name:</label>
            </div>
            <div className="col-md-3 mt-4  p-0">
              <select
                id="projectname"
                className="input_styling  ml-md-3"
                onChange={(e) => {
                  if (e.target.value !== "Select") {
                    setSectionList(
                      resultsproject.data[
                        document.getElementById("projectname").options[
                          document.getElementById("projectname").selectedIndex
                        ].dataset.idx
                      ]
                    );
                    setprojectid(
                      document.getElementById("projectname").options[
                        document.getElementById("projectname").selectedIndex
                      ].dataset.id
                    );
                    // document.getElementById("land").selectedIndex = 0;
                    // document.getElementById("deals").selectedIndex = 0;
                  }
                }}
              >
                <option>Select</option>
                {resultsproject.data.map((x, index) => (
                  <option data-id={x.ProjectId} data-idx={index} key={index}>
                    {x.ProjectName}
                  </option>
                ))}
              </select>
              <span className="estaric">*</span>
              {/* <select className="input_styling ml-md-3" id="projectname" >
                        <option>Select</option>
                        {resultsproject.data.map((val, index) => (
                            <option key={index} data-id={val.ProjectId}>{val.ProjectName}</option>
                        ))}
                    </select> */}
            </div>
            <div className="col-md-1 mt-4 p-0"></div>
            <div className="col-md-2 mt-4  text-left p-0">
              <label className="input_label m-0 ">
                {" "}
                {stateSectionList !== undefined
                  ? stateSectionList.ProjectOwner === "PERSONAL"
                    ? "Land Name"
                    : "Deal Name"
                  : null}
              </label>
            </div>
            <div className="col-md-3 mt-4  p-0">
              {stateSectionList !== undefined ? (
                stateSectionList.ProjectOwner === "PERSONAL" ? (
                  <>
                    {" "}
                    <select className="input_styling ml-md-3" id="land">
                      <option data-id={0}>Select</option>
                      {resultallland.data.map((val, index) =>
                        parseInt(stateprojectid) ===
                        parseInt(val.Project.ProjectId) ? (
                          <option key={index} data-id={val.LandPurchasedId}>
                            {val.LandName}
                          </option>
                        ) : null
                      )}
                    </select>
                    <span className="estaric">*</span>{" "}
                  </>
                ) : (
                  <>
                    {" "}
                    <select className="input_styling ml-md-3" id="deals">
                      <option data-id={0}>Select</option>
                      {resultsalldealbought.data.map((val, index) =>
                        parseInt(stateprojectid) ===
                        parseInt(val.Project.ProjectId) ? (
                          <option key={index} data-id={val.DealBoughtId}>
                            {val.DealName}
                          </option>
                        ) : null
                      )}
                    </select>
                    <span className="estaric">*</span>
                  </>
                )
              ) : null}
            </div>
          </div>
          <div className="row ">
            <div className="col-md-2 mt-4  text-center p-0">
              <label className="input_label m-0 ">Section:</label>
            </div>
            <div className="col-md-3 mt-4  p-0">
              <select id="sectionname" className="input_styling  ml-md-3">
                <option>Select</option>
                {stateSectionList !== undefined &&
                stateSectionList.Sections !== undefined
                  ? stateSectionList.Sections.map((x, index) => (
                      <option
                        data-id={x.SectionId}
                        data-idx={index}
                        key={index}
                      >
                        {x.SectionName}
                      </option>
                    ))
                  : null}
              </select>
              <span className="estaric">*</span>
              {/* <select className="input_styling ml-md-3" id="sectionname" >
                        <option>Select</option>
                        {resultsallsections.data.map((val, index) => (
                            <option key={index} data-id={val.SectionId}>{val.SectionName}</option>
                        ))}
                    </select> */}
            </div>
            <div className="col-md-1 mt-4 p-0"></div>
            <div className="col-md-2 mt-4  text-left p-0">
              <label className="input_label m-0 ">Upload CSV:</label>
            </div>
            <div className="col-md-3 mt-4  p-0">
              <input
                type="file"
                id="singleupload"
                accept="image/*"
                name="img"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 mt-4 text-right pl-2">
              <button
                className="save_btn  ml-2"
                onClick={uploadcsv}
                id="cvssave"
              >
                <BsCloudUpload size="1.3em" />
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="errormodal" class="modal">
        <div class="modal-content-cat">
          <div className="row">
            <div className="col-12  page_heading">
              <h4 className="  text-center pt-2">Error</h4>
              <span class="close pr-2  " onClick={closeerrormodal}>
                &times;
              </span>
            </div>
          </div>

          <div className="row ">
            <div className=" col-12  table_height pr-0 pl-0">
              <div className="table-responsive mt-4">
                <table className="table table-borderless m-0">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>message</th>
                      <th>description </th>
                    </tr>
                  </thead>
                  <tbody>
                    {errorlist !== undefined
                      ? errorlist.map((val, i) => (
                          <tr>
                            <td>{i + 1}</td>
                            <td>{val.message}</td>

                            <td>{val.description}</td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer theme="dark" autoClose={2000} />
    </>
  );
};
