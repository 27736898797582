import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import moment from "moment";
import IndividualPrintHeader from "../../../Prints/PrintHeader/IndividualPrintHeader";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";
var converter = require("number-to-words");

class GeneralAdjustmentPrint extends Component {
  render() {
    return (
      <div className="row m-0 p-0">
        <div className="watermarked">
          <img
            src={gravity}
            style={{ height: "600px", width: "600px" }}
            alt="Company logo"
          />
        </div>

        <div className="col-4 text-center">
          <img
            src={gravity}
            style={{ height: "175px", width: "175px", borderRadius: "100px" }}
            className="pt-2"
            alt="Company logo"
          />
        </div>
        <div className="col-3"></div>
        <div className="col-5 text-center mt-4">
          <h2 style={{ color: "#1b7a7f" }}>Gravity Associates Pvt Limited</h2>
          <hr />
          <h6 style={{ color: "grey", marginTop: "5px" }}>
            <i>204 R/B Road, East Canal Road, Faisalabad.</i>
          </h6>
        </div>

        <h4 className="col-12 text-center">
          <u>General Adjustments</u>
        </h4>
        <h4 className="col-12 text-center">
          {localStorage.getItem("ProjectName") === "THE GRAND CITY"
            ? "THE GRAND ICONIC CITY"
            : localStorage.getItem("ProjectName")}
        </h4>
        {/* <div className="col-6 mt-2">
                        Voucher No:{" "}
                        {this.props.printData !== undefined ?

                            this.props.printData.GeneralVoucherId

                            : null}
                </div>
                <div className="col-6 mt-2 text-right">
                        Date:{" "}
                                {this.props.printData !== undefined ?

                                    this.props.printData.EntryDate.split("T")[0]

                                    : null}
                </div> */}

        {/* <div className="col-6 mt-2">
                       Payment Type:{" "}
                       {this.props.printData !== undefined ?

                            this.props.printData.VoucherType

                            : null}
                </div>
                <div className="col-6 mt-2 text-right">
                       
                </div> */}

        {/* <div className="col-12 mt-2">
                    Received with thanks from:
                </div>
            
                <div className="col-6 mt-2">
                    Name: {this.props.printData !== undefined ?

this.props.printData.AffiliateCnicNavigation.AffiliateName

                                : null}
                </div>
                <div className="col-6 mt-2 text-right">
                    CNIC:
                    {this.props.printData !== undefined ?

                        this.props.printData.Contract.AffiliateCnic

                                : null}
                </div>
                
                <div className="col-6 mt-2">
                    Cell: {this.props.printData !== undefined ?

                        this.props.printData.Contract.AffiliateCnicNavigation.PhoneNumber

                                : null}
                </div>
                 */}
        <div className="col-6 mt-2 text-right"></div>

        <div className="col-12 mt-3">
          <table className="table">
            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Sr.
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Date
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Voucher No.
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Payment Type
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Receipt Type
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Amount
                </th>
                <th
                  style={{
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                  }}
                >
                  Narration
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.printData.data !== undefined
                ? this.props.printData.data.map((val, index) => {
                    let year = val.EntryDate.slice(0, 4);
                    let day = val.EntryDate.slice(8, 10);
                    let month = val.EntryDate.slice(5, 7);
                    switch (month) {
                      case "01":
                        month = "Jan";

                        break;
                      case "02":
                        month = "Feb";

                        break;
                      case "03":
                        month = "Mar";

                        break;
                      case "04":
                        month = "Apr";

                        break;
                      case "05":
                        month = "May";

                        break;
                      case "06":
                        month = "Jun";

                        break;
                      case "07":
                        month = "Jul";

                        break;
                      case "08":
                        month = "Aug";

                        break;
                      case "09":
                        month = "Sep";

                        break;
                      case "10":
                        month = "Oct";
                        break;
                      case "11":
                        month = "Nov";
                        break;
                      case "12":
                        month = "Dec";
                        break;
                      default:
                        break;
                    }
                    return (
                      <tr key={index}>
                        <td
                          style={{
                            borderLeft: "1px solid grey",
                            borderRight: "1px solid grey",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid grey",
                            borderRight: "1px solid grey",
                          }}
                        >
                          {day + "-" + month + "-" + year}
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid grey",
                            borderRight: "1px solid grey",
                          }}
                        >
                          {val.AdjustmentVoucherId}
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid grey",
                            borderRight: "1px solid grey",
                          }}
                        >
                          {val.PaymentType === "SELECT"
                            ? "WRITE OFF"
                            : val.PaymentType}
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid grey",
                            borderRight: "1px solid grey",
                          }}
                        >
                          {val.ReceiptType}
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid grey",
                            borderRight: "1px solid grey",
                          }}
                        >
                          {val.Amount !== undefined
                            ? val.Amount.toLocaleString()
                            : null}
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid grey",
                            borderRight: "1px solid grey",
                          }}
                        >
                          {val.Narration}
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
            {/* <thead style={{backgroundColor:"lightgray"}}>
                                            <tr>

                                            <th style={{borderLeft:"1px solid grey"}}>Total</th>
                                            <th></th>



                                            <th></th>
                                            
                                            
                                            

                                            <th style={{borderLeft:"1px solid grey", borderRight:"1px solid grey"}}>{this.props.printData !== undefined ?parseFloat(this.props.printData.Amount).toLocaleString():null}</th>

                                        </tr>
                                    </thead> */}
          </table>
        </div>

        {/* <div className="col-12 mt-2">
                    Total Amount: {this.props.printData !== undefined ?parseFloat(this.props.printData.Amount).toLocaleString():null}
                </div> 
            
                <div className="col-12 mt-2">
                    Amount in words: {this.props.printData !== undefined ?converter.toWords(parseFloat(this.props.printData.Amount)):null}
                </div>    */}

        {/* <div className="col-12 mt-2">
                    On account payment of shop:
                </div>                          
             */}
        <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-5 text-center">
          <b>___________________</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Received By:</b>
        </div>
        <div className="col-6 mt-4 text-center">
          <b>Authorized By:</b>
        </div>
        <div className="col-12 mt-4">
          <hr />
        </div>
      </div>
    );
  }
}

export default GeneralAdjustmentPrint;
export class GeneralAdjustmentIndividualPrint extends Component {
  render() {
    return (
      <div className="row m-0 p-0">
        <IndividualPrintHeader />

        <h4 className="col-12 text-center">
          <u>General Adjustments</u>
        </h4>
        <h4 className="col-12 text-center">
          {localStorage.getItem("ProjectName") === "THE GRAND CITY"
            ? "THE GRAND ICONIC CITY"
            : localStorage.getItem("ProjectName")}
        </h4>

        <div className="col-6 mt-2">
          <ul>
            <li className="mt-2">
              <b>Project:</b>{" "}
              {this.props.printData !== undefined &&
              this.props.printData.Project !== undefined
                ? this.props.printData.Project.ProjectName === "THE GRAND CITY"
                  ? "THE GRAND ICONIC CITY"
                  : this.props.printData.Project.ProjectName
                : null}
            </li>
            <li className="mt-2">
              <b> Adjustment VoucherId:</b>{" "}
              {this.props.printData !== undefined &&
              this.props.printData.AdjustmentVoucherId !== undefined
                ? this.props.printData.AdjustmentVoucherId
                : null}
            </li>
            <li className="mt-2">
              <b> ReceiptType:</b>{" "}
              {this.props.printData !== undefined &&
              this.props.printData.ReceiptType !== undefined
                ? this.props.printData.ReceiptType
                : null}
            </li>
            <li className="mt-2">
              <b> Narration:</b>{" "}
              {this.props.printData !== undefined &&
              this.props.printData.Narration !== undefined
                ? this.props.printData.Narration
                : null}
            </li>
          </ul>
        </div>
        <div className="col-6 mt-2">
          <ul>
            <li className="mt-2">
              <b>Date:</b>{" "}
              {this.props.printData !== undefined &&
              this.props.printData.EntryDate !== undefined
                ? moment(this.props.printData.EntryDate.split("T")[0]).format(
                    "DD-MMM-YYYY"
                  )
                : null}
            </li>
            <li className="mt-2">
              <b>Amount:</b>{" "}
              {this.props.printData !== undefined &&
              this.props.printData.Amount !== undefined
                ? this.props.printData.Amount.toLocaleString()
                : null}
            </li>
            <li className="mt-2">
              <b> PaymentType:</b>{" "}
              {this.props.printData !== undefined &&
              this.props.printData.PaymentType !== undefined
                ? this.props.printData.PaymentType === "SELECT"
                  ? "WRITE OFF"
                  : this.props.printData.PaymentType
                : null}
            </li>
          </ul>
        </div>

        {/* <div className="col-12 mt-2">
                    Total Amount: {this.props.printData !== undefined ?parseFloat(this.props.printData.Amount).toLocaleString():null}
                </div> 
            
                <div className="col-12 mt-2">
                    Amount in words: {this.props.printData !== undefined ?converter.toWords(parseFloat(this.props.printData.Amount)):null}
                </div>    */}

        {/* <div className="col-12 mt-2">
                    On account payment of shop:
                </div>                          
             */}
        {/* <div className="col-6 mt-5 text-center">
                    <b>___________________</b>
                </div>
                <div className="col-6 mt-5 text-center">
                    <b>___________________</b>
                </div>
                <div className="col-6 mt-4 text-center">
                    <b>Received By:</b>
                </div>
                <div className="col-6 mt-4 text-center">
                    <b>Authorized By:</b>
                </div>
                <div className="col-12 mt-4">
                    <hr />
                </div> */}
      </div>
    );
  }
}
export class GeneralAdjModal extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <PrintHeader />
        </div>
        <div
          className="row m-3 p-0"
          style={{ border: "5px solid darkgrey", height: "85%" }}
        >
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>
                General Adjustment Transactions -{" "}
                {localStorage.getItem("ProjectName")}{" "}
              </u>
            </h4>
            <h5 className="text-right pt-3 ">
              <div className="row">
                <div className="col-2 text-right">
                  <b>Voucher # : </b>
                </div>
                <div className="col-4 text-left">
                  {this.props?.printData?.length > 0 &&
                    this.props?.printData[0]?.VoucherId}
                </div>
              </div>

              <div className="row">
                <div className="col-2 text-right">
                  <b>Voucher Date : </b>
                </div>
                <div className="col-2 text-left">
                  {this.props?.printData?.length > 0 &&
                    moment(
                      this.props?.printData[0]?.EntryDate?.slice(0, 10)
                    ).format("DD-MMM-YYYY")}
                </div>
                <div className="col-4"></div>
                <div className="col-2 text-right">
                  <b>Print Date: </b>
                </div>

                <div className="col-2 text-center">
                  {moment().format("DD-MMM-YYYY")}
                </div>
              </div>
            </h5>

            {/* <h5 className="d-flex justify-content-between" >
                            <div className="text-left">

                                <b>Voucher No:{" "}</b>
                                {this.props.Data !== undefined ?

                                    this.props.Data.GeneralVoucherId

                                    : null}</div>
                            <div className="text-right">
                                <b>Payment Type:{" "}</b>
                                {this.props.Data !== undefined ?

                                    this.props.Data.VoucherType

                                    : null}
                            </div>

                        </h5> */}

            <div className="mt-5">
              <div class="table-responsive-print col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Account</th>
                      <th>Narration</th>
                      <th>Debit</th>

                      <th>Credit</th>
                    </tr>
                  </thead>
                  {this.props.printData !== undefined
                    ? this.props.printData.map((x, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{x.Account.AccountTitle}</td>
                          <td>{x.Narration}</td>
                          <td>
                            {x.TransactionType === "DEBIT"
                              ? x.Amount.toLocaleString()
                              : null}
                          </td>

                          <td>
                            {x.TransactionType === "CREDIT"
                              ? x.Amount.toLocaleString()
                              : null}
                          </td>
                        </tr>
                      ))
                    : null}
                  <tr>
                    <td></td>
                    <td></td>

                    <td>
                      {" "}
                      <label className="input_label m-0">Total:</label>
                    </td>
                    <td>{this.props.creditTotal?.toLocaleString()}</td>
                    <td>{this.props.debitTotal?.toLocaleString()}</td>
                    {/* <td><input type="text" className="input_total_styling " placeholder="Balance" disabled value={stateledgerdata[stateledgerdata.length - 1]?.AccountBalance?.toLocaleString()}></input></td> */}
                    <td></td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="col-12 text-left mt-2">
              Total Amount:{" "}
              {this.props.total !== undefined
                ? parseFloat(this.props.total).toLocaleString()
                : null}
            </div>

            <div className="col-12 text-left mt-2">
              Amount in words:{" "}
              {this.props.total !== undefined
                ? converter.toWords(parseFloat(this.props.total))
                : null}
            </div>
            <div className="signature">
              <h5 className=" pt-3 ">
                <div className="row">
                  <div className="col-2">
                    <b>Prepared By: </b>
                  </div>
                  <div className="col-2">
                    <>----------------</>
                  </div>
                  <div className="col-2">
                    <b>Checked By: </b>
                  </div>
                  <div className="col-2">
                    <>----------------</>
                  </div>
                  <div className="col-2">
                    <b>Approved By: </b>
                  </div>

                  <div className="col-2">
                    <>----------------</>
                  </div>
                </div>
              </h5>
              {/* <h5 className="text-right pt-3 ">
                                <div className="row">
                                    <div className="col-8">

                                    </div>
                                    <div className="col-2 text-center">
                                        <b>Date:{" "}</b>
                                    </div>

                                    <div className="col-2 text-center">
                                        {moment().format("DD-MMM-YYYY")}
                                    </div>
                                </div>
                            </h5> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
