import React, { useState, useRef, useEffect } from "react";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import { CSVLink, CSVDownload } from "react-csv";
import GeneralJournalPrint from "../ReportPrints/GeneralJournalPrint";
const GeneralJournalReport = () => {
  const [stategeneraljournaldata, setgeneraljournaldata] = useState([]);
  const [statedebit, setdebit] = useState(0);
  const [statecredit, setcredit] = useState(0);
  const [loader, setloader] = useState(false);
  const [CsvData, setCsvData] = useState([]);

  // print function
  const componentRef = useRef();

  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });

  const savedata = () => {
    document.getElementById("go").disabled = true;
    if (
      document.getElementById("fromdate").value === "" ||
      document.getElementById("todate").value === ""
    ) {
      toast.info("Select FromDate To ToDate");
      document.getElementById("go").disabled = false;
    } else {
      setloader(true);

      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}Reports/GeneralJournal`,
        data: JSON.stringify({
          From: document.getElementById("fromdate").value,
          To: document.getElementById("todate").value,
          ProjectId: localStorage.getItem("ProjectId")
            ? localStorage.getItem("ProjectId")
            : null,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result.data.IsSuccess === true) {
            var credit = 0;
            var debit = 0;

            result.data.Data.map((val) =>
              val.Debit === ""
                ? (credit += parseFloat(val.Credit))
                : (debit += parseFloat(val.Debit))
            );
            setcredit(credit);
            setdebit(debit);
            setgeneraljournaldata(result.data.Data);
            document.getElementById("go").disabled = false;
            setloader(false);
          } else {
            setloader(false);
            document.getElementById("go").disabled = false;
          }
        },
        (error) => {
          setloader(false);
          document.getElementById("go").disabled = false;
        }
      );
    }
  };

  useEffect(() => {
    let arr = [];
    stategeneraljournaldata?.forEach((val) => {
      arr.push({
        Date: moment(val.TransDate.split("T")[0]).format("DD-MMM-YYYY"),
        ID: val.ID,
        Narration: val.Particular,
        Debit: val.Debit,
        Credit: val.Credit,
      });
    });
    arr.push({
      Date: "",
      ID: "",
      Narration: "Total",
      Debit: statedebit,
      Credit: statecredit,
    });
    setCsvData(arr);
  }, [stategeneraljournaldata]);

  return (
    <>
      <div hidden>
        <GeneralJournalPrint
          ref={componentRef}
          statecredit={statecredit}
          statedebit={statedebit}
          printData={stategeneraljournaldata}
        />
      </div>
      <div className="row app_font ">
        <div className="dash_background col-12 pt-2 ">
          <div className="row ">
            <div className="col-md-3 text-center  pr-0 pl-0">
              <label className="input_label m-0">From:</label>
              <input
                type="date"
                className="input_date ml-3"
                id="fromdate"
                defaultValue={localStorage.getItem("OpeningYear")}
              ></input>
            </div>
            <div className="col-md-3 text-center pr-0 pl-0">
              <label className="input_label m-0">To:</label>
              <input
                type="date"
                className="input_date ml-3"
                id="todate"
                defaultValue={moment().format("YYYY-MM-DD")}
              ></input>
            </div>
            <div className="col-md-1 pr-0 pl-0">
              <button className="btn_Go" onClick={savedata} id="go">
                Go
              </button>
            </div>
            <div className="col-md-1 pr-0 pl-0">
              {loader && (
                <Loader type="ThreeDots" color="green" height={40} width={40} />
              )}
            </div>
          </div>

          <div className=" col-12  table_height pr-0 pl-0">
            <div className="table-responsive mt-2">
              <table className="table table-borderless m-0">
                <thead>
                  <tr>
                    <th>Sr</th>
                    <th>Date</th>
                    <th>ID</th>
                    <th>Narration</th>
                    <th>Debit</th>
                    <th>Credit</th>
                  </tr>
                </thead>
                <tbody>
                  {stategeneraljournaldata.map((val, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {moment(val.TransDate.split("T")[0]).format(
                          "DD-MMM-YYYY"
                        )}
                      </td>

                      <td>{val.ID}</td>
                      <td>{val.Particular}</td>

                      {val.Debit !== "" ? (
                        <td>{parseFloat(val.Debit).toLocaleString()}</td>
                      ) : (
                        <td></td>
                      )}
                      {val.Credit !== "" ? (
                        <td>{parseFloat(val.Credit).toLocaleString()}</td>
                      ) : (
                        <td></td>
                      )}
                    </tr>
                  ))}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      {" "}
                      <label className="input_label m-0">Total:</label>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="input_total_styling "
                        placeholder="Debit"
                        disabled
                        defaultValue={parseFloat(statedebit).toLocaleString()}
                        key={statedebit}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="input_total_styling "
                        placeholder="Credit"
                        disabled
                        defaultValue={parseFloat(statecredit).toLocaleString()}
                        key={statecredit}
                      ></input>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12  text-right pr-0 pl-0">
            <button
              disabled={stategeneraljournaldata?.length > 0 ? false : true}
              className="print_btn float-left"
              onClick={() => {
                printPaymentRec();
              }}
            >
              <TiPrinter size="1.3em" />
              Print
            </button>
            {stategeneraljournaldata.length > 0 && (
              <button
                style={{ color: "black !important" }}
                className="print_btn ml-2 float-left"
              >
                <CSVLink
                  style={{ color: "black" }}
                  filename="General_Journal_Csv"
                  data={CsvData}
                >
                  Download CSV
                </CSVLink>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralJournalReport;
