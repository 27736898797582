import axios from "axios";
import { toast } from "react-toastify";
export const GetAllAllowances = (data) => {
  return {
    type: "get_allallowances",
    payload: data,
    loader: false,
  };
};
export function getallAllowances() {
  return (dispatch) => {
    axios({
      url: `${process.env.REACT_APP_API_URL}Allowances/GetAll`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllAllowances(result.data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
export const GetAllDesignation = (data) => {
  return {
    type: "get_alldesignation",
    payload: data,
    loader: false,
  };
};
export function getallDesignation() {
  return (dispatch) => {
    axios({
      url: `${process.env.REACT_APP_API_URL}Designations/GetAll`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllDesignation(result.data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
// deducttion
export const GetAllDeduction = (data) => {
  return {
    type: "get_allDeduction",
    payload: data,
    loader: false,
  };
};
export function getallDeduction() {
  return (dispatch) => {
    axios({
      url: `${process.env.REACT_APP_API_URL}Deductions/GetAll`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess === true) {
          dispatch(GetAllDeduction(result.data));
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        toast.info("Something went wrong!");
      }
    );
  };
}
