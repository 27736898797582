import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getUnSubmittedVouchers } from "../../../actions/vouchersactions/GetUnsubmittedVouchers";
import FullScreenLoader from "../../../FullScreenLoader/FullScreenLoader";
import DeleteUnsubmittedVouchersModal from "./DeleteUnsubmittedModal";
import { CSVLink } from "react-csv";
import UnSubmittedVouchersModal from "./UnSubmittedVouchersModal";

function UnSubmittedVouchers() {
  const [apiLoading, setapiloading] = useState(false);
  const dispatch = useDispatch();
  const [allData, setallData] = useState([]);
  const VouchersData = useSelector((state) => state.getAllUnSubmittedVouchers);
  const [voucherIds, setvoucherIds] = useState([]);
  const [showTransactions, setshowTransactions] = useState(false);
  const [transactionData, settransactionData] = useState(false);
  const [transactionDebitTotal, settransactionDebitTotal] = useState(0);
  const [transactionCreditTotal, settransactionCreditTotal] = useState(0);
  const [multiTransaction, setmultiTransaction] = useState(false);
  let taxTotal = 0;
  const [screenLoader, setscreenLoader] = useState(false);
  const [sendNotification, setsendNotification] = useState("");
  const [loading1, setLoading1] = useState(false);
  const [unitRecieptRowData, setunitRecieptRowData] = useState(null);
  const [taxDetails, settaxDetails] = useState([]);
  const [DeleteVoucherData, setDeleteVoucherData] = useState({
    Email: "",
    Password: "",
    Reason: "",
  });
  const [voucherName, setvoucherName] = useState(null);
  const [filterObject, setfilterObject] = useState({
    user: "",
    voucherNumber: "",
    type: "",
  });
  useEffect(() => {
    dispatch(getUnSubmittedVouchers());
  }, [dispatch]);
  console.log(voucherIds);
  //   useEffect(()=>{
  //     let arr=[];
  // allData?.map((all)=>{
  //   voucherIds?.map((vouchId)=>{
  // if(all?.Notification === true && all?.VoucherId===vouchId){
  //   arr.push({
  //     voucherId :vouchId,
  //     Notification :all?.Notification
  //   })
  // }
  // else if(all?.Notification === false && all?.VoucherId===vouchId){
  //   arr.push({
  //     voucherId :vouchId,
  //     Notification :false
  //   })
  // }
  //   })
  // })
  // console.log(arr);
  //   },[allData,voucherIds])
  useEffect(() => {
    if (VouchersData?.data?.Vouchers) {
      setallData(JSON.parse(JSON.stringify(VouchersData?.data?.Vouchers)));
    }
  }, [VouchersData]);
  const onTextChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setfilterObject({ ...filterObject, [name]: value });
    console.log(filterObject.type);
    if (
      document.getElementById("accountfilter").value === "" &&
      document.getElementById("datefilter").value === "" &&
      document.getElementById("voucherfilter").value === ""
    ) {
      setallData(VouchersData?.data?.Vouchers);
    } else {
      let suggestions = [];
      {
        const regex = new RegExp(
          document.getElementById("accountfilter").value,
          "i"
        );
        const regex1 = new RegExp(
          document.getElementById("voucherfilter").value,
          "i"
        );
        const regex2 = new RegExp(
          document.getElementById("datefilter").value,
          "i"
        );

        suggestions = VouchersData?.data?.Vouchers?.sort().filter(
          (val) =>
            (document.getElementById("accountfilter").value === "" ||
              regex.test(val.VoucherName)) &&
            (document.getElementById("voucherfilter").value === "" ||
              regex1.test(val.VoucherId)) &&
            (document.getElementById("datefilter").value === "" ||
              regex2.test(val.AddedBy))
        );
      }

      setallData(suggestions);
    }
  };
  const submitAll = () => {
    document.getElementById("loansave").disabled = true;
    setLoading1(true);
    let arr = [];
    allData?.map((all) => {
      voucherIds?.map((vouchId) => {
        if (all?.Notification === true && all?.VoucherId === vouchId) {
          arr.push({
            voucherId: vouchId,
            Notification: all?.Notification,
          });
        } else if (all?.Notification === false && all?.VoucherId === vouchId) {
          arr.push({
            voucherId: vouchId,
            Notification: false,
          });
        }
      });
    });
    // let url2=`${process.env.REACT_APP_API_URL}Vouchers/SubmitAll?Notification=${sendNotification}`;

    let url1 = `${process.env.REACT_APP_API_URL}Vouchers/SubmitAll`;
    document.getElementById("loansave").disabled = true;
    axios({
      method: "post",
      // url: `${process.env.REACT_APP_API_URL}Lenders/AddLoan`,
      url: url1,
      data: JSON.stringify(arr),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        document.getElementById("loansave").disabled = false;
        setLoading1(false);

        if (result.data.IsSuccess === true) {
          setsendNotification(false);
          dispatch(getUnSubmittedVouchers());
          toast.info(result.data.Message);
          setallData(JSON.parse(JSON.stringify(VouchersData?.data?.Vouchers)));

          setvoucherIds([]);
        } else {
          toast.info(result.data.Message);
        }
      },
      (error) => {
        document.getElementById("loansave").disabled = false;
        setLoading1(false);

        toast.info("Someting went wrong");
      }
    );
  };
  const getTransactions = (item) => {
    setmultiTransaction(false);
    if (item.VoucherName === "Unit Receipt") {
      toast.info("Unit Receipt Details not Available");
      return;
    }
    setscreenLoader(true);
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}Vouchers/UnsubmittedDetail?voucherId=${item?.VoucherId}`,

      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    })
      .then((res) => {
        setscreenLoader(false);
        if (res.data.IsSuccess === true) {
          let DebitAmount = 0;
          let creditAmount = 0;
          settaxDetails(res.data[`TaxDetail`]);
          const sumOfTaxAmount = res.data[`TaxDetail`].reduce(
            (sum, item) => sum + item.TaxAmount,
            0
          );
          if (
            item.VoucherName === "C-RV" ||
            item.VoucherName === "B-RV" ||
            item.VoucherName === "C-PV" ||
            item.VoucherName === "B-PV"
          ) {
            let findObj = res.data[`GeneralDetail`].find(
              (item) => item.GeneralVoucherSavedDetail?.length > 0
            );
            if (findObj) {
              setmultiTransaction(true);
              findObj?.GeneralVoucherSavedDetail?.forEach((item) => {
                if (item.TransactionType === "CREDIT") {
                  creditAmount = creditAmount + item?.Amount;
                } else {
                  DebitAmount = DebitAmount + item.Amount;
                }
              });

              if (item.VoucherName === "C-RV" || item.VoucherName === "B-RV") {
                DebitAmount = DebitAmount + findObj?.NetAmount;
              } else {
                creditAmount = creditAmount + findObj?.NetAmount;
              }
            } else {
              res.data[`GeneralDetail`]?.forEach((item) => {
                DebitAmount = creditAmount + item.NetAmount;
                creditAmount = DebitAmount + item?.NetAmount;
              });
            }
            settransactionData(findObj ? findObj : res.data[`GeneralDetail`]);

            if (item.VoucherName == "C-RV" || item.VoucherName == "B-RV") {
              setvoucherName("GR");
              DebitAmount = DebitAmount + sumOfTaxAmount;
            } else {
              setvoucherName("GP");
              creditAmount = creditAmount + sumOfTaxAmount;
            }
          } else if (item.VoucherName === "JV") {
            settransactionData(res.data[`JournalDetail`]);
            res.data[`JournalDetail`]?.forEach((item) => {
              // arr.push({...item?.cre})
              if (item.TransactionType === "DEBIT") {
                DebitAmount = DebitAmount + item.Amount;
              } else if (item.TransactionType === "CREDIT") {
                creditAmount = creditAmount + item.Amount;
              }
            });
            setvoucherName("JV");
          } else if (item.VoucherName === "Unit Receipt") {
            settransactionData(res.data[`URDetail`]);
          } else if (item.VoucherName === "Commission Payment") {
            console.log(res.data[`ComissionPaymentDetail`]);
            settransactionData(res.data[`ComissionPaymentDetail`]);
            res.data[`ComissionPaymentDetail`]?.forEach((item) => {
              DebitAmount = item.Amount;
              creditAmount = item?.Amount;
              // arr.push({...item?.cre})
              // if (item.TransactionType === "DEBIT") {
              //     DebitAmount = DebitAmount + item.Amount
              // } else if (item.TransactionType === "CREDIT") {
              //     creditAmount = creditAmount + item.Amount
              // }
            });
            setvoucherName("CP");
          }
          settransactionDebitTotal(DebitAmount);
          settransactionCreditTotal(creditAmount);
          setshowTransactions(true);
        }
      })
      .catch((err) => {
        setscreenLoader(false);
        toast.error("Something went wrong!");
      });
  };

  const handelDeleteVouvherdataChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setDeleteVoucherData({ ...DeleteVoucherData, [name]: value });
  };
  function showmodal2(data) {
    setunitRecieptRowData(data);
    document.getElementById("mymodal2").style.display = "block";
  }
  function closemodal2() {
    document.getElementById("mymodal2").style.display = "none";
    setDeleteVoucherData({
      Email: "",
      Password: "",
      Reason: "",
    });
  }
  const [loading2, setloading2] = useState(false);
  function deleteUnitRecipteVoucher() {
    if (DeleteVoucherData?.Email === "") {
      return toast.info("Email is Required");
    } else if (DeleteVoucherData?.Password === "") {
      return toast.info("Password is Required");
    } else if (DeleteVoucherData?.Reason === "") {
      return toast.info("Reason is Required");
    }
    setloading2(true);
    let idss = [];
    console.log(voucherIds);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}Scripts/DeleteAllUnsubmittedVouchers`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
      data: {
        Email: DeleteVoucherData?.Email,
        Password: DeleteVoucherData?.Password,
        Reason: DeleteVoucherData?.Reason,
        voucherId: voucherIds,
      },
    }).then(
      (result) => {
        console.log(result);
        // document.getElementById("headingsave").disabled = false;
        if (result.data.IsSuccess === true) {
          setDeleteVoucherData({
            Email: "",
            Password: "",
            Reason: "",
          });
          // dispatch(getAllContractTablePopulate());
          dispatch(getUnSubmittedVouchers());

          toast.info(result.data.Message);
          setloading2(false);
          setunitRecieptRowData(null);
          setvoucherIds([]);
          closemodal2();
        } else {
          toast.info(result.data.Message);
          setDeleteVoucherData({
            Email: "",
            Password: "",
            Reason: "",
          });
          setloading2(false);
          // setvoucherRowdata(null)
        }
      },
      (error) => {
        setloading2(false);

        toast.info("Someting went wrong");
        // document.getElementById("headingsave").disabled = false;
      }
    );
  }

  const [CsvData, setCsvData] = useState([]);
  useEffect(() => {
    let arr = [];

    allData?.forEach((itm, idx) => {
      arr.push({
        Sr: idx + 1,
        Date: moment(itm.VoucherDate).format("DD-MMM-YYYY"),
        "Voucher No.": itm.VoucherId,
        Type: itm.VoucherName,
        "Debit Acc":
          itm?.VoucherName === "JV" ? itm.DebitAccountJV : itm?.DebitAccountGV,
        "Credit Acc":
          itm?.VoucherName === "JV"
            ? itm.CreditAccountJV
            : itm?.CreditAccountGV,
        Amount: itm.Amount?.toLocaleString(),
        User: itm.AddedBy,
        Narration: itm.Narration,
      });
    });
    setCsvData(arr);
  }, [allData]);
  console.log(transactionData);
  return (
    <div className="row">
      <div className="dash_background col-12 p-0">
        <div className="row ">
          <div className="col-md-12">
            <>
              <div className="row pt-3">
                <div className="col-md-1 pr-0 pl-0">
                  <label className="input_label m-0">Search:</label>
                </div>
                <div className="col-md-3 pr-0 pl-0">
                  <input
                    autoComplete="off"
                    type="text"
                    className="input_styling "
                    id="datefilter"
                    name="user"
                    readonly="readonly"
                    onMouseDown={(e) => {
                      e.target.removeAttribute("readonly");
                    }}
                    value={filterObject?.user}
                    onChange={onTextChange}
                    placeholder="User"
                  ></input>
                </div>
                <div className="col-md-3 pr-0 pl-0">
                  <input
                    autoComplete="off"
                    type="text"
                    className="input_styling "
                    id="voucherfilter"
                    name="voucherNumber"
                    readonly="readonly"
                    onMouseDown={(e) => {
                      e.target.removeAttribute("readonly");
                    }}
                    value={filterObject?.voucherNumber}
                    onChange={onTextChange}
                    placeholder="Voucher No"
                  ></input>
                </div>
                <div className="col-md-3 pr-0 pl-0">
                  <input
                    autoComplete="off"
                    type="text"
                    className="input_styling "
                    id="accountfilter"
                    name="type"
                    readonly="readonly"
                    onMouseDown={(e) => {
                      e.target.removeAttribute("readonly");
                    }}
                    value={filterObject?.type}
                    onChange={onTextChange}
                    placeholder="Type"
                  ></input>
                </div>
                <div className="col-md-1 pr-0 pl-0">
                  <UnSubmittedVouchersModal />
                </div>
                <div className="col-md-1 pr-0 pl-0">
                  {VouchersData?.loading ? (
                    <Loader
                      type="ThreeDots"
                      color="green"
                      height={40}
                      width={40}
                    />
                  ) : null}
                </div>
                {voucherIds?.length > 0 && (
                  <div className="col-md-12 mt-2">
                    <div className="float-right  ">
                      {loading1 && (
                        <Loader
                          type="ThreeDots"
                          color="green"
                          height={40}
                          width={40}
                        />
                      )}
                      <span>
                        <button
                          onClick={() => {
                            setallData(
                              JSON.parse(
                                JSON.stringify(VouchersData?.data?.Vouchers)
                              )
                            );

                            setvoucherIds([]);
                          }}
                          className="cancel_btn ml-2  "
                        >
                          Cancel
                        </button>
                      </span>
                      <span>
                        <button
                          onClick={() => {
                            showmodal2();
                          }}
                          className="cancel_btn ml-2  "
                        >
                          Delete
                        </button>
                      </span>
                      <span>
                        <button
                          onClick={submitAll}
                          className="save_btn ml-2  "
                          id="loansave"
                        >
                          <BsCloudUpload size="1.3em" />
                          Submit
                        </button>
                      </span>
                    </div>
                  </div>
                )}
              </div>
              {/* <div className="row pt-3 ">
                                <div className="col-md-1 pr-0 pl-0">



                                </div>
 
                                <div className="col-md-3   pr-0 pl-0">
                                    <label className="input_label m-0">From:</label>
                                    <input type="date" className="input_date ml-3" id="fromdate"></input>
                                </div>
                                <div className="col-md-3  pr-0 pl-0">
                                    <label className="input_label m-0">To:</label>
                                    <input type="date" className="input_date ml-3" id="todate"></input>
                                </div>

                                <div className="col-md-1 pr-0 pl-0">
                                    <button className="btn_Go"
                                    //  onClick={ongo}
                                    >Go</button>
                                </div>
                                <div className="col-md-1 pr-0 pl-0">


                                    {apiLoading && <Loader type="ThreeDots"
                                        color="green"
                                        height={40}
                                        width={40} />}
                                </div>
                            </div> */}

              <div className="table-responsive mt-2">
                <table className="table table-borderless m-0">
                  <thead>
                    <tr>
                      <th>Notification</th>
                      <th></th>
                      <th>Date</th>
                      <th>Voucher</th>
                      <th>Type</th>
                      <th>Debit Acc</th>
                      <th>Credit Acc</th>
                      <th>Amount</th>
                      <th>User</th>
                      <th>Narration</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allData?.map((itm, idx) => {
                      // console.log(itm);
                      return (
                        <tr key={idx}>
                          <td>
                            <input
                              checked={itm?.Notification}
                              // value={sendNotification}
                              key={idx}
                              onChange={(e) => {
                                // setsendNotification(idx)
                                console.log(allData[idx]);
                                let vap = JSON.parse(JSON.stringify(allData));
                                vap[idx].Notification = e.target.checked;
                                setallData(vap);
                              }}
                              type="checkbox"
                              id={`checkedVoucherNoti`}
                            />
                          </td>
                          <td>
                            <input
                              checked={itm.checked ? itm.checked : false}
                              key={idx}
                              onChange={(e) => {
                                let arr = [...voucherIds];
                                if (e.target.checked) {
                                  arr.push(itm.VoucherId);
                                } else {
                                  let index = arr.indexOf(itm.VoucherId);
                                  arr.splice(index, 1);
                                }
                                allData[idx].checked = e.target.checked;

                                setvoucherIds(arr);
                              }}
                              type="checkbox"
                              id={`checkedVoucher${idx}`}
                            />
                          </td>
                          <td
                            className="table_data"
                            onClick={() => getTransactions(itm)}
                          >
                            {moment(itm.VoucherDate).format("DD-MMM-YYYY")}
                          </td>

                          <td
                            className="table_data"
                            onClick={() => getTransactions(itm)}
                          >
                            {itm.VoucherId}
                          </td>
                          <td
                            className="table_data"
                            onClick={() => getTransactions(itm)}
                          >
                            {itm.VoucherName}
                          </td>
                          <td
                            className="table_data"
                            onClick={() => getTransactions(itm)}
                          >
                            {itm?.VoucherName === "JV"
                              ? itm.DebitAccountJV
                              : itm?.DebitAccountGV}
                          </td>
                          <td
                            className="table_data"
                            onClick={() => getTransactions(itm)}
                          >
                            {itm?.VoucherName === "JV"
                              ? itm.CreditAccountJV
                              : itm?.CreditAccountGV}
                          </td>
                          <td
                            className="table_data"
                            onClick={() => getTransactions(itm)}
                          >
                            {itm.Amount?.toLocaleString()}
                          </td>

                          <td
                            className="table_data"
                            onClick={() => getTransactions(itm)}
                          >
                            {itm.AddedBy}
                          </td>
                          <td
                            className="table_data"
                            onClick={() => getTransactions(itm)}
                          >
                            {itm.Narration}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <button
                style={{ color: "black !important" }}
                className="print_btn ml-2 float-left"
              >
                <CSVLink
                  style={{ color: "black" }}
                  filename="UnSubmitted_Vouchers_Csv"
                  data={CsvData}
                >
                  Download CSV
                </CSVLink>
              </button>
            </>
          </div>
          <div className="col-md-12"></div>
        </div>
      </div>
      {showTransactions && (
        <div id="mymodal" style={{ display: "block" }} class="modal">
          <div class="modal-content-cat_voucher">
            <div className="row">
              <div className="col-12  page_heading">
                <h4 className="  text-center pt-2">Voucher Detail</h4>
                <span
                  class="close pr-2  "
                  onClick={() => {
                    setshowTransactions(false);
                  }}
                >
                  &times;
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mt-2">
                <div className="table-responsive_modal mt-4 ">
                  <table className="table table-borderless m-0 ">
                    <thead>
                      <tr>
                        <th>Sr</th>
                        <th>Account</th>
                        <th>Narration</th>
                        <th>Debit</th>

                        <th>Credit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {taxDetails?.map((itm, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{itm.Tax?.PayableAccount?.AccountTitle}</td>
                            <td>{itm.Tax?.PayableAccount?.Narration}</td>{" "}
                            <td>
                              {itm.ReferenceType?.includes("Receipt")
                                ? itm.TaxAmount
                                : ""}
                            </td>
                            <td>
                              {" "}
                              {itm.ReferenceType.includes("Payment")
                                ? itm.TaxAmount
                                : ""}
                            </td>
                          </tr>
                        );
                      })}
                      {voucherName === "JV" ? (
                        transactionData?.map((x, i) => {
                          return (
                            <>
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{x.Account?.AccountTitle}</td>
                                <td>{x.Description}</td>
                                <td>
                                  {x.TransactionType === "DEBIT"
                                    ? x.Amount?.toLocaleString()
                                    : null}
                                </td>
                                <td>
                                  {x.TransactionType === "CREDIT"
                                    ? x.Amount?.toLocaleString()
                                    : null}
                                </td>
                              </tr>
                            </>
                          );
                        })
                      ) : voucherName === "CP" ? (
                        transactionData?.map((x, i) => {
                          let taxTotal = 0;
                          x?.VoucherTaxSubmitedDetail?.forEach((tx) => {
                            taxTotal = taxTotal + tx.TaxAmount;
                          });
                          return (
                            <>
                              <tr key={i}>
                                <td>{i + 1 + taxDetails.length}</td>
                                <td>
                                  {x?.AffiliateCnicNavigation?.AffiliateName ||
                                    x?.CustomerCnicNavigation?.CustomerName}
                                </td>
                                <td>{x.Narration}</td>
                                <td>{x.Amount?.toLocaleString()}</td>

                                <td></td>
                              </tr>
                              <tr key={i}>
                                <td>{i + 2 + taxDetails.length}</td>
                                <td>{x.BankAccount?.AccountTitle}</td>
                                <td>{x.Narration}</td>
                                <td></td>
                                <td>
                                  {(x.Amount - taxTotal)?.toLocaleString()}
                                </td>
                              </tr>
                              {/* {x?.VoucherTaxSubmitedDetail?.map((item, ind) => {
                                taxTotal = taxTotal + Number(item?.TaxAmount);
                                console.log(taxTotal);
                                return (
                                  <>
                                    <tr key={ind}>
                                      <td>{ind + 3}</td>
                                      <td>{item.Tax?.TaxName}</td>
                                      <td>{x.Narration}</td>
                                      <td></td>
                                      <td>
                                        {Number(
                                          JSON.stringify(
                                            item?.TaxAmount
                                          )?.split(".")[0]
                                        )?.toLocaleString()}
                                      </td>
                                    </tr>
                                  </>
                                );
                              })} */}
                            </>
                          );
                        })
                      ) : voucherName === "GR" ? (
                        !multiTransaction ? (
                          <>
                            {transactionData?.map((x, i) => {
                              return (
                                <>
                                  <tr key={i}>
                                    <td>{i + 1 + taxDetails.length}</td>
                                    <td>{x.DebitAccount?.AccountTitle}</td>
                                    <td>{x.Narration}</td>
                                    <td>{x.NetAmount?.toLocaleString()}</td>

                                    <td></td>
                                  </tr>
                                  <tr key={i}>
                                    <td>{i + 2}</td>
                                    <td>{x.CreditAccount?.AccountTitle}</td>
                                    <td>{x.Narration}</td>
                                    <td></td>
                                    <td>{x.NetAmount?.toLocaleString()}</td>
                                  </tr>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <tr>
                              <td>{taxDetails.length + 1}</td>
                              <td>
                                {transactionData.DebitAccount?.AccountTitle}
                              </td>
                              <td>{transactionData.Narration}</td>
                              <td>
                                {transactionData.NetAmount?.toLocaleString()}
                              </td>

                              <td></td>
                            </tr>
                            {transactionData?.GeneralVoucherSavedDetail?.map(
                              (x, i) => {
                                return (
                                  <>
                                    <tr key={i}>
                                      <td>{i + taxDetails.length + 2}</td>
                                      <td>{x?.Account?.AccountTitle}</td>
                                      <td>{x.Narration}</td>
                                      <td>
                                        {" "}
                                        {x.TransactionType == "DEBIT"
                                          ? x?.Amount?.toLocaleString()
                                          : ""}
                                      </td>

                                      <td>
                                        {x.TransactionType == "CREDIT"
                                          ? x?.Amount?.toLocaleString()
                                          : ""}
                                      </td>
                                    </tr>
                                  </>
                                );
                              }
                            )}
                          </>
                        )
                      ) : voucherName === "GP" ? (
                        !multiTransaction ? (
                          transactionData?.map((x, i) => {
                            return (
                              <>
                                <tr key={i}>
                                  <td>{i + 1 + taxDetails.length}</td>
                                  <td>{x.DebitAccount?.AccountTitle}</td>
                                  <td>{x.Narration}</td>
                                  <td>{x.NetAmount?.toLocaleString()}</td>

                                  <td></td>
                                </tr>
                                <tr key={i}>
                                  <td>{i + 2 + taxDetails.length}</td>
                                  <td>{x.CreditAccount?.AccountTitle}</td>
                                  <td>{x.Narration}</td>
                                  <td></td>
                                  <td>{x.NetAmount?.toLocaleString()}</td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <>
                            <td>{taxDetails.length + 1}</td>
                            <td>
                              {transactionData.CreditAccount?.AccountTitle}
                            </td>
                            <td>{transactionData.Narration}</td>
                            <td></td>
                            <td>
                              {transactionData.NetAmount?.toLocaleString()}
                            </td>

                            {transactionData?.GeneralVoucherSavedDetail?.map(
                              (x, i) => {
                                return (
                                  <>
                                    <tr key={i}>
                                      <td>{i + 2 + taxDetails.length}</td>
                                      <td>{x?.Account?.AccountTitle}</td>
                                      <td>{x.Narration}</td>
                                      <td>
                                        {" "}
                                        {x.TransactionType == "DEBIT"
                                          ? x?.Amount?.toLocaleString()
                                          : ""}
                                      </td>

                                      <td>
                                        {x.TransactionType == "CREDIT"
                                          ? x?.Amount?.toLocaleString()
                                          : ""}
                                      </td>
                                    </tr>
                                  </>
                                );
                              }
                            )}
                          </>
                        )
                      ) : null}
                      <tr>
                        <td></td>
                        <td></td>

                        <td>
                          {" "}
                          <label className="input_label m-0">Total:</label>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="input_total_styling "
                            placeholder="Debit"
                            disabled
                            defaultValue={parseFloat(
                              transactionDebitTotal
                            ).toLocaleString()}
                          ></input>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="input_total_styling "
                            placeholder="Credit"
                            disabled
                            defaultValue={parseFloat(
                              transactionCreditTotal
                            ).toLocaleString()}
                          ></input>
                        </td>
                        {/* <td><input type="text" className="input_total_styling " placeholder="Balance" disabled value={stateledgerdata[stateledgerdata.length - 1]?.AccountBalance?.toLocaleString()}></input></td> */}
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-12 mt-2">
                {/* <button className="print_btn float-left" onClick={() => { printGenrealRecModal(); }}><TiPrinter size="1.3em" />Print</button> */}
              </div>
            </div>
          </div>
        </div>
      )}
      {screenLoader && <FullScreenLoader setshowModal={setscreenLoader} />}
      <DeleteUnsubmittedVouchersModal
        closemodal2={closemodal2}
        DeleteVoucherData={DeleteVoucherData}
        handelDeleteVouvherdataChange={handelDeleteVouvherdataChange}
        loading2={loading2}
        setunitRecieptRowData={setunitRecieptRowData}
        deleteUnitRecipteVoucher={deleteUnitRecipteVoucher}
      />
    </div>
  );
}

export default UnSubmittedVouchers;
