import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import PropTypes from "prop-types";

function ChangeStatusModal({
  selectedUser,
  selectedUserStatus,
  closemodal,
  callGetApi,
}) {
  const [loading, setLoading] = useState(false);

  function handleDelete() {
    if (document.getElementById("userEmail").value === "") {
      toast.info("Email is required");
    } else if (document.getElementById("userPassword").value === "") {
      toast.info("Password is required");
    } else {
      setLoading(true);

      const postDate = {
        UserId: selectedUser?.Id,
        Status: selectedUserStatus,
        Email: document.getElementById("userEmail").value,
        Password: document.getElementById("userPassword").value,
      };

      axios({
        url: `${process.env.REACT_APP_API_URL}Customers/UserStatusChange`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
        data: JSON.stringify(postDate),
      }).then(
        (result) => {
          setLoading(false);
          if (result.data.IsSuccess === true) {
            toast.info(result.data.Message);
            document.getElementById("userEmail").value = "";
            document.getElementById("userPassword").value = "";
            callGetApi();
            closemodal();
          } else {
            toast.info(result.data.Message);
          }
        },
        () => {
          setLoading(false);
          toast.info("Something went wrong!");
        }
      );
    }
  }
  return (
    <div id="changeStatusModal" class="modal">
      <div class="modal-add-seller">
        <div className="row">
          <div className="col-12  page_heading">
            <h4 className="  text-center pt-2">Change User Status</h4>
            <span
              class="close pr-2  "
              onClick={() => {
                document.getElementById("userEmail").value = "";
                document.getElementById("userPassword").value = "";
                closemodal();
              }}
            >
              &times;
            </span>
          </div>
        </div>

        <div class="modal-body">
          <p className="font-weight-bold">
            Please enter Email and Password to change the status of "
            {selectedUser?.FullName}"
          </p>
          <div className="row mt-2">
            <div className="col-2 text-left p-0">
              <label className="input_label m-0 ">Email :</label>
            </div>
            <div className="col-10  p-0">
              <input
                type="email"
                className="input_styling ml-md-3"
                id="userEmail"
                autoComplete="off"
                readOnly
                onMouseDown={(e) => {
                  e.target.removeAttribute("readOnly");
                }}
              />
              <span className="estaric">*</span>
            </div>

            <div className="col-2 text-left p-0 mt-4">
              <label className="input_label m-0 ">Password :</label>
            </div>
            <div className="col-10 p-0 mt-4">
              <input
                type="password"
                className="input_styling ml-md-3"
                id="userPassword"
                autoComplete="off"
                readOnly
                onMouseDown={(e) => {
                  e.target.removeAttribute("readOnly");
                }}
              />
              <span className="estaric">*</span>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          {loading && (
            <Loader
              type="Oval"
              color="black"
              height={30}
              width={30}
              radius={12}
            />
          )}
          <button
            className="save_btn  ml-2"
            id="creditorsave"
            disabled={loading}
            onClick={handleDelete}
          >
            Change
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChangeStatusModal;
ChangeStatusModal.propTypes = {
  selectedUser: PropTypes.any,
  selectedUserStatus: PropTypes.bool,
  closemodal: PropTypes.func,
  callGetApi: PropTypes.func,
};
