import React, { Component } from "react";
import gravity from "../../../../assets/GravityLogo.png";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";
import moment from "moment";
class BalanceSheetPrint extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "white", height: "100%", margin: "10px" }}>
        <div className="row m-0 p-0">
          <PrintHeader />
        </div>
        <div
          className="row m-3 p-0"
          // style={{ border: "5px solid darkgrey", height: "auto" }}
        >
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>Balance Sheet </u>
            </h4>
          </div>
        </div>
        {/* assets */}
        <div className="row ">
          <div className="col-md-6 pr-0 pl-0 pt-3 ">
            <h5>
              <b>Assets</b>
            </h5>
          </div>
          <div className="col-md-3 pr-0 pl-0">
            <div>
              <b>
                {/* {statealldata.data !== undefined
                    ? statealldata.data.PastYear
                    : null} */}
                {/* {this.props.statealldata.data?.LastOpeningDate &&
                  moment(this.props.statealldata.data?.LastOpeningDate)?.format(
                    "DD-MMM-YYYY"
                  )}{" "}
                {this.props.statealldata.data?.LastClosingDate &&
                this.props.statealldata.data?.LastClosingDate
                  ? "To"
                  : ""}{" "} */}
                {this.props.statealldata.data?.LastClosingDate &&
                  moment(this.props.statealldata.data?.LastClosingDate)?.format(
                    "DD-MMM-YYYY"
                  )}
              </b>
            </div>
            <div>
              <b>Rupees</b>
            </div>
          </div>
          <div className="col-md-3 pr-0 pl-0">
            <div>
              <b>
                {/* {statealldata.data !== undefined
                    ? statealldata.data.CurrentYear
                    : null} */}
                {/* {this.props.statealldata.data?.CurrentOpeningDate &&
                  moment(
                    this.props.statealldata.data?.CurrentOpeningDate
                  )?.format("DD-MMM-YYYY")}{" "}
                {this.props.statealldata.data?.CurrentOpeningDate &&
                this.props.statealldata.data?.CurrentClosingDate
                  ? "To"
                  : ""}{" "} */}
                {this.props.statealldata.data?.CurrentClosingDate &&
                  moment(
                    this.props.statealldata.data?.CurrentClosingDate
                  )?.format("DD-MMM-YYYY")}
              </b>
            </div>
            <div>
              <b>Rupees</b>
            </div>
          </div>
        </div>
        {/* non current assets */}
        <div className="row ">
          <div className="col-6 pr-0 pl-3">
            <label className="input_label m-0">
              <b>Non Current Assets</b>
            </label>
          </div>
          <div className="col-3 pr-0 pl-3"></div>
          <div className="col-3 pr-0 pl-3"></div>
        </div>
        <div className="row pt-3">
          <div className="col-6 pr-0 pl-3">
            {this.props.statenewdata?.FixedAsset !== undefined
              ? this.props.statenewdata?.FixedAsset.map((val) => (
                  <div>{val.Title}</div>
                ))
              : null}
          </div>
          <div className="col-3 pr-0 pl-3">
            {this.props.stateolddata?.FixedAsset !== undefined
              ? this.props.stateolddata?.FixedAsset.map((val) => (
                  <div>{val.Balance?.toLocaleString()}</div>
                ))
              : null}
          </div>
          <div className="col-3 pr-0 pl-3">
            {this.props.statenewdata?.FixedAsset !== undefined
              ? this.props.statenewdata?.FixedAsset.map((val) => (
                  <div>{val.Balance?.toLocaleString()}</div>
                ))
              : null}
          </div>
        </div>
        {/* Current Assets */}
        <div className="row pt-3">
          <div className="col-6 pr-0 pl-3">
            <label className="input_label m-0">
              <b>Current Assets</b>
            </label>
          </div>
          <div className="col-3 pr-0 pl-3"></div>
          <div className="col-3 pr-0 pl-3"></div>
        </div>
        <div className="row pt-3">
          <div className="col-6 pr-0 pl-3">
            {this.props.statenewdata?.CurrentAsset !== undefined
              ? this.props.statenewdata?.CurrentAsset.map((val) => (
                  <div>{val.Title}</div>
                ))
              : null}
          </div>
          <div className="col-3 pr-0 pl-3">
            {this.props.stateolddata?.CurrentAsset !== undefined
              ? this.props.stateolddata?.CurrentAsset.map((val) => (
                  <div>{val.Balance?.toLocaleString()}</div>
                ))
              : null}
          </div>
          <div className="col-3 pr-0 pl-3">
            {this.props.statenewdata?.CurrentAsset !== undefined
              ? this.props.statenewdata?.CurrentAsset.map((val) => (
                  <div>{val.Balance?.toLocaleString()}</div>
                ))
              : null}
          </div>
        </div>
        {/* Total Assets */}
        <div className="row pt-3">
          <div className="col-6 pr-0 pl-3 text-center">
            <label className="input_label m-0">
              <b>Total Assets</b>
            </label>
          </div>
          <div className="col-3 pr-0 pl-3">
            {parseFloat(
              this.props.stateolddata?.FixedAssetSum +
                this.props.stateolddata?.CurrentAssetSum
            ).toLocaleString()}
          </div>
          <div className="col-3 pr-0 pl-3">
            {parseFloat(
              this.props.statenewdata?.FixedAssetSum +
                this.props.statenewdata?.CurrentAssetSum
            ).toLocaleString()}
          </div>
        </div>
        {/* Equity and liability */}
        <div className="row pt-3">
          <div className="col12 pr-0 pl-3 text-center">
            <h5>
              <b>Equity and Liability </b>
            </h5>
          </div>
          {/* <div className="col-3 pr-0 pl-3">

                  
                           
                        </div>
                        <div className="col-3 pr-0 pl-3">

       
                        </div> */}
        </div>
        {/* SHARE CAPITAL AND RESERVES */}
        <div className="row pt-3">
          <div className="col-6 pr-0 pl-3 ">
            <label className="input_label m-0">
              <b>Share Capital and Reserves</b>
            </label>
          </div>
          <div className="col-3 pr-0 pl-3"></div>
          <div className="col-3 pr-0 pl-3"></div>
        </div>
        {/* Authorized share capital (No Financial Effect) */}
        <div className="row pt-3">
          <div className="col-6 pr-0 pl-3 ">
            <label className="input_label m-0">
              <b>Authorized share capital (No Financial Effect)</b>
            </label>
          </div>
          <div className="col-3 pr-0 pl-3"></div>
          <div className="col-3 pr-0 pl-3"></div>
        </div>

        {/* share capital */}
        <div className="row pt-3">
          <div className="col-6 pr-0 pl-3">
            <label className="input_label m-0">
              <b>Share Capital</b>
            </label>
          </div>
          <div className="col-3 pr-0 pl-3"></div>
          <div className="col-3 pr-0 pl-3"></div>
        </div>
        <div className="row pt-3">
          <div className="col-6 pr-0 pl-3">
            {this.props.statenewdata?.ShareCapital !== undefined
              ? this.props.statenewdata?.ShareCapital.map((val) => (
                  <div>{val.Title}</div>
                ))
              : null}
          </div>
          <div className="col-3 pr-0 pl-3">
            {this.props.stateolddata?.ShareCapital !== undefined
              ? this.props.stateolddata.ShareCapital.map((val) => (
                  <div>{val.Balance.toLocaleString()}</div>
                ))
              : null}
          </div>
          <div className="col-3 pr-0 pl-3">
            {this.props.statenewdata?.ShareCapital !== undefined
              ? this.props.statenewdata?.ShareCapital.map((val) => (
                  <div>{val.Balance?.toLocaleString()}</div>
                ))
              : null}
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-md-6 pr-0 pl-0">
            <label className="input_label m-0">
              <b>Retained Earning</b>
            </label>
          </div>
          <div className="col-md-3 pr-0 pl-0">
            {this.props.statealldata?.data?.OldProfit
              ? parseFloat(
                  this.props.statealldata.data.OldProfit
                ).toLocaleString()
              : 0}
          </div>
          <div className="col-md-3 pr-0 pl-0">
            {this.props.statealldata?.data?.NewProfit
              ? parseFloat(
                  this.props.statealldata.data.NewProfit
                ).toLocaleString()
              : 0}
          </div>
          <div className="col-md-6 pr-0 pl-0 text-center">
            <label className="input_label m-0">
              <b> Profit / Loss for the Period</b>
            </label>
          </div>
          <div className="col-md-3 pr-0 pl-0">
            {this.props.statealldata?.data?.OldProfit
              ? parseFloat(
                  this.props.statealldata.data.OldProfit
                ).toLocaleString()
              : 0}
          </div>
          <div className="col-md-3 pr-0 pl-0">
            {this.props.statealldata?.data?.NewProfit
              ? parseFloat(
                  this.props.statealldata.data.NewProfit
                ).toLocaleString()
              : 0}
          </div>
        </div>
        {/* non current laibilites */}
        <div className="row pt-3">
          <div className="col-6 pr-0 pl-3">
            <label className="input_label m-0">
              <b>Non Current Laibilites</b>
            </label>
          </div>
          <div className="col-3 pr-0 pl-3"></div>
          <div className="col-3 pr-0 pl-3"></div>
        </div>
        <div className="row pt-3">
          <div className="col-6 pr-0 pl-3">
            {this.props.statenewdata?.FixedLiabilities !== undefined
              ? this.props.statenewdata?.FixedLiabilities.map((val) => (
                  <div>{val.Title}</div>
                ))
              : null}
          </div>
          <div className="col-3 pr-0 pl-3">
            {this.props.stateolddata?.FixedLiabilities !== undefined
              ? this.props.stateolddata?.FixedLiabilities.map((val) => (
                  <div>{val.Balance.toLocaleString()}</div>
                ))
              : null}
          </div>
          <div className="col-3 pr-0 pl-3">
            {this.props.statenewdata?.FixedLiabilities !== undefined
              ? this.props.statenewdata?.FixedLiabilities.map((val) => (
                  <div>{val.Balance.toLocaleString()}</div>
                ))
              : null}
          </div>
        </div>
        {/*  current laibilites */}
        <div className="row pt-3">
          <div className="col-6 pr-0 pl-3">
            <label className="input_label m-0">
              <b>Current Laibilites</b>
            </label>
          </div>
          <div className="col-3 pr-0 pl-3"></div>
          <div className="col-3 pr-0 pl-3"></div>
        </div>
        <div className="row pt-3">
          <div className="col-6 pr-0 pl-3">
            {this.props.statenewdata?.CurrentLiabilities !== undefined
              ? this.props.statenewdata?.CurrentLiabilities.map((val) => (
                  <div>{val.Title}</div>
                ))
              : null}
          </div>
          <div className="col-3 pr-0 pl-3">
            {this.props.stateolddata?.CurrentLiabilities !== undefined
              ? this.props.stateolddata?.CurrentLiabilities.map((val) => (
                  <div>{val.Balance.toLocaleString()}</div>
                ))
              : null}
          </div>
          <div className="col-3 pr-0 pl-3">
            {this.props.statenewdata?.CurrentLiabilities !== undefined
              ? this.props.statenewdata?.CurrentLiabilities.map((val) => (
                  <div>{val.Balance?.toLocaleString()}</div>
                ))
              : null}
          </div>
        </div>
        {/* CONTINGENCIES AND COMMITMENTS */}
        <div className="row pt-3">
          <div className="col-6 pr-0 pl-3 ">
            <label className="input_label m-0">
              <b>Contingencies And Commitments</b>
            </label>
          </div>
          <div className="col-3 pr-0 pl-3">
            {parseFloat(
              this.props.stateolddata?.FixedLiabilitiesSum +
                this.props.stateolddata?.CurrentLiabilitiesSum
            ).toLocaleString()}
          </div>
          <div className="col-3 pr-0 pl-3">
            {parseFloat(
              this.props.statenewdata?.FixedLiabilitiesSum +
                this.props.statenewdata?.CurrentLiabilitiesSum
            ).toLocaleString()}
          </div>
        </div>
        {/* CONTINGENCIES AND COMMITMENTS */}
        <div className="row pt-3">
          <div className="col-6 pr-0 pl-3 ">
            <label className="input_label m-0">
              <b>Total Equities And Liabilities</b>
            </label>
          </div>
          <div className="col-3 pr-0 pl-3">
            {parseFloat(
              this.props.stateolddata?.FixedLiabilitiesSum +
                this.props.stateolddata?.CurrentLiabilitiesSum +
                this.props.stateolddata?.ShareCapitalSum +
                this.props.statealldata?.data.OldProfit
            ).toLocaleString()}
          </div>
          <div className="col-3 pr-0 pl-3">
            {parseFloat(
              this.props.statenewdata?.FixedLiabilitiesSum +
                // this.props.stateolddata?.FixedLiabilitiesSum +
                this.props.statenewdata?.CurrentLiabilitiesSum +
                // this.props.stateolddata?.CurrentLiabilitiesSum +
                this.props.statenewdata?.ShareCapitalSum +
                // this.props.stateolddata?.ShareCapitalSum +
                this.props.statealldata?.data.NewProfit
              // this.props.statealldata?.data.OldProfit
            ).toLocaleString()}
          </div>
        </div>
      </div>
    );
  }
}

export default BalanceSheetPrint;
